<template>
  <div class="wrapper greetings">
    <div v-if="HomeTrigger" class="container">
      <div class="home">
        <!--
        <div class="home-banner">
          <img src="../assets/images/thumb.jpg">
        </div>-->
        <div class="home-content">
          <h4 class="title">{{ text().home.title }}</h4>
          <p class="subtext">{{ text().home.text }}</p>
          <ul class="home-content-step_nav">
            <li v-for="(value, key) in text().home.stepNav">
              <span>{{ key + 1 }}</span>
              <span>{{ value }}</span>
            </li>
          </ul>
          <div class="home-button">
            <button class="bt_style" @click="StartButton()">{{ text().home.button }}</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="step1" class="steps">
        <div class="container pd-bot-none">
          <div class="title-step">
            <span class="step-number">{{ text().step1.step_number }}</span>
            <span class="title-st">{{ text().step1.title }}</span>
          </div>
          <!--<p class="subtext">{{text().step1.text}}</p>-->
        </div>
        <div class="container">
          <div class="slider">
            <div class="img_list">
              <agile>
                <div
                    v-for="(item, index) in this.sliderImages"
                    class="slide"
                    :data-id="item.id"
                    @click="thisImage(index, item.hash)"
                >
                  <div v-if="item.okTrigger" class="this_ok"></div>
                  <img :src="$root.globalUrl + 'uploads/templates/' + item.hash">
                  <!-- <img :src="item.link"> -->
                </div>
              </agile>
            </div>
          </div>
        </div>
        <div class="button-next">
          <button class="bt_style step1_bt" @click="nextStepText">{{ text().step1.button }}</button>
        </div>
      </div>
      <div v-if="step2" class="step2_container">
        <div class="container">
          <div class="title-step">
            <span class="step-number">{{ text().step2.step_number }}</span>
            <span class="title-st">{{ text().step2.title }}</span>
          </div>
          <div class="settings_text">
            <div id="printMe" class="img_user" ref="printMe">
              <img :src="this.userImg">

              <div class="text-content" id="small_font" :style="this.styleTemplate">
                <h3>{{ this.userText }}</h3>
              </div>
            </div>
            <div class="input-content">
              <div class="textarea-content">
                <input
                    class="type_txt"
                    type="text"
                    id="adroid-bug"
                    maxlength="22"
                    placeholder="Введіть ім'я"
                    @keyup="WriteText($event.target.value, $event)"
                >
                <span>{{ this.userText.length }}/{{ this.maxLenUserText }}</span>
              </div>
              <div class="bt-content">
                <button class="bt_style" v-html="text().step2.bt_back"
                        @click="backStep('step1')"></button>
                <button class="bt_style" v-html="text().step2.bt_next" @click="printImage()"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step3" v-if="step3">
        <div class="container">
          <div class="title-step">
            <span class="step-number">{{ text().step3.step_number }}</span>
            <span class="title-st">{{ text().step3.title }}</span>
          </div>
          <div class="img_user_out">
            <div class="us_content">
              <img :src="output">

              <div class="bt-content pdd_nw">
                <button class="bt_style pdd_nw" @click="backStep('step2')">{{ text().step3.bt_back }}
                </button>
                <button
                    :class="{blue_color: showModalWin}"
                    class="bt_style pdd_nw"
                    @click="showModal()"
                >{{ text().step3.bt_next }}
                </button>
                <transition name="fade">
                  <div v-if="showModalWin" class="btModal">
                    <div>
                      <button @click="Send('send')">{{ text().step3.send }}</button>
                    </div>
                    <div>
                      <button @click="Send('save')">{{ text().step3.save }}</button>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div v-if="this.showModalTrigger" class="save-modal-content">
            <div class="window-modal">
              <!--<div class="close" @click="closeModal()"></div>-->
              <div class="icon">
                <img v-if="this.triggerModal" src="../assets/img/send-icon.png">
                <img v-else src="../assets/img/save-icon.png">
              </div>
              <div class="message" v-if="this.triggerModal" v-html="this.messageModal"></div>
              <div class="button">
                <button class="bt_style modal_bt" @click="returnImg()">{{ this.saveTextModal.button }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="loading" v-if="isLoading">
      <img src="../assets/img/loading_pro.gif"/>
    </div>
    <div>
      <a download href="#" id="download-image"></a>
      <a id="viber_share" href></a>
    </div>

  </div>
</template>

<script>
import VueAgile from "vue-agile";
import axios from "axios";


export default {
  name: "Home",

  data: () => ({
    text() {
      return require(`../text-data/home.json`).ua;
    },
    messageModal: "",
    saveTextModal: {
      massage: "Вашу листівку<br>відправлено",
      button: "Створити ще одну листівку"
    },
    showModalTrigger: false,
    triggerModal: true,
    getValueInput: "",
    options: {
      arrows: false,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            dots: false
          }
        },

        {
          breakpoint: 900,
          settings: {
            arrows: true,
            dots: true,
            infinite: false
          }
        }
      ]
    },
    sliderImages: [
      {id: 11, hash: "12c3e621a32e0c5919f63b2d145f78d8"},
      {id: 12, hash: "ccf5ab4640dcd99ce447f4c473ce3937"},
      {id: 13, hash: "0bfcac81278c0454e218decadd8b0c1f"},
      {id: 14, hash: "cd25cd244eac93590d9909e1b5cb9af0"},
      {id: 15, hash: "bed5748ae68787d8eb6f69c73d3913ff"},
      {id: 16, hash: "ec504a86694c33dfb9594f89e6a00af6"},
      {id: 17, hash: "a8defbd8779ccf42700255b8136a0998"},
      {id: 18, hash: "a1c3fa40a0dba99bb8e09690d02a68e4"}
    ],
    getUser: {
      user: "fc09cab8844e0ba4ffb8424bf3d58cfa",
      dispatch: "a798b883aa5a7e6ccf3c08e0107255b1"
    },
    nextStepImagePath: "",
    isLoading: false,
    HomeTrigger: true,
    rememberID: 0,
    getTemplateId: 0,
    styleTemplate: "",
    getHashImage: "",
    step1: false,
    step2: false,
    step3: false,
    okImage: false,
    maxLenUserText: 22,
    userText: "",
    userImg: "",
    output: "",
    showModalWin: false,
    axiosData: {
      user: "98019027345126b59f7a1173a9d7543a",
      dispatch: "02879c78efa99ef404eccfb14ec17260"
    }
  }),


  methods: {

    getStyleTemp(id) {
      if (parseInt(id) == 1) {
        this.styleTemplate = 'font-family: "Lato-Medium"; text-transform: uppercase;     top: 3%; left: 6%; right: 6%; font-size: 4vw;';
      } else if (parseInt(id) == 2) {
        this.styleTemplate =
            'font-family: "Xarrovv.woff"; bottom: 87.5%; left: 10%; right: 10%; font-size: 7.6vw;';
      } else if (parseInt(id) == 3) {
        this.styleTemplate =
            'font-family: "Xarrovv.woff";     bottom: 91%; left: 5%; right: 5%; font-size: 5.5vw; color: #4A7D84;';
      } else if (parseInt(id) == 4) {
        this.styleTemplate =
            'font-family: "VogueHighlineSerif-Medium";  bottom: 90%; left: 5%; right: 5%; font-size: 5.5vw; color: #69686E;';
      } else if (parseInt(id) == 5) {
        this.styleTemplate =
            'font-family: "Lato-Medium";      bottom: 92%; left: 10%; right: 10%; font-size: 5vw;';
      } else if (parseInt(id) == 6) {
        this.styleTemplate =
            'font-family: "Lato-Medium"; text-transform: uppercase; bottom: 93%; left: 4%; right: 4%; font-size: 5vw; color: #76858C';
      }
    },
    getUserToken() {
      axios
          .get(this.$root.globalUrl + 'api/' + "v1/greetings/greetings")
          .then(response => {
            this.sliderImages = {};
            this.sliderImages = response.data.data;

            /*
        this.sliderImages.forEach(element => {
          document.getElementById('getError').innerText += '<img src="' + element.hash + '">';
        });*/
          })
          .catch(error => {
          });

      // axios
      //   .get(this.$root.globalUrl + 'api/' + "get_user")
      //   .then(response => {
      //     this.axiosData.user = response.data.userToken;
      //     this.axiosData.dispatch = response.data.dispatchToken;


      // })
      // .catch(error => {
      //
      // });
    },
    returnImg() {
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.showModalTrigger = false;
    },
    newSlide() {
    },
    stepActive(index) {
      if (this.step1 == true && index + 1 == 1) {
        return true;
      }
      if (this.step2 == true && index + 1 == 2) {
        return true;
      }
      if (this.step3 == true && index + 1 == 3) {
        return true;
      }
    },
    StartButton() {
      this.HomeTrigger = false;
      this.step1 = true;
    },

    nextStepText() {
      this.isLoading = true;
      this.userImg = document.getElementsByClassName(
          "agile__slide--active"
      )[0].children[0].src;
      this.getTemplateId = document.getElementsByClassName(
          "agile__slide--active"
      )[0].dataset["id"]; //getTemplateId

      this.axiosData.id = this.getTemplateId;
      this.getStyleTemp(this.axiosData.id);
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");


      axios
          .post(this.$root.globalUrl + 'api/' + "v1/action/", {
            "name": "Выбор шаблона",
            "data": {
              "template_id": this.getTemplateId
            },
          })
          .then(response => {
            if (response.data.status == "success") {
              this.isLoading = false;
              this.step1 = false;
              this.step2 = true;
            }
          });
    },
    thisImage(imgName, index, testUrl) {
      /*
      for (let key in this.sliderImages) {
        this.sliderImages[key].okTrigger = false;
      }
      this.sliderImages[index].okTrigger = true;
      this.nextStepImagePath = this.sliderImages[index].link;

      this.okImage = true;

      this.userImg = testUrl;
      */
    },
    WriteText(val, event) {
      //event.preventDefault();
      let AndroidBug = document.getElementById("adroid-bug");
      let txtAnd = "";

      if (
          parseInt(AndroidBug.value.length) - 1 <
          parseInt(this.maxLenUserText)
      ) {
        this.userText = val.slice(0, val.length);
        this.getValueInput = this.userText;
        /*
        txtAnd = val;
        AndroidBug.value = '';
        AndroidBug.value = txtAnd; */
        if (parseInt(val.length) >= 17) {
          let getEl = (document.getElementById("small_font").style.fontSize =
              "3.8vw");
        } else {
          let getEl = (document.getElementById("small_font").style.fontSize =
              "5vw");
        }
      } else {
        //AndroidBug.value = '';

        //.slice(0, this.getValueInput.length);

        AndroidBug.value = AndroidBug.value.substring(0, this.maxLenUserText);

        return false;
      }
    },
    async printImage() {
      this.isLoading = true;
      const el = this.$refs.printMe;


      let options = {
        allowTaint: true,
        type: "dataURL"
      };

      this.output = await this.$html2canvas(el, options);


      /*
      if(this.rememberID != 0){
        this.axiosData.id = this.rememberID;
      }*/

      let myFormData = new FormData();
      let blob = this.dataURItoBlob(this.output);
      myFormData.append("picture", blob);


      axios.post(this.$root.globalUrl + 'api/' + "v1/greetings/greeting", myFormData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
          .then(response => {

            this.getHashImage = response.data.data.hash;
            let gturl = this.$root.globalUrl + 'uploads/greetings/';
            document
                .getElementById("download-image")
                .setAttribute("href", gturl + this.getHashImage);
            this.axiosData.userText = this.userText.trim();

            let data = false;
            if (this.userText === "") {
              data = false;
            } else {
              data = true;
            }

            axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

            axios.post(this.$root.globalUrl + 'api/' + "v1/action/", {
              "name": "Редактирование шаблона",
              "data": {
                "personalize": data,
                "userText": this.userText,
                "template_id": this.getTemplateId
              }
            })
                .then(response => {
                  if (response.data.status == "success") {
                    this.isLoading = false;
                    this.step2 = false;
                    this.step3 = true;
                  }
                });

          });

    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      let byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      let mimeString = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      let arrayBuffer = new ArrayBuffer(byteString.length);
      let _ia = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }

      let dataView = new DataView(arrayBuffer);
      let blob = new Blob([dataView], {type: mimeString});
      return blob;
    },
    backStep(backTo) {

      if (backTo == "step1") {
        this.isLoading = true;
        this.userText = '';
        //delete this.axiosData.id;

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

        axios
            .post(this.$root.globalUrl + 'api/' + "v1/action/", {
              "name": "Выбор другого шаблона",
              "data": null
            })
            .then(response => {
              if (response.data.status == "success") {
                this.isLoading = false;
                this.step1 = true;
                this.step2 = false;
              }
            });
      } else if (backTo == "step2") {
        //this.rememberID = 0;
        this.isLoading = true;
        this.rememberID = this.axiosData.id;
        //delete this.axiosData.id;
        //delete this.axiosData.userText;


        //axios.delete(this.$root.globalUrl + 'api/' + 'delete_image', {hash: this.getHashImage}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        //if(response.data.success == true){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

        axios
            .post(this.$root.globalUrl + 'api/' + "v1/action/", {
              "name": "Перередактирование шаблона",
              "data": {
                "template_id": this.getTemplateId

              },
            })
            .then(response => {
              if (response.data.status == "success") {
                this.isLoading = false;
                this.step3 = false;
                this.step2 = true;
              }
            });
        //}

        //})
      }
    },
    showModal() {
      if (this.showModalWin == false) {
        this.showModalWin = true;
      } else {
        this.showModalWin = false;
      }
    },
    closeModal() {
      this.showModalTrigger = false;
    },
    Send(status) {
      this.isLoading = true;
      if (status == "send") {
        this.axiosData.sendType = "viber";

        let geturl = this.$root.globalUrl + 'uploads/greetings/';

        this.messageModal = "Вашу листівку<br>відправлено";
        document
            .getElementById("viber_share")
            .setAttribute(
                "href",
                "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
                encodeURIComponent(
                    "viber://forward?text=" +
                    encodeURIComponent(geturl + this.getHashImage)
                )
            );
        document.getElementById("viber_share").click();
      } else if (status == "save") {
        this.axiosData.sendType = "gallery";
        this.messageModal = "Вашу листівку<br>збережено в галереї";
        document.getElementById("download-image").click();
      }


      let data = false;
      if (this.userText === "") {
        data = false;
      } else {
        data = true;
      }
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios
          .post(this.$root.globalUrl + 'api/' + "v1/action/", {
            "name": "Тип сохранения шабона",
            "data": {
              "personalize": data,
              "template_id": this.getTemplateId,
              "save_type": this.axiosData.sendType,

            },
          })
          .then(response => {
            this.isLoading = false;
            this.saveTextModal.massage = "";
            this.triggerModal = true;
            this.showModalWin = false;
          });
      this.showModalTrigger = true;
    }
  },
  created() {
    //alert('width: ' + window.innerWidth + "; height: " + window.innerHeight)
    this.getUserToken();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss'>
@font-face {
  font-family: "AireBoldItalicStd";
  src: url("../assets/fonts/AireBoldItalicStd.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "ComicNefors";
  src: url("../assets/fonts/ComicNefors.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "GothamPro-Light";
  src: url("../assets/fonts/GothamPro-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "GothamPro-Medium";
  src: url("../assets/fonts/GothamPro-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Lato-Medium";
  src: url("../assets/fonts/Lato-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Xarrovv.woff";
  src: url("../assets/fonts/Xarrovv.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "VogueHighlineSerif-Medium";
  src: url("../assets/fonts/VogueHighlineSerif-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../assets/fonts/Roboto-Black.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../assets/fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto-LightItalic";
  src: url("../assets/fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../assets/fonts/Roboto-Thin.woff") format("woff");
}

.greetings {
  button {
    border: 0;
  }

  .home-button,
  .button-next {
    button {
      margin: auto;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }

  &.wrapper {
    min-width: 320px;
    background: #d5eaf8;
    font-family: "Roboto-Thin";
    max-width: 768px;
    background: #fff;
    margin: auto;

    .steps {
      background: url(../assets/img/bg_shadow.png) center top no-repeat #d5eaf8;
      background-size: 100%;
      min-height: 100vh;
    }

    .pd-top-none {
      padding-top: 0;
    }

    .pd-bot-none {
      padding-bottom: 0;
    }
  }

  .container {
    /*padding: 40px;*/
  }

  .title {
    font-size: 28px;
    color: #233f85;
    text-align: center;
    margin: 0;
    font-family: "Roboto-Bold";
  }

  .home {
    color: #233f85;
    padding: 0.1px;
    background: url(../assets/img/bg_home.png) center 0 no-repeat #9ac9e3;
    background-size: cover;
    min-height: 100vh;
    margin-top: 0;

    &-banner {
      img {
        width: 100%;
      }
    }

    &-content {
      margin-top: 88%;
      padding: 0 16px;

      > p {
        text-align: center;
        margin-top: 5px;
      }

      &-step_nav {
        display: flex;
        justify-content: space-between;
        padding-bottom: 45px;

        > li {
          list-style: none;
          padding-bottom: 25px;
          padding: 0 3%;
          position: relative;

          &::before {
            content: "";
            width: 26px;
            height: 16px;
            background: url(../assets/img/arrow_home.png) center center no-repeat;
            background-size: 100%;
            display: block;
            position: absolute;
            right: -5vw;
            top: 18px;
          }

          > span:first-child {
            font-weight: bold;
            position: relative;
            z-index: 2;

            width: 50px;
            height: 50px;
            padding-top: 5px;
            display: block;

            font-size: 29px;
            color: #29ade5;
            font-family: "Roboto-Bold";
            margin: auto;
            text-align: center;
            border-radius: 50%;
            border: 3px solid rgba(255, 255, 255, 0.5);
          }

          > span:last-child {
            padding-top: 7px;
            display: block;
            text-align: center;
          }
        }

        > li:last-child {
          &::before {
            display: none;
          }
        }
      }
    }

    &-button {
      padding-bottom: 5%;

      button {
        min-width: 220px;
      }
    }
  }

  .step-header {
    position: relative;

    .line {
      position: absolute;
      height: 1px;
      background: #fff;
      width: 85%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    padding: 25px 6.7%;
    background: #ccc;

    ul {
      display: flex;
      justify-content: space-between;

      li.active {
        span {
          color: #fff;
          background: #000;
        }
      }

      li {
        display: inline-block;
        vertical-align: top;
        position: relative;

        span {
          position: relative;
          z-index: 2;
          display: block;
          width: 30px;
          height: 30px;
          text-align: center;
          background: #fff;
          padding-top: 6px;
          border-radius: 50%;
        }
      }

      li:last-child {
        padding: 0;
      }
    }
  }

  .slider {
    position: relative;
    width: 90%;
    margin: auto;
    margin-top: 5%;

    .agile {
      /*
      background: url(../assets/images/shadow.png) center center no-repeat;
      background-size: cover;
      */
    }

    .agile__list {
      box-shadow: 0 20px 70px 1px rgba(37, 66, 139, 0.3);
    }

    .slide {
      position: relative;
      /*max-height: 396px;*/
      > img {
        width: 100%;
        display: block;
      }
    }

    .agile__nav-button--prev {
      left: -5.5%;
      background: url(../assets/img/left_arr.png) center center no-repeat;
      background-size: 100%;

      font-size: 0;


      ::v-deep svg {
        display: none;
      }
    }

    .agile__nav-button--next {
      right: -5.5%;
      font-size: 0;
      background: url(../assets/img/right_arr.png) center center no-repeat;
      background-size: 100%;

      ::v-deep svg {
        display: none;
      }
    }

    .agile__list {
    }

    .agile__nav-button {
      border: 0;
      position: absolute;
      width: 12%;
      height: 15%;
      top: 30%;
      bottom: 0;
      margin: auto;
    }

    .agile__dot {
      button {
        border: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: block;
        margin-right: 10px;
        background: #dbe5ef;
      }
    }

    .agile__dots {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      margin-top: 5%;
      margin-bottom: 8%;
      position: relative;
    }

    .agile__dot--current {
      button {
        background: #294298;
      }
    }
  }

  .button-next {
    padding: 0 40px;
    text-align: center;

    button {
      width: 100%;
    }
  }

  .opecity_img {
    opacity: 0.5;
  }

  .this_ok {
    width: 45px;
    height: 45px;
    background: url(../assets/img/slider/ok-icon.png) center center no-repeat;
    background-size: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .img_user {
    position: relative;
    box-shadow: 0 20px 70px 1px rgba(37, 66, 139, 0.3);

    img {
      width: 100%;
      display: block;
    }

    .text-content {
      z-index: 10;
      position: absolute;

      color: #fff;
      /*padding: 20px 0;*/
      h3 {
        text-align: center;
        font-weight: normal;
        margin: 0;

        @media (min-width: 768px) {
          /*font-size: 4.6vw;*/
          font-size: 30px;
          //margin-top: 25px;

        }
      }

      @media (min-width: 768px) {
        //padding: 20px 0;
      }
    }
  }

  .input-content {
    .type_txt {
      font-family: "Roboto-LightItalic";
      resize: none;
      width: 100%;
      border: 0;
      background: none;
      padding: 3px 0;
      height: 30px;
      font-size: 18px;
      color: #294790;
      border-bottom: 1px solid #294790;
    }
  }

  .bt-content {
    justify-content: space-between;
    display: flex;
    margin-top: 3%;

    > button {
      width: 45%;
      padding: 5px 15px;
      font-size: 12px;
    }
  }

  .btModal {
    position: absolute;
    top: -100%;
    width: 100%;

    background: #00a6e0;
    padding: 0 5%;

    button {
      background: none;
      width: 100%;
      text-align: left;
      color: #fff;
      font-size: 12px;
      padding: 7% 0;
      font-weight: bold;
      font-family: "Roboto-Light";
    }

    button:first-child {
      border-bottom: 1px solid #56c0e9;
    }

    &::after {
      border: 20px solid transparent;
      border-top: 20px solid #00a6e0;
      content: "";
      position: absolute;
      bottom: -35px;
      right: 17%;
    }
  }

  .bt_style {
    /*margin: auto;*/
    display: block;
    border: 0;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-family: "Roboto-Bold";
    padding: 12px 0;
    background: #00ace6;
    border-radius: 12px;
  }

  .title-step {
    padding-top: 4%;
    color: #2b4991;
    display: flex;

    .step-number {
      font-family: "Roboto-Bold";
      background: url(../assets/img/step_icon.png) left center no-repeat;
      background-size: 100%;
      font-size: 10px;
      min-width: 75px;
      text-align: center;
      display: inline-block;
      padding: 10px 0;
      text-transform: uppercase;
    }

    .title-st {
      font-size: 18px;
      height: 33px;
      padding-top: 5px;
      display: inline-block;
      padding-left: 10px;
    }
  }

  .step1_bt {
    max-width: 172px;
  }

  .step2_container {
    background: url(../assets/img/bg_shadow.png) center top no-repeat #d5eaf8;
    background-size: 100%;
    min-height: 100vh;

    .settings_text {
      width: 90.5%;
      margin: auto;
    }

    .title-step {
      margin-bottom: 3%;
    }

    .input-content {
      h5 {
        margin: 3% 0;
        margin-bottom: 1%;
        font-size: 10px;
        color: #294790;
      }
    }

    .textarea-content {
      margin-top: 3%;
      position: relative;

      span {
        position: absolute;
        right: 0;
        top: 0;
        color: #294790;
      }
    }
  }

  .step3 {
    background: url(../assets/img/bg_shadow.png) center top no-repeat #d5eaf8;
    background-size: 100%;

    .title-step {
      margin-bottom: 3%;
    }

    .img_user_out {
      position: relative;

      img {
        width: 100%;
        box-shadow: 0 20px 70px 1px rgba(37, 66, 139, 0.3);
      }

      .text-content {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        padding: 20px;
      }
    }

    min-height: 100vh;
    /*background: #D5EAF8;*/
    .container {
      min-height: 100vh;
    }

    .us_content {
      width: 90.5%;
      margin: auto;
    }

    .pdd_nw {
      padding: 5% 0;
      margin-top: 6%;
      position: relative;
    }

    .bt-content {
      > button {
        transition: all 0.3s;
      }

      > button:first-child {
        background: url(../assets/img/button-arrow-left.png) 12% center no-repeat #00a6e0;
        background-size: 8px;
        padding-left: 5%;
      }

      > button:last-child {
        background: url(../assets/img/button-arrow-right.png) 84% center no-repeat #00a6e0;
        background-size: 8px;
        padding-right: 5%;
      }
    }
  }

  .blue_color {
    background: url(../assets/img/button-arrow-right.png) 84% center no-repeat #1e3b89;
    background-size: 8px;
    padding-right: 5% !important;
  }

  .save-modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 59, 137, 0.4);
    z-index: 3;
    @media screen and (min-width: 426px) {
      position: absolute;
    }

    .window-modal {
      width: 90.5%;
      position: absolute;
      height: 230px;
      margin: auto;
      left: 0;
      right: 0;
      background: #d7ebf8;
      top: 20%;
      @media (min-width: 768px) {
        width: 350px;
      }
    }

    .close {
      width: 25px;
      height: 25px;
      background: url(../assets/img/close-icon.png) center center no-repeat;
      background-size: 18px 19px;
      position: absolute;
      right: 10px;
      top: 7px;
    }

    .icon {
      margin-top: 30px;
      text-align: center;
    }

    .message {
      color: #0678c0;
      font-size: 18px;
      text-align: center;
      margin-top: 25px;
    }

    .modal_bt {
      min-width: 200px;
      font-size: 11px;
      margin: auto;
      margin-top: 25px;
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9;
    @media screen and (min-width: 426px) {
      position: absolute;
    }

    img {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #294790;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #294790;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #294790;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #294790;
  }
}

</style>
