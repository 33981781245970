<template>
    <div class="product-popup">
        <div class="content">
            <div class="detail-title">
                <div class="name">{{product.name}}</div>
                <div class="close" @click="$emit('closeDetail')">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.258301 0.258301L18.7418 18.7418" stroke="#8F67A4" stroke-width="1.7" stroke-miterlimit="10"/>
                        <path d="M18.7418 0.258301L0.258301 18.7418" stroke="#8F67A4" stroke-width="1.7" stroke-miterlimit="10"/>
                    </svg>
                </div>
            </div>

            <div class="gallery">
                <div :class="product.images.length > 1 ? 'image' : 'image one'">
                    <div class="left arrow" v-if="product.images.length > 1" @click="decrementIndex()">
                        <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8403 19.7418L1.09863 10.0001" :stroke="currentImgIndex === 0 ? '#AC98C5' : '#8F67A4'" stroke-width="1.7" stroke-miterlimit="10"/>
                            <path d="M1.00004 11.0985L10.8403 1.25823" :stroke="currentImgIndex === 0 ? '#AC98C5' : '#8F67A4'" stroke-width="1.7" stroke-miterlimit="10"/>
                        </svg>
                    </div>

                    <div class="note" v-if="product.note != null">
                        {{product.note}}
                    </div>

                    <img :src="$root.globalUrl + product.images[currentImgIndex].path" :width="product.images[currentImgIndex].width" alt="">
                        <!-- <img src="../../../assets/images/products/kubitan-156x149.jpg" :width="product.images[currentImgIndex].width" alt=""> -->

                    <div class="right arrow" v-if="product.images.length > 1" @click="incrementIndex()">
                        <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.07971 1.25824L10.8214 10.9999" :stroke="currentImgIndex === product.images.length - 1 ? '#AC98C5' : '#8F67A4'" stroke-width="1.7" stroke-miterlimit="10"/>
                            <path d="M10.92 9.90149L1.07971 19.7418" :stroke="currentImgIndex === product.images.length - 1 ? '#AC98C5' : '#8F67A4'" stroke-width="1.7" stroke-miterlimit="10"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div class="information">
                <div class="row" v-for="(p,index) in product.properties" :key="index">
                    <div class="left">{{p.name}}</div>
                    <div class="right" v-html="p.value"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['product'],
    data : () => ({
        currentImgIndex : 0
    }),
    created(){
        this.$nextTick(() => {
        if(window.screen.width > 426){
            const scrollPosition = window.pageYOffset;
            let element = document.getElementsByClassName('content');
            element[0].style.top = 50 + scrollPosition + 'px';
        }
        })
    },
    methods : {
        decrementIndex(){
            if(this.currentImgIndex > 0){
                this.currentImgIndex--;
            }
            else{
                return false;
            }
        },
        incrementIndex(){
            if(this.currentImgIndex < this.product.images.length - 1){
                this.currentImgIndex++;
            }
            else{
                return false;
            }
        }
    }
}
</script>

<style lang="scss">
.product-popup{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100%;
    height: 100%;

    background: rgba(115, 89, 130, 0.6);
    padding: 30px 0;

    overflow: scroll;

    @media screen and (min-width: 426px) {
        position: absolute;
        display: flex;
        align-items: center;
    }

    .content{
        background: #FFFFFF;
        border-radius: 4px;
        margin: 50px 15px 0;
        padding: 0;

        display: flex;
        flex-direction: column;

        @media screen and (min-width: 426px) {
            position: absolute;
            left: 0;
            right: 0;
            top: 50px;

            max-width: 95%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .detail-title{
            background: #F1EDF7;
            padding: 20px 15px;
            width: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            .name{
                font-weight: bold;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.11em;
                text-transform: uppercase;
                color: #AC98C5;
                padding-right: 40px;
            }

            .close {
                cursor: pointer;
            }
        }

        .gallery{
            width: 100%;
            height: 141px;

            display: flex;
            align-items: flex-end;

            .fade-enter-active, .fade-leave-active {
                transition: opacity .5s;
            }


            .image{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                height: 100%;

                padding: 0 15px;

                position: relative;

                .note{
                    position: absolute;
                    right: 40px;
                    top: 11px;
                    background: rgba(172, 152, 197, 0.3);

                    font-weight: 600;
                    font-size: 12px;
                    line-height: 130%;
                    color: #8F67A4;

                    padding: 1px 4px;
                    border-radius: 10px;
                }
            }

            .one{
                justify-content: center;
            }
        }


        .information{
            width: 100%;
            padding: 10px 15px;

            .row{
                display: flex;
                flex-direction: row;
                border-top: 1px solid rgba(172, 152, 197, 0.2);

                padding: 7px 0 12px;
                font-size: 16px;
                line-height: 22px;
                color: #4A2A80;

                align-items: center;


                .left{
                    width: 35%;
                }

                .right{
                    padding-left: 15%;
                }
            }
        }
    }
}
</style>
