<template>
  <div class="registration-clinical">
    <div class="registration-block">

      <p class="h1-title">
        <span>Реєстрація</span>
        <span @click="backArrow()" v-if="getRegistrationStage=== '2'">
                    <svg width="11" height="20"
                         viewBox="0 0 11 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.44319 9.99948L10.4755 0.96715C10.6977 0.74501 10.6977 0.388746 10.4755 0.166606C10.2534 -0.0555352 9.89711 -0.0555352 9.67497 0.166606L0.240275 9.6013C0.0181342 9.82344 0.0181342 10.1797 0.240275 10.4018L9.67497 19.8323C9.78394 19.9413 9.93064 20 10.0731 20C10.2157 20 10.3623 19.9455 10.4713 19.8323C10.6935 19.6102 10.6935 19.2539 10.4713 19.0318L1.44319 9.99948Z"
                        fill="#4A2A80"/>
                    </svg>
                </span>
        <span @click="backToLogin()" v-else>
                    <svg width="11" height="20"
                         viewBox="0 0 11 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.44319 9.99948L10.4755 0.96715C10.6977 0.74501 10.6977 0.388746 10.4755 0.166606C10.2534 -0.0555352 9.89711 -0.0555352 9.67497 0.166606L0.240275 9.6013C0.0181342 9.82344 0.0181342 10.1797 0.240275 10.4018L9.67497 19.8323C9.78394 19.9413 9.93064 20 10.0731 20C10.2157 20 10.3623 19.9455 10.4713 19.8323C10.6935 19.6102 10.6935 19.2539 10.4713 19.0318L1.44319 9.99948Z"
                        fill="#4A2A80"/>
                    </svg>
                </span>
      </p>

      <!--stage1 registration-->

      <!-- <div class="stage1" v-if="registrationStageValue === '1'"
           v-bind:class="[errorStage === true ? errorClass: '', ``]"> -->

      <div class="stage1" v-if="getRegistrationStage === '1'">

        <div class="phone-wrapper padding-all active" v-bind:class="[errorStage.phone === true ? errorClass: '', ``]">
          <the-mask class="required" :mask="['+38 (0##) ### ## ##']" v-model="phone" required/>
          <span class="reg-text">Телефон<span class="star">*</span></span>
        </div>

        <div class="name-wrapper padding-all"
             v-bind:class="[name !== '' ? activeClass: '', ``, errorStage.name === true ? errorClass: '', ``]">
          <input class="required" type="text" v-model="name">
          <span class="reg-text">Ім’я<span class="star">*</span></span>
        </div>

        <div class="middle_name-wrapper padding-all"
             v-bind:class="[middleName !== '' ? activeClass: '', ``]">
          <input type="text" v-model="middleName">
          <span class="reg-text">По батькові</span>
        </div>

        <div class="surname-wrapper padding-all"
             v-bind:class="[surname !== '' ? activeClass: '', ``, errorStage.surname === true ? errorClass: '', ``]">
          <input class="required" type="text" v-model="surname">
          <span class="reg-text">Прізвище<span class="star">*</span></span>
        </div>

        <div class="gender-wrapper" v-bind:class="[errorStage.gender === true ? errorClass: '', ``]">
          <h3>Стать<span class="star">*</span></h3>

          <div class="input-wrapper">

            <label class="container">
              <input name="gander" type="radio" v-model="gender" :value="`true`">
              <span class="checkmark"></span>
              <p>Чоловіча</p>
            </label>

            <label class="container">
              <input name="gander" type="radio" v-model="gender" :value="`false`">
              <span class="checkmark"></span>
              <p>Жіноча</p>
            </label>
          </div>

        </div>

        <div class="region-wrapper">
          <div class="areas">
            <div
                v-bind:class="[isOpenDrop === true ? activeClass: '', `select`, , errorStage.areasField === true ? errorClass: '', ``]">
              <div class="visible-item"
                   @click="openDropList()">

                <div class="border required">
                  <p v-html="areasField"></p>
                  <div class="arrow-icon">
                    <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path d="M17.5666 0.413574L9.00002 8.58661L0.433411 0.413574"
                              stroke="#AC98C5"
                              stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="18" height="9" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>

              <div class="hide-item-list">
                <p v-bind:class="[selectAreas === key.id ? activeClass: '', ``]"
                   @click="selectRubricF(key.id, key.title)"
                   v-for="(key, value) in areas">
                  {{ key.title }}
                  <span class="icon-check">
                                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9946 2L6.48911 9.49411L3.01253 6.02985L2 5.01178"
                                                  stroke="#AC98C5" stroke-width="3"
                                                  stroke-miterlimit="10"/>
                                        </svg>
                                    </span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="city-wrapper padding-all"
             v-bind:class="[city !== '' ? activeClass: '', ``]">
          <input type="text" v-model="city">
          <span class="reg-text">Населений пункт</span>
        </div>

        <div class="footnotes">
          <span>*</span>
          <p>Всі поля, позначені зірочкою,
            обов'язкові для заповнення</p>
        </div>

        <div class="btn-wrapper" @click="nextStage()">
          <div class="next-btn">
            <p>Далі</p>
          </div>
        </div>

      </div>


      <!--stage2 registration-->
      <div class="stage2" v-if="getRegistrationStage === '2'">

        <div class="day-wrapper padding-all"
             v-bind:class="[bornData.day !== '' ? activeClass: '', ``]">
          <the-mask :mask="['##']" v-model="bornData.day"></the-mask>
          <span class="reg-text">День народження</span>
        </div>

        <div class="bornData">

          <div class="month-block">
            <div class="month-wrapper">
              <div class="month">
                <div v-bind:class="[isOpenDrop2 === true ? activeClass: '', `select`]">
                  <div class="visible-item"
                       @click="openDropList2()">

                    <div class="border">
                      <p>{{ monthField }}</p>
                      <div class="arrow-icon">
                        <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path d="M17.5666 0.413574L9.00002 8.58661L0.433411 0.413574"
                                  stroke="#AC98C5"
                                  stroke-width="1.7" stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="18" height="9" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>

                  </div>

                  <div class="hide-item-list">
                    <p v-bind:class="[selectMonth === value ? activeClass: '', ``]"
                       @click="selectMonthF(value, key.name, key.id)"
                       v-for="(key, value) in month">
                      {{ key.name }}
                      <span class="icon-check">
                                                <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.9946 2L6.48911 9.49411L3.01253 6.02985L2 5.01178"
                                                          stroke="#AC98C5" stroke-width="3" stroke-miterlimit="10"/>
                                                </svg>
                                            </span>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="year-block" v-bind:class="[bornData.year !== '' ? activeClass: '', ``]">
            <the-mask :mask="['####']" v-model="bornData.year"></the-mask>
            <span class="reg-text">Рік</span>
          </div>

        </div>

        <div class="profession">
          <div
              v-bind:class="[isOpenDrop3 === true ? activeClass: '', `select`, errorStage.professionField === true ? errorClass: '', ``]">
            <div class="visible-item"
                 @click="openDropList3()">

              <div class="border">
                <p v-html="professionField"></p>
                <div class="arrow-icon">
                  <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M17.5666 0.413574L9.00002 8.58661L0.433411 0.413574"
                            stroke="#AC98C5"
                            stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="18" height="9" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>

            </div>

            <div class="hide-item-list">
              <p v-bind:class="[selectProfession === key.id ? activeClass: '', ``]"
                 @click="selectProfessionF(key.id, key.title)"
                 v-for="(key, value) in profession">
                {{ key.title }}
                <span class="icon-check">
                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.9946 2L6.48911 9.49411L3.01253 6.02985L2 5.01178" stroke="#AC98C5"
                                              stroke-width="3"
                                              stroke-miterlimit="10"/>
                                    </svg>
                                </span>
              </p>
            </div>

          </div>
        </div>

        <div class="work-place-wrapper padding-all"
             v-bind:class="[workPlace !== '' ? activeClass: '', ``]">
          <input type="text" v-model="workPlace">
          <span class="reg-text">Місце роботи</span>
        </div>

        <div class="email-wrapper padding-all"
             v-bind:class="[email !== '' ? activeClass: '', ``]">
          <input type="email" v-model="email">
          <span class="reg-text">E-mail</span>
        </div>

        <div class="footnotes">
          <span>*</span>
          <p>Всі поля, позначені зірочкою,
            обов'язкові для заповнення</p>
        </div>

        <div class="agree-wrapper" v-bind:class="[errorStage.canSendData === true ? errorClass: '', ``]">
          <label class="container">
            <input type="checkbox" v-model="canSendData">
            <span class="checkmark"></span>
          </label>

          <p class="link">Даю згоду на <span @click="openPopUpFromPopUp()">обробку персональних даних</span>
          </p>
        </div>

        <div class="error-message" v-if="errorMessagePhone === true">
          <p>
            Цей номер телефону вже зареєстрований.
          </p>
        </div>

        <div class="btn-wrapper" @click="sendData()">
          <div class="next-btn">
            <p>Зареєструватися</p>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import axios from "axios"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "registration",

  computed: {
    ...mapGetters(['getRegistrationStage', 'getMenuCtrl'])
  },

  data: () => ({

    errorClass: "error",
    errorStage: {
      phone: false,
      gender: false,
      name: false,
      surname: false,
      areasField: false,
      professionField: false,
      canSendData: false
    },
    errorMessagePhone: false,

    // stage 1
    phone: "380",
    name: "",
    middleName: "",
    surname: "",
    city: "",

    gender: null,

    // stage2
    bornData: {
      day: "",
      month: "",
      year: "",
    },

    workPlace: "",
    email: "",

    activeClass: "active",

    //dropList1
    areasField: "Область<span class='star'>*</span>",
    selectAreas: null,
    isOpenDrop: false,

    //dropList1
    monthField: "Місяць",
    monthFieldNumber: "",
    selectMonth: null,
    isOpenDrop2: false,

    //dropList3
    professionField: "Спеціальність<span class='star'>*</span>",
    selectProfession: null,
    isOpenDrop3: false,

    canSendData: false,
    sendDataDate: "",

    profession: null,
    areas: null,
    month: [
      {
        name: "Січень",
        id: "01",
      }, {
        name: "Лютий",
        id: "02",
      }, {
        name: "Березень",
        id: "03",
      }, {
        name: "Квітень",
        id: "04",
      }, {
        name: "Травень",
        id: "05",
      }, {
        name: "Червень",
        id: "06",
      }, {
        name: "Липень",
        id: "07",
      }, {
        name: "Серпень",
        id: "08",
      }, {
        name: "Вересень",
        id: "09",
      }, {
        name: "Жовтень",
        id: "10",
      }, {
        name: "Листопад",
        id: "11",
      }, {
        name: "Грудень",
        id: "12",
      },

    ],
  }),

  created() {
    this.getArea();
    this.getSpeciality();
  },

  methods: {

    ...mapActions([
      'setRegistrationStage',
      'setMenuData',
      'setPrevPopup'
    ]),

    backToLogin() {
      this.setMenuData({popupState: '1'})
    },

    backArrow() {
      this.setRegistrationStage('1')

      this.phone = '380' + this.phone;
      this.errorMessagePhone = false;
    },

    getArea() {
      axios.get(this.$root.globalUrl + "api/v1/area").then((res) => {
        this.areas = JSON.parse(res.data);

        let array = this.areas.filter(object => {
          return object.id !== 26 && object.id !== 8;
        });

        this.areas = array.sort((a, b) => {
          return a.title.localeCompare(b.title)
        });

      })
    },

    getSpeciality() {
      axios.get(this.$root.globalUrl + "api/v1/speciality/clinical").then((res) => {
        this.profession = JSON.parse(res.data);
      })
    },

    selectRubricF(id, name) {
      this.selectAreas = id;
      this.areasField = name;
      this.isOpenDrop = false;
    },

    openDropList() {
      if (this.isOpenDrop !== true) {
        this.isOpenDrop = true;
      } else {
        this.isOpenDrop = false;
      }
    },

    selectMonthF(id, name, number) {
      this.selectMonth = id;
      this.monthField = name;
      this.monthFieldNumber = number;
      this.isOpenDrop2 = false;

    },

    openDropList2() {
      if (this.isOpenDrop2 !== true) {
        this.isOpenDrop2 = true;
      } else {
        this.isOpenDrop2 = false;
      }

    },

    selectProfessionF(id, name) {
      this.selectProfession = id;
      this.professionField = name;
      this.isOpenDrop3 = false;

    },

    openDropList3() {
      if (this.isOpenDrop3 !== true) {
        this.isOpenDrop3 = true;
      } else {
        this.isOpenDrop3 = false;
      }

    },
    clearErrorObj() {
      let obj = this.errorStage;

      Object.keys(obj).forEach(function (item) {
        obj[item] = false;
      });

      this.errorStage = obj;
    },
    nextStage() {

      this.clearErrorObj();

      if (this.phone.length === 12) {
        this.phone = this.phone.substring(3);
      }

      // if (this.gender !== null && this.phone.length === 9 && this.name !== "" && this.surname !== "" && this.areasField !== "" && this.areasField !== "Область*") {
      //     this.registrationStageValue = '2'
      // } else {
      //     this.errorStage = true;
      // }

      let isError = false;

      if (this.gender === null) {
        this.errorStage.gender = true;

        isError = true;
      }

      if (this.phone.length !== 9) {
        this.errorStage.phone = true;

        isError = true;
      }

      if (this.name === "") {
        this.errorStage.name = true;

        isError = true;
      }

      if (this.surname === "") {
        this.errorStage.surname = true;

        isError = true;
      }

      if (this.areasField === "" || this.areasField === "Область*") {
        this.errorStage.areasField = true;

        isError = true;
      }

      if (!isError) {
        this.setRegistrationStage('2')
      }

      if (this.gender === "false") {
        this.gender = false
      } else if (this.gender === "true") {
        this.gender = true
      }
    },

    openPopUpFromPopUp() {
      this.setPrevPopup(this.getMenuCtrl.popupState)
      this.setMenuData({popupState: '4'})
    },

    sendData() {
      this.clearErrorObj();

      let isError = false;


      if (this.professionField === "" || this.professionField === "Спеціальність*") {
        this.errorStage.professionField = true;

        isError = true;
      }

      if (this.canSendData !== true) {
        this.errorStage.canSendData = true;

        isError = true;
      }


      if (isError) {
        return false;
      }

      if (this.bornData.year !== "" && this.monthFieldNumber !== "" && this.bornData.day !== "") {
        this.sendDataDate = this.bornData.year + "-" + this.monthFieldNumber + "-" + this.bornData.day
      }

      axios.post(this.$root.globalUrl + "api/v1/security/register", {
        "phone_number": '380' + this.phone, //important
        "first_name": this.name, //important
        "middle_name": this.middleName,
        "last_name": this.surname, //important
        "area_id": this.selectAreas, //important
        "city": this.city,
        "speciality_id": this.selectProfession, //important
        "birth_date": this.sendDataDate,
        "organization": this.workPlace,
        "email": this.email,
        "gender": this.gender //important

      }).then((res) => {
        this.$router.go();
      }).catch((error, res) => {
        if ("That phone number is already registered." === error.response.data.message) {
          this.errorMessagePhone = true;
        } else {
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../style/vars";

.registration-clinical {
  overflow: auto;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(115, 89, 130, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 100;

  .star {
    color: #4A2A80 !important;
  }

  @media screen and (min-width: 426px) {
    position: absolute;
  }

  .registration-block {
    min-height: 562px;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    .stage1 {
      .error {
        .required {
          border-color: red !important;
        }

        .container {
          .checkmark {
            border-color: red !important;
          }
        }

      }
    }

    .stage2 {
      .error {
        .border {
          border-color: red !important;
        }

        .checkmark {
          border-color: red !important;
        }
      }
    }

    .h1-title {
      /*margin-bottom: 25px;*/
      border-radius: 4px 4px 0 0;
      padding: 0 14px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F1EDF7;

      span {
        font-size: 12px;
        font-family: $fontB;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #AC98C5;
        cursor: pointer;
      }

    }
  }

  .areas, .month, .profession {

    .select {

      transition: all 0.2s ease;

      .visible-item {
        cursor: pointer;

        .arrow-icon {
          transition: all 0.2s ease;
        }


        .border {
          padding: 10px 0;
          border-bottom: 1px solid rgba(172, 152, 197, 0.3);
          margin: 0 15px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        p {
          color: #AC98C5;
          font-size: 16px;
          font-family: $fontL;
        }


      }

      .hide-item-list {
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 1s ease;

        p {
          cursor: pointer;

          &:first-child {
            margin-top: 10px;
          }

          &:last-child {
            padding-bottom: 10px;
          }

          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #4A2A80;
          font-family: $fontL;
          font-size: 16px;
          padding-bottom: 30px;

          &:active, &.active {
            font-family: $fontSB;

            span {
              opacity: 1;
            }
          }

          span {
            transition: all 0.2s ease;
            opacity: 0;
          }
        }
      }

      &.active {
        background-color: #F1EDF7;

        .border {


          .arrow-icon {
            transform: rotate(180deg);
          }
        }

        .hide-item-list {
          max-height: 3000px;

        }
      }

    }
  }

  .padding-all {
    position: relative;
    margin: 0 15px 20px 15px;;

    &.phone-wrapper {
      margin-top: 30px;
    }

    input {
      background: none;
      position: relative;
      z-index: 2;
      color: #4A2A80;
      border: none;
      border-bottom: 1px solid rgba(172, 152, 197, 0.3);
      width: 100%;
      padding: 10px 0;

      &:focus {
        outline: none;
      }
    }

    &.active, &:hover, &:focus {
      .reg-text {
        top: -10px;
        font-size: 10px;
      }
    }

    .reg-text {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 8px;
      color: #AC98C5;
      font-size: 16px;
      font-family: $fontL;

      transition: all 0.2s ease;

      .star {
        color: #4A2A80;

      }

    }

    .month-block, .year-block {
      position: relative;
    }

    &.day-wrapper {
      margin-top: 30px;
    }
  }

  .visible-item {
    margin-bottom: 20px;
  }

  .footnotes {
    padding: 0 15px;
    display: flex;
    flex-direction: row;

    span {
      color: #4A2A80;
      font-family: $fontB;
      font-size: 16px;
    }

    p {
      padding-left: 15px;
      font-size: 16px;
      font-family: $fontL;
      color: #4A2A80;
    }
  }

  .btn-wrapper {
    margin: 20px 0 10px 0;
    padding: 0 15px;

    .next-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #8F67A4;
      border-radius: 4px;
      cursor: pointer;

      p {
        color: white;
        text-transform: uppercase;
        font-size: 15px;
        font-family: $fontB;
        letter-spacing: 2px;
      }
    }
  }

  .bornData {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: flex-start;

    .month-block {
      width: 50%;

      .visible-item {
        margin-bottom: 0 !important;
      }
    }

    .year-block {
      position: relative;
      padding: 0 15px 0 0;
      width: 50%;
      margin-left: 10px;

      input {
        background: none;
        position: relative;
        z-index: 2;
        color: #1E3B89;
        border: none;
        border-bottom: 1px solid #DAE3ED;
        width: 100%;
        padding: 14px 0 10px 0;

        &:focus {
          outline: none;
        }
      }

      &.active, &:focus, &:hover {
        .reg-text {
          top: -10px;
          font-size: 10px;
        }
      }

      .reg-text {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 10px;
        color: #93ABC3;
        font-size: 16px;
        font-family: $fontL;

        transition: all 0.2s ease;

        .star {
          color: #4a2a80;

        }

      }
    }
  }

  .profession {
    margin-bottom: 20px;

    .visible-item {
      margin-bottom: 0 !important;
    }
  }

  .agree-wrapper {
    margin-top: 30px;
    padding: 0 15px;

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      font-size: 16px;
      font-family: $fontL;
      color: #1E3B89;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .link {
      padding-left: 35px;
      color: #4A2A80;
      font-family: $fontL;
      font-size: 16px;
      cursor: pointer;

      span {
        color: #AC98C5;
        text-decoration: underline;

      }
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: white;
      border: 1px solid #8F67A4;
      cursor: pointer;
    }

    /* On mouse-over, add a grey background color */

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #8F67A4;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 2px;
      top: 0;
      width: 4px;
      height: 6px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .gender-wrapper {
    padding: 0 15px;
    height: 50px;

    h3 {
      font-size: 14px;
      font-family: $fontL;
      font-weight: normal;
      color: #AC98C5;
      margin-bottom: 5px;
    }

    .input-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    .container {
      margin-right: 20px;
      display: block;
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      font-size: 16px;
      font-family: $fontL;
      color: #AC98C5;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: white;
      border: 1px solid #AC98C5;
    }

    .container input:checked ~ .checkmark {
      background-color: #AC98C5;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      left: 2px;
      top: 0;
      width: 4px;
      height: 6px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .error-message {
    padding: 0 15px;
    margin-top: 15px;
    font-family: $fontR;
    color: red;

  }
}

</style>
