import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import topMenu from "./components/topMenu"
import info from "./components/info"
import questionPopup from "./components/question-popup"
import firstPopup from "./components/first-popup"
import personalData from "./components/personal-data"
import userInfo from "./components/user-info"
import personalDataLogin from './components/personal-data-login'
import registration from "./components/registration"
import login from "./components/login"
import blockFooter from './components/block-footer.vue';
import resources from './components/resources.vue';
import popupDietType1 from './components/popup-diet-type1';
import popupDietType2 from './components/popup-diet-type2';

import popupDietType3 from './components/popup-diet-type3';
import popupDietType4 from './components/popup-diet-type4';
import popupDietType5 from './components/popup-diet-type5';

import popupDietType6 from './components/popup-diet-type6';
import popupDietType7 from './components/popup-diet-type7';
import popupDietType8 from './components/popup-diet-type8';
import popupDietType9 from './components/popup-diet-type9';

import VueTheMask from 'vue-the-mask'
import VueHtml2Canvas from 'vue-html2canvas';
import VueAgile from 'vue-agile'
import './registerServiceWorker'

import 'vue-smooth-picker/dist/css/style.css'
import SmoothPicker from 'vue-smooth-picker'
import smoothscroll from 'smoothscroll-polyfill';

import axios from 'axios';

import HighchartsVue from 'highcharts-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(HighchartsVue)

smoothscroll.polyfill();

Vue.use(SmoothPicker);

Vue.use(VueAgile);
Vue.use(VueHtml2Canvas);
Vue.use(VueTheMask);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.component("popup-diet-type1", popupDietType1);
Vue.component("popup-diet-type2", popupDietType2);

Vue.component("popup-diet-type3", popupDietType3);
Vue.component("popup-diet-type4", popupDietType4);
Vue.component("popup-diet-type5", popupDietType5);
Vue.component("popup-diet-type6", popupDietType6);
Vue.component("popup-diet-type7", popupDietType7);
Vue.component("popup-diet-type8", popupDietType8);
Vue.component("popup-diet-type9", popupDietType9);

Vue.component("top-menu", topMenu);
Vue.component("info", info);
Vue.component("question-popup", questionPopup);
Vue.component("personal-data", personalData);
Vue.component("user-info", userInfo);
Vue.component("personal-data-login", personalDataLogin);
Vue.component("registration", registration);
Vue.component("login", login);
Vue.component("firstPopup", firstPopup);

Vue.component("footer-project", blockFooter);
Vue.component("resources", resources);

Vue.config.productionTip = false;

let URLPATH = '';
if (location.hostname.includes('localhost')) {
  URLPATH = 'https://hcp.nutricia.ua/'
  // URLPATH = 'https://hcp.inch.digital/'
} else {
  URLPATH = 'https://' + location.hostname + '/';
}

router.beforeEach((to, from, next) => {
  if (to.meta.menuPointName != null) {
    localStorage.setItem('active-page', to.meta.menuPointName);
  }

  axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");
  axios.post(URLPATH + "api/v1/statistic/visit", {
    "url": to.path
  })
      .then(res => {
      });


  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');


