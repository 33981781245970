<template>
  <div class="popup-diet-type6">

    <a id="viber_share" style="display: none;" href=""></a>

    <div class="content">
      <div class="popup-header">
        <p>Довідковий матеріал</p>
        <div @click="closeTrigger" class="close-btn">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M0.410156 0.257812L18.8937 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
              <path d="M18.8937 0.257812L0.410156 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect x="0.152344" width="19" height="19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div class="popup-info">


        <div class="padding-content">
          <div class="dropdown-block">
            <div class="title-text">
              <span>Постуральна терапія</span><br>
              при функціональних зригуваннях
            </div>
            <div class="triple-btn">
              <a @click="title='Постуральна терапія'; img='download-local/type6-1.png'; openImg = !openImg"
                 class="btn watch">
                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#00ACE6"/>
                    <path
                        d="M7.11162 22.3924L7.11161 22.3924C6.87608 22.7075 6.9005 23.1869 7.16854 23.4667L7.16854 23.4667C7.44143 23.7514 7.86026 23.7193 8.09931 23.3994C8.21323 23.247 8.32964 23.0951 8.44754 22.9439C11.4548 28.2615 16.8728 31.65 22.5 31.65C28.1272 31.65 33.5452 28.2615 36.5525 22.9439C36.6704 23.0951 36.7868 23.2471 36.9007 23.3994C37.139 23.7182 37.5577 23.7523 37.8315 23.4667L37.7954 23.4321L37.8315 23.4667C38.0995 23.187 38.1239 22.7075 37.8884 22.3924C34.0383 17.2422 28.3143 12.95 22.5 12.95C16.685 12.95 10.961 17.2433 7.11162 22.3924ZM22.5 30.1192C17.208 30.1192 12.1141 26.8764 9.37996 21.8073C10.4591 20.561 12.2322 18.737 14.4821 17.2206C16.7479 15.6935 19.494 14.4808 22.5 14.4808C25.506 14.4808 28.2521 15.6935 30.5179 17.2206C32.7678 18.737 34.5409 20.561 35.62 21.8072C32.8859 26.8764 27.792 30.1192 22.5 30.1192Z"
                        fill="white" stroke="white" stroke-width="0.1"/>
                    <circle cx="22.5007" cy="21.9772" r="4.31667" stroke="white" stroke-width="1.7"/>
                  </svg>


                </div>
                <div class="text">Дивитись</div>
              </a>
              <a :href="`${$root.globalUrl}download-local/type6-1.png`" download class="download-btn btn">
                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#00ACE6"/>
                    <path
                        d="M34.2837 22.2106C33.8858 22.2106 33.5675 22.5289 33.5675 22.9269V29.4421C33.5675 31.2142 32.1244 32.652 30.3576 32.652H14.6424C12.8703 32.652 11.4325 31.2089 11.4325 29.4421V22.8207C11.4325 22.4228 11.1142 22.1045 10.7163 22.1045C10.3183 22.1045 10 22.4228 10 22.8207V29.4421C10 32.0047 12.0851 34.0845 14.6424 34.0845H30.3576C32.9202 34.0845 35 31.9994 35 29.4421V22.9269C35 22.5342 34.6817 22.2106 34.2837 22.2106Z"
                        fill="white"/>
                    <path
                        d="M21.9959 26.397C22.1339 26.5349 22.3196 26.6092 22.5 26.6092C22.6804 26.6092 22.8661 26.5403 23.004 26.397L27.5562 21.8448C27.8374 21.5636 27.8374 21.1126 27.5562 20.8314C27.275 20.5502 26.824 20.5502 26.5428 20.8314L23.2162 24.1633V9.6313C23.2162 9.23338 22.8979 8.91504 22.5 8.91504C22.1021 8.91504 21.7837 9.23338 21.7837 9.6313V24.1633L18.4518 20.8314C18.1706 20.5502 17.7196 20.5502 17.4384 20.8314C17.1572 21.1126 17.1572 21.5636 17.4384 21.8448L21.9959 26.397Z"
                        fill="white"/>
                  </svg>


                </div>
                <div class="text">Зберегти</div>
              </a>

              <div class="share-btn btn" @click="sendToServerScreen('type6-1.png')">

                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#00ACE6"/>
                    <path
                        d="M28.8084 26.061C27.3705 26.061 26.0883 26.7463 25.2681 27.8052L18.4004 23.9691C18.5613 23.5071 18.6496 23.0087 18.6496 22.4948C18.6496 21.9757 18.5613 21.4826 18.3952 21.0154L25.2578 17.1844C26.0728 18.2485 27.3601 18.939 28.8032 18.939C31.2638 18.939 33.2727 16.9352 33.2727 14.4695C33.2727 12.0037 31.269 10 28.8032 10C26.3375 10 24.3338 12.0037 24.3338 14.4695C24.3338 14.9886 24.422 15.4869 24.5881 15.9489L17.7308 19.7799C16.9158 18.7105 15.6284 18.0253 14.1853 18.0253C11.7247 18.0253 9.71582 20.0291 9.71582 22.4948C9.71582 24.9605 11.7247 26.9643 14.1905 26.9643C15.6336 26.9643 16.921 26.2739 17.7412 25.2045L24.6037 29.0407C24.4376 29.5079 24.3441 30.0114 24.3441 30.5305C24.3441 32.9911 26.3479 35 28.8136 35C31.2794 35 33.2831 32.9963 33.2831 30.5305C33.2831 28.0648 31.2742 26.061 28.8084 26.061ZM28.8084 11.4068C30.5007 11.4068 31.8763 12.7824 31.8763 14.4747C31.8763 16.1669 30.5007 17.5426 28.8084 17.5426C27.1162 17.5426 25.7405 16.1669 25.7405 14.4747C25.7405 12.7824 27.1213 11.4068 28.8084 11.4068ZM14.1905 25.5627C12.4982 25.5627 11.1226 24.1871 11.1226 22.4948C11.1226 20.8025 12.4982 19.4269 14.1905 19.4269C15.8828 19.4269 17.2584 20.8025 17.2584 22.4948C17.2584 24.1871 15.8776 25.5627 14.1905 25.5627ZM28.8084 33.5932C27.1162 33.5932 25.7405 32.2176 25.7405 30.5253C25.7405 28.8331 27.1162 27.4574 28.8084 27.4574C30.5007 27.4574 31.8763 28.8331 31.8763 30.5253C31.8763 32.2176 30.5007 33.5932 28.8084 33.5932Z"
                        fill="white"/>
                  </svg>

                </div>
                <div class="text">Поділитись</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <imgPopup v-if="openImg" @closePopup="openImg = !openImg; img=''; title='';" :img="`${img}`"
              :title="`${title}`"></imgPopup>
  </div>
</template>

<script>
import axios from "axios";
import imgPopup from "@/components/img-popup.vue";

export default {
  name: "popup-diet-type6",
  components: {imgPopup},
  data: () => ({
    openImg: false,
    img: "",
    title: ""
  }),
  methods: {
    closeTrigger() {
      this.$emit('clicked')
    },
    sendToServerScreen(img) {
      document
          .getElementById("viber_share")
          .setAttribute(
              "href",
              "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
              encodeURIComponent(
                  "viber://forward?text=" +
                  encodeURIComponent(this.$root.globalUrl + "download-local/" + img)
              )
          );
      document.getElementById("viber_share").click();
    },
  }
}
</script>

<style scoped lang="scss">
@import "../style/vars";

.popup-diet-type6 {
  width: 100%;
  height: 100%;
  background: rgba(88, 101, 136, 0.6);
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  padding: 0 15px;

  @media screen and (min-width: 426px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }


  .content {
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    max-height: 80%;
    height: 100%;

    display: flex;
    flex-direction: column;


    .popup-header {
      background: #EEF3F8;
      padding: 20px 15px;

      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        align-items: center;
        color: #9AB9D8;
        font-family: $fontB;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

    .popup-info {
      overflow-y: scroll;
      padding: 15px 0;
      max-height: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;

      .title {
        padding: 0 15px;
        text-align: center;

        color: #1E3B89;
        font-family: $fontSB;
        font-size: 15px;

        span {
          font-size: 17px;
          font-family: $fontB;
        }
      }

      .padding-content {
        margin-top: 20px;
        padding: 0 15px;

        .more-info {

          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 25px;

          .icon {
            margin-right: 10px;
          }

          .text {
            color: #00ACE6;
            font-size: 14px;
            font-family: $fontR;

            span {
              font-family: $fontB;
            }
          }
        }

        .dropdown-block {
          margin-bottom: 20px;


          border: 1px solid #9AB9D8;
          box-sizing: border-box;
          border-radius: 4px;

          .title-text {

            padding: 15px;
            background: rgba(213, 234, 248, 0.8);
            color: #1E3B89;

            font-size: 15px;
            font-family: $fontR;

            span {
              text-transform: uppercase;
              font-family: $fontB;
              font-size: 17px;
            }
          }

          .triple-btn {
            padding: 10px;
            display: flex;
            justify-content: center;

            .btn {
              margin: 0 10px;
              width: 66px;
              min-width: 66px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;


              .text {
                color: #1E3B89;
                font-size: 12px;
                font-family: $fontR;
              }
            }
          }
        }
      }
    }
  }
}
</style>
