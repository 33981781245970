<template>
  <div class="cards">
    <div class="cards-content">
      <div class="header-image">
        <img src="../assets/images/comfort-cards/page-header-new.png" alt=""/>
      </div>

      <div class="cards-container">
        <div class="card" v-for="(card, index) in cards" :key="index">
          <div class="card-image">
            <img :src="card.img" alt=""/>
          </div>
          <div class="card-content">
            <div class="number">
              {{ index + 1 }}
            </div>
            <hr/>
            <div class="title">
              {{ card.title }}
            </div>
            <div class="text" v-html="card.text"></div>
            <div class="drop-list" v-for="(list, index) in card.drop_down_lists" :key="index">
              <div class="list-title" @click="list.isOpened = !list.isOpened">
                <div class="text">
                  {{ list.title }}
                </div>
                <div class="icon" :class="list.isOpened ? 'rotate' : ''">
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75678 1.24322L6.26947 5.7787" stroke="white" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.26951 5.7787L10.7787 1.24323" stroke="white" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <div class="list-content" v-if="list.isOpened">
                <div v-html="list.data"></div>
              </div>
            </div>
          </div>
          <div class="card-link" v-if="card.link">
            <hr/>
            <router-link class="url" :to="card.link.ref">{{
                card.link.name
              }}
            </router-link>
          </div>
        </div>

        <div class="cards-container-info">
          Безумовна перевага надається грудному вигодовуванню. Матеріал призначений для медичних працівників.
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Image1 from "../assets/images/comfort-cards/img1.png";
import Image1_5 from "../assets/images/comfort-cards/img1,5.png";
import Image2 from "../assets/images/comfort-cards/img2.png";
import Image3 from "../assets/images/comfort-cards/img3.png";
import Image4 from "../assets/images/comfort-cards/img4.png";
import Image5 from "../assets/images/comfort-cards/img5.png";
import Image6 from "../assets/images/comfort-cards/img6.png";
import Image7 from "../assets/images/comfort-cards/img7.png";

export default {
  name: "cards",
  data: () => ({
    cards: [
      {
        img: Image1,
        title: "Першість в інноваціях",
        text:
            "Nutricia першою в світі у 2000 році створила й постійно вдосконалює молочні суміші комплексної дії для дітей з функціональними кишковими кольками, запорами й легкими зригуваннями",
        link: "",
      },
      {
        img: Image1_5,
        title: "Оновлена формула",
        text: `
                    <p>Збережено комбінацію частково гідролізованого сироваткового білка, ефективної дози пребіотиків GOS/FOS 9:1 й значної частки бета-пальмітату для дієтотерапії функціональних розладів травлення.</p>
                    <p>Істотно збільшено вміст <strong>вітаміну D<sub>3</sub></strong> для розвитку кісток й імунної системи, докозагексаєнової <strong>(DHA)</strong> й арахідонової <strong>(ARA)</strong> жирних кислот, а також <strong>інозитолу</strong> для розвитку мозку і зору.</p>
                    `,
        drop_down_lists: [
          {
            title: 'Nutrilon Комфорт 1',
            isOpened: false,
            data: `
                    <table class="structure-table" cellspacing="0" cellpadding="0">
                        <thead>
                            <th></th>
                            <th>Попередня формула</th>
                            <th>Нова формула</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>DHA</th>
                                <td>6,4 мг</td>
                                <td>16,5 мг</td>
                            </tr>
                            <tr>
                                <th>ARA</th>
                                <td>11 мг</td>
                                <td>16,5 мг</td>
                            </tr>
                            <tr>
                                <th>Вітамін D<sub>3</sub></th>
                                <td>1,2 мкг<br> (48 МО)</td>
                                <td>1,7 мкг<br> (68 МО)</td>
                            </tr>
                            <tr>
                                <th>Інозитол</th>
                                <td>3,7 мг</td>
                                <td>8,5 мг</td>
                            </tr>
                        </tbody>
                    </table>
                `
          },
          {
            title: 'Nutrilon Комфорт 2',
            isOpened: false,
            data: `
                    <table class="structure-table" cellspacing="0" cellpadding="0">
                        <thead>
                            <th></th>
                            <th>Попередня формула</th>
                            <th>Нова формула</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>DHA</th>
                                <td>5.9 мг</td>
                                <td>16,5 мг</td>
                            </tr>
                            <tr>
                                <th>ARA</th>
                                <td>10 мг</td>
                                <td>9,9 мг</td>
                            </tr>
                            <tr>
                                <th>Вітамін D<sub>3</sub></th>
                                <td>1,4 мкг<br> (56 МО)</td>
                                <td>1,6 мкг<br> (64 МО)</td>
                            </tr>
                            <tr>
                                <th>Інозитол</th>
                                <td>3,4 мг</td>
                                <td>9 мг</td>
                            </tr>
                        </tbody>
                    </table>
                `
          }
        ],
        link: "",
      },
      {
        img: Image2,
        title: "Професіонали рекомендують",
        text:
            "Асоціація педіатрів-гастроентерологів та нутриціологів України рекомендує суміш Nutrilon Комфорт, виходячи з клінічно доведеної ефективності у вирішенні функціональних розладів травлення",
        link: {
          name: "Ознайомитись",
          ref: "/library-item/article/13",
        },
      },
      {
        img: Image3,
        title: "Вирішення проблеми кишкових кольок",
        text: `
                    <p>Для легкого засвоєння суміші Nutrilon Комфорт у її складі:</p>
                    <p><b>• знижений вміст лактози</b> (у 3 рази порівняно з базовими сумішами або грудним молоком) й <b>частково гідролізований сироватковий білок</b>, що усувають надмірне утворення газів, які є основною причиною кольок;</p>
                    <p><b>• запатентований комплекс пребіотиків GOS/FOS 9:1</b> у доведено ефективній кількості 0,8 г / 100 мл, що знижує ризик надмірного газоутворення завдяки формуванню здорової кишкової мікрофлори</p>
                    `,
        link: {
          name: "Перейти до алгоритму",
          ref: "/algorithms/colic",
        },
      },
      {
        img: Image4,
        title: "Вирішення проблеми запорів",
        text: `
                    <p>Для нормалізації випорожнень у складі суміші Nutrilon Комфорт:</p>
                    <p><b>• β-пальмітат</b> становить значну частку – 41% пальмітинової кислоти (у 4-5 разів більше, ніж у базових сумішах), що попереджає ущільнення випорожнень та порушення перистальтики кишечника;</p>
                    <p><b>•</b> харчові волокна <b>запатентованого комплексу пребіотиків GOS/FOS 9:1</b> у доведено ефективній кількості 0,8 г / 100 мл наближують консистенцію й частоту стулу до показників на грудному вигодовуванні</p>
                    `,
        link: {
          name: "Перейти до алгоритму",
          ref: "/algorithms/constipation",
        },
      },
      {
        img: Image5,
        title: "Вирішення проблеми легких зригуваннь",
        text: `
                    Для зменшення легких (1-2 бали за шкалою оцінки інтенсивності) зригувань, пов’язаних зі швидким заковтуванням суміші, до складу суміші Nutrilon Комфорт входить <b>загущувач-полісахарид амілопектин</b>, що підвищує в’язкість суміші та зменшує зригування
                    `,
        link: {
          name: "Перейти до алгоритму",
          ref: "/algorithms/vomiting",
        },
      },
      {
        img: Image6,
        title: "Відповідність рекомендаціям МОЗ України",
        text: `
                    Склад суміші відповідає методичним рекомендаціям з дієтотерапії функціональних кишкових кольок, запорів і зригувань, узгодженим Міністерством охорони здоров’я України й Національною академією медичних наук України
                    `,
        link: {
          name: "Ознайомитись",
          ref: "/library-item/guidance/1",
        },
      },
      {
        img: Image7,
        title: "Розрахунок добової норми суміші",
        text: `
                    Добову потребу дитини в суміші при виключно штучному вигодовуванні зручно розрахувати за допомогою калькулятора, в основу якого покладені норми для здорових дітей, затверджені наказом МОЗ України №149 від 20.03.2008 р.
                    `,
        link: {
          name: "Розрахувати",
          ref: "/production/0",
        },
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "../style/vars";

.cards {
  height: 100%;
  position: relative;

  &-content {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;

    .header-image {
      margin-bottom: 30px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-container {
    padding: 0 15px;

    .card {
      background: #fff;
      border-radius: 4px;
      margin-bottom: 36px;

      &-image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &-content {
        padding: 9px 19px 20px 20px;

        .number {
          width: 34px;
          height: 34px;

          max-width: 34px;
          max-height: 34px;

          margin-bottom: 8px;

          border-radius: 50%;
          border: 1.17241px solid #9ab9d8;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 17px;
          font-weight: bold;
          color: #9ab9d8;
        }

        hr {
          margin-bottom: 14px;
        }

        .title {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;

          color: #1e3b89;

          margin-bottom: 12px;
        }

        .text {
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;

          color: #1e3b89;

          p {
            margin-bottom: 15px;

            sub {
              line-height: 0;
            }
          }
        }

        .drop-list {
          display: flex;
          flex-direction: column;

          margin-bottom: 20px;

          .list-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            padding: 10px 20px 8px 11px;
            background: rgba(0, 172, 230, 0.6);
            border-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .icon {
              display: flex;
              align-items: center;
            }

            .text {
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: #fff;
            }

            .rotate {
              transform: rotate(180deg);
            }
          }

          .list-content {
            ::v-deep .structure-table {
              width: 100%;
              margin-top: 4px;

              border-bottom: none;
              color: #1E3B89;

              thead {
                th {
                  padding-top: 2px;

                  text-align: center;

                  font-size: 12px;
                  line-height: 14px;
                }

                th:first-child {
                  background: rgba(203, 223, 242, 0.15);
                  border-left: 1px solid #E0ECF7;
                }

                th:last-child {
                  border-right: 1px solid #E0ECF7;
                  border-left: 1px solid #E0ECF7;
                }
              }

              tbody {
                padding-top: 17px;

                tr {

                  th {
                    background: rgba(203, 223, 242, 0.15);

                    min-width: 90px;

                    text-align: left;
                    padding: 15px 6px 15px 11px;

                    font-size: 14px;
                    line-height: 18px;

                    border-left: 1px solid #E0ECF7;


                    sub {
                      line-height: 0;
                    }
                  }

                  td {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;

                    padding: 0 10px;
                  }

                  td:last-child {
                    border-right: 1px solid #E0ECF7;
                    border-left: 1px solid #E0ECF7;
                  }
                }

                tr:last-child {
                  th, td {
                    border-bottom: 1px solid #E0ECF7;
                  }
                }
              }
            }
          }
        }

        .drop-list:last-child {
          margin-bottom: 0;
        }
      }

      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #dae3ed;
        margin-bottom: 7px;
        padding: 0;
      }

      &-link {
        padding-bottom: 8px;

        .url {
          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: bold;
          font-size: 15px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: #00ace6;
        }

        .url::after {
          content: "\203A";

          margin-left: 5px;
          margin-bottom: 3px;

          font-weight: bold;
          font-size: 25px;
          color: #00ace6;
        }
      }
    }

    &-info {
      margin-bottom: 30px;
      background: rgba(154, 185, 216, 0.6);
      padding: 20px 20px;

      font-weight: 300;
      font-size: 12px;
      line-height: 15px;

      color: #FFFFFF;
    }
  }


}
</style>
