<template>
  <div class="question">

    <div class="head-links">
      <router-link class="back-links" to="/pediatrics">Головна</router-link>
      <span>/</span>
      <router-link class="back-links" to="/pediatrics/cathedra">Відкрита кафедра</router-link>
      <span>/</span>
      <router-link class="now-page" to="/pediatrics/cathedra/question">Ваше запитання</router-link>
    </div>

    <div class="content-wrapper">

      <form class="question-block" @submit.prevent="confirmQuestion()">
        <div class="h2-title">
          <button class="arrow-btn" @click="$router.go(-1)">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989592 6.3033 0.696699C6.01041 0.403805 5.53553 0.403805 5.24264 0.696698L0.46967 5.46967ZM13 5.25L1 5.25L1 6.75L13 6.75L13 5.25Z"
                  fill="#00ACE6"/>
            </svg>
          </button>
          ваше запитання
        </div>
        <div class="content">
          <p class="recipient">
            Кому: <strong>{{ recipient.whom }}</strong>
          </p>
          <div class="tabs">
            <button
                type="button"
                class="tabs-btn"
                :class="{active: activeTab === 1}"
                @click="setActiveTab(1)"
            >
              теоретичне
            </button>
            <button
                type="button"
                class="tabs-btn"
                :class="{active: activeTab === 2}"
                @click="setActiveTab(2)"
            >
              практичне
            </button>
          </div>
          <p class="text" v-if="activeTab === 2">
            Будь ласка, опишіть стан пацієнта, не зазначаючи його персональні дані (ПІБ
            й іншу інформацію, за якою можна встановити особу). Ми не зможемо взяти
            до розгляду запитання, що містять персональні дані пацієнтів.​
          </p>
          <textarea
              v-model="message.text"
              class="field"
              placeholder="Ваше запитання"
          ></textarea>
          <div class="agree">
            <input
                type="checkbox"
                class="agree-checkbox"
                v-model="agree"
                id="agree"
            >
            <label class="agree-label" for="agree">
              <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4972 1L5.99179 8.49411L2.51521 5.02985L1.50269 4.01178" stroke="white" stroke-width="2"
                      stroke-miterlimit="10"/>
              </svg>
            </label>
            Погоджуюсь на оприлюднення на порталі Nutricia моїх ПІБ, спеціальності й населеного пункту, а також
            запитання й відповіді на нього, оскільки це може допомогти колегам. Особисто несу відповідальність за
            ведення пацієнта й корекцію його дієти. У власних рішеннях керуюсь рекомендаціями МОЗ України й ВООЗ.
          </div>

          <button
              type="submit"
              class="btn dark-btn"
              :disabled="!agree || isLoading || !message.text"
          >
            <p v-if="!isLoading">відправити</p>
            <p v-if="isLoading">Зачекайте...</p>
          </button>
        </div>
      </form>

      <div
          class="popup"
          v-if="popupActive"
      >
        <div class="background"></div>
        <div class="popup__container">
          <div class="popup__header">
            <p class="popup__title">
              ваше запитання
            </p>
            <span
                class="popup__closer"
                @click="closePopup()"
            >
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.258301 0.258301L18.7418 18.7418" stroke="#00ACE6" stroke-width="1.7"
                      stroke-miterlimit="10"/>
                <path d="M18.7418 0.258301L0.258301 18.7418" stroke="#00ACE6" stroke-width="1.7"
                      stroke-miterlimit="10"/>
              </svg>
            </span>
          </div>

          <div class="popup__content popup__info" v-if="popupActive === 'SUCCESS'">
            <svg width="65" height="30" viewBox="0 0 65 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5899 1H63.9799L60.3099 28.3H16.9199" stroke="#1E3B89" stroke-width="1.5"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.5898 1L39.9798 18.14L63.9798 1" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M36.0399 14.6499L16.9199 28.2999" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M60.3099 28.2999L44.8599 14.6499" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.4201 8.99023H9.33008" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.5899 14.6504H13.6099" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 20.3203H16.92" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p class="text l">
              Запитання відправлено!
            </p>
            <p class="text m">
              Ми зв’яжемося з вами протягом<br><strong>трьох робочих днів</strong>
            </p>
            <p class="text s">
              У разі критичної зайнятості обраного адресата узгодимо інший строк розгляду або залучення іншого вченого
            </p>
          </div>

          <div class="popup__content popup__info" v-if="popupActive === 'ERROR'">
            <p class="text l">
              Запитання не було відправлено!
            </p>
            <p class="text m">
              Будь ласка, спробуйте пізніше
            </p>
            <p class="text s">
              Ми вирішуємо технічну проблему
            </p>
          </div>
          <button
              class="btn dark-btn"
              @click="closePopup()"
          >
            ОК
          </button>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: "question",
  data: () => ({

    message: {
      text: '',
    },

    popupActive: null,
    agree: false,
    activeTab: 1,
    error: null,
    isLoading: false,
  }),

  computed: {
    recipient() {
      return JSON.parse(localStorage.getItem('specialist'))
    }
  },

  methods: {
    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },

    openDropListNeed() {
      if (this.dropList === false) {
        this.dropList = true
      } else {
        this.dropList = false
      }
    },

    setActiveTab(tab) {
      this.activeTab = tab;
    },

    closePopup() {
      this.popupActive = null;
      this.$router.go(-1);
    },

    async getUserData() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");
      return axios.get(this.$root.globalUrl + "api/v1/security/participant", {})
          .then((res) => JSON.parse(res.data))
    },

    async postData(data) {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");
      return axios.post('https://com.nutricia.ua/v1/api/send-feedback/?api_key=9f5221de3afe4b8fb2ec2baca2beb749', data)
          .then((res) => res.data)
    },

    async confirmQuestion() {
      if (this.message.text && this.agree) {
        this.isLoading = true;

        const userData = await this.getUserData();
        const body = {
          "email": userData.email || 'none@email.com',
          "topic": 7,
          "question": `
                Кому: ${this.recipient.whom}.
                Від: ${userData.id}
                ${this.message.text}
              `,
          "name": userData.name || 'HCP Portal',
          "phone": `+${userData.phone}`,
          "callback_by": "phone",
        }

        try {
          await this.postData(body)
          this.popupActive = 'SUCCESS';
        } catch (error) {
          this.popupActive = 'ERROR';
        } finally {
          this.message.text = null;
          this.agree = false;
          this.isLoading = false;
        }
      }
    }

  }
}
</script>

<style lang="scss">
@import "../style/vars";

.question {

  * {
    -webkit-tap-highlight-color: transparent;
  }

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }
}

.content-wrapper {
  position: relative;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 100%;

    margin-top: 33px;

    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    border: 1px solid #00ACE6;
    border-radius: 4px;
    outline: none;

    color: #00ACE6;
    background-color: #fff;

    transition: all .5s;


    &:disabled {
      opacity: 0.5;
    }
  }

  .dark-btn {
    margin-top: 40px;
    border: 1px solid transparent;
    color: #fff;
    background-color: #00ACE6;

    &:active {
      border: 1px solid #00ACE6;
      color: #00ACE6;
      background-color: #fff;
    }
  }

  .light-btn {
    margin-top: 16px;
    border: 1px solid #00ACE6;
    color: #00ACE6;
    background-color: #fff;

    &:active {
      border: 1px solid transparent;
      color: #fff;
      background-color: #00ACE6;
    }
  }

  .question-block {
    background-color: white;
    margin: 11px 15px 27px;

    .h2-title {
      position: relative;
      text-align: center;
      padding: 11px 0;
      color: #9AB9D8;
      font-family: $fontB;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      border-bottom: 1px solid #DAE3ED;

      .arrow-btn {
        position: absolute;
        left: 15px;
        top: 11px;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .content {
      padding: 20px 15px;
      color: #1E3B89;

      .recipient {
        margin-bottom: 18px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
      }

      .tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .tabs-btn {
          background-color: transparent;
          color: inherit;
          border: 1px solid #DAE3ED;
          border-radius: 4px;
          font-weight: 600;
          font-size: 10px;
          text-transform: uppercase;
          text-align: center;
          width: 49%;
          padding: 15px 0;
          transition: .4s;
          cursor: pointer;

          &.active {
            background: #D5EAF8;
            border: 1px solid transparent;
          }
        }
      }

      .text {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
      }

      .field {
        border: 1px solid #DAE3ED;
        box-sizing: border-box;
        border-radius: 4px;
        min-width: 100%;
        min-height: 100px;
        padding: 17px 14px;
        font-size: 12px;
        line-height: 122.56%;
        outline: none;
        color: inherit;

        &::placeholder {
          font-size: 12px;
          line-height: 122.56%;
          color: #93ABC3;
          opacity: 0.5;
        }
      }

      .agree {
        display: flex;

        margin-top: 30px;

        font-weight: 300;
        font-size: 12px;
        line-height: 146%;

        color: #1E3B89;

        .agree-checkbox {
          display: none;
        }

        .agree-label {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 17px;
          min-width: 17px;
          margin: 3px 15px 0 0;
          border: 1px solid #00ACE6;

          svg {
            display: none;
          }
        }

        .agree-checkbox:checked + .agree-label {
          background-color: #00ACE6;
          border: 1px solid transparent;

          svg {
            display: block;
          }
        }
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  height: 100vh;
  width: 100%;

  overflow: auto;

  .background {
    position: absolute;

    width: 100%;
    height: 100%;

    background: #586588;
    opacity: 0.6;
  }

  &__container {
    position: relative;
    width: 90%;
    max-width: 395px;
    margin: 11% auto 0;
    padding-bottom: 25px;
    background: #fff;
    border-radius: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    background: #EEF3F8;
    border-radius: 4px 4px 0px 0px;
  }

  &__title {
    width: 100%;
    padding: 11px 0;
    color: #9AB9D8;
    font-family: $fontB;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__content {
    padding: 40px 15px 0;
  }

  &__info {
    text-align: center;

    svg {
      margin-bottom: 30px;
    }

    .text {
      font-weight: 300;
      line-height: 140%;
      color: #1E3B89;
      margin-bottom: 22px;
      padding: 0 15px;

      &.l {
        font-size: 19px;
      }

      &.m {
        font-size: 15px;
      }

      &.s {
        font-size: 13px;
      }

      strong {
        display: block;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .agree {
      display: flex;

      margin-top: 30px;

      font-weight: 300;
      font-size: 12px;
      line-height: 146%;

      color: #1E3B89;

      .agree-checkbox {
        display: none;
      }

      .agree-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17px;
        min-width: 17px;
        margin: 3px 15px 0 0;
        border: 1px solid #00ACE6;

        svg {
          display: none;
        }
      }

      .agree-checkbox:checked + .agree-label {
        background-color: #00ACE6;
        border: 1px solid transparent;

        svg {
          display: block;
        }
      }
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    .text {
      margin-top: 18px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      color: #1E3B89;

      strong {
        display: block;
        font-size: 19px;
        font-weight: 600;
      }
    }
  }

  &__not-allowed {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      margin-top: 50px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      color: #1E3B89;

      strong {
        display: block;
        margin-bottom: 15px;
        font-size: 19px;
        font-weight: 600;
      }
    }
  }

  .btn {
    margin: 20px 15px 0;
    width: inherit;
  }
}
</style>
