<template>
    <div class="product" @click="$emit('openDetail')">
        <div class="image">
            <div class="note" v-if="product.note != null">{{product.note}}</div>
            <slot></slot>
        </div>
        <div class="name">{{product.name}}</div>
        <div class="detail">Деталі</div>
    </div>
</template>

<script>
export default {
    props: ['product'],
    created(){

    }
}
</script>

<style lang="scss">
.product{
    width: 140px;
    height: 207px;
    background: #ffffff;
    border-radius: 1px;

    padding: 8px 10px 16px;

    position: relative;

    .image{
        text-align: center;
        height: 113px;
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;

        

        margin-bottom: 4px;

        .note{
            position: absolute;
            right: 10px;
            top: 11px;
            background: rgba(172, 152, 197, 0.3);

            font-weight: 600;
            font-size: 12px;
            line-height: 130%;
            color: #8F67A4;

            padding: 1px 4px;
            border-radius: 10px;
        }

        img{
            height: auto;
        }
    }

    .name{
        font-size: 13px;
        line-height: 18px;
        color: #4A2A80;
        text-align: center;
        font-weight: 600;

        height: 38px;

        margin-bottom: 8px;
    }

    .detail{
        text-align: right;
        font-size: 14px;
        line-height: 19px;
        text-decoration-line: underline;
        font-weight: 300;
        color: #508ECC;

        padding-right: 6px;
        cursor: pointer;
    }
}
</style>