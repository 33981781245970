<template>
    <root-popup :popupName="'видання'" @closePopup="$emit('closePopup')">

        <div class="edition-info">
            <div class="edition-name">
                «{{editionInfo.title}}»
            </div>
            <div class="edition-description">
                {{editionInfo.description}}
            </div>
        </div>

        <div class="materials-list">
            <div class="title">Всі матеріали:</div>

            <div v-for="(material, index) in cardsList" class="material" :key="index" :class="activeSource === material.sourceId ? (compareTitle(material.title) ? 'active' : '') : ''" @click="goTo(material.sourceId, material.id)">
                <div class="content">
                    {{material.title}}
                </div>
                <div class="subtitle">
                   <span v-if="material.authors.length === 1">{{getShortName(material.authors[0].name)}}</span>
                    <span v-if="material.authors.length > 1">{{material.author != null ? getShortName(material.author.name) : getShortName(material.authors[0].name)}} та ін.</span>
                    <span v-if="material.authors.length > 0"> &#8211;</span> {{material.publishYear}}.
                    <span v-if="material.authors.length > 0 && material.publishTitle">	&#8211;</span> {{material.publishTitle}}
                </div>
            </div>
        </div>
    </root-popup>
</template>


<script>
import RootPopup from '../popups/root-popup';

export default {
     props : ['editionInfo', 'currentArticleTitle', 'currentResearchTitle'],
    components : {
        'root-popup' : RootPopup
    },
    data : () => ({
        cardsList : [],
        activeSource : ''
    }),
    mounted(){
        this.activeSource = this.getActiveSource();

        if(this.editionInfo.articles != undefined)
            this.editionInfo.articles = this.setSourceInObj(this.editionInfo.articles, 'article', 'Наукові статті');
        if(this.editionInfo.researches != undefined)
            this.editionInfo.researches = this.setSourceInObj(this.editionInfo.researches, 'research', 'Клінічні дослідження');


        let allCards = [];
        if(this.editionInfo.articles != undefined)
            allCards = allCards.concat(this.editionInfo.articles);
        if(this.editionInfo.researches != undefined)
            allCards = allCards.concat(this.editionInfo.researches);

        allCards = allCards.filter(value => Object.keys(value).length !== 0);

        allCards.sort((a, b) => {
            if(a.publishYear > b.publishYear) { return -1; }
            if(a.publishYear < b.publishYear) { return 1; }
            return 0;
        })

        allCards.forEach(element => {
            if(!this.cardsList.find(x => x.sourceId === element.sourceId && x.title === element.title)){
                this.cardsList.push(element);
            }
        })
    },
    methods : {
        getActiveSource(){
            if(this.currentArticleTitle)
                return 'article';
            if(this.currentResearchTitle)
                return 'research';
        },
        compareTitle(materialTitle){
            switch(this.activeSource){
                case 'article' : {
                    return this.currentArticleTitle === materialTitle;
                }
                break;
                case 'research' : {
                    return this.currentResearchTitle === materialTitle;
                }
                break;
            }
        },
        setSourceInObj(objArr, sourceId, sourceTitle){
            if(objArr != null){
                for(let i = 0; i < objArr.length; i++){
                    objArr[i].sourceId = sourceId;
                    objArr[i].sourceTitle = sourceTitle;
                }
            }

            return objArr;
        },
        getShortName(name){
            name = name.split(" ");
            return name[0].substring(0,1) + '.' + name[1].substring(0,1) + '. ' + name[2];
        },
        goTo(type, id){
            this.$emit('closePopup');
            this.$router.push({ name : 'libraryItem', params : { type, id } });
        },
        getFilteredArr(object){
            let filtered = [];

            for(let i = 0; i < object.length; i++){
                if(!filtered.find(obj => {
                    return obj.title === object[i].title;
                })){
                    filtered.push(object[i]);
                }
            }

            return filtered;
        }
    }
}
</script>

<style lang="scss" scoped>
.edition-info{
    padding: 15px 16px 23px 14px;

    .edition-name{
        font-weight: 600;
        font-size: 17px;
        line-height: 135%;
        color: #4A2A80;

        padding-bottom: 10px;
    }

    .edition-description{
        color: #4A2A80;
        line-height: 135%;
        font-size: 13px;
        padding-bottom: 3px;
    }
}

.materials-list{
    .title{
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #AC98C5;

        padding-bottom: 20px;

        border-bottom: 1px solid #EFE1F7;
    }

    .material{
        padding: 13px 20px 13px 14px;

        border-bottom: 1px solid #DAE3ED;

        .content{
            font-size: 13px;
            line-height: 125%;
            color: #4A2A80;

            padding-bottom: 10px;
        }

        .subtitle{
            font-size: 12px;
            line-height: 135%;
            color: #AC98C5;
        }
    }

    .active{
        background: #EFE1F7;
    }   
}
</style>