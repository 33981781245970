<template>
    <div class="annotation">
        <div class="info-block">
            i
        </div>
        <div class="container">
            {{data}}
        </div>
    </div>
</template>

<script>
export default {
    props : ['data']
}
</script>

<style lang="scss" scoped>
@import "../../../style/vars";

.annotation{
    padding: 30px 29px 33px 41px;
    position: relative;

    .info-block{
        position: absolute;
        width: 24px;
        height: 24px;

        border-radius: 50%;
        border: 1px solid #4A2A80;
        box-sizing: border-box;
        
        display: flex;
        justify-content: center;
        align-items: center;

        top: 39px;
        left: 29px;
        background: #F1EDF7;

        color: #4A2A80;
        font-family: $fontB;
        font-size: 20px;
        padding-bottom: 2px;
    }

    .container{
        border: 1px solid #4A2A80;
        box-sizing: border-box;
        border-radius: 4px;

        padding: 10px 15px 10px 20px;

        font-size: 13px;
        line-height: 130%;
        color: #4A2A80;
    }
}
</style>