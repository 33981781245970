<template>
  <div class="personal-data-clinical">
    <div class="personal-data-block">
      <p class="h1-title"><span>Згода на обробку<br>персональних даних</span>
        <i class="close-btn" @click="closePopup()">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M0.25824 0.258301L18.7417 18.7418" stroke="#8F67A4" stroke-width="1.7"
                    stroke-miterlimit="10"/>
              <path d="M18.7417 0.258301L0.25824 18.7418" stroke="#8F67A4" stroke-width="1.7"
                    stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="19" height="19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </i>
      </p>

      <p class="reg-text" v-for="(key, value) in regText">{{ key }}</p>

      <div class="btn-wrapper">
        <div class="btn-block" @click="closePopup()">
          <p>Закрити</p>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "personal-data",
  data: () => ({
    regText: [
      "Відповідно до чинної редакції Закону України «Про захист персональних даних» №2297-VI надаю згоду на внесення своїх персональних даних до бази даних ТОВ з іноземними інвестиціями «Нутриція Україна» (далі – «компанія») в якості володільця з їх подальшим опрацюванням відповідальною особою (в тому числі третіми особами, залученими компанією за відповідним договором для досягнення мети, сформульованої у цій згоді) для отримання будь-якої інформації від компанії шляхом взаємодії зі співробітниками компанії, поштових відправлень, телефонних дзвінків, електронних повідомлень (sms, e-mail, Viber, WhatsApp, Facebook Messenger тощо) та іншими шляхами, що не суперечать чинному законодавству України терміном до відкликання дозволу, в тому числі згоду на:",
      "- обробку моїх персональних даних з первинних джерел, зокрема відомості про прізвище, ім’я, по-батькові, професію, номер телефону, електронну пошту, місце роботи;",
      "- використання персональних даних, що передбачає дії володільця бази щодо обробки цих даних, в тому числі використання персональних даних працівниками компанії відповідно до їх професійних чи службових обов’язків, дії щодо їх захисту, а також дії щодо надання часткового або повного права обробки персональних даних іншим суб’єктам відносин, пов’язаних із персональними даними (стаття 10 зазначеного Закону);",
      "- поширення персональних даних, що передбачає дії володільця бази персональних даних щодо передачі відомостей про фізичну особу з бази персональних даних виключно в межах цілей, які зазначені у цій згоді (стаття 14 зазначеного Закону);",
      "- доступ до персональних даних третіх осіб, що визначає дії володільця бази персональних даних у разі отримання запиту від третьої особи щодо доступу до персональних даних, у тому числі порядок доступу суб’єкта персональних даних до відомостей про себе (стаття 16 зазначеного Закону).",
      "Я повідомлений про свої права, визначені Законом України «Про захист персональних даних», мета обробки та коло осіб, яким можуть передаватися мої персональні дані, роз’яснені та зрозумілі."
    ]
  }),
  computed: {
    ...mapGetters(['getPrevPopup'])
  },
  methods: {
    ...mapActions([
      'setMenuData',
      'setPrevPopup',
    ]),
    closePopup() {
      this.setMenuData({popupState: this.getPrevPopup})
      this.setPrevPopup('')
    },
  }
}
</script>

<style lang="scss">
@import "../../style/vars";

.personal-data-clinical {

  overflow: auto;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(115, 89, 130, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 100;

  @media screen and (min-width: 426px) {
    position: absolute;
  }

  .personal-data-block {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    .h1-title {
      margin-bottom: 25px;
      border-radius: 4px 4px 0 0;
      padding: 0 14px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F1EDF7;

      span {
        font-size: 12px;
        font-family: $fontB;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #AC98C5;
      }

    }

    .reg-text {
      padding: 0 15px;
      margin-bottom: 20px;
      color: #4A2A80;
      font-size: 16px;
      font-family: $fontL;
    }

    .btn-wrapper {
      padding: 0 15px;

      .btn-block {
        width: 100%;
        height: 40px;
        background-color: #8F67A4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        border-radius: 4px;
        cursor: pointer;

        p {
          font-family: $fontB;
          font-size: 15px;
          color: white;
          text-transform: uppercase;
        }

      }

    }
  }
}


</style>
