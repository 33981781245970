<template>
    <div class="norm-function">
        <div class="content">
            <div class="title">
                <div class="title-text">Функція не порушена</div>
                <div class="close" @click="$emit('closePopup')">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.258301 0.258301L18.7418 18.7418" stroke="#8F67A4" stroke-width="1.7" stroke-miterlimit="10"/>
                        <path d="M18.7418 0.258301L0.258301 18.7418" stroke="#8F67A4" stroke-width="1.7" stroke-miterlimit="10"/>
                    </svg>
                </div>
            </div>
            <div class="main">

                <div class="text">
                    <p><strong>2 Етап </strong>
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01529 9.70417C6.378 10.0835 6.9829 10.1002 7.36637 9.74138L11.7011 5.68535C12.0845 5.32652 12.1014 4.72809 11.7387 4.34872C11.376 3.96934 10.7711 3.95268 10.3876 4.31151L6.0529 8.36754C5.66943 8.72636 5.65259 9.32479 6.01529 9.70417Z" fill="#AC98C5"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01504 0.296063C5.65248 0.675576 5.66955 1.274 6.05317 1.63268L10.3879 5.6856C10.7715 6.04428 11.3764 6.02739 11.7389 5.64787C12.1015 5.26836 12.0844 4.66993 11.7008 4.31125L7.3661 0.258339C6.98249 -0.10034 6.3776 -0.0834509 6.01504 0.296063Z" fill="#AC98C5"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.261385 9.70423C0.62409 10.0836 1.22899 10.1003 1.61247 9.74144L5.94715 5.68541C6.33063 5.32658 6.34747 4.72815 5.98476 4.34878C5.62206 3.9694 5.01716 3.95274 4.63368 4.31157L0.298997 8.3676C-0.0844794 8.72643 -0.101319 9.32486 0.261385 9.70423Z" fill="#AC98C5"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.261132 0.296063C-0.101425 0.675576 -0.0843527 1.274 0.299263 1.63268L4.63395 5.6856C5.01757 6.04428 5.62246 6.02739 5.98502 5.64787C6.34757 5.26836 6.3305 4.66993 5.94689 4.31125L1.6122 0.258339C1.22858 -0.10034 0.623689 -0.0834509 0.261132 0.296063Z" fill="#AC98C5"/>
                    </svg>
                    <strong> Основний</strong> <br> (початкове  харчування)</p>
                    <p><strong>Задача: </strong>оцінити  функцію нирок</p>
                </div>

                <div class="dropdown-blocks">
                    <div class="block-title" @click="isGNN = !isGNN">
                        <div class="product-text">
                            ГНН* є
                        </div>
                        <div :class="isGNN ? 'open arrow rotated' : 'open arrow no-rateted'">
                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7788 1.24323L6.26611 5.7787" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.26608 5.7787L1.75684 1.24323" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <transition name="fade" v-if="isGNN">
                        <div class="dropdown-block">
                            <div class="task">
                                <strong>Задача:</strong> <br>
                                оцінити наявність гіперглікемії
                            </div>
                            <div class="products">
                                <div class="item">
                                    <div class="title">
                                        ГГК** є
                                    </div>
                                    <div class="bottom-arrow">
                                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293037 6.01529C-0.0825061 6.378 -0.0989974 6.98289 0.256203 7.36637L4.27127 11.7011C4.62647 12.0845 5.21885 12.1014 5.5944 11.7387C5.96994 11.376 5.98643 10.7711 5.63123 10.3876L1.61617 6.0529C1.26097 5.66943 0.668581 5.65259 0.293037 6.01529Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 6.01504C9.23017 5.65248 8.63779 5.66955 8.28274 6.05317L4.27076 10.3879C3.9157 10.7715 3.93242 11.3764 4.3081 11.7389C4.68378 12.1015 5.27616 12.0844 5.63122 11.7008L9.6432 7.3661C9.99825 6.98249 9.98153 6.3776 9.60585 6.01504Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292549 0.261385C-0.0829943 0.624089 -0.0994856 1.22899 0.255715 1.61246L4.27078 5.94715C4.62598 6.33063 5.21837 6.34747 5.59391 5.98476C5.96945 5.62206 5.98594 5.01716 5.63074 4.63368L1.61568 0.298997C1.26048 -0.0844801 0.668093 -0.10132 0.292549 0.261385Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 0.261132C9.23017 -0.101425 8.63779 -0.0843529 8.28274 0.299263L4.27076 4.63395C3.9157 5.01757 3.93242 5.62246 4.3081 5.98502C4.68378 6.34757 5.27616 6.3305 5.63122 5.94688L9.6432 1.6122C9.99825 1.22858 9.98153 0.623689 9.60585 0.261132Z" fill="#AC98C5"/>
                                        </svg>
                                    </div>
                                    <div class="image">
                                        <img src="../../../../assets/images/products/nutrizon-diazon-energy-163x200-2.png" height="114" alt="">
                                    </div>
                                    <div class="product-name">Нутрiзон Едванст Діазон ВП Енергія</div>
                                </div>
                                <div class="item">
                                    <div class="title">
                                        ГГК** немає
                                    </div>
                                    <div class="bottom-arrow">
                                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293037 6.01529C-0.0825061 6.378 -0.0989974 6.98289 0.256203 7.36637L4.27127 11.7011C4.62647 12.0845 5.21885 12.1014 5.5944 11.7387C5.96994 11.376 5.98643 10.7711 5.63123 10.3876L1.61617 6.0529C1.26097 5.66943 0.668581 5.65259 0.293037 6.01529Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 6.01504C9.23017 5.65248 8.63779 5.66955 8.28274 6.05317L4.27076 10.3879C3.9157 10.7715 3.93242 11.3764 4.3081 11.7389C4.68378 12.1015 5.27616 12.0844 5.63122 11.7008L9.6432 7.3661C9.99825 6.98249 9.98153 6.3776 9.60585 6.01504Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292549 0.261385C-0.0829943 0.624089 -0.0994856 1.22899 0.255715 1.61246L4.27078 5.94715C4.62598 6.33063 5.21837 6.34747 5.59391 5.98476C5.96945 5.62206 5.98594 5.01716 5.63074 4.63368L1.61568 0.298997C1.26048 -0.0844801 0.668093 -0.10132 0.292549 0.261385Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 0.261132C9.23017 -0.101425 8.63779 -0.0843529 8.28274 0.299263L4.27076 4.63395C3.9157 5.01757 3.93242 5.62246 4.3081 5.98502C4.68378 6.34757 5.27616 6.3305 5.63122 5.94688L9.6432 1.6122C9.99825 1.22858 9.98153 0.623689 9.60585 0.261132Z" fill="#AC98C5"/>
                                        </svg>
                                    </div>
                                    <div class="image">
                                        <img src="../../../../assets/images/products/nutrieon-energy-84x114-2.png" height="114" alt="">
                                    </div>
                                    <div class="product-name">Нутрізон Енергія</div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="block-title" @click="noGNN = !noGNN">
                        <div class="product-text">
                            ГНН* немає
                        </div>
                        <div :class="noGNN ? 'open arrow rotated' : 'open arrow no-rateted'">
                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7788 1.24323L6.26611 5.7787" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.26608 5.7787L1.75684 1.24323" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <transition name="fade" v-if="noGNN">
                        <div class="dropdown-block">
                            <div class="task">
                                <strong>Задача:</strong> <br>
                                оцінити наявність гіперглікемії
                            </div>
                            <div class="products">
                                <div class="item">
                                    <div class="title">
                                        ГГК** є
                                    </div>
                                    <div class="bottom-arrow">
                                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293037 6.01529C-0.0825061 6.378 -0.0989974 6.98289 0.256203 7.36637L4.27127 11.7011C4.62647 12.0845 5.21885 12.1014 5.5944 11.7387C5.96994 11.376 5.98643 10.7711 5.63123 10.3876L1.61617 6.0529C1.26097 5.66943 0.668581 5.65259 0.293037 6.01529Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 6.01504C9.23017 5.65248 8.63779 5.66955 8.28274 6.05317L4.27076 10.3879C3.9157 10.7715 3.93242 11.3764 4.3081 11.7389C4.68378 12.1015 5.27616 12.0844 5.63122 11.7008L9.6432 7.3661C9.99825 6.98249 9.98153 6.3776 9.60585 6.01504Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292549 0.261385C-0.0829943 0.624089 -0.0994856 1.22899 0.255715 1.61246L4.27078 5.94715C4.62598 6.33063 5.21837 6.34747 5.59391 5.98476C5.96945 5.62206 5.98594 5.01716 5.63074 4.63368L1.61568 0.298997C1.26048 -0.0844801 0.668093 -0.10132 0.292549 0.261385Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 0.261132C9.23017 -0.101425 8.63779 -0.0843529 8.28274 0.299263L4.27076 4.63395C3.9157 5.01757 3.93242 5.62246 4.3081 5.98502C4.68378 6.34757 5.27616 6.3305 5.63122 5.94688L9.6432 1.6122C9.99825 1.22858 9.98153 0.623689 9.60585 0.261132Z" fill="#AC98C5"/>
                                        </svg>
                                    </div>
                                    <div class="image">
                                        <img src="../../../../assets/images/products/nutrieon-diazon-83x113.png" width="83" height="113" alt="">
                                    </div>
                                    <div class="product-name">Нутрiзон Діазон</div>
                                </div>
                                <div class="item">
                                    <div class="title">
                                        ГГК** немає
                                    </div>
                                    <div class="bottom-arrow">
                                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293037 6.01529C-0.0825061 6.378 -0.0989974 6.98289 0.256203 7.36637L4.27127 11.7011C4.62647 12.0845 5.21885 12.1014 5.5944 11.7387C5.96994 11.376 5.98643 10.7711 5.63123 10.3876L1.61617 6.0529C1.26097 5.66943 0.668581 5.65259 0.293037 6.01529Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 6.01504C9.23017 5.65248 8.63779 5.66955 8.28274 6.05317L4.27076 10.3879C3.9157 10.7715 3.93242 11.3764 4.3081 11.7389C4.68378 12.1015 5.27616 12.0844 5.63122 11.7008L9.6432 7.3661C9.99825 6.98249 9.98153 6.3776 9.60585 6.01504Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292549 0.261385C-0.0829943 0.624089 -0.0994856 1.22899 0.255715 1.61246L4.27078 5.94715C4.62598 6.33063 5.21837 6.34747 5.59391 5.98476C5.96945 5.62206 5.98594 5.01716 5.63074 4.63368L1.61568 0.298997C1.26048 -0.0844801 0.668093 -0.10132 0.292549 0.261385Z" fill="#AC98C5"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60585 0.261132C9.23017 -0.101425 8.63779 -0.0843529 8.28274 0.299263L4.27076 4.63395C3.9157 5.01757 3.93242 5.62246 4.3081 5.98502C4.68378 6.34757 5.27616 6.3305 5.63122 5.94688L9.6432 1.6122C9.99825 1.22858 9.98153 0.623689 9.60585 0.261132Z" fill="#AC98C5"/>
                                        </svg>
                                    </div>
                                    <div class="image" >
                                        <!-- <img src="../../../../assets/images/products/nutrizon-protein-intens-46x110.png" width="46" height="110" alt=""> -->
                                        <img src="../../../../assets/images/products/nutrizon-protein-intens-79x131-2.png" width="40"  alt="" style="padding-bottom: 5px">
                                    </div>
                                    <div class="product-name">Нутрізон Протеїн Інтенс</div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>

                <div class="footer-text">
                    <p>*ГНН - гостра ниркова недостатність.</p>
                    <p>**ГГК - гіперглікемія;</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data : () => ({
        isGNN : false,
        noGNN : false
    })
}
</script>

<style lang="scss" scoped>
.norm-function{
    // overflow: scroll;
    // width: 100vw;
    // height: 100vh;
    // background: rgba(115, 89, 130, 0.6);

    // padding: 30px 0;

    // position: fixed;
    // z-index: 1000;
    // top: 0;
    // left: 0;


    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100%;
    height: 100%;

    background: rgba(115, 89, 130, 0.6);
    padding: 30px 0;

    overflow: scroll;
  
    @media screen and (min-width: 426px) {
        position: absolute;
        display: flex;
        align-items: center;
    }

    .content{
        background: #FFFFFF;
        border-radius: 4px;
        margin: 50px 15px 0;
        padding: 0;

        display: flex;
        flex-direction: column;

        @media screen and (min-width: 426px) {
            position: absolute;
            left: 0;
            right: 0;

            max-width: 95%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .title{
            background: #F1EDF7;
            padding: 17px 15px;
            width: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            .close {
                cursor: pointer;
            }

            .title-text{
                font-weight: bold;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.11em;
                text-transform: uppercase;
                color: #AC98C5;
                padding-right: 15px;
            }
        }

        .main{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 8px;
            width: 100%;

            .text{
                padding: 0 7px;
                font-size: 16px;
                line-height: 20px;
            }

            .dropdown-blocks{
                width: 100%;

                .block-title{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    margin-top: 16px;

                    padding: 13px 15px;
                    background: #AC98C5;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    cursor: pointer;

                    .arrow{
                        padding-bottom: 5px;
                        cursor: pointer;
                    }

                    .rotated{
                        transform: rotate( -180deg );
                        transition: transform 500ms ease;
                    }

                    .no-rateted {
                        transform: rotate( -360deg );
                        transition: transform 500ms ease;
                    }
                }

                .dropdown-block{
                    background: #F1EDF7;
                    padding: 15px 12px 24px 15px;

                    .task{
                        font-size: 16px;
                        line-height: 20px;
                        color: #8F67A4;

                        padding-bottom: 18px;
                    }

                    .products{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .item{
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            text-align: center;
                            flex: 1 1 0px;

                            .title{
                                padding: 0;
                                padding-bottom: 6px;
                                display: flex;
                                justify-content: center;

                                font-weight: 600;
                                font-size: 16px;
                                line-height: 22px;

                                color: #4A2A80;
                            }

                            .bottom-arrow{
                                padding-bottom: 10px;
                            }

                            .image{
                                padding-bottom: 4px;
                                height: 113px;

                                display: flex;
                                align-items: flex-end;
                            }

                            .product-name{
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 19px;
                                text-align: center;

                                padding: 0 10px;

                                color: #4A2A80;
                            }
                        }
                    }

                }
            }
        }

        .footer-text{
            padding-top: 20px;
            width: 100%;

            p{
                font-size: 12px;
                line-height: 20px;

                color: #8F67A4;
            }
        }
    }
}
</style>
