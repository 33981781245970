<template>
  <div class="popup-diet-type4">

    <a id="viber_share" style="display: none;" href=""></a>

    <div class="content">
      <div class="popup-header">
        <p>Довідковий матеріал</p>
        <div @click="closeTrigger" class="close-btn">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M0.410156 0.257812L18.8937 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
              <path d="M18.8937 0.257812L0.410156 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect x="0.152344" width="19" height="19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div class="popup-info">
        <p class="title"><span>Послаблююча дієта матері, </span><br>
          якщо у дитини на ГВ запори</p>


        <div class="padding-content">
          <div class="dropdown-block">
            <div class="title-text">
              <span>ВКЛЮЧИТИ ДО РАЦІОНУ</span><br>
              продукти з послаблюючою дією і багаті на клітковину
            </div>
            <div class="triple-btn">
              <a @click="title='ВКЛЮЧИТИ ДО РАЦІОНУ'; img='download-local/type4-1.png'; openImg = !openImg"
                 class="btn watch">
                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1E3B89"/>
                    <path
                        d="M22.8336 9C22.3365 9 21.9336 9.43398 21.9336 9.77917V13.5292C21.9336 13.8744 22.3365 14.3496 22.8336 14.3496C23.3307 14.3496 23.7336 13.8744 23.7336 13.5292V9.77917C23.7336 9.43398 23.3307 9 22.8336 9Z"
                        fill="white"/>
                    <path
                        d="M32.4252 11.4873C32.0744 11.1351 31.4826 11.1559 31.238 11.3995L28.5811 14.0459C28.3366 14.2895 28.2842 14.9103 28.635 15.2625C28.9858 15.6147 29.6068 15.5648 29.8514 15.3212L32.5083 12.6748C32.7528 12.4312 32.776 11.8394 32.4252 11.4873Z"
                        fill="white"/>
                    <path
                        d="M12.6354 11.4873C12.9862 11.1351 13.578 11.1559 13.8225 11.3995L16.4794 14.0459C16.724 14.2895 16.7763 14.9103 16.4256 15.2625C16.0748 15.6147 15.4537 15.5648 15.2091 15.3212L12.5523 12.6748C12.3077 12.4312 12.2846 11.8394 12.6354 11.4873Z"
                        fill="white"/>
                    <path
                        d="M8.06693 24.2123L8.06692 24.2123C7.82329 24.4882 7.84941 24.9093 8.12524 25.1529L8.12525 25.1529C8.40112 25.3966 8.82229 25.3705 9.06588 25.0946C9.15832 24.9899 9.25255 24.8855 9.34796 24.7815C12.1801 28.9783 17.2448 31.6449 22.5 31.6449C27.7552 31.6449 32.8198 28.9783 35.652 24.7815C35.7474 24.8856 35.8417 24.99 35.9341 25.0946C36.1769 25.3696 36.598 25.3973 36.8747 25.1529L36.8085 25.078L36.8748 25.1529C37.1506 24.9093 37.1767 24.4881 36.9331 24.2123C33.3244 20.1268 27.9567 16.7184 22.5 16.7184C17.0425 16.7184 11.6749 20.1276 8.06693 24.2123ZM22.5 30.3121C17.5814 30.3121 12.8506 27.7704 10.298 23.801C12.3429 21.811 16.9459 18.0512 22.5 18.0512C28.0542 18.0512 32.6571 21.811 34.702 23.801C32.1494 27.7704 27.4186 30.3121 22.5 30.3121Z"
                        fill="white" stroke="white" stroke-width="0.2"/>
                    <circle cx="22.5003" cy="24.2493" r="3.93333" stroke="white" stroke-width="1.8"/>
                  </svg>

                </div>
                <div class="text">Дивитись</div>
              </a>
              <a :href="`${$root.globalUrl}download-local/type4-1.png`" download class="download-btn btn">
                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1E3B89"/>
                    <path
                        d="M34.2837 22.2106C33.8858 22.2106 33.5675 22.5289 33.5675 22.9269V29.4421C33.5675 31.2142 32.1244 32.652 30.3576 32.652H14.6424C12.8703 32.652 11.4325 31.2089 11.4325 29.4421V22.8207C11.4325 22.4228 11.1142 22.1045 10.7163 22.1045C10.3183 22.1045 10 22.4228 10 22.8207V29.4421C10 32.0047 12.0851 34.0845 14.6424 34.0845H30.3576C32.9202 34.0845 35 31.9994 35 29.4421V22.9269C35 22.5342 34.6817 22.2106 34.2837 22.2106Z"
                        fill="white"/>
                    <path
                        d="M21.9959 26.397C22.1339 26.5349 22.3196 26.6092 22.5 26.6092C22.6804 26.6092 22.8661 26.5403 23.004 26.397L27.5562 21.8448C27.8374 21.5636 27.8374 21.1126 27.5562 20.8314C27.275 20.5502 26.824 20.5502 26.5428 20.8314L23.2162 24.1633V9.6313C23.2162 9.23338 22.8979 8.91504 22.5 8.91504C22.1021 8.91504 21.7837 9.23338 21.7837 9.6313V24.1633L18.4518 20.8314C18.1706 20.5502 17.7196 20.5502 17.4384 20.8314C17.1572 21.1126 17.1572 21.5636 17.4384 21.8448L21.9959 26.397Z"
                        fill="white"/>
                  </svg>

                </div>
                <div class="text">Зберегти</div>
              </a>

              <div class="share-btn btn" @click="sendToServerScreen('type4-1.png')">

                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1E3B89"/>
                    <path
                        d="M28.8089 26.061C27.371 26.061 26.0888 26.7463 25.2686 27.8052L18.4009 23.9691C18.5618 23.5071 18.6501 23.0087 18.6501 22.4948C18.6501 21.9757 18.5618 21.4826 18.3957 21.0154L25.2583 17.1844C26.0732 18.2485 27.3606 18.939 28.8037 18.939C31.2643 18.939 33.2732 16.9352 33.2732 14.4695C33.2732 12.0037 31.2695 10 28.8037 10C26.338 10 24.3343 12.0037 24.3343 14.4695C24.3343 14.9886 24.4225 15.4869 24.5886 15.9489L17.7313 19.7799C16.9163 18.7105 15.6289 18.0253 14.1858 18.0253C11.7252 18.0253 9.71631 20.0291 9.71631 22.4948C9.71631 24.9605 11.7252 26.9643 14.191 26.9643C15.6341 26.9643 16.9215 26.2739 17.7416 25.2045L24.6042 29.0407C24.4381 29.5079 24.3446 30.0114 24.3446 30.5305C24.3446 32.9911 26.3484 35 28.8141 35C31.2799 35 33.2836 32.9963 33.2836 30.5305C33.2836 28.0648 31.2747 26.061 28.8089 26.061ZM28.8089 11.4068C30.5012 11.4068 31.8768 12.7824 31.8768 14.4747C31.8768 16.1669 30.5012 17.5426 28.8089 17.5426C27.1166 17.5426 25.741 16.1669 25.741 14.4747C25.741 12.7824 27.1218 11.4068 28.8089 11.4068ZM14.191 25.5627C12.4987 25.5627 11.1231 24.1871 11.1231 22.4948C11.1231 20.8025 12.4987 19.4269 14.191 19.4269C15.8833 19.4269 17.2589 20.8025 17.2589 22.4948C17.2589 24.1871 15.8781 25.5627 14.191 25.5627ZM28.8089 33.5932C27.1166 33.5932 25.741 32.2176 25.741 30.5253C25.741 28.8331 27.1166 27.4574 28.8089 27.4574C30.5012 27.4574 31.8768 28.8331 31.8768 30.5253C31.8768 32.2176 30.5012 33.5932 28.8089 33.5932Z"
                        fill="white"/>
                  </svg>

                </div>
                <div class="text">Поділитись</div>
              </div>
            </div>
          </div>

          <div class="dropdown-block">
            <div class="title-text">
              <span>ВИКЛЮЧИТИ З РАЦІОНУ</span><br>
              продукти, що сприяють запорам
            </div>
            <div class="triple-btn">
              <a @click="title='ВИКЛЮЧИТИ З РАЦІОНУ'; img='download-local/type4-2.png'; openImg = !openImg"
                 class="btn watch">
                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1E3B89"/>
                    <path
                        d="M22.8336 9C22.3365 9 21.9336 9.43398 21.9336 9.77917V13.5292C21.9336 13.8744 22.3365 14.3496 22.8336 14.3496C23.3307 14.3496 23.7336 13.8744 23.7336 13.5292V9.77917C23.7336 9.43398 23.3307 9 22.8336 9Z"
                        fill="white"/>
                    <path
                        d="M32.4252 11.4873C32.0744 11.1351 31.4826 11.1559 31.238 11.3995L28.5811 14.0459C28.3366 14.2895 28.2842 14.9103 28.635 15.2625C28.9858 15.6147 29.6068 15.5648 29.8514 15.3212L32.5083 12.6748C32.7528 12.4312 32.776 11.8394 32.4252 11.4873Z"
                        fill="white"/>
                    <path
                        d="M12.6354 11.4873C12.9862 11.1351 13.578 11.1559 13.8225 11.3995L16.4794 14.0459C16.724 14.2895 16.7763 14.9103 16.4256 15.2625C16.0748 15.6147 15.4537 15.5648 15.2091 15.3212L12.5523 12.6748C12.3077 12.4312 12.2846 11.8394 12.6354 11.4873Z"
                        fill="white"/>
                    <path
                        d="M8.06693 24.2123L8.06692 24.2123C7.82329 24.4882 7.84941 24.9093 8.12524 25.1529L8.12525 25.1529C8.40112 25.3966 8.82229 25.3705 9.06588 25.0946C9.15832 24.9899 9.25255 24.8855 9.34796 24.7815C12.1801 28.9783 17.2448 31.6449 22.5 31.6449C27.7552 31.6449 32.8198 28.9783 35.652 24.7815C35.7474 24.8856 35.8417 24.99 35.9341 25.0946C36.1769 25.3696 36.598 25.3973 36.8747 25.1529L36.8085 25.078L36.8748 25.1529C37.1506 24.9093 37.1767 24.4881 36.9331 24.2123C33.3244 20.1268 27.9567 16.7184 22.5 16.7184C17.0425 16.7184 11.6749 20.1276 8.06693 24.2123ZM22.5 30.3121C17.5814 30.3121 12.8506 27.7704 10.298 23.801C12.3429 21.811 16.9459 18.0512 22.5 18.0512C28.0542 18.0512 32.6571 21.811 34.702 23.801C32.1494 27.7704 27.4186 30.3121 22.5 30.3121Z"
                        fill="white" stroke="white" stroke-width="0.2"/>
                    <circle cx="22.5003" cy="24.2493" r="3.93333" stroke="white" stroke-width="1.8"/>
                  </svg>

                </div>
                <div class="text">Дивитись</div>
              </a>
              <a :href="`${$root.globalUrl}download-local/type4-2.png`" download class="download-btn btn">
                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1E3B89"/>
                    <path
                        d="M34.2837 22.2106C33.8858 22.2106 33.5675 22.5289 33.5675 22.9269V29.4421C33.5675 31.2142 32.1244 32.652 30.3576 32.652H14.6424C12.8703 32.652 11.4325 31.2089 11.4325 29.4421V22.8207C11.4325 22.4228 11.1142 22.1045 10.7163 22.1045C10.3183 22.1045 10 22.4228 10 22.8207V29.4421C10 32.0047 12.0851 34.0845 14.6424 34.0845H30.3576C32.9202 34.0845 35 31.9994 35 29.4421V22.9269C35 22.5342 34.6817 22.2106 34.2837 22.2106Z"
                        fill="white"/>
                    <path
                        d="M21.9959 26.397C22.1339 26.5349 22.3196 26.6092 22.5 26.6092C22.6804 26.6092 22.8661 26.5403 23.004 26.397L27.5562 21.8448C27.8374 21.5636 27.8374 21.1126 27.5562 20.8314C27.275 20.5502 26.824 20.5502 26.5428 20.8314L23.2162 24.1633V9.6313C23.2162 9.23338 22.8979 8.91504 22.5 8.91504C22.1021 8.91504 21.7837 9.23338 21.7837 9.6313V24.1633L18.4518 20.8314C18.1706 20.5502 17.7196 20.5502 17.4384 20.8314C17.1572 21.1126 17.1572 21.5636 17.4384 21.8448L21.9959 26.397Z"
                        fill="white"/>
                  </svg>

                </div>
                <div class="text">Зберегти</div>
              </a>

              <div class="share-btn btn" @click="sendToServerScreen('type4-2.png')">

                <div class="icon">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1E3B89"/>
                    <path
                        d="M28.8089 26.061C27.371 26.061 26.0888 26.7463 25.2686 27.8052L18.4009 23.9691C18.5618 23.5071 18.6501 23.0087 18.6501 22.4948C18.6501 21.9757 18.5618 21.4826 18.3957 21.0154L25.2583 17.1844C26.0732 18.2485 27.3606 18.939 28.8037 18.939C31.2643 18.939 33.2732 16.9352 33.2732 14.4695C33.2732 12.0037 31.2695 10 28.8037 10C26.338 10 24.3343 12.0037 24.3343 14.4695C24.3343 14.9886 24.4225 15.4869 24.5886 15.9489L17.7313 19.7799C16.9163 18.7105 15.6289 18.0253 14.1858 18.0253C11.7252 18.0253 9.71631 20.0291 9.71631 22.4948C9.71631 24.9605 11.7252 26.9643 14.191 26.9643C15.6341 26.9643 16.9215 26.2739 17.7416 25.2045L24.6042 29.0407C24.4381 29.5079 24.3446 30.0114 24.3446 30.5305C24.3446 32.9911 26.3484 35 28.8141 35C31.2799 35 33.2836 32.9963 33.2836 30.5305C33.2836 28.0648 31.2747 26.061 28.8089 26.061ZM28.8089 11.4068C30.5012 11.4068 31.8768 12.7824 31.8768 14.4747C31.8768 16.1669 30.5012 17.5426 28.8089 17.5426C27.1166 17.5426 25.741 16.1669 25.741 14.4747C25.741 12.7824 27.1218 11.4068 28.8089 11.4068ZM14.191 25.5627C12.4987 25.5627 11.1231 24.1871 11.1231 22.4948C11.1231 20.8025 12.4987 19.4269 14.191 19.4269C15.8833 19.4269 17.2589 20.8025 17.2589 22.4948C17.2589 24.1871 15.8781 25.5627 14.191 25.5627ZM28.8089 33.5932C27.1166 33.5932 25.741 32.2176 25.741 30.5253C25.741 28.8331 27.1166 27.4574 28.8089 27.4574C30.5012 27.4574 31.8768 28.8331 31.8768 30.5253C31.8768 32.2176 30.5012 33.5932 28.8089 33.5932Z"
                        fill="white"/>
                  </svg>

                </div>
                <div class="text">Поділитись</div>
              </div>
            </div>
          </div>

          <div class="more-info">
            <div class="icon">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24.3473 0H7.65169C6.50108 0 5.56473 0.936348 5.56543 2.12174L5.92021 9.20348L6.81691 27.1311L6.95604 29.913C6.95604 31.0637 7.89239 32 9.043 32H22.956C24.1066 32 25.043 31.0637 25.0423 29.9478L26.0232 10.3304L26.4336 2.12174C26.4343 2.10991 26.4343 2.09878 26.4343 2.08696C26.4343 0.936348 25.498 0 24.3473 0ZM23.6517 29.913C23.6517 30.2963 23.34 30.6087 22.956 30.6087H9.043C8.659 30.6087 8.34734 30.2963 8.34665 29.8783L8.24439 27.8261H15.9995H23.756L23.6517 29.913ZM23.8256 26.4348H15.9995H8.17482L7.35604 10.0536C7.37621 10.0598 7.39847 10.0654 7.41865 10.071C7.53273 10.105 7.64265 10.1419 7.75117 10.1795C7.79569 10.1948 7.8423 10.2094 7.88682 10.2254C8.035 10.2783 8.18178 10.3332 8.32995 10.3889C9.29969 10.7534 10.3028 11.1304 12.2847 11.1304C14.2694 11.1304 15.2733 10.7541 16.2437 10.3903C17.1369 10.0557 17.9807 9.73913 19.7073 9.73913C21.4374 9.73913 22.2819 10.0557 23.1759 10.391C23.3595 10.4598 23.548 10.528 23.7414 10.5955C23.7985 10.6157 23.8569 10.6344 23.9153 10.6539C24.1233 10.7228 24.3404 10.7889 24.5783 10.848C24.5873 10.8501 24.5943 10.8529 24.6033 10.855L23.8256 26.4348ZM24.675 9.43096C24.6451 9.42261 24.6138 9.41426 24.5846 9.40591C24.4726 9.37252 24.3633 9.33704 24.2569 9.30087C24.2075 9.28417 24.1567 9.26817 24.108 9.25078C23.9592 9.19791 23.8124 9.14365 23.6635 9.088C22.6938 8.72417 21.69 8.34783 19.7073 8.34783C17.7282 8.34783 16.7258 8.72417 15.7553 9.0873C14.8614 9.42261 14.0169 9.73913 12.2847 9.73913C10.5553 9.73913 9.71221 9.42191 8.81969 9.08661C8.68891 9.03722 8.55534 8.98783 8.42039 8.93843C8.37865 8.92313 8.33621 8.90922 8.29447 8.89391C8.18873 8.85635 8.08021 8.82017 7.96821 8.7847C7.91882 8.7687 7.86943 8.75339 7.81934 8.73809C7.69204 8.69983 7.55847 8.66296 7.42004 8.62817C7.38665 8.61983 7.35534 8.61078 7.32126 8.60244C7.30873 8.59965 7.29691 8.59548 7.28439 8.5927L6.95604 2.08696C6.95604 1.70365 7.26769 1.3913 7.65169 1.3913H24.3473C24.7265 1.3913 25.0346 1.696 25.043 2.07235L24.675 9.43096Z"
                    fill="#00ACE6"/>
              </svg>


            </div>
            <div class="text"><span>Споживати
достатній об’єм рідини. </span><br>
              Нестача призводить до підвищення жирності молока.
            </div>
          </div>
        </div>


      </div>
    </div>

    <imgPopup v-if="openImg" @closePopup="openImg = !openImg; img=''; title='';" :img="`${img}`"
              :title="`${title}`"></imgPopup>

  </div>
</template>

<script>
import axios from "axios";
import imgPopup from "@/components/img-popup.vue";

export default {
  name: "popup-diet-type4",
  data: () => ({
    openImg: false,
    img: "",
    title: ""
  }),
  methods: {
    closeTrigger() {
      this.$emit('clicked')
    },
    sendToServerScreen(img) {
      document
          .getElementById("viber_share")
          .setAttribute(
              "href",
              "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
              encodeURIComponent(
                  "viber://forward?text=" +
                  encodeURIComponent(this.$root.globalUrl + "download-local/" + img)
              )
          );
      document.getElementById("viber_share").click();
    },
  },
  components: {
    imgPopup
  }
}
</script>

<style scoped lang="scss">
@import "../style/vars";

.popup-diet-type4 {
  width: 100%;
  height: 100%;
  background: rgba(88, 101, 136, 0.6);
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  padding: 0 15px;

  @media screen and (min-width: 426px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }


  .content {
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    max-height: 80%;
    height: 100%;

    display: flex;
    flex-direction: column;


    .popup-header {
      background: #EEF3F8;
      padding: 20px 15px;

      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        align-items: center;
        color: #9AB9D8;
        font-family: $fontB;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

    .popup-info {
      overflow-y: scroll;
      padding: 15px 0;
      max-height: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;

      .title {
        padding: 0 15px;
        text-align: center;

        color: #1E3B89;
        font-family: $fontSB;
        font-size: 15px;

        span {
          font-size: 17px;
          font-family: $fontB;
        }
      }

      .padding-content {
        margin-top: 20px;
        padding: 0 15px;

        .more-info {

          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 25px;

          .icon {
            margin-right: 10px;
          }

          .text {
            color: #00ACE6;
            font-size: 14px;
            font-family: $fontR;

            span {
              font-family: $fontB;
            }
          }
        }

        .dropdown-block {
          margin-bottom: 20px;


          border: 1px solid #9AB9D8;
          box-sizing: border-box;
          border-radius: 4px;

          .title-text {

            padding: 15px;
            background: rgba(213, 234, 248, 0.8);
            color: #1E3B89;

            font-size: 15px;
            font-family: $fontR;

            span {
              font-family: $fontB;
              font-size: 17px;
            }
          }

          .triple-btn {
            padding: 10px;
            display: flex;
            justify-content: center;

            .btn {
              margin: 0 10px;
              width: 66px;
              min-width: 66px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;


              .text {
                color: #1E3B89;
                font-size: 12px;
                font-family: $fontR;
              }
            }
          }
        }
      }
    }
  }
}
</style>
