<template>
    <div class="item" :class="{disabled: isDisabled}">
        <div class="visible-item" :class="!isOpen ? '': 'active'" @click.stop="isOpen = !isOpen">
            <div class="icon-text">
                <div class="icon">
                    <svg v-if="defaultName === 'Стан'" width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19666 2C6.19666 0.895431 7.09209 0 8.19666 0H18.0898C19.1944 0 20.0898 0.895431 20.0898 2V2.9249H25.9522V34.0016H0.176758V2.9249H6.19666V2ZM6.19666 4.38677H1.67017V32.5265H24.4433V4.38677H20.0898V7.67778H6.19666V4.38677ZM7.55005 2.3532C7.55005 1.80092 7.99776 1.3532 8.55005 1.3532H17.7499C18.3022 1.3532 18.7499 1.80092 18.7499 2.3532V6.34649H7.55005V2.3532Z" fill="#1E3B89"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1498 5.36665C14.0004 5.36665 14.6898 4.67718 14.6898 3.82667C14.6898 2.97616 14.0004 2.28668 13.1498 2.28668C12.2993 2.28668 11.6099 2.97616 11.6099 3.82667C11.6099 4.67718 12.2993 5.36665 13.1498 5.36665ZM13.1558 4.38723C13.4587 4.38723 13.7042 4.1417 13.7042 3.83882C13.7042 3.53594 13.4587 3.29041 13.1558 3.29041C12.853 3.29041 12.6074 3.53594 12.6074 3.83882C12.6074 4.1417 12.853 4.38723 13.1558 4.38723Z" fill="#1E3B89"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1587 21.9506C16.5913 21.9506 19.3741 19.1679 19.3741 15.7352C19.3741 12.3026 16.5913 9.5199 13.1587 9.5199C9.72607 9.5199 6.94336 12.3026 6.94336 15.7352C6.94336 19.1679 9.72607 21.9506 13.1587 21.9506ZM13.1527 20.6402C15.8589 20.6402 18.0527 18.4464 18.0527 15.7402C18.0527 13.0341 15.8589 10.8403 13.1527 10.8403C10.4466 10.8403 8.25278 13.0341 8.25278 15.7402C8.25278 18.4464 10.4466 20.6402 13.1527 20.6402Z" fill="#1E3B89"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.15 12.0399C12.7892 12.0399 12.4967 12.3324 12.4967 12.6932V15.0731H10.1167C9.75588 15.0731 9.46338 15.3656 9.46338 15.7264C9.46338 16.0872 9.75588 16.3798 10.1167 16.3798H12.4967V18.7598C12.4967 19.1206 12.7892 19.4131 13.15 19.4131C13.5108 19.4131 13.8033 19.1206 13.8033 18.7598V16.3798H16.1833C16.5441 16.3798 16.8366 16.0872 16.8366 15.7264C16.8366 15.3656 16.5441 15.0731 16.1833 15.0731H13.8033V12.6932C13.8033 12.3324 13.5108 12.0399 13.15 12.0399Z" fill="#1E3B89"/>
                        <rect x="4.00342" y="26.1331" width="1.58665" height="10.9666" rx="0.793325" transform="rotate(-90 4.00342 26.1331)" fill="#1E3B89"/>
                        <rect x="4.00342" y="29.3996" width="1.58665" height="13.9532" rx="0.793325" transform="rotate(-90 4.00342 29.3996)" fill="#1E3B89"/>
                    </svg>
                </div>
                <p v-if="!selectedData">{{defaultName}}</p>
                <p v-else>{{selectedData}}</p>
            </div>
            <div class="arrow" :class="!isOpen ? '': 'rotated'">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.022 1L5.50928 5.53548" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.50924 5.53548L1 1" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <transition name="fade">
            <div class="container" :class="!isOpen ? '': 'active'" v-if="isOpen">
                <label class="checkbox-container" v-for="(d, index) in data" :key="index">
                    <div class="row name">
                        {{d.name}}
                        <input type="radio" :value="d" v-model="localSelectedData">
                        <span class="checkmark"></span>

                        <div class="icon" :class="openedPoints.indexOf(d.name) !== -1 ? 'rotated': ''" v-if="d.points" @click="openIfNoOpened(d.name)">
                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7788 1L6.26611 5.53548" stroke="#1E3B89" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.26608 5.53548L1.75684 1" stroke="#1E3B89" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <div class="row" v-if="openedPoints.indexOf(d.name) !== -1 && d.points">
                        <transition name="fade">
                            <ul class="points" v-if="openedPoints.indexOf(d.name) !== -1">
                                <li class="point" v-for="(p, pointIndex) in d.points" :key="pointIndex">
                                    {{p}}
                                </li>
                            </ul>
                        </transition>
                    </div>
                </label>                
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props : ['data', 'selectedData', 'iconArr', 'defaultName', 'isDisabled'],
    data : () => ({
        isOpen : false,
        localSelectedData : [],
        openedPoints : []
    }),
    created(){
        this.localSelectedData = this.selectedData;
    },
    watch : {
        localSelectedData : function(value){
            if(value && value.hasOwnProperty('name'))
                this.openedPoints.push(value.name);

            this.$emit('selectedDataChange', value);
        }
    },
    methods : {
        openIfNoOpened(val){
            if(this.openedPoints.includes(val)){
                this.openedPoints = this.openedPoints.filter(item => item !== val)
            }
            else{
                this.openedPoints.push(val);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/vars";

.item {
    border-bottom: 1px solid rgba(30, 59, 137, 0.25);

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .visible-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        cursor: pointer;

        .arrow{
            transition: transform 500ms ease;
        }

        .rotated{
            transform: rotate(-180deg);
            transition: transform 500ms ease;
        }


        .icon-text {
            display: flex;
            align-items: center;
            width: 90%;

            .icon {
                margin-right: 15px;
                max-width: 30px;
                width: 100%;

                svg {
                    height: auto;
                    display: block;
                }

            }

            p {
                color: #1E3B89;
                font-size: 15px;
                font-family: $fontR;
            }

            .clip{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .active{
        background: #B5CFE9;
    }

    .container{
        border-top: 1px solid #DAE3ED;

        max-height: 320px;
        overflow: auto;

        background: #B5CFE9;

        .checkbox-container {
            display: block;
            position: relative;
            padding-top: 11px;
            font-size: 15px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            color: #1E3B89;
            font-family: $fontB;

            display: column;
        }

        .row{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            border-bottom: 1px solid rgba(30, 59, 137, 0.25);

            padding-left: 35px;
            padding-right: 20px;
            padding-bottom: 15px;

            .icon{
                transition: transform 500ms ease;
            }

            .rotated{
                transform: rotate(-180deg);
                transition: transform 500ms ease;
            }

            .points{
                padding-top: 6px;
                margin-left: 20px;

                .point{
                    padding-bottom: 10px;
                }

                .point:last-child{
                    padding-bottom: 0;
                }
            }            
        }

        .name{
            font-family: $fontR;
        }

        .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: auto;
            left: 10px;
            height: 18px;
            width: 18px;
            background-color: #B5CFE9;
            border: 1px solid #1E3B89;
            // border-radius: 2px;
            border-radius: 50%;
            cursor: pointer;
        }

        .checkbox-container input:checked ~ .checkmark {
            background: #B5CFE9;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        .checkbox-container input:checked ~ .checkmark:after {
            display: block;
        }

        .checkbox-container .checkmark:after {
            left: 2px;
            top: 2px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #1E3B89;
            // width: 3px;
            // height: 8px;
            // border: solid white;
            // border-width: 0 3px 3px 0;
            // -webkit-transform: rotate(45deg);
            // -ms-transform: rotate(45deg);
            // transform: rotate(45deg);
        }
    }

    .hidden-item{
        padding: 15px 20px;
        cursor: pointer;

        border-top: 1px solid #DAE3ED;

        display: flex;
        flex-direction: row;
        align-items: center;

        background: #B5CFE9;
        box-shadow: 0px 14px 21px rgba(0, 0, 0, 0.08);

        .icon{
            width: 39px;
            height: 33px;
            margin-right: 10px;

            div{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg{
                    width: 100%;
                    height: auto;
                }
            }
        }

        .text{
            font-size: 15px;
            line-height: 20px;
            color: #1E3B89;
        }
    }

}
</style>