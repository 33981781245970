<template>
  <div class="library-item">

    <div class="head-links">
      <router-link class="back-links" to="/pediatrics">Головна</router-link>
      <span>/</span>
      <router-link class="back-links" to="/library">Бібліотека</router-link>
      <span>/</span>
      <a class="now-page">{{ articleObject.title }}</a>
    </div>

    <div class="content" :class="articleObject.sourceId != null ? 'visable' : ''"
         :key="articleObject.id + '_' + articleObject.sourceId">

      <guidelines v-if="articleObject.sourceId === 'guidance'" :articleItem="articleObject"/>

      <scientific-article v-if="articleObject.sourceId === 'article'" :articleItem="articleObject"/>

      <clinical-study v-if="articleObject.sourceId === 'research'" :articleItem="articleObject"/>

      <video-materials v-if="articleObject.sourceId === 'video'" :articleItem="articleObject"/>

      <reference-material v-if="articleObject.sourceId === 'compendium'" :articleItem="articleObject"/>

    </div>

  </div>

</template>

<script>
import axios from "axios";
import Guidelines from '../components/library-items/guidelines';
import ScientificArticle from '../components/library-items/scientific-article';
import ClinicalStady from '../components/library-items/clinical-study';
import VideoMaterials from '../components/library-items/video-materials';
import ReferenceMaterial from '../components/library-items/reference-material';

export default {
  name: "library-item",

  components: {
    'guidelines': Guidelines,
    'scientific-article': ScientificArticle,
    'clinical-study': ClinicalStady,
    'video-materials': VideoMaterials,
    'reference-material': ReferenceMaterial
  },

  data: () => ({

    articleObject: {},

    activeClass: "active",
  }),
  mounted() {
    if (sessionStorage.getItem("myConf") === "true") {
      this.isinteresting = true;
      sessionStorage.removeItem("myConf");
    }
  },

  beforeCreate() {
    const UNAUTHORIZED = 401;
    axios.interceptors.response.use(
        response => response,
        error => {
          const {status} = error.response;
          if (status === UNAUTHORIZED && sessionStorage.getItem('key')) {
            sessionStorage.removeItem('key');
            location.reload();
          }
          return Promise.reject(error);
        }
    );

    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

    axios.post(this.$root.globalUrl + "api/v1/library/" + this.$route.params.type + "/" + this.$route.params.id)
        .then(res => {
          res.data.data.sourceId = this.$route.params.type;
          this.articleObject = res.data.data;

        })

  },
  beforeRouteUpdate(to, from, next) {
    const UNAUTHORIZED = 401;
    axios.interceptors.response.use(
        response => response,
        error => {
          const {status} = error.response;
          if (status === UNAUTHORIZED) {
            sessionStorage.removeItem('key');
          }
          return Promise.reject(error);
        }
    );

    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

    axios.post(this.$root.globalUrl + "api/v1/library/" + to.params.type + "/" + to.params.id)
        .then(res => {
          res.data.data.sourceId = to.params.type;
          this.articleObject = res.data.data;

        })
        .catch(e => {
          if (e.response.status === 401 || e.response.status === 403) {
            sessionStorage.removeItem('key');
            location.reload();
          }
        })

    next();

  },
}
</script>

<style scoped lang="scss">
@import "../style/vars";

.library-item {
  font-family: "OpenSans-Bold";
  position: relative;
  background: #eff3f8;

  .content {
    opacity: 0;
  }

  .visable {
    opacity: 1;
    transition: opacity 1000ms ease;
  }

  .drop-item1, .drop-item2 {
    cursor: pointer;
    border-top: 1px solid #DAE3ED;
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    transition: background-color .5s ease;
    cursor: pointer;

    .visible-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      span {
        display: flex;
        font-size: 15px;
        font-family: $fontR;
        color: #1E3B89;
      }

      .icon {
        display: flex;
        margin-right: 20px;
      }

      .arrow {
        transition: transform .2s ease;
        display: flex;
        margin-left: auto;
      }
    }

    .hide-item {
      overflow-y: scroll;
      padding: 0 15px;
      z-index: 10;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      max-height: 0;
      background: #E0ECF7;
      /*overflow: hidden;*/
      transition: padding .5s ease, max-height .5s ease;


      p {
        padding: 10px 0;
        color: #1E3B89;
        font-size: 15px;
        font-family: $fontL;

        &.active, &:hover {
          font-family: $fontR;
        }
      }
    }

    &.active {
      background: #E0ECF7;

      .hide-item {
        border-top: 1px solid #DAE3ED;
        max-height: 500px;
        padding: 10px 15px;
      }

      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #00ACE6;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;

    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }

  .conferences {
    padding: 0 15px;

    &-filter {
      padding: 0.1px;
      margin-bottom: 30px;
      background: #fff;

      &-title {
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #9ab9d8;
        letter-spacing: 0.11em;
        padding: 11px 0;
        border-bottom: 1px solid #dfe7ef;
      }

      &-interesting {
        > div {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: normal;
          padding: 20px;
          color: #1e3b89;
          transition: all 0.3s;
        }

        &-past {
          cursor: pointer;
          border-top: 1px solid #DAE3ED;

          display: flex;
          justify-content: space-between;
          align-items: center;

          .score {

            background: #00ACE6;
            border-radius: 17px;

            padding: 5px 10px;
            font-size: 7px;
            font-family: $fontR;
            color: white;

            text-align: center;
            line-height: 1.3em;

            b {
              font-family: $fontB;
              font-size: 12px;
            }
          }
        }

        &-future {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .score {

            background: #00ACE6;
            border-radius: 17px;

            padding: 5px 10px;
            font-size: 7px;
            font-family: $fontR;
            color: white;

            text-align: center;
            line-height: 1.3em;

            b {
              font-family: $fontB;
              font-size: 12px;
            }
          }
        }
      }

      &-button {
        margin: 13px 0;
        display: flex;
        cursor: pointer;

        > div {
          width: 50%;
          text-align: center;
          position: relative;
        }

        &-all {
          border-right: 1px solid #dfe7ef;
        }
      }

      &-select {
        font-size: 15px;
        color: #1e3b89;
        cursor: pointer;

        .arrow-icon {
          background: url(../assets/img/arrow.svg) right center no-repeat;
          background-size: 9px 5px;
        }

        &-years {
          padding: 20px;
          border-bottom: 1px solid #e8edf4;
          border-top: 1px solid #e8edf4;
          cursor: pointer;
        }

        &-areas {
          padding: 20px;
          cursor: pointer;
        }

        &-list {
          height: 175px;
          overflow: hidden;
          overflow-y: auto;
          background: #e0ecf7;

          > div {
            > div {
              padding: 12px 20px;
              color: #1e3b89;
              font-size: 15px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .icon {
      width: 37px;
      height: 41px;
      margin: auto;
      position: relative;
    }

    .count {
      width: 25px;
      height: 25px;
      background: #00ace6;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      position: absolute;
      right: -8px;
      top: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /*POST CONTENT*/
    &-post {
      &-item {
        border-radius: 5px;
        background: #fff;
        margin-bottom: 29px;
        overflow: hidden;
        cursor: pointer;


        &.dis {
          .conferences-post-item-button {
            div {
              opacity: 0.3;
              cursor: pointer;
            }
          }
        }

        > div.content {
          padding: 15px;
          position: relative;

          display: flex;
          flex-direction: column;

          .score {

            position: absolute;
            right: 0;
            top: 0;
            width: 44px;
            height: 44px;

            background: rgba(0, 172, 230, 0.15);
            display: flex;
            justify-content: center;
            border-radius: 0 0 0 10px;

            p {
              text-align: center;
              color: #00ACE6;
              font-family: $fontR;
              font-size: 9px;
              line-height: 0.8em;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;

              ::v-deep span {
                font-family: $fontB;
                font-size: 15px;
              }
            }
          }
        }

        &-registration {
          text-align: center;
          background: #1e3b89;
          color: #fff;
          font-size: 12px;
          padding: 10px 0;
          cursor: pointer;
        }

        &-small {
          font-family: $fontB;
          font-size: 12px;
          color: rgba(30, 59, 137, 0.7);
          padding: 10px 0;
          margin-right: 44px;
        }

        &-title {
          font-size: 15px;
          color: #1e3b89;
          font-weight: 600;
          margin-bottom: 30px;
          margin-right: 44px;
        }

        &-date {
          display: flex;
          justify-content: space-between;
          margin: 12px 0 0 0;
          width: 100%;

          > div:first-child {
            font-size: 10px;
            color: #1e3b89;
            background: url(../assets/img/date_icon.svg) left center no-repeat;
            background-size: 13px 14px;
            padding-left: 18px;
          }

          > div:last-child {
            font-size: 10px;
            color: #1e3b89;
            background: url(../assets/img/area_icon.svg) left center no-repeat;
            background-size: 11px 14px;
            padding-left: 16px;
          }
        }

        &-button {
          border-top: 1px solid #dfe7ef;
          text-transform: uppercase;

          cursor: pointer;

          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          > div, a {
            display: block;
            width: 50%;
            text-align: center;
            font-size: 10px;
            padding: 5px 0;
            font-weight: normal;
          }

          > div:first-child, a:first-child {
            color: #00ace6;
            border-right: 1px solid #e3eaf1;

            span {
              background: url(../assets/img/callendar_icon.svg) left center no-repeat;
              background-size: 12px 15px;
              padding-left: 17px;
            }
          }

          > a:last-child {
            color: #1e3b89;

            span {
              background: url(../assets/img/search_icon.svg) left center no-repeat;
              background-size: 14px 15px;
              padding-left: 18px;
            }
          }
        }
      }
    }
  }

  .active_button {
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -13px;
      border-bottom: 2px solid #00ace6;
    }
  }

  .active_tab {
    background: #E0ECF7;
    cursor: pointer;
  }

  .fade-list-enter-active,
  .fade-list-leave-active {
    transition: opacity 0.5s;
  }

  .fade-list-enter,
  .fade-list-leave-to {
    opacity: 0;
  }
}
</style>
