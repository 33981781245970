<template>
    <div class="info-clinical" v-if="showInfo === true">

        <div class="icon">
            <svg width="25" height="37" viewBox="0 0 25 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.1705 36.2638H2.82955C1.68244 36.2638 0.743896 35.3348 0.743896 34.1994V2.80068C0.743896 1.66528 1.68244 0.736328 2.82955 0.736328H22.1774C23.3245 0.736328 24.2631 1.66528 24.2631 2.80068V34.1994C24.2561 35.3348 23.3176 36.2638 22.1705 36.2638Z"
                      stroke="#AC98C5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.9702 24.7653H14.1407V21.9647C14.1407 21.4761 13.7375 21.077 13.2439 21.077H11.7492C11.2556 21.077 10.8523 21.4761 10.8523 21.9647V24.7653H8.02281C7.5292 24.7653 7.12598 25.1644 7.12598 25.653V27.1324C7.12598 27.621 7.5292 28.0201 8.02281 28.0201H10.8523V30.8207C10.8523 31.3093 11.2556 31.7084 11.7492 31.7084H13.2439C13.7375 31.7084 14.1407 31.3093 14.1407 30.8207V28.0201H16.9702C17.4639 28.0201 17.8671 27.621 17.8671 27.1324V25.653C17.8671 25.1644 17.4639 24.7653 16.9702 24.7653Z"
                      stroke="#AC98C5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.49219 5.53943H19.2575" stroke="#AC98C5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M5.49219 10.3906H19.2575" stroke="#AC98C5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M5.49219 14.8909H19.2575" stroke="#AC98C5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </div>

        <p>Інформація на цьому порталі призначена для медичних працівників</p>

        <div class="close" @click="hideInfo()">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path d="M0.258301 0.258301L18.7418 18.7418" stroke="#AC98C5" stroke-width="1.7"
                          stroke-miterlimit="10"/>
                    <path d="M18.7418 0.258301L0.258301 18.7418" stroke="#AC98C5" stroke-width="1.7"
                          stroke-miterlimit="10"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="19" height="19" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>

    </div>
</template>

<script>
    export default {
        name: "info",
        data: () => ({
            showInfo: true
        }),
        methods: {
            hideInfo() {
                this.showInfo = false
            }
        }
    }
</script>

<style lang="scss">
@import "../../style/vars";
.close {
    cursor: pointer;
}

.info-clinical {
    height: 67px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: #F1EDF7;

    p {
        margin: 0 20px;
        color: #8F67A4;
        font-family: $fontR;
        font-size: 10px;
    }
}

</style>
