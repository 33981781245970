<template>
  <div class="send-question-block">
    <p class="h2-title"><span>Лінія підтримки</span><br>У Вас є питання? Напишіть нам!</p>
    <div class="open-question-btn" @click="questionDataPopup()">
      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.9123 1.97461H2.80212C1.81095 1.97461 1 2.83114 1 3.87802V10.9424V15.6166L3.62337 12.8458H14.9123C15.9035 12.8458 16.7145 11.9892 16.7145 10.9424V3.87802C16.7145 2.83114 15.9035 1.97461 14.9123 1.97461Z"
            stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
            stroke-linejoin="round"/>
        <path d="M4.5708 6.22729H13.2158" stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5708 8.97925H10.3196" stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p>Написати</p>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions([
      'setMenuData'
    ]),
    questionDataPopup() {
      this.setMenuData({popupState: '2'})
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/vars";

.send-question-block {
  background: url("../../assets/images/question-bg.jpg") no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 229px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .h2-title {
    font-family: $fontSB;
    font-size: 16px;
    text-align: center;
    color: white;

    span {
      font-family: $fontB;
      font-size: 28px;
    }

  }

  .open-question-btn {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    width: 148px;
    height: 41px;
    transition: all 0.2s ease;

    svg {
      path {
        transition: all 0.2s ease;

      }
    }

    p {
      transition: all 0.2s ease;
      margin-left: 15px;
      text-transform: uppercase;
      font-size: 15px;
      font-family: $fontB;
      color: #8F67A4;
    }

    &:active {
      background-color: #8F67A4;

      p {
        color: white;
      }

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}
</style>