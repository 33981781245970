<script>

export default {
  name: 'reg-conf-popup',
  props: {
    link: String
  },
  methods: {
    closeTrigger() {
      this.$emit('clicked')
    },
  },

  data: () => ({}),
}
</script>
<template>
  <div class="plan-popup">
    <div class="content">
      <div class="popup-header">
        <p>Програма заходу</p>
        <div class="close-btn" @click="closeTrigger">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M0.410156 0.257812L18.8937 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
              <path d="M18.8937 0.257812L0.410156 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect x="0.152344" width="19" height="19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="text-wrapper">
        <p style="text-align: center">Наведений графік є орієнтовним, можливі зміни</p>

        <a :href="`${link}`" class="watch" target="_blank">дивитись</a>

        <a download :href="`${link}`" class="download">завантажити</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../style/vars";

.plan-popup {
  width: 100%;
  height: 100%;
  background: rgba(88, 101, 136, 0.6);
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  padding: 0 15px;
  @media screen and (min-width: 426px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }

  .content {
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;

    display: flex;
    flex-direction: column;

    .popup-header {
      background: #EEF3F8;
      padding: 20px 15px;

      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        align-items: center;
        color: #9AB9D8;
        font-family: $fontB;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

    .text-wrapper {

      padding: 14px 16px 14px 16px;

      p {
        color: #1E3B89;
        font-size: 15px;
        font-family: $fontL;
      }

      a {
        max-width: 170px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: $fontB;
        font-size: 15px;
        text-transform: uppercase;
        background: #00ACE6;
        margin: 12px auto 0 auto;
        padding: 10px;
        border-radius: 4px;

        &.watch {
          border: 1px solid #00ACE6;
          background: rgba(0, 0, 0, 0);
          color: #00ACE6;
        }
      }
    }
  }
}
</style>