<template>
    <div class="dw-instruction">
        <div class="content">
            <div class="icon">
                <svg width="100" height="128" viewBox="0 0 100 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d)">
                    <path d="M57.7195 104H24.7897C21.0553 104 18 100.945 18 97.2102V18.7897C18 15.0553 21.0553 12 24.7897 12H57.7195C61.4538 12 64.5092 15.0553 64.5092 18.7897V97.2102C64.5092 100.945 61.4538 104 57.7195 104Z" fill="white"/>
                    <path d="M62.642 92.7971L62.642 18.4503L19.8671 18.4503L19.8671 92.7971L62.642 92.7971Z" fill="#8F67A4"/>
                    <path d="M62.642 21.3359V18.4503L19.8671 18.4503V21.3359L62.642 21.3359Z" fill="#AC98C5"/>
                    <path d="M41.5941 101.454C43.3753 101.454 44.8192 100.01 44.8192 98.2289C44.8192 96.4477 43.3753 95.0038 41.5941 95.0038C39.813 95.0038 38.369 96.4477 38.369 98.2289C38.369 100.01 39.813 101.454 41.5941 101.454Z" fill="#F1F1F1"/>
                    <path d="M53.2214 16.0741H29.9668C29.5475 16.0741 29.203 15.7312 29.203 15.3103C29.203 14.891 29.5475 14.5464 29.9668 14.5464H53.2214C53.6423 14.5464 53.9852 14.8893 53.9852 15.3103C53.9852 15.7312 53.6423 16.0741 53.2214 16.0741Z" fill="#F1F1F1"/>
                    <path d="M31.1209 34.966H24.6198C23.4995 34.966 22.5829 34.0494 22.5829 32.9291V26.428C22.5829 25.3077 23.4995 24.3911 24.6198 24.3911H31.1209C32.2412 24.3911 33.1578 25.3077 33.1578 26.428V32.9291C33.1578 34.0494 32.2412 34.966 31.1209 34.966Z" fill="#AC98C5"/>
                    <path d="M44.5951 34.966H38.094C36.9737 34.966 36.0571 34.0494 36.0571 32.9291V26.428C36.0571 25.3077 36.9737 24.3911 38.094 24.3911H44.5951C45.7154 24.3911 46.632 25.3077 46.632 26.428V32.9291C46.632 34.0494 45.7154 34.966 44.5951 34.966Z" fill="#AC98C5"/>
                    <path d="M57.9012 34.966H51.4001C50.2798 34.966 49.3632 34.0494 49.3632 32.9291V26.428C49.3632 25.3077 50.2798 24.3911 51.4001 24.3911H57.9012C59.0215 24.3911 59.9381 25.3077 59.9381 26.428V32.9291C59.9381 34.0494 59.0215 34.966 57.9012 34.966Z" fill="#AC98C5"/>
                    <path d="M31.1209 48.7762H24.6198C23.4995 48.7762 22.5829 47.8596 22.5829 46.7393V40.2382C22.5829 39.1179 23.4995 38.2013 24.6198 38.2013H31.1209C32.2412 38.2013 33.1578 39.1179 33.1578 40.2382V46.7393C33.1578 47.8596 32.2412 48.7762 31.1209 48.7762Z" fill="#AC98C5"/>
                    <path d="M44.5951 48.7762H38.094C36.9737 48.7762 36.0571 47.8596 36.0571 46.7393V40.2382C36.0571 39.1179 36.9737 38.2013 38.094 38.2013H44.5951C45.7154 38.2013 46.632 39.1179 46.632 40.2382V46.7393C46.632 47.8596 45.7154 48.7762 44.5951 48.7762Z" fill="#AC98C5"/>
                    <path d="M57.9012 48.7762H51.4001C50.2798 48.7762 49.3632 47.8596 49.3632 46.7393V40.2382C49.3632 39.1179 50.2798 38.2013 51.4001 38.2013H57.9012C59.0215 38.2013 59.9381 39.1179 59.9381 40.2382V46.7393C59.9381 47.8596 59.0215 48.7762 57.9012 48.7762Z" fill="#AC98C5"/>
                    <path d="M31.1209 62.5218H24.6198C23.4995 62.5218 22.5829 61.6052 22.5829 60.4849V53.9838C22.5829 52.8635 23.4995 51.9469 24.6198 51.9469H31.1209C32.2412 51.9469 33.1578 52.8635 33.1578 53.9838V60.4849C33.1578 61.6052 32.2412 62.5218 31.1209 62.5218Z" fill="#AC98C5"/>
                    <path d="M44.5951 62.5218H38.094C36.9737 62.5218 36.0571 61.6052 36.0571 60.4849V53.9838C36.0571 52.8635 36.9737 51.9469 38.094 51.9469H44.5951C45.7154 51.9469 46.632 52.8635 46.632 53.9838V60.4849C46.632 61.6052 45.7154 62.5218 44.5951 62.5218Z" fill="#AC98C5"/>
                    <path d="M57.9012 62.5218H51.4001C50.2798 62.5218 49.3632 61.6052 49.3632 60.4849V53.9838C49.3632 52.8635 50.2798 51.9469 51.4001 51.9469H57.9012C59.0215 51.9469 59.9381 52.8635 59.9381 53.9838V60.4849C59.9381 61.6052 59.0215 62.5218 57.9012 62.5218Z" fill="#AC98C5"/>
                    <path d="M31.1209 76.332H24.6198C23.4995 76.332 22.5829 75.4154 22.5829 74.2951V67.794C22.5829 66.6737 23.4995 65.7571 24.6198 65.7571H31.1209C32.2412 65.7571 33.1578 66.6737 33.1578 67.794V74.2951C33.1578 75.4154 32.2412 76.332 31.1209 76.332Z" fill="#AC98C5"/>
                    <path d="M44.5951 76.332H38.094C36.9737 76.332 36.0571 75.4154 36.0571 74.2951V67.794C36.0571 66.6737 36.9737 65.7571 38.094 65.7571H44.5951C45.7154 65.7571 46.632 66.6737 46.632 67.794V74.2951C46.632 75.4154 45.7154 76.332 44.5951 76.332Z" fill="white"/>
                    <path d="M80.8634 29.9301C80.5488 29.9547 80.3011 29.9858 80.031 30.0196C79.7663 30.0556 79.5053 30.0955 79.246 30.1373C78.7273 30.2246 78.2136 30.3251 77.7052 30.4352C76.688 30.6629 75.6843 30.9285 74.6936 31.2394C72.7126 31.8537 70.7903 32.6328 68.9299 33.5413C67.0732 34.45 65.282 35.4957 63.5737 36.6586C61.869 37.8254 60.2597 39.1268 58.7309 40.5153L57.6017 41.5747L56.5228 42.6847C56.1553 43.0487 55.8185 43.4383 55.4781 43.824C55.1413 44.2136 54.7845 44.5893 54.4622 44.9907C53.8193 45.7953 53.1547 46.5842 52.5662 47.4283C52.2674 47.8475 51.9541 48.2548 51.6662 48.6818L50.819 49.9691L50.3954 50.6146L50.0027 51.2782L49.2193 52.6036C48.9569 53.0465 48.7329 53.5079 48.4888 53.9591C48.252 54.4143 48.0062 54.8635 47.7749 55.3208L46.6925 57.6253L44.67 56.7241C44.78 57.9288 44.9224 59.1179 45.0659 60.2773C45.2537 61.4496 45.4517 62.6019 45.6582 63.7283C46.5473 62.9728 47.4299 62.2413 48.3003 61.5354C49.2165 60.8482 50.1097 60.1787 51.0107 59.545L48.9883 58.6439L50.0276 56.4366C50.2469 55.9975 50.4826 55.5684 50.7093 55.1333C50.9432 54.7022 51.157 54.261 51.4074 53.8399L52.1566 52.5748L52.5312 51.9422L52.9349 51.3295L53.7405 50.1021C54.0126 49.6968 54.3119 49.3095 54.5969 48.9103C55.1557 48.1079 55.7869 47.3606 56.3946 46.5956C56.6993 46.2141 57.0366 45.86 57.3558 45.4902C57.6785 45.1244 57.9977 44.7546 58.3457 44.4121L59.3662 43.3633L60.4331 42.3648C61.8767 41.055 63.3934 39.8283 64.9983 38.7284C66.6086 37.6343 68.291 36.6495 70.0391 35.7943C71.787 34.9429 73.5936 34.2095 75.4461 33.6348C76.3706 33.3455 77.3083 33.0978 78.2482 32.8875C78.7191 32.7833 79.1913 32.6923 79.663 32.6124C79.8998 32.5734 80.1346 32.5363 80.3654 32.5064C80.5925 32.4764 80.8456 32.4474 81.0292 32.4324L80.8634 29.9301Z" fill="#3D3D3D"/>
                    </g>
                    <defs>
                    <filter id="filter0_d" x="0" y="0" width="99.0292" height="128" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="6"/>
                    <feGaussianBlur stdDeviation="9"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.344646 0 0 0 0 0.31625 0 0 0 0 0.366667 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    </defs>
                </svg>
            </div>
            <div class="description">
                <div class="text">
                    Інструкція по завантаженню порталу на Ваш телефон
                </div>
                <div class="btn" @click="goToInstruction()">
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.18213 10.4436H13.074" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M7.08984 6.08519V1.18359" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M10.567 3.66959L7.09014 6.85296L3.61328 3.66959" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                    <p>Ознайомитись</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods : {
        goToInstruction(){
            this.$router.push('/clinical/instruction');
        }
    }
}
</script>

<style lang="scss">
@import "../../style/vars";



.dw-instruction{
    background: #F1EDF7;

.content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 50px 20px;

    .description{
        display: flex;
        flex-direction: column;

        .text{
            font-size: 14px;
            line-height: 19px;
            color: #4A2A80;
            padding-bottom: 11px;
        }
    }
}



.btn {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #8F67A4;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    padding: 10px 15px;
    transition: all 0.2s ease;

    svg {
        path {
        transition: all 0.2s ease;

        }
    }

    p {
        transition: all 0.2s ease;
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 15px;
        font-family: $fontB;
        color: #FFFFFF;
    }

    &:active {
        background-color: #8F67A4;

        p {
        color: white;
        }

        svg {
        path {
            stroke: white;
        }
        }
    }
}
}

</style>
