<template>
  <div class="conferences-single">

    <div class="head-links">
      <router-link class="back-links" to="/pediatrics">Головна</router-link>
      <span>/</span>
      <router-link class="now-page" to="/conferences">Конференції</router-link>
    </div>

    <section class="conferences">
      <div class="conferences-post">
        <router-link to="/conferences">
          <div class="conferences-post-back">Конференції</div>
        </router-link>

        <div class="points" v-html="postObject.points"></div>

        <div class="conferences-post-title">
          <div v-if="postObject.private" class="conferences-post-title-registration">Обов’язкова реєстрація
          </div>

          <div class="cont">
            <div style="color: red" class="conferences-post-title-small" v-show="postObject.description !==null">
              {{ postObject.description }}
            </div>
            <div class="conferences-post-title-big">{{ postObject.title }}</div>
          </div>
        </div>

        <div class="conferences-post-date">
          <div>
            <span v-html="postObject.startAt"></span>
            <span
                v-if="postObject.year >= currentYear.getFullYear() && !postObject.timer.includes('-')">(за {{
                postObject.timer
              }})</span>
            <span
                v-if="postObject.timer.includes('-')">(відбулось)</span>
          </div>
          <div>{{ postObject.city }}</div>
        </div>

        <div class="conferences-post-content">

          <div v-if="postObject.conferenceVideos.length !== 0">
            <div class="conferences-post-content-video" v-for="(key, value ) in postObject.conferenceVideos">
              <a :href="`${key.link}`">
                <img src="../assets/bg-test.png" alt="">
                <div class="center">
                  <p v-html="key.name"></p>
                  <div class="btn">
                    <p>Переглянути відео</p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div v-if="postObject.specialty !== null">
            <div class="conferences-post-content-title color1">Запрошуються</div>
            <div class="conferences-post-content-text">
              {{ postObject.specialty }}
            </div>
          </div>
          <div v-if="postObject.organiser !== null">
            <div class="conferences-post-content-title color2">Організатор</div>
            <div class="conferences-post-content-text">
              {{ postObject.organiser }}
            </div>
          </div>
          <div v-if="postObject.partner !== null">
            <div class="conferences-post-content-title color2">Технічний партнер</div>
            <div class="conferences-post-content-text">
              {{ postObject.partner }}
            </div>
          </div>
          <!--          <div>-->
          <!--            <div class="conferences-post-content-title">Країни-учасниці</div>-->
          <!--            <div class="conferences-post-content-text">{{postObject.countries}}-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <div class="conferences-post-content-title">-->
          <!--              <span>Кількість учасників</span>-->
          <!--              <span class="conferences-post-content-title-count">{{ postObject.size }}</span>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div class="conferences-post-phone" v-if="postObject.phone">
          <span>Контактний<br>телефон</span>
          <span>+38&nbsp;<a :href="`tel:${postObject.phone}`">{{ postObject.phone }}</a></span>
        </div>

        <div class="conferences-post-phone" v-if=" postObject.countries !== null && postObject.countries.includes('@')">
          <span>E-mail</span>
          <span><a :href="`mailto:${postObject.countries}`">{{ postObject.countries }}</a></span>
        </div>

        <div class="conferences-post-info">
          <div class="border">

            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.9717" cy="12.4437" r="11.5" fill="white" stroke="#1E3B89"/>
              <path
                  d="M13.9285 19.1637H11.6665V8.75426H13.9285V19.1637ZM11.5317 5.99661C11.5317 5.60132 11.6408 5.29701 11.859 5.08367C12.0836 4.87034 12.4013 4.76367 12.812 4.76367C13.2098 4.76367 13.5178 4.87034 13.736 5.08367C13.9606 5.29701 14.0729 5.60132 14.0729 5.99661C14.0729 6.37308 13.9606 6.67112 13.736 6.89073C13.5178 7.10406 13.2098 7.21073 12.812 7.21073C12.4013 7.21073 12.0836 7.10406 11.859 6.89073C11.6408 6.67112 11.5317 6.37308 11.5317 5.99661Z"
                  fill="#1E3B89"/>
            </svg>

            <p><span>Виникло запитання щодо реєстрації або проведення заходу?</span> Будь ласка, звертайтеся за
              вказаними
              вище контактами до організатора або технічного партнера.</p>
            <p>Nutricia ділиться з вами інформацією про заходи, але не відповідає за вирішення організаційних питань</p>
          </div>
        </div>

        <div class="conferences-post-button">

          <div v-if="postObject.private"
               class="conferences-post-button-title"
          >Ця подія є платною та вимагає обов’язкової реєстрації.
          </div>

          <a v-if="postObject.agenda !== null"
             class="conferences-post-button-plan"
             @click="popupType = '1'; popupLink = `${$root.globalUrl}agenda/${postObject.agenda}`">
            <p>
                <span>
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M15.3086 8.57969H9.73093C9.44917 8.57969 9.22077 8.80809 9.22077 9.08984C9.22077 9.3716 9.44917 9.6 9.73093 9.6H15.3086C15.5903 9.6 15.8187 9.3716 15.8187 9.08984C15.8187 8.80809 15.5903 8.57969 15.3086 8.57969ZM15.3086 12.5406H9.73093C9.44917 12.5406 9.22077 12.769 9.22077 13.0508C9.22077 13.3325 9.44917 13.5609 9.73093 13.5609H15.3086C15.5903 13.5609 15.8187 13.3325 15.8187 13.0508C15.8187 12.769 15.5903 12.5406 15.3086 12.5406ZM15.3086 16.4H9.73093C9.44917 16.4 9.22077 16.6284 9.22077 16.9102C9.22077 17.1919 9.44917 17.4203 9.73093 17.4203H15.3086C15.5903 17.4203 15.8187 17.1919 15.8187 16.9102C15.8187 16.6284 15.5903 16.4 15.3086 16.4ZM17.1601 2.49965H14.3198C13.8892 2.06841 13.3128 1.80234 12.6784 1.80234H12.4104C12.1337 1.25928 11.5979 0.9 11 0.9C10.402 0.9 9.86631 1.25929 9.58958 1.80234H9.32073C8.68638 1.80234 8.11002 2.06841 7.67938 2.49965H4.83993C3.76249 2.49965 2.9 3.44956 2.9 4.59948V20.0002C2.9 21.1501 3.76249 22.1 4.83993 22.1H17.1601C18.2375 22.1 19.1 21.1501 19.1 20.0002V4.59948C19.1 3.44956 18.2376 2.49965 17.1601 2.49965ZM9.32077 2.82265H9.90892L10.2727 2.42168L10.3696 2.44632C10.3696 2.44631 10.3696 2.44631 10.3696 2.4463C10.4496 2.13156 10.7125 1.92031 11 1.92031C11.2876 1.92031 11.5505 2.13157 11.6304 2.44631C11.6856 2.6634 11.8706 2.82265 12.0911 2.82265H12.6784C13.4047 2.82265 14.0193 3.40703 14.1276 4.18128H7.87161C7.97994 3.40703 8.59457 2.82265 9.32077 2.82265ZM18.1453 20.0002C18.1453 20.6034 17.6956 21.0797 17.1601 21.0797H4.83993C4.30446 21.0797 3.85472 20.6034 3.85472 20.0002V4.59948C3.85472 3.99632 4.30446 3.51996 4.83993 3.51996H7.04897C6.95278 3.80225 6.90001 4.10701 6.90001 4.42484V4.69144C6.90001 4.96519 7.10604 5.2016 7.37737 5.2016H14.6219C14.8932 5.2016 15.0992 4.96519 15.0992 4.69144V4.42484C15.0992 4.10705 15.0465 3.80226 14.9503 3.51996H17.1601C17.6956 3.51996 18.1453 3.99632 18.1453 4.59948V20.0002Z"
    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.2"/>
<circle cx="7" cy="9" r="1" fill="#00ACE6"/>
<circle cx="7" cy="13" r="1" fill="#00ACE6"/>
<circle cx="7" cy="17" r="1" fill="#00ACE6"/>
</svg>
                </span>
              Програма заходу
            </p>
          </a>

          <div class="conferences-post-button-tosite" v-if="postObject.url !== null && postObject.timer.includes('-')">
            <a @click="popupType = '2'; popupLink = `${postObject.url}`; type='2'">
              <p>
                <span>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M6.73097 19.922L1.38608 18.0697L15.75 10.3719L11.564 25.821L8.42773 21.2156L4.67305 25.9001L2.97629 24.6065L6.73097 19.922Z"
    stroke="#00ACE6" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path
    d="M25.0913 9.59623H19.8711M21.8641 3.016L18.5797 6.98608M14.5548 1.37796L15.7293 6.35587M8.72143 5.92467L13.4141 8.16486M21.8641 16.1765L18.5797 12.2064"
    stroke="#00ACE6" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </span>
                сайт заходу
              </p>
            </a>
          </div>

          <div v-bind:class="[{ dis: postObject.registrationUrl === null  }]"
               class="conferences-post-button-tosite"
               v-if="!postObject.timer.includes('-')">
            <a @click="popupType = '2'; popupLink = `${postObject.registrationUrl}`; type='1'"
               v-if="postObject.registrationUrl !== null">
              <p>
                <span>
                   <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M16.0228 1.33788L17.5041 2.34003C17.6871 2.46381 17.7351 2.71249 17.6113 2.89547L10.3102 13.6874C10.253 13.772 10.1656 13.8315 10.0659 13.8537L8.79458 14.1371C8.57015 14.1871 8.34986 14.038 8.3128 13.8111L8.10282 12.5256C8.08636 12.4248 8.10907 12.3216 8.16629 12.237L15.4674 1.44504C15.5912 1.26207 15.8398 1.21409 16.0228 1.33788Z"
    stroke="#00ACE6" stroke-width="1.2"/>
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.1366 2.40344H2.40918C1.30461 2.40344 0.40918 3.29887 0.40918 4.40344V20.2891C0.40918 21.3937 1.30461 22.2891 2.40918 22.2891H14.1851C15.2896 22.2891 16.1851 21.3937 16.1851 20.2891V11.4666H14.9851V20.2891C14.9851 20.731 14.6269 21.0891 14.1851 21.0891H2.40918C1.96735 21.0891 1.60918 20.731 1.60918 20.2891V4.40344C1.60918 3.96161 1.96735 3.60344 2.40918 3.60344H11.1366V2.40344Z"
      fill="#00ACE6"/>
</svg>
                </span>
                Реєстрація
              </p>
            </a>

            <a v-else>
              <p>
                <span>
                   <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M16.0228 1.33788L17.5041 2.34003C17.6871 2.46381 17.7351 2.71249 17.6113 2.89547L10.3102 13.6874C10.253 13.772 10.1656 13.8315 10.0659 13.8537L8.79458 14.1371C8.57015 14.1871 8.34986 14.038 8.3128 13.8111L8.10282 12.5256C8.08636 12.4248 8.10907 12.3216 8.16629 12.237L15.4674 1.44504C15.5912 1.26207 15.8398 1.21409 16.0228 1.33788Z"
    stroke="#00ACE6" stroke-width="1.2"/>
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.1366 2.40344H2.40918C1.30461 2.40344 0.40918 3.29887 0.40918 4.40344V20.2891C0.40918 21.3937 1.30461 22.2891 2.40918 22.2891H14.1851C15.2896 22.2891 16.1851 21.3937 16.1851 20.2891V11.4666H14.9851V20.2891C14.9851 20.731 14.6269 21.0891 14.1851 21.0891H2.40918C1.96735 21.0891 1.60918 20.731 1.60918 20.2891V4.40344C1.60918 3.96161 1.96735 3.60344 2.40918 3.60344H11.1366V2.40344Z"
      fill="#00ACE6"/>
</svg>
                </span>
                Реєстрація
              </p>
            </a>
          </div>


          <div v-if="postObject.favorite === false" class="conferences-post-button-interesting bg"
               @click="addToFavorite(postObject.id)">
            <p>
                <span>
                  <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5182 2.09644H1.38867V20.2608H17.5182V2.09644Z" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M5.24854 0.261047V4.22491" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M9.4458 0.261047V4.22491" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M13.6431 0.261047V4.22491" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M4.97248 11.0109L8.12796 14.0046L13.2194 8.8526" stroke="white"/>
</svg>

                </span>
              додати до цікавих
            </p>
          </div>

          <div v-if="postObject.favorite === true" class="conferences-post-button-interesting bg"
               @click="addToFavorite(postObject.id)">
            <p>
                <span>
                    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5182 2.09644H1.38867V20.2608H17.5182V2.09644Z" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M5.24854 0.261047V4.22491" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M9.4458 0.261047V4.22491" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M13.6431 0.261047V4.22491" stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
<path d="M4.97248 11.0109L8.12796 14.0046L13.2194 8.8526" stroke="white"/>
</svg>
                </span>
              Видалити з цікавих
            </p>
          </div>

        </div>
      </div>
    </section>

    <planPopup @clicked="closePopUp" :link="popupLink" v-if="popupType === '1'"></planPopup>
    <regConfPopup @clicked="closePopUp" :link="popupLink" :type="type" v-if="popupType === '2'"></regConfPopup>

  </div>
</template>

<script>
import axios from "axios"
import planPopup from "@/components/conf-popup/plan-popup.vue"
import regConfPopup from "@/components/conf-popup/reg-conf-popup.vue"


export default {

  name: "SingleConferences",

  components: {
    planPopup,
    regConfPopup
  },

  data: () => ({
    postObject: {},

    currentYear: new Date(),
    popupPlan: false,
    popupReg: false,
    url: location.origin,

    popupType: null,
    popupLink: '',
    type: ''
  }),

  created() {
    this.getConfData();
  },

  methods: {
    closePopUp() {
      this.popupType = null
      this.popupLink = ''
      this.type = ''
    },

    addToFavorite(id) {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.post(this.$root.globalUrl + "api/v1/conference/", {
        "id": id
      }).then((res) => {
        this.getConfData();

      }).catch((error) => {

      })
    },
    getConfData() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/conference/" + this.$route.params.id).then((res) => {


        let localArray = res.data;

        this.postObject = {};

        const monthNames = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня",];

        const days = ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

        let date = Date.parse(localArray.startAt);
        let dateEnd = Date.parse(localArray.endAt);

        let currentDate = new Date(date);
        let endDate = new Date(dateEnd);
        let timeNow = new Date();
        let timeToConf = this.count(currentDate);


        if (currentDate !== endDate && localArray.endAt !== null && currentDate.getDate() !== endDate.getDate()) {
          localArray.startAt = currentDate.getDate() + " " + monthNames[currentDate.getMonth()] + " " + "- " + endDate.getDate() + " " + monthNames[endDate.getMonth()] + ",<br>" + days[currentDate.getDay()] + "-" + days[endDate.getDay()] + " ";
        } else {
          localArray.startAt = currentDate.getDate() + " " + monthNames[currentDate.getMonth()] + ",<br>" + days[currentDate.getDay()] + " ";
        }

        localArray["year"] = currentDate.getFullYear();
        localArray["timer"] = timeToConf + ' ' + this.declOfNum(timeToConf, ["день", "дні", "днів"]);
        localArray['points'] = '<span>' + 'Кількість атестаційних балів' + '</span>' + '<span>' + localArray.points + '</span>'

        this.postObject = localArray;

      }).catch((error) => {

      })
    },
    count(timeStamp) {
      // let currentDate = timeStamp;
      // let setdate2 = new Date(currentDate.getFullYear(), 0, 1, 0, 0);
      // let day2 = (currentDate - setdate2) / 1000 / 60 / 60 / 24;
      // day2 = Math.round(day2);

      // let now = new Date();
      // let setdate = new Date(now.getFullYear(), 0, 1, 0, 0);
      // let day = (now - setdate) / 1000 / 60 / 60 / 24;
      // day = Math.round(day);

      // return day2 - day;

      let currentDate = timeStamp;
      let now = new Date();

      var daysLag = Math.ceil((currentDate.getTime() - now.getTime()) / (1000 * 3600 * 24));

      return daysLag;
    },
    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
  }
};
</script>

<style lang="scss">
@import "../style/vars";

.conferences-single {
  font-family: "OpenSans-Bold";

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }

  .conferences {

    .points {

      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(0, 172, 230, 0.15);
      border-radius: 10px 10px 0 0;

      span {
        &:nth-child(1) {
          font-size: 12px;
          font-family: $fontSB;
          color: #00ACE6;
        }

        &:nth-child(2) {
          color: #00ACE6;
          font-size: 15px;
          font-family: $fontB;
        }

      }
    }

    &-post {
      margin: 15px 15px 0 15px;
      background: #fff;
      border-radius: 5px;
      margin-bottom: 50px;

      &-back {
        text-align: center;
        letter-spacing: 0.11em;
        text-transform: uppercase;
        color: #9ab9d8;
        font-size: 12px;
        background: url(../assets/img/arrow_back.png) 17px center no-repeat;
        background-size: 5px 9px;
        padding: 7px 0;
      }

      &-title {
        background: #dae3ed;

        div.cont {
          padding: 15px;
        }

        &-registration {
          font-size: 12px;
          background: #1e3b89;
          border-radius: 5px 5px 0 0;
          text-align: center;
          color: #fff;
          padding: 7px 0;
        }

        &-small {
          font-size: 14px;
          line-height: 17px;
          font-family: $fontSB;
          padding: 0 0 17px 0;
        }

        &-big {
          font-weight: 600;
          font-size: 16px;
          line-height: normal;
          color: #1e3b89;
        }
      }

      &-date {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: 300;
        font-size: 12px;
        line-height: normal;
        color: #1e3b89;
        padding: 15px;
        border-bottom: 1px solid #dae3ed;

        > div:first-child {
          font-size: 10px;
          color: #1e3b89;
          background: url(../assets/img/date_icon.svg) left top no-repeat;
          background-size: 13px 14px;
          padding-left: 18px;
        }

        > div:last-child {
          font-size: 10px;
          color: #1e3b89;
          background: url(../assets/img/area_icon.svg) left top no-repeat;
          background-size: 11px 14px;
          padding-left: 16px;
          display: flex;
          align-items: flex-start;
        }
      }

      &-content {
        padding: 15px;
        border-bottom: 1px solid #dae3ed;

        > div {
          margin-bottom: 13px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          padding-bottom: 5px;

          &.color1 {
            color: #1E3B89;
          }

          &.color2 {
            color: #00ACE6;
          }

          &-count {
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #1e3b89;
            font-weight: 100;
            float: right;
          }
        }

        &-text {
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #1e3b89;
        }

        &-video {
          position: relative;
          border-radius: 7px;
          overflow: hidden;
          padding-top: 5px;

          a {
            &:active {
              .btn {
                background: rgba(255, 255, 255, 0.76);
              }
            }
          }

          .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 220px;

            p {
              font-size: 16px;
              color: #FFFFFF;
              font-family: $fontB;
              width: 100%;
              text-align: center;
            }

            .btn {
              margin-top: 15px;
              display: flex;
              text-decoration: none;
              justify-content: center;
              align-items: center;
              background: #FFFFFF;
              border-radius: 4px;
              padding: 10px 0;

              transition: all .2s ease;


              p {
                color: #00ACE6;
                text-transform: uppercase;
                font-family: $fontB;
              }
            }
          }

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      &-phone {
        font-size: 16px;
        color: #1e3b89;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #dae3ed;

        span:last-child {
          color: #1E3B89;
          font-weight: 300;
          font-family: $fontL;

          display: flex;
          align-items: center;
        }

        a {
          font-size: 16px;
          color: #1e3b89;
          font-family: $fontSB;

        }

        > span:last-child {
        }
      }

      &-button {
        padding: 30px 0 20px 0;

        > div:last-child {
          margin-bottom: 0;
        }

        &-title {
          font-weight: bold;
          font-size: 15px;
          line-height: normal;
          text-align: center;
          color: #1e3b89;
          padding-top: 25px;
        }

        &-plan, &-interesting, &-tosite {

          max-width: 240px;
          max-height: 40px;
          min-height: 40px;
          height: 100%;
          width: 100%;
          cursor: pointer;

          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          margin: 0 auto 15px auto;

          border: 1px solid #00ACE6;
          border-radius: 4px;
          transition: all .2s ease;

          a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.dis {
            border: 1px solid #CBDFF2;

            cursor: default !important;

            a {
              cursor: default !important;

            }

            path {
              stroke: #CBDFF2;
              fill: #CBDFF2;
            }

            p {
              color: #CBDFF2;
            }
          }

          &:not(.dis) {
            &:active {
              background: rgba(0, 172, 230, 0.2);
            }
          }


          &.bg {
            background: #00ACE6 !important;

            p {
              color: white;
            }
          }

          p {


            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;

            color: #00ACE6;
            font-family: $fontB;
            text-transform: uppercase;

            span {
              max-width: 23px;
              min-width: 23px;
              width: 100%;

              margin-right: 5px;

              height: auto;
              display: block;
            }
          }
        }
      }

      &-info {
        padding: 20px 14px 0 25px;

        .border {
          padding: 15px 5px 15px 22px;
          border: 1px solid #1E3B89;
          border-radius: 4px;
          position: relative;

          svg {
            width: 25px;
            height: 25px;
            display: block;
            position: absolute;
            left: -13px;
            top: 9px;
          }

          p {
            color: #1E3B89;
            font-size: 11px;
            font-family: $fontL;
            margin-bottom: 10px;
            line-height: 122.56%;


            &:last-child {
              margin-bottom: 0;
            }

            @media screen and (min-width: 425px) {
              font-size: 13px;
              line-height: 130%;

            }

            span {
              font-family: $fontSB;
            }

          }
        }
      }
    }
  }

  .popupPlan {
    background: rgba(88, 101, 136, 0.6);

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 426px) {
      position: fixed;
      height: 100vh;
      top: 0;
      right: 0;
      padding-top: 20px;

      margin: 0 auto;

      max-width: 425px;
      width: 100%;
    }

    .popupPlan-content {
      background: white;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;

      .popupPlan-title {
        height: 60px;
        background: #EEF3F8;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        p {
          color: #9AB9D8;
          font-size: 12px;

          font-family: $fontB;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      .center-text {
        padding: 0 15px;
        margin: 30px 0;

        p {
          font-family: $fontL;
          font-size: 15px;
          line-height: 20px;

          color: #1E3B89;

        }
      }

      .btn-wrapper {
        padding: 15px;
        width: 70%;
        margin: 0 auto;

        .show {
          margin-bottom: 20px;

          width: 100%;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;

          border: 1px solid #00ACE6;
          border-radius: 4px;

          p {
            color: #00ACE6;
            font-size: 15px;
            font-family: $fontB;
            text-transform: uppercase;
            letter-spacing: 0.05em;

          }
        }

        .download {
          width: 100%;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;

          background: #00ACE6;
          border-radius: 4px;

          p {
            color: white;
            font-size: 15px;
            font-family: $fontB;
            letter-spacing: 0.05em;

            text-transform: uppercase;
          }
        }
      }
    }
  }

  .popupReg {
    background: rgba(88, 101, 136, 0.6);

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 426px) {
      position: fixed;
      height: 100vh;
      top: 0;
      right: 0;
      padding-top: 20px;

      margin: 0 auto;

      max-width: 425px;
      width: 100%;
    }

    .popupReg-content {
      background: white;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;

      .popupReg-title {
        height: 60px;
        background: #EEF3F8;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        p {
          color: #9AB9D8;
          font-size: 12px;

          font-family: $fontB;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      .center-text {
        padding: 0 15px;
        margin: 30px 0;

        p {
          font-family: $fontL;
          font-size: 15px;
          line-height: 20px;

          color: #1E3B89;

        }
      }

      .btn-wrapper {
        padding: 15px;
        width: 70%;
        margin: 0 auto;

        .show {
          margin-bottom: 20px;

          width: 100%;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;

          border: 1px solid #00ACE6;
          border-radius: 4px;

          p {
            color: #00ACE6;
            font-size: 15px;
            font-family: $fontB;
            text-transform: uppercase;
            letter-spacing: 0.05em;

          }
        }

        .download {
          width: 100%;
          height: 40px;


          display: flex;
          justify-content: center;
          align-items: center;

          background: #00ACE6;
          border-radius: 4px;

          p {
            color: white;
            font-size: 15px;
            font-family: $fontB;
            text-transform: uppercase;
            letter-spacing: 0.05em;

          }
        }
      }
    }
  }
}
</style>
