<template>
  <div class="header-wrapper">

    <div class="header" v-if="!$route.fullPath.includes('clinical')">
      <div class="head-line">

        <div @click="menuCtrl('1')"
             v-bind:class="[menuActive === '1' ? activeClass: '', `menu-icon`]">
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                  fill="#00ACE6"/>
          </svg>
        </div>

        <div class="logo">
          <img src="../assets/logo.png" height="55" alt="">
          <!-- <svg width="101" height="27" viewBox="0 0 101 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.85309 16.2762L4.43564 8.78001C3.66838 7.73317 3.2626 6.81485 3.13433 6.47136H3.09002V16.2762H0V1.19275H3.04804L8.38154 8.97162C9.19077 10.1493 9.68051 11.0232 9.76913 11.3878H9.81111V1.19275H12.8825V16.2762H9.85309Z"
                    fill="#21409A"/>
              <path d="M23.0714 16.6617C18.4865 16.6617 15.3732 15.1265 15.3732 10.2965V1.19275H18.6987V10.598C18.6987 13.2665 20.8746 14.1217 23.2183 14.1217C23.8596 14.1217 24.4777 14.0376 25.0957 13.888V1.19275H28.3349V16.1523C26.6115 16.4701 24.8415 16.6617 23.0714 16.6617Z"
                    fill="#21409A"/>
              <path d="M38.7221 3.90566V16.2762H35.3989V3.90566H30.7697V1.19275H43.3513V3.90566H38.7221Z"
                    fill="#21409A"/>
              <path d="M57.9082 16.2809H53.9249L51.1498 10.9579C50.8093 10.2709 50.4898 10.2078 50.1493 10.2078H49.1045V16.2318H45.7766V1.15076H50.6623C53.6474 1.15076 57.1456 1.66249 57.1456 5.46898C57.1456 7.54163 55.8023 8.97403 53.8363 9.38062L57.9082 16.2809ZM50.7253 3.77954H49.1045V7.7753H50.9165C52.1969 7.7753 53.6894 7.39442 53.6894 5.72602C53.6894 3.99218 52.0476 3.77954 50.7253 3.77954Z"
                    fill="#21409A"/>
              <path d="M60.59 16.2762V1.19275H63.9156V16.2762H60.59Z" fill="#21409A"/>
              <path d="M73.9226 16.6431C69.3377 16.6431 66.4553 13.353 66.4553 8.90629C66.4553 4.70023 68.8643 0.786255 73.9226 0.786255C75.7743 0.786255 77.1432 1.17415 78.2277 1.66252L77.1432 4.41982C76.1591 3.88706 75.0723 3.60665 74.1115 3.60665C70.9772 3.60665 69.9114 6.42705 69.9114 8.81516C69.9114 11.9627 71.98 13.8157 74.3494 13.8157C75.5411 13.8157 76.6069 13.4558 77.3531 13.0072L78.2463 15.5916C77.1642 16.1524 75.3918 16.6431 73.9226 16.6431Z"
                    fill="#21409A"/>
              <path d="M80.9888 16.2762V1.19275H84.3191V16.2762H80.9888Z" fill="#21409A"/>
              <path d="M97.2318 16.2763C97.0802 15.0799 96.868 13.8391 96.5719 12.624H90.8139C90.5364 13.8391 90.2985 15.0799 90.1306 16.2763H86.7817C87.6166 11.215 89.0625 5.59291 91.8587 1.17188H95.8023C98.6194 5.78452 100.026 11.1075 100.898 16.2763H97.2318ZM93.7127 3.71421H93.6707C92.6913 5.68171 91.9007 7.83848 91.3013 10.1284H96.0798C95.4804 7.88054 94.7132 5.7004 93.7127 3.71421Z"
                    fill="#21409A"/>
              <path d="M73.0188 19.336C74.1231 19.336 74.9702 19.5366 75.5602 19.9379C76.1577 20.332 76.4565 20.9232 76.4565 21.7115C76.4565 22.1414 76.3733 22.5105 76.2069 22.8186C76.048 23.1196 75.8136 23.3668 75.5035 23.5603C75.2009 23.7466 74.8303 23.8828 74.3916 23.9688C73.9529 24.0547 73.4575 24.0977 72.9054 24.0977H72.0318V26.8602H70.9313V19.5187C71.2414 19.447 71.5855 19.4005 71.9637 19.379C72.3494 19.3503 72.7012 19.336 73.0188 19.336ZM73.1096 20.2496C72.6406 20.2496 72.2814 20.2604 72.0318 20.2819V23.2056H72.86C73.2382 23.2056 73.5785 23.1841 73.8811 23.1411C74.1836 23.0909 74.437 23.0121 74.6412 22.9046C74.853 22.79 75.0156 22.6359 75.1291 22.4424C75.2425 22.2489 75.2992 22.0017 75.2992 21.7007C75.2992 21.4141 75.2387 21.1776 75.1177 20.9913C75.0043 20.805 74.8454 20.6581 74.6412 20.5506C74.4446 20.4359 74.2139 20.3571 73.9491 20.3141C73.6844 20.2711 73.4046 20.2496 73.1096 20.2496Z"
                    fill="#42B4E4"/>
              <path d="M82.7295 24.0655C82.7295 24.5098 82.6614 24.9111 82.5253 25.2694C82.3891 25.6277 82.1963 25.9358 81.9467 26.1938C81.7046 26.4518 81.4134 26.6524 81.0731 26.7957C80.7327 26.9319 80.3621 27 79.9612 27C79.5603 27 79.1897 26.9319 78.8494 26.7957C78.509 26.6524 78.214 26.4518 77.9644 26.1938C77.7224 25.9358 77.5333 25.6277 77.3972 25.2694C77.261 24.9111 77.1929 24.5098 77.1929 24.0655C77.1929 23.6284 77.261 23.2307 77.3972 22.8724C77.5333 22.5069 77.7224 22.1952 77.9644 21.9372C78.214 21.6792 78.509 21.4822 78.8494 21.346C79.1897 21.2027 79.5603 21.131 79.9612 21.131C80.3621 21.131 80.7327 21.2027 81.0731 21.346C81.4134 21.4822 81.7046 21.6792 81.9467 21.9372C82.1963 22.1952 82.3891 22.5069 82.5253 22.8724C82.6614 23.2307 82.7295 23.6284 82.7295 24.0655ZM81.629 24.0655C81.629 23.4349 81.4777 22.9369 81.1752 22.5714C80.8802 22.1988 80.4755 22.0124 79.9612 22.0124C79.4469 22.0124 79.0385 22.1988 78.7359 22.5714C78.4409 22.9369 78.2934 23.4349 78.2934 24.0655C78.2934 24.6961 78.4409 25.1977 78.7359 25.5703C79.0385 25.9358 79.4469 26.1185 79.9612 26.1185C80.4755 26.1185 80.8802 25.9358 81.1752 25.5703C81.4777 25.1977 81.629 24.6961 81.629 24.0655Z"
                    fill="#42B4E4"/>
              <path d="M86.3923 21.1525C86.483 21.1525 86.5851 21.1597 86.6986 21.174C86.8196 21.1812 86.9369 21.1955 87.0503 21.217C87.1638 21.2314 87.2659 21.2493 87.3566 21.2708C87.455 21.2851 87.5268 21.2994 87.5722 21.3138L87.3907 22.1844C87.3075 22.1558 87.1675 22.1235 86.9709 22.0877C86.7818 22.0447 86.536 22.0232 86.2334 22.0232C86.0368 22.0232 85.8401 22.0447 85.6435 22.0877C85.4544 22.1235 85.3296 22.1486 85.2691 22.1629V26.8602H84.214V21.5072C84.4636 21.4213 84.7737 21.3424 85.1443 21.2708C85.5149 21.1919 85.9309 21.1525 86.3923 21.1525Z"
                    fill="#42B4E4"/>
              <path d="M89.5774 21.2708H91.8124V22.1092H89.5774V24.6889C89.5774 24.9684 89.6001 25.2013 89.6455 25.3876C89.6909 25.5668 89.7589 25.7101 89.8497 25.8176C89.9405 25.9179 90.0539 25.9896 90.1901 26.0326C90.3262 26.0756 90.485 26.097 90.6666 26.097C90.9842 26.097 91.2376 26.0648 91.4267 26.0003C91.6234 25.9286 91.7595 25.8785 91.8351 25.8498L92.0393 26.6775C91.9335 26.7277 91.7481 26.7886 91.4834 26.8602C91.2187 26.939 90.9162 26.9785 90.5758 26.9785C90.1749 26.9785 89.8421 26.9319 89.5774 26.8387C89.3202 26.7384 89.1122 26.5915 88.9534 26.398C88.7946 26.2045 88.6811 25.9681 88.613 25.6886C88.5525 25.4019 88.5223 25.0723 88.5223 24.6997V19.7122L89.5774 19.5402V21.2708Z"
                    fill="#42B4E4"/>
              <path d="M95.1519 26.14C95.4015 26.14 95.6208 26.1365 95.8099 26.1293C96.0066 26.115 96.1692 26.0935 96.2978 26.0648V24.3987C96.2221 24.3629 96.0973 24.3342 95.9234 24.3127C95.757 24.2841 95.5527 24.2697 95.3107 24.2697C95.1519 24.2697 94.9817 24.2805 94.8002 24.302C94.6262 24.3235 94.4636 24.3701 94.3123 24.4417C94.1686 24.5062 94.0476 24.5994 93.9493 24.7212C93.8509 24.8358 93.8018 24.9899 93.8018 25.1834C93.8018 25.5417 93.9228 25.7925 94.1648 25.9358C94.4069 26.072 94.7359 26.14 95.1519 26.14ZM95.0611 21.131C95.4847 21.131 95.8402 21.1848 96.1276 21.2923C96.4226 21.3926 96.657 21.5395 96.831 21.733C97.0125 21.9193 97.1411 22.145 97.2167 22.4102C97.2924 22.6681 97.3302 22.9548 97.3302 23.2701V26.7635C97.2394 26.7778 97.1108 26.7993 96.9444 26.828C96.7856 26.8495 96.6041 26.871 96.3999 26.8925C96.1957 26.914 95.9725 26.9319 95.7305 26.9462C95.496 26.9677 95.2616 26.9785 95.0271 26.9785C94.6943 26.9785 94.388 26.9462 94.1081 26.8817C93.8283 26.8172 93.5862 26.7169 93.382 26.5807C93.1778 26.4374 93.0189 26.2511 92.9055 26.0218C92.792 25.7925 92.7353 25.5166 92.7353 25.1941C92.7353 24.886 92.7996 24.6209 92.9282 24.3987C93.0643 24.1766 93.2459 23.9974 93.4728 23.8613C93.6997 23.7251 93.9644 23.6248 94.2669 23.5603C94.5695 23.4958 94.8872 23.4636 95.22 23.4636C95.3258 23.4636 95.4355 23.4707 95.549 23.4851C95.6624 23.4922 95.7683 23.5066 95.8666 23.5281C95.9725 23.5424 96.0633 23.5567 96.1389 23.571C96.2146 23.5854 96.2675 23.5961 96.2978 23.6033V23.3238C96.2978 23.159 96.2789 22.9978 96.241 22.8401C96.2032 22.6753 96.1351 22.532 96.0368 22.4102C95.9385 22.2812 95.8023 22.1808 95.6284 22.1092C95.462 22.0304 95.2426 21.991 94.9704 21.991C94.6224 21.991 94.3161 22.016 94.0514 22.0662C93.7942 22.1092 93.6013 22.1558 93.4728 22.2059L93.348 21.3783C93.4841 21.3209 93.711 21.2672 94.0287 21.217C94.3464 21.1597 94.6905 21.131 95.0611 21.131Z"
                    fill="#42B4E4"/>
              <path d="M100.852 26.9677C100.202 26.9534 99.7406 26.8208 99.4684 26.57C99.1961 26.3192 99.0599 25.9286 99.0599 25.3984V18.691L100.115 18.519V25.2371C100.115 25.4019 100.13 25.5381 100.16 25.6456C100.191 25.7531 100.24 25.8391 100.308 25.9036C100.376 25.9681 100.467 26.0182 100.58 26.0541C100.694 26.0827 100.834 26.1078 101 26.1293L100.852 26.9677Z"
                    fill="#42B4E4"/>
          </svg> -->
        </div>

        <div @click="menuCtrl('2')"
             v-bind:class="[menuActive === '2' ? activeClass: '', `account`]">
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 10.2413C11.7614 10.2413 14 8.1726 14 5.62067C14 3.06875 11.7614 1 9 1C6.23858 1 4 3.06875 4 5.62067C4 8.1726 6.23858 10.2413 9 10.2413Z"
                stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"/>
            <path d="M9 10.2413C13.4184 10.2413 17 13.9189 17 18.4559H1C1 15.6013 2.41538 13.0894 4.56504 11.617"
                  stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

      <div v-if="menuActive === '1'"
           v-bind:class="[menuActive === '1' ? activeClass: '', `drop-nav-menu drop-menu`]">

        <div class="link-list">
          <router-link to="/pediatrics"><span @click="menuCtrl('0')">Головна</span></router-link>
          <router-link to="/conferences"><span @click="menuCtrl('0')">Конференцiї</span></router-link>
          <router-link to="/library"><span @click="menuCtrl('0')">Бібліотека</span></router-link>
          <router-link to="/calculator"><span @click="menuCtrl('0')">Калькулятор</span></router-link>
          <router-link to="/pediatrics/sampling"><span @click="menuCtrl('0')">Замовити зразки</span></router-link>
          <router-link to="/algorithms"><span @click="menuCtrl('0')">Алгоритми</span></router-link>
          <!-- <router-link to="/scheme">Схема прикорма</router-link> -->
          <router-link to="/clinical"><span @click="menuCtrl('0')">Портал клінічного харчування</span></router-link>
          <!-- <router-link to="/greetings">Конструктор привітань</router-link> -->
        </div>

      </div>

      <div v-if="menuActive === '2'"
           v-bind:class="[menuActive === '2' ? activeClass: '', `drop-account-menu drop-menu`]">

        <div class="popup-btn">
          <div class="user-data item" @click="menuCtrl('0'); selfDataPopup();">
            <div class="icon">
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6 7.31143C7.70698 7.31143 9.09076 5.90792 9.09076 4.17659C9.09076 2.44527 7.70698 1.04175 6 1.04175C4.29302 1.04175 2.90924 2.44527 2.90924 4.17659C2.90924 5.90792 4.29302 7.31143 6 7.31143Z"
                    stroke="#1E3B89" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round"/>
                <path d="M1 12.3869C1 9.58375 3.24039 7.31555 6 7.31555C8.75961 7.31555 11 9.5879 11 12.3869H1Z"
                      stroke="#1E3B89" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>

            </div>
            <p>Особисті дані</p>
          </div>


          <div class="conference item" @click="menuCtrl('0'); goToConf()">
            <div class="icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1.27087H1V8.00404H13V1.27087Z" stroke="#1E3B89" stroke-width="0.7"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.00299 8.00403V11.5891L9.72618 13.5522" stroke="#1E3B89" stroke-width="0.7"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.57306 13.6001L7.00298 11.5891" stroke="#1E3B89" stroke-width="0.7"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>Мої конференції</p>
          </div>

          <div class="quit item" @click="menuCtrl('0');  exit();">
            <div class="icon">
              <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.41534 7.86113H9.3274V9.75849L14.5114 5.49997L9.3274 1.24146V3.13881H3.41534"
                      stroke="#1E3B89" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M6.35666 10.5315H0.488586V0.468506H6.35666" stroke="#1E3B89" stroke-width="0.7"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <p>Вийти з сайту</p>
          </div>
        </div>
      </div>
    </div>

    <top-menu-clinical v-else></top-menu-clinical>

  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import TopMenu from "@/components/Clinical/top-menu.vue";

export default {
  name: "topMenu",
  data: () => ({
    menuActive: "",
    activeClass: "active",
    currentRout: ""
  }),
  components: {
    "top-menu-clinical": TopMenu,
  },
  mounted() {
    this.currentRout = this.$route.path;
  },

  methods: {
    ...mapActions([
      'setMenuData'
    ]),

    selfDataPopup() {
      this.setMenuData({popupState: '6'})
    },

    menuCtrl(id) {
      if (id !== this.menuActive) {
        this.menuActive = id;
      } else {
        this.menuActive = '';
      }
    },

    goToConf() {
      document.getElementsByTagName("body")[0].style.overflow = "visible";
      sessionStorage.setItem("myConf", "true");
      this.$router.push({name: "Conferences"})
    },

    exit() {
      sessionStorage.removeItem("key");
      this.$router.go();
    }
  }
}
</script>

<style lang="scss">
@import "../style/vars";

.header {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  max-height: 60px;
  height: 100%;
  background-color: white;

  @media screen and (min-width: 426px) {
    position: absolute;
  }

  .head-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
  }

  .menu-icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
      path {

        transition: all 0.2s ease-in-out;
      }

    }

    &.active, &:active {
      background-color: #00ACE6;

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .account {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
      path {
        transition: all 0.2s ease-in-out;
      }

    }

    &.active, &:active {
      background-color: #00ACE6;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .drop-menu {
    background: white;

    &.drop-nav-menu {
      padding-bottom: 30px;
      padding-top: 45px;

      .link-list {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #DAE3ED;
        margin-left: 30px;

        a {
          color: #1E3B89;
          position: relative;
          font-size: 16px;
          font-family: $fontL;
          padding-left: 30px;
          padding-bottom: 45px;
          transition: all 0.2s ease;

          &:before {
            transition: all 0.2s ease;
            content: '';
            opacity: 0;
            width: 3px;
            height: 22px;
            background: #00ACE6;
            position: absolute;
            left: 0;
            top: 0;
          }

          &:last-child {
            padding-bottom: 20px;
          }

          &.router-link-exact-active, &:active {
            font-family: $fontSB;
            color: #1E3B89;

            &:before {
              opacity: 1;
            }

          }
        }
      }
    }

    &.drop-account-menu {
      padding-top: 45px;

      .popup-btn {
        margin-left: 30px;
        cursor: pointer;

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 45px;
          cursor: pointer;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            margin-right: 25px;
          }

          p {
            color: #1E3B89;
            font-size: 16px;
            font-family: $fontL;
          }

          svg {
            path {
              transition: all 0.2s ease;
            }
          }

          &.active, &:active {
            .icon {
              svg {
                path {
                  stroke-width: 1.7;
                }
              }
            }

            p {
              font-family: $fontSB;
              color: #1E3B89;
            }

          }

        }
      }
    }
  }
}

</style>
