<script>
export default {
  name: "img-popup",
  props: {
    img: String,
    title: String
  }
}
</script>

<template>
  <div class="img-popup">

    <div class="popup-white">

      <div class="popup-header">
        <p>{{ title }}</p>
        <div class="close-btn" @click="$emit('closePopup')">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M0.410156 0.257812L18.8937 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
              <path d="M18.8937 0.257812L0.410156 18.7413" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect x="0.152344" width="19" height="19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div class="scroll">
        <img :src="`${$root.globalUrl}${img }`" alt="">
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "../style/vars";

.img-popup {
  width: 100%;
  height: 100%;
  background: rgba(88, 101, 136, 0.6);
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  padding: 0 15px;

  @media screen and (min-width: 426px) {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }

  .popup-white {
    background: #FFFFFF;
    border-radius: 4px;
    overflow-x: hidden;
    width: 100%;
    max-height: 80%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .popup-header {
      background: #EEF3F8;
      padding: 20px 15px;

      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        align-items: center;
        color: #9AB9D8;
        font-family: $fontB;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

    .scroll{
      overflow-y: auto;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
</style>