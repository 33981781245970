<template>
    <div class="product-zond">

        <div class="product-title" @click="openInfo()">
            <div class="left">
                <div class="product-icon">
                    <slot></slot>
                </div>
                <div class="product-text">
                    {{product.diagnos}}
                </div>
            </div>
            <div class="right">
                <div :class="isShowInfo ? 'open arrow rotated' : 'open arrow no-rateted'">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7788 1.24323L6.26611 5.7787" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.26608 5.7787L1.75684 1.24323" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div class="product-container" v-if="isShowInfo">
                
                <div class="info-first-row">
                    <div class="row-element kg-per-day">
                        кг/на добу
                    </div>
                    <div class="row-element kkal">
                        <div class="name">
                            ккал
                        </div>
                        <div class="value">
                            {{product.d_kkal}}
                        </div>
                    </div>
                    <div class="row-element belok">
                        <div class="name">
                            білок
                        </div>
                        <div class="value">
                            {{product.d_belok}}
                        </div>
                    </div>
                </div>

                <div class="global-info">
                    <div class="arrow">
                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.295769 6.01523C-0.0836067 6.37793 -0.100266 6.98283 0.258559 7.36631L4.31459 11.701C4.67342 12.0845 5.27185 12.1013 5.65122 11.7386C6.0306 11.3759 6.04726 10.771 5.68843 10.3875L1.6324 6.05284C1.27357 5.66937 0.675145 5.65253 0.295769 6.01523Z" fill="#AC98C5"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70394 6.01498C9.32442 5.65242 8.726 5.66949 8.36732 6.05311L4.3144 10.3878C3.95572 10.7714 3.97261 11.3763 4.35213 11.7389C4.73164 12.1014 5.33007 12.0843 5.68874 11.7007L9.74166 7.36604C10.1003 6.98243 10.0835 6.37753 9.70394 6.01498Z" fill="#AC98C5"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.295769 0.261385C-0.0836067 0.624089 -0.100266 1.22899 0.258559 1.61247L4.31459 5.94715C4.67342 6.33063 5.27185 6.34747 5.65122 5.98476C6.0306 5.62206 6.04726 5.01716 5.68843 4.63368L1.6324 0.298997C1.27357 -0.0844796 0.675145 -0.101319 0.295769 0.261385Z" fill="#AC98C5"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70394 0.261132C9.32442 -0.101425 8.726 -0.0843528 8.36732 0.299263L4.3144 4.63395C3.95572 5.01757 3.97261 5.62246 4.35213 5.98502C4.73164 6.34757 5.33007 6.3305 5.68874 5.94688L9.74166 1.6122C10.1003 1.22858 10.0835 0.623689 9.70394 0.261132Z" fill="#AC98C5"/>
                            </g>
                        </svg>
                    </div>

                    <div class="title">
                        {{product.name}}
                    </div>

                    <div class="image">
                        <img :src="$root.globalUrl + product.img" width="163" alt="">
                        <!-- <img src="../../../assets/images/enteral/nutrieon-advance-diazon-163x200.jpg" width="163" alt=""> -->
                    </div>

                    <div class="undertext">
                        {{product.capacity}}
                    </div>
                </div>

                <div class="info-second-row">
                    <div class="row-element kkal">
                        <div class="name">
                            ккал
                        </div>
                        <div class="value">
                            {{product.kkal}}
                        </div>
                    </div>
                    <div class="row-element belok">
                        <div class="name">
                            білок
                        </div>
                        <div class="value">
                            {{product.belok}}
                        </div>
                    </div>
                    <div class="row-element dha-epa" v-if="!product.disableDHA">
                        DHA+EPA
                    </div>
                    <div class="row-element food" v-if="product.food != null">
                        <div class="name">
                            харчові волокна 
                        </div>
                        <div class="value">
                            {{product.food}}
                        </div>

                        <img src="../../../../assets/images/enteral/mf6.png" width="40" class="lable">
                    </div>
                </div>

            </div>

        </transition>

    </div>
</template>

<script>
export default {
    props: ['product'],
    data : () => ({
        isShowInfo : false
    }),
    methods : {
        openInfo(){
            this.isShowInfo = !this.isShowInfo;
        }
    }
}
</script>

<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.product-zond{
    width: 100%;
    height: auto;
    background: none;
    padding: 0;
    padding-bottom: 16px;
    cursor: pointer;

    .product-title{
        background: #AC98C5;
        padding: 10px 20px 10px 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;

            .product-text{
                color: #ffffff;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;

                padding-left: 10px;

            }
        }

        .right{
            .arrow{
                padding-bottom: 5px;
            }

            .rotated{
                transform: rotate( -180deg );            
                transition: transform 500ms ease;
            }

            .no-rateted {
                transform: rotate( -360deg );            
                transition: transform 500ms ease;
            }
        }

        
        
    }

    .product-container{
        display: flex;
        flex-direction: column;

        .info-first-row, .info-second-row{
            display: flex;
            flex-direction: row;

            .row-element{
                height: 40px;

                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 1 0px;
            }

            .food{
                flex-grow: 1.5;
            }
        }

        .kkal, .belok, .food{
            display: flex;
            flex-direction: column;
            color: #4A2A80;

            .name{
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
            }

            .value{
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .kkal{
            background: rgba(172, 152, 197, 0.25);
        }

        .belok{
            background: rgba(80, 142, 204, 0.25);
        }

        .food{
            background: #ffffff;
            border-top: 1px solid rgba(186, 186, 186, 0.25);

            position: relative;

            .name{
                font-size: 10px;
                line-height: 10px;
                text-align: center;
            }

            .lable{
                position: absolute;
                top: -33px;
            }
        }
        
        .dha-epa{
            background: rgba(186, 186, 186, 0.2);
            color: #4A2A80;

            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
        }

        .info-first-row{
            .kg-per-day{
                font-weight: 500;
                font-size: 10px;
                letter-spacing: 0.06em;
                color: #AC98C5;

                background: #ffffff;
            }            
        }
        
        .global-info{
            background: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;

            padding: 20px 0 12px;
            
            .arrow{
                padding-bottom: 16px;
                cursor: pointer;
            }

            .title{
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: #4A2A80;

                padding-bottom: 16px;
            }

            .image{
                padding-bottom: 4px;
            }

            .undertext{
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.06em;
                color: #AC98C5;
            }
        }
    }
}
</style>