<template>
  <div class="resources">
    <div class="popup-block">

      <div class="head">
        <p>ресурси Nutricia</p>
      </div>

      <div class="btn-wrapper">
        <a href="https://profutura.pronutrilon.com/">
          <img src="@/assets/images/resources/kid.jpg" alt="">
          <p>Дитячі суміші</p>
        </a>

        <a href="https://new.pronutrilon.com/">
          <img src="@/assets/images/resources/kid-prem.jpg" alt="">
          <p>Дитячі суміші</p>
        </a>

        <a href="https://comfort.pronutrilon.com/">
          <img src="@/assets/images/resources/com.jpg" alt="">
          <p>Комфортне травлення</p>
        </a>

        <a href="https://milupa.com.ua/">
          <img src="@/assets/images/resources/mil.jpg" alt="">
          <p>Дитячі суміші та прикорм</p>
        </a>

        <a href="https://medical.nutricia.ua/">
          <div class="bg">
            <img src="@/assets/images/resources/clinical.png" alt="">
          </div>
          <p>Клінічне харчування</p>
        </a>

      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "resources",
    methods: {
        ...mapActions(['setMenuData']),
        confirm() {
            console.log('a')
            this.setMenuData({popupState: '00'})
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../style/vars";

.resources {

  overflow: auto;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(88, 101, 136, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 100;

  @media screen and (min-width: 426px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }

  .popup-block {
    min-height: 400px;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    max-width: 425px;

    @media screen and (min-width: 426px) {
      position: absolute;

      max-width: 395px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .head {
      padding: 20px 14px;

      background: #F1EDF7;

      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        display: block;
        width: 19px;
        height: auto;
        cursor: pointer;
      }

      p {
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.11em;
        text-transform: uppercase;

        color: #AC98C5;

        font-family: $fontB;
      }
    }

    .btn-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 20px;

      a {
        text-decoration: none;
        max-width: calc(50% - 10px);
        width: 100%;
        overflow: hidden;

        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to bottom, #FFFFFF, rgba(74, 42, 128, 0.3)) 1;
        margin-bottom: 15px;
        border-radius: 5px;


        display: block;

        color: #4A2A80;

        .bg {
          background: #8F67A4;
          padding: 5px 0 10px 0;

          img {
            max-width: 106px;
            margin: 0 auto;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-size: 12px;
          line-height: 120%;

          color: #1E3B89;
          font-family: $fontL;

          padding: 20px;
          text-align: center;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:last-child {
          max-width: 100%;
        }
      }
    }
  }
}
</style>