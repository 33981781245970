<template>
    <div class="login-clinical">
        <div class="login-block">
            <p class="h1-title">
                <span>Вхід</span>
            </p>

            <div class="phone-wrapper padding-all active">

                <the-mask class="corr" :mask="['+38 (0##) ### ## ##']" v-model="phone" required/>

                <span class="reg-text">Телефон</span>
            </div>

            <transition name="fade">
                <div class="code-wrapper padding-all"
                     v-if="loginStage === '2'"
                     v-bind:class="[code !== '' ? activeClass: '', ``]">
                    <the-mask class="corr" :mask="['X-X-X-X']" v-model="code" required/>

                    <!--                    <input type="text" v-model="code" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">-->
                    <span class="reg-text">Код з повідомлення</span>
                </div>
            </transition>

            <transition>
                <div v-if="errorStatus === '1'" class="error-number">
                    <p>На порталі немає користувача
                        з таким номером телефона.
                        Будь ласка, зареєструйтесь. </p>
                </div>
            </transition>

            <transition>
                <div v-if="errorStatus === '2'" class="error-number">
                    <p>Помилка :(<br>
                        Неправильно введений код </p>
                </div>
            </transition>

            <div class="agree-wrapper">
                <label class="container">
                    Запам’ятати мене
                    <input type="checkbox" v-model="savePhone">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="btn-wrapper" v-if="errorStatus === '2'" @click="sendNewCode()">
                <div class="btn">
                    <p>отримати новий код</p>
                </div>
            </div>

            <div :class="errorStatus === '2' ? 'btn-wrapper disable' : 'btn-wrapper'" @click="enterUser()">
                <div class="btn">
                    <p>Увійти</p>
                </div>
            </div>

            <div class="register-link" @click="openPopUpFromPopUp()">
                Зареєструватись
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: "login",
        data: () => ({
            errorStatus: "",
            savePhone: false,
            prevRoute: null,
            activeClass: "active",
            phone: "380",
            code: "",
            loginStage: "1",
            phone_number: "380",
            hexTokens: {
                F: {
                    pattern: /[0-9a-fA-F]/,
                }
            }

        }),

        mounted() {
            if (localStorage.getItem("phone") !== null) {
                this.phone = localStorage.getItem("phone")
                this.savePhone = true;
            }
        },

        methods: {
            openPopUpFromPopUp() {
                this.$emit('touchstart');
            },
            sendNewCode(){
                this.errorStatus = '';
                this.loginStage = '1';

                this.enterUser();
            },
            enterUser() {

                this.errorStatus = "";

                axios.post(this.$root.globalUrl + "api/v1/security/auth-phone", {
                    "phone_number": '380' + this.phone
                }).then((res) => {
                        if (this.savePhone === true) {
                        localStorage.setItem("phone", this.phone)
                    }

                    sessionStorage.setItem("key", res.data.authorization);

                    this.$emit('click');
                    this.$router.go()
                }).catch((error) => {

                    this.errorStatus = "1"
                })

                // if(this.errorStatus === '2'){
                //     return false;
                // }

                // if (this.loginStage === '1') {

                //     this.errorStatus = "";

                //     axios.post(this.$root.globalUrl + "api/v1/security/generate", {
                //         "phone_number": '380' + this.phone
                //     }).then((res) => {
                //         this.loginStage = "2"
                //     }).catch((error) => {
                //         this.errorStatus = "1"
                //     })

                // } else {

                //     this.errorStatus = "";
                //     axios.post(this.$root.globalUrl + "api/v1/security/verify", {
                //         "phone_number": '380' + this.phone,
                //         "code": this.code

                //     }).then((res) => {

                //         if (this.savePhone === true) {
                //             localStorage.setItem("phone", this.phone)
                //         }

                //         sessionStorage.setItem("key", res.data.authorization);

                //         this.$emit('click');
                //         this.$router.go()
                //     }).catch((error) => {
                //         this.errorStatus = "2"
                //         this.code = "";
                //     })

                //     if (this.savePhone === true) {
                //         localStorage.setItem("phone", this.phone)
                //     }

                // }
            }
        }

    }
</script>

<style lang="scss">
    @import "../../style/vars";

    .login-clinical {
        overflow: auto;
        padding: 0 15px;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(115, 89, 130, 0.6);
        width: 100%;
        height: 100vh;
        z-index: 100;
        @media screen and (min-width: 426px){
            position: absolute;
        }

        .error-number {
            padding: 10px 15px;
            font-family: $fontL;
            font-size: 16px;
            color: #CA8989;
        }

        .login-block {
            margin-top: 50px;
            margin-bottom: 20px;
            width: 100%;
            background-color: white;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            position: relative;

            .h1-title {
                /*margin-bottom: 25px;*/
                border-radius: 4px 4px 0 0;
                padding: 0 14px;
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #F1EDF7;

                span {
                    font-size: 12px;
                    font-family: $fontB;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: #AC98C5;
                }

            }

            .padding-all {
                position: relative;
                margin: 0 15px 20px 15px;

                &.phone-wrapper {
                    margin-top: 30px;
                    cursor: text;
                }

                input {
                    background: none;
                    position: relative;
                    z-index: 2;
                    color: #4A2A80;
                    border: none;
                    border-bottom: 1px solid #AC98C5;
                    width: 100%;
                    padding: 10px 0 5px 0;
                    cursor: text;

                    &:focus {
                        outline: none;
                    }
                }

                &.active, &:hover, &:focus {
                    .reg-text {
                        top: -10px;
                        font-size: 10px;
                    }
                }

                .reg-text {
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    top: 8px;
                    color: #AC98C5;
                    font-size: 16px;
                    font-family: $fontL;

                    transition: all 0.2s ease;

                    .star {
                        color: #1E3B89;

                    }

                }

                .month-block, .year-block {
                    position: relative;
                }

                &.day-wrapper {
                    margin-top: 30px;
                }
            }

            .agree-wrapper {
                padding: 0 15px;

                .container {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    font-size: 16px;
                    font-family: $fontL;
                    color: #4A2A80;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }


                /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    height: 12px;
                    width: 12px;
                    background-color: white;
                    border: 1px solid #8F67A4;
                    cursor: pointer;
                }

                /* On mouse-over, add a grey background color */

                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                    background-color: #8F67A4;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 2px;
                    top: 0;
                    width: 4px;
                    height: 6px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }

            .btn-wrapper {
                margin: 20px 0 10px 0;
                padding: 0 15px;

                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 40px;
                    background-color: #8F67A4;
                    border-radius: 4px;
                    cursor: pointer;

                    p {
                        color: white;
                        text-transform: uppercase;
                        font-size: 15px;
                        font-family: $fontB;
                        letter-spacing: 2px;
                    }
                }
            }

            .disable{
                margin: 0;

                .btn {
                    background: #F2F2F2;
                    cursor: not-allowed;

                    p{
                        color: #E0E0E0;
                    }
                }
            }

            .register-link {
                text-align: center;
                margin: 20px 0 30px;
                text-decoration: underline;
                color: #8F67A4;
                font-family: $fontL;
                cursor: pointer;
            }
        }
    }

</style>
