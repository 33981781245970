<template>
    <root-popup :popupName="'автор'" @closePopup="$emit('closePopup')">

        <div class="author-info">
            <div class="author-name">
                {{authorInfo.name}}
            </div>
            <div class="author-awards">
                {{authorInfo.description}}
            </div>
            <div class="author-email">
                {{authorInfo.email}}
            </div>
        </div>

        <div class="materials-list">
            <div class="title">Всі матеріали:</div>

            <div v-for="(material, index) in cardsList" class="material" :key="index" :class="activeSource === material.sourceId ? (compareTitle(material.title) ? 'active' : '') : ''" @click="goTo(material.sourceId, material.id)">
                <div class="content">
                    {{material.title}}
                </div>
                <div class="subtitle" v-if="material.sourceId !== 'video'">
                    {{material.publisher.title}} &#8211; {{material.publishYear}}
                </div>
                <div class="subtitle" v-else>
                    Відеоматеріал, {{material.publishYear}}
                </div>
            </div>
        </div>

    </root-popup>
</template>

<script>
import axios from 'axios';
import RootPopup from '../popups/root-popup';

export default {
    props : ['authorInfo', 'currentArticleTitle', 'currentResearchTitle', 'currentVideoTitle'],
    components : {
        'root-popup' : RootPopup
    },
    data : () => ({
        cardsList : [],
        activeSource : ''
    }),
    mounted(){
        this.activeSource = this.getActiveSource();

        if(this.authorInfo.articles != undefined)
            this.authorInfo.articles = this.setSourceInObj(this.authorInfo.articles, 'article', 'Наукові статті');
        if(this.authorInfo.researches != undefined)
            this.authorInfo.researches = this.setSourceInObj(this.authorInfo.researches, 'research', 'Клінічні дослідження');
        if(this.authorInfo.videos != undefined)
            this.authorInfo.videos = this.setSourceInObj(this.authorInfo.videos, 'video', 'Відеоматеріали');


        let allCards = [];
        if(this.authorInfo.articles != undefined)
            allCards = allCards.concat(this.authorInfo.articles);
        if(this.authorInfo.researches != undefined)
            allCards = allCards.concat(this.authorInfo.researches);
        if(this.authorInfo.videos != undefined)
            allCards = allCards.concat(this.authorInfo.videos);

        allCards = allCards.filter(value => Object.keys(value).length !== 0);

        allCards.sort((a, b) => {
            if(a.publishYear > b.publishYear) { return -1; }
            if(a.publishYear < b.publishYear) { return 1; }
            return 0;
        })

        allCards.forEach(element => {
            if(!this.cardsList.find(x => x.sourceId === element.sourceId && x.title === element.title)){
                this.cardsList.push(element);
            }
        })
    },
    methods : {
        getActiveSource(){
            if(this.currentArticleTitle)
                return 'article';
            if(this.currentResearchTitle)
                return 'research';
            if(this.currentVideoTitle)
                return 'video'
        },
        compareTitle(materialTitle){
            switch(this.activeSource){
                case 'article' : {
                    return this.currentArticleTitle === materialTitle;
                }
                break;
                case 'research' : {
                    return this.currentResearchTitle === materialTitle;
                }
                break;
                case 'video' : {
                    return this.currentVideoTitle === materialTitle;
                }
                break;
            }
        },
        setSourceInObj(objArr, sourceId, sourceTitle){
            if(objArr != null){
                for(let i = 0; i < objArr.length; i++){
                    objArr[i].sourceId = sourceId;
                    objArr[i].sourceTitle = sourceTitle;
                }
            }

            return objArr;
        },
        goTo(type, id){
            this.$emit('closePopup');
            this.$router.push({ name : 'libraryItem', params : { type, id } });
        },
        getFilteredArr(object){
            let filtered = [];

            for(let i = 0; i < object.length; i++){
                if(!filtered.find(obj => {
                    return obj.title === object[i].title;
                })){
                    filtered.push(object[i]);
                }
            }

            return filtered;
        }
    }
}
</script>

<style lang="scss" scoped>
.author-info{
    padding: 15px 16px 23px 14px;

    .author-name{
        font-weight: 600;
        font-size: 17px;
        line-height: 135%;
        color: #4A2A80;

        padding-bottom: 10px;
    }

    .author-awards{
        color: #4A2A80;
        line-height: 135%;
        font-size: 13px;
        padding-bottom: 10px;
    }

    .author-email{
        color: #4A2A80;
        text-decoration: underline;
        font-size: 13px;
        line-height: 135%;
    }
}

.materials-list{
    .title{
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #AC98C5;

        padding-bottom: 20px;

        border-bottom: 1px solid #EFE1F7;
    }

    .material{
        padding: 13px 20px 13px 14px;

        border-bottom: 1px solid #DAE3ED;

        .content{
            font-size: 13px;
            line-height: 125%;
            color: #4A2A80;

            padding-bottom: 10px;
        }

        .subtitle{
            font-size: 12px;
            line-height: 135%;
            color: #AC98C5;
        }
    }

    .active{
        background: #EFE1F7;
    }   
}
</style>