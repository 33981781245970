import { render, staticRenderFns } from "./algorithms.vue?vue&type=template&id=5d8b7fed&"
import script from "./algorithms.vue?vue&type=script&lang=js&"
export * from "./algorithms.vue?vue&type=script&lang=js&"
import style0 from "./algorithms.vue?vue&type=style&index=0&id=5d8b7fed&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports