<template>
  <div class="library">
    <div class="head-links">
      <router-link class="back-links" to="/clinical/product-categories"
        >Продукти</router-link
      >
      <span>/</span>
      <router-link
        class="back-links"
        :to="`/clinical/products/${productCategorySlug}`"
        >{{ productCategory }}</router-link
      >
      <span>/</span>
      <router-link class="now-page" to="#">{{
        product.titleSingle
      }}</router-link>
    </div>

    <div class="content">
      <div class="single-product">
        <div class="single-product__card">
          <div class="single-product__slider-container">
            <div
              class="single-product__nav prev"
              @click="moveSlider(-1)"
              :class="{disabled: atHeadOfList}"
              v-if="product.image.length > 1"
            ></div>
            <div class="single-product__slider">
              <div
                class="single-product__images"
                style="{transform: translateX(0)}"
              >
              <div ref="slider"
                class="single-product__image"
                v-for="img of product.image"
                :key="img"
              >
                <img
                  :src="
                    require(`@/assets/images/clinical-products/${productCategorySlug}/${img}`)
                  "
                  :class="{max_width: (img === '1.png' || img === '1.2.png'|| img === '1.3.png')}"
                  alt
                />
              </div>
              </div>
            </div>
            <div
              class="single-product__nav next"
              @click="moveSlider(1)"
              :class="{disabled: atEndOfList}"
              v-if="product.image.length > 1"
            ></div>
          </div>
          <div class="single-product__title">
            <strong>{{ product.titleSingle }}</strong>
            / {{ product.titleSingleEn }}
          </div>
        </div>
        <div class="product-accordions">
          <div
            class="product-accordion"
            v-for="(accordion, key) in product.accordions"
            :key="key"
          >
            <div
              class="product-accordion__title"
              :class="{ active: accordion.isActive }"
              @click="toggleAccordion(key)"
            >
              {{ accordion.title }}
            </div>
            <div class="product-accordion__main" v-show="accordion.isActive">
              <div
                class="product-accordion__content"
                v-if="accordion.content !== undefined"
              >
                <div
                  class="product-accordion__content-item"
                  v-for="(content, key) in accordion.content"
                  :key="`content-${key}`"
                  v-html="content"
                ></div>
              </div>

              <div
                class="product-accordion__list"
                v-if="accordion.list !== undefined"
              >
                <div
                  class="product-accordion__list-item"
                  v-for="(item, key) in accordion.list"
                  :key="`list-${key}`"
                  v-html="item"
                ></div>
              </div>

              <div
                class="product-accordion__content"
                v-if="accordion.content2 !== undefined"
              >
                <div
                  class="product-accordion__content-item"
                  v-for="(content, key) in accordion.content2"
                  :key="`content2-${key}`"
                  v-html="content"
                ></div>
              </div>

              <div
                class="product-accordion__links"
                v-if="accordion.links !== undefined"
              >
                <div
                  class="product-accordion__links-item"
                  v-for="(link, key) in accordion.links"
                  :key="`link-${key}`"
                  v-html="link"
                ></div>
              </div>
              <div
                class="product-accordion__tables"
                v-if="accordion.tables !== undefined"
              >
                <div
                  class="product-accordion__tables-title"
                  v-if="accordion.tables.title !== null"
                >
                  {{ accordion.tables.title }}
                </div>

                <div
                  class="product-accordion__tables-item"
                  v-for="(table, key) in accordion.tables.list"
                  :key="`table-${key}`"
                  v-html="table"
                ></div>
                
                <div
                  class="product-accordion__tables-link"
                  v-for="(link, key) in accordion.tables.links"
                  :key="`link-${key}`"
                  v-html="link"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Product",
  data() {
    return {
      productCategory: "",
      productCategorySlug: "",
      product: [],

      currentOffset: 0,
      paginationFactor: 350,
    };
  },
  computed: {
    ...mapGetters(["getClinicalProducts"]),
    atEndOfList() {
      return this.currentOffset <= (this.paginationFactor * -1) * (this.product.image.length - 400 / 350);
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  mounted() {
    this.paginationFactor = this.$refs.slider[0].getBoundingClientRect().width
  },
  methods: {
    moveSlider(direction) {
      const slider = document.querySelector('.single-product__images');

      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
        slider.style.transform = `translateX(${this.currentOffset}px)`;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
        slider.style.transform = `translateX(${this.currentOffset}px)`;
      }
    },
    toggleAccordion(key) {
      this.product.accordions[key].isActive = !this.product.accordions[key]
        .isActive;
    },
  },
  created() {
    var category = this.getClinicalProducts[this.$route.params.category];
    this.productCategory = category.title;
    this.productCategorySlug = this.$route.params.category;
    this.product = category.products[this.$route.params.id];
  },
};
</script>

<style scoped lang="scss">
@import "~@/style/vars";

.head-links {
  background: rgba(255, 255, 255, 0.49);
  width: 100%;
  padding: 10px 15px;
  .back-links {
    color: #ac98c5;
    font-family: $fontR;
    font-size: 11px;
  }
  span {
    padding: 0 5px;
    color: #ac98c5;
    font-family: $fontR;
    font-size: 11px;
  }
  .now-page {
    color: #8f67a4;
    font-family: $fontSB;
    font-size: 11px;
  }
}

.content {
  padding: 16px 15px 60px;
}

.single-product {
  &__card {
    margin-bottom: 16px;
    padding: 16px 16px 24px 16px;
    background: #ffffff;
    border-radius: 1px;
    text-align: center;
  }
  &__slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  &__nav {
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid #4a2a80;
    border-right: 2px solid #4a2a80;
    cursor: pointer;

    &.disabled {
      opacity: .3;
    }

    &.prev {
      left: 0;
      transform: translateY(-50%) rotate(-135deg);
    }

    &.next {
      right: 0;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &__slider {
    overflow: hidden;
    max-width: 100%;
  }
  &__images {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    transition: .6s;
  }
  &__image {
    margin-bottom: 8px;
    min-width: 350px;

    img {
      max-height: 200px;

      &.max_width {
        max-width: 20%;
      }
    }
  }
  &__title {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #4a2a80;
    strong {
      font-weight: 600;
    }
  }
}
.product {
  &-accordion {
    margin-bottom: 16px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &__title {
      position: relative;
      padding: 14px 35px 12px 15px;
      background: #ac98c5;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        width: 5px;
        height: 5px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: translateY(-50%) rotate(135deg);
      }
      &.active {
        &:after {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
    &__main {
      background: #fff;
      padding-top: 16px;
    }
    &__content {
      padding: 0 15px 24px;
      &::v-deep {
        p {
          margin-bottom: 10px;
        }
        img {
          margin-top: 20px;
        }
      }
      &-item {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #4a2a80;
        cursor: pointer;
        &:last-child {
          &::v-deep {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &__list {
      padding: 0 15px 24px;
      &:empty {
        background: #f1edf7;
      }
      &-item {
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #4a2a80;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__links {
      padding: 0 15px 24px;
      &:last-child {
        margin-bottom: 0;
      }
      &-item {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 130%;
        color: #8f67a4;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &::v-deep {
          strong {
            font-weight: 700;
          }
          a {
            text-decoration: underline;
            color: #7e9cd0;
          }
        }
      }
    }
    &__tables {
      &-title {
        position: relative;
        display: block;
        padding: 14px 15px;
        background: rgba($color: #8f67a4, $alpha: 0.5);
        border-bottom: 1px solid rgba($color: #ac98c5, $alpha: 0.3);
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.11em;
        text-transform: uppercase;
        color: #ffffff;
      }
      &-item {
        &::v-deep {
          table {
            width: 100%;
            border-collapse: collapse;
          }
          thead {
            background-color: rgba($color: #ac98c5, $alpha: 0.25);
            th {
              padding: 10px 0px;
              border: 1px solid rgba($color: #ac98c5, $alpha: 0.3);
              font-weight: normal;
              font-size: 14px;
              word-break: break-word;
              line-height: 18px;
              color: #4a2a80;
              &:nth-child(1) {
                padding: 10px 15px;
              }
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                text-align: center;
              }
              &:first-of-type {
                border-left: none;
              }
              &:last-of-type {
                border-right: none;
              }
              &:last-child {
                border: none;
              }
            }
          }
          tbody {
            tr {
              &:first-of-type {
                td {
                  border-top: none;
                }
              }
            }
            td {
              padding: 10px 0px;
              border: 1px solid rgba($color: #ac98c5, $alpha: 0.3);
              font-size: 14px;
              word-break: break-word;
              line-height: 18px;
              color: #4a2a80;

              span {
                color: #8f67a4;
              }
              &:nth-child(1) {
                padding: 10px 15px;
              }
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                width: 70px;
                text-align: center;
              }
              &:first-of-type {
                border-left: none;
              }
              &:last-of-type {
                border-right: none;
              }
            }
          }
        }
      }

      &-link {
        padding: 10px 15px;
        font-size: 12px;
        line-height: 130%;
        color: #8f67a4;
      }
    }
  }
}
</style>
