<template>
    <div class="header-item">
        <div class="name">{{cardType}}</div>

        <div class="links">
            <a v-if="((card.contentRu || card.linkRu) || (card.contentEn || card.linkEn)) && card.contentUa || card.linkUa">Укр | </a> 
            <span v-if="card.contentRu || card.linkRu"><a>Рус</a></span>
            <span v-if="(card.contentEn || card.linkEn) && (card.contentRu || card.linkRu)" class="delimetr">|</span>
            <span v-if="card.contentEn || card.linkEn"><a>EN</a></span>
        </div>
    </div>
</template>

<script>
export default {
    props : ['cardType', 'card']
}
</script>

<style lang="scss" scoped>
@import "../../../../style/vars";

.header-item {
    background: #EFE1F7;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    .name {
        color: #4A2A80;
        font-size: 12px;
        font-family: $fontL;
    }

    .links {
        font-family: $fontSB;
        font-size: 12px;
        color: #8F67A4;
        text-transform: uppercase;

        display: flex;
        align-items: center;

        span {
            display: inline-block;
            margin: 0 5px;
        }

        .delimetr{
            margin: 0;
        }

        a {
            color: #8F67A4;
        }
    }
}
</style>