<template>
  <div class="vomiting">

    <div class="head-links">
      <router-link class="back-links" to="/algorithms">Алгоритми діагностики</router-link>
      <span>/</span>
      <router-link class="now-page" to="/algorithms/vomiting">
        Зригування
      </router-link>
    </div>

    <div class="content-wrapper">

      <div class="chose-next-step">

        <div class="h2-title">
          <div @click="$router.go(-1)" class="back-arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.53552 1.52856L1.00004 5.81836" stroke="#00ACE6" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.00004 5.81897L5.53552 10.1055" stroke="#00ACE6" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="middle-text">
            <p>Огляд пацієнта</p>
          </div>
          <div @click="openliteraturePopup()" class="popup-info-icon">
            <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7.5" cy="8.5" r="7" fill="#00ACE6" stroke="#00ACE6"/>
              <path
                  d="M6.61719 10.1582V9.72461C6.61719 9.34961 6.69727 9.02344 6.85742 8.74609C7.01758 8.46875 7.31055 8.17383 7.73633 7.86133C8.14648 7.56836 8.41602 7.33008 8.54492 7.14648C8.67773 6.96289 8.74414 6.75781 8.74414 6.53125C8.74414 6.27734 8.65039 6.08398 8.46289 5.95117C8.27539 5.81836 8.01367 5.75195 7.67773 5.75195C7.0918 5.75195 6.42383 5.94336 5.67383 6.32617L5.03516 5.04297C5.90625 4.55469 6.83008 4.31055 7.80664 4.31055C8.61133 4.31055 9.25 4.50391 9.72266 4.89062C10.1992 5.27734 10.4375 5.79297 10.4375 6.4375C10.4375 6.86719 10.3398 7.23828 10.1445 7.55078C9.94922 7.86328 9.57812 8.21484 9.03125 8.60547C8.65625 8.88281 8.41797 9.09375 8.31641 9.23828C8.21875 9.38281 8.16992 9.57227 8.16992 9.80664V10.1582H6.61719ZM6.42969 12.1621C6.42969 11.834 6.51758 11.5859 6.69336 11.418C6.86914 11.25 7.125 11.166 7.46094 11.166C7.78516 11.166 8.03516 11.252 8.21094 11.4238C8.39062 11.5957 8.48047 11.8418 8.48047 12.1621C8.48047 12.4707 8.39062 12.7148 8.21094 12.8945C8.03125 13.0703 7.78125 13.1582 7.46094 13.1582C7.13281 13.1582 6.87891 13.0723 6.69922 12.9004C6.51953 12.7246 6.42969 12.4785 6.42969 12.1621Z"
                  fill="white"/>
            </svg>
          </div>
        </div>

        <div class="chose-wrapper">
          <router-link class="btn-first-consultation item-link" to="/algorithms/vomiting/firstConsultation">
            <div class="item">
              <div class="icon">
                <svg width="32" height="39" viewBox="0 0 32 39" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.3646 4.23828H1.63525V37.2592H30.3646V4.23828Z" stroke="#1E3B89"
                        stroke-width="1.5" stroke-miterlimit="10"/>
                  <path d="M8.51013 0.902344V8.10822" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M15.9868 0.902344V8.10822" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M23.4636 0.902344V8.10822" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M13.1145 15.5234H17V27.5179" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M12.323 27.5195H21.6509" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                </svg>
              </div>
              <div class="text">
                Первинна<br>консультація
              </div>
              <div class="arrow">
                <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.5" cy="13.4023" r="12" transform="rotate(-90 12.5 13.4023)"
                          stroke="#D1DDE9"/>
                  <path d="M11 8.87989L15.5355 13.3926" stroke="#00ACE6" stroke-width="2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.5355 13.3931L11 17.9023" stroke="#00ACE6" stroke-width="2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </div>
          </router-link>

          <router-link class="btn-second-visit item-link" to="/algorithms/vomiting/secondConsultation">
            <div class="item">
              <div class="icon">
                <svg width="32" height="38" viewBox="0 0 32 38" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.8075 3.58594H30.3467V36.2524H1.65332V3.58594H9.02266" stroke="#1E3B89"
                        stroke-width="1.5" stroke-miterlimit="10"/>
                  <path d="M22.9709 1.55273H9.02271V5.51663H22.9709V1.55273Z" stroke="#1E3B89"
                        stroke-width="1.5" stroke-miterlimit="10"/>
                  <path d="M7.71606 14.4375H24.2775" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M7.71606 14.4375H24.2775" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M7.71606 20.2871H24.2775" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                  <path d="M7.71606 26.1348H24.2775" stroke="#1E3B89" stroke-width="1.5"
                        stroke-miterlimit="10"/>
                </svg>
              </div>
              <div class="text">
                Повторний<br>візит
              </div>
              <div class="arrow">
                <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.5" cy="13.4023" r="12" transform="rotate(-90 12.5 13.4023)"
                          stroke="#D1DDE9"/>
                  <path d="M11 8.87989L15.5355 13.3926" stroke="#00ACE6" stroke-width="2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.5355 13.3931L11 17.9023" stroke="#00ACE6" stroke-width="2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </div>
          </router-link>
        </div>

      </div>

    </div>

    <transition name="fade">
      <div class="literature-popup" v-if="literaturePopup === true">
        <div class="literature-popup-content">
          <div class="close-header">
            <div @click="closeliteraturePopup()" class="close-btn">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M0.258301 0.257812L18.7418 18.7413" stroke="#00ACE6" stroke-width="1.7"
                      stroke-miterlimit="10"/>
                <path d="M18.7418 0.257812L0.258301 18.7413" stroke="#00ACE6" stroke-width="1.7"
                      stroke-miterlimit="10"/>
              </svg>
            </div>
          </div>
          <div class="text-block">
            <p class="h3-title">Література:</p>
            <p class="list-text" style="margin-bottom: 15px">
              <span>1.</span>
              Шадрін О.Г. Алгоритм діагностики й лікування функціональних гастроінтестинальних розладів у дітей раннього
              віку / О.Г. Шадрін, Т.Л. Марушко, О.С. Няньковська, Н.Ю. Бондаренко // Здоров’я дитини. – 2019. – Т. 14,
              №8. – С. 31-39.
            </p>

            <p class="list-text">
              <span>2.</span>
              Особливості діагностики та підходи до лікувально- профілактичного харчування дітей раннього віку з
              функціональними розладами травної системи : метод. рекоменд. / [О.Г. Шадрін, С.Л. Няньковський, Г.В.
              Бекетова та ін.] – К. : Люди в білому, 2016. – 28 с.
            </p>
          </div>

        </div>
      </div>
    </transition>

  </div>

</template>

<script>
export default {
  name: "vomiting",
  data: () => ({
    literaturePopup: false,
  }),

  methods: {

    openliteraturePopup() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.literaturePopup = true;
    },

    closeliteraturePopup() {
      document.getElementsByTagName("body")[0].style.overflow = "visible";
      this.literaturePopup = false;
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../../style/vars";

.vomiting {

  .components_send-question-block, .section-nav {
    display: none;
  }

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }


  .content-wrapper {
    padding: 25px 15px 0 15px;
    margin-bottom: 80px;

    .chose-next-step {
      background: white;

      .h2-title {
        border-bottom: 1px solid #DAE3ED;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .popup-info-icon {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .back-arrow {
          cursor: pointer;
        }

        .middle-text {
          p {
            color: #9AB9D8;
            font-family: $fontB;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
        }

        padding: 10px;
      }

      .chose-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;

        a {
          display: flex;
          width: 50%;

          &:first-child {
            .item {
              border-right: 1px solid #DAE3ED;
            }
          }

        }

        .item {

          padding: 25px 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          transition: all 0.2s ease;

          svg {
            path, circle {
              transition: all 0.2s ease;
            }
          }

          &:active {
            background: #E0ECF7;

            .arrow {

              path {
                stroke: white;
              }
            }

            svg {


              circle {
                fill: #00ACE6;
              }
            }
          }

          .text {
            text-align: center;
            color: #1E3B89;
            font-size: 15px;
            font-family: $fontR;
            margin: 10px 0;
          }

        }
      }
    }
  }

  .literature-popup {
    padding: 15px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(88, 101, 136, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 425px;
    margin: 0 auto;
    right: 0;

    .literature-popup-content {
      background: #FFFFFF;
      border-radius: 4px;

      .close-header {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 15px;
        background: #EEF3F8;
        border-radius: 4px 4px 0 0;

      }

      .text-block {
        padding: 15px;

        .h3-title {
          color: #1E3B89;
          font-size: 15px;
          font-family: $fontSB;
          padding-bottom: 15px;
        }

        .list-text {
          color: #1E3B89;
          font-size: 15px;
          font-family: $fontL;

          span {
            font-family: $fontSB;
          }
        }
      }
    }
  }
}

</style>
