<template>
  <div class="home" :key="routeKey" v-if="isRendered">

    <div class="head-bg block2" v-if="blockVisibility" style="order: 1;">
      <p class="h1-title">
        Ласкаво просимо
        <br/>на клінічний портал
      </p>
    </div>

    <send-question-clinical
        class="block3"
        @openQuestionPopup="openPopUp('1')"
        :style="{ order: orderBlock }"
        v-if="getActivePage().indexOf('/clinical/instruction') === -1"
    />

    <router-view class="block4" style="order: 3;"/>

    <main-menu style="order : 4;" class="block7" @click="openPopUp('1')" v-if="mainMenuVisibility"/>

    <download-instruction-clinical
        class="block5"
        style="order: 4;"
        v-if="getActivePage().indexOf('/clinical/algorithms/') === -1 && getActivePage().indexOf('/clinical/instruction') === -1"
    />

    <footer-text class="block6" style="order: 5;"/>

  </div>
</template>

<script>
import DownloadInstruction from "../../components/Clinical/download-instruction";
import Registration from "../../components/Clinical/registration";
import SendQuestion from "../../components/Clinical/send-question";
import TopMenu from "../../components/Clinical/top-menu";
import UserInfo from "../../components/Clinical/user-info";
import Login from "../../components/Clinical/login";
import Info from "../../components/Clinical/info";
import FooterText from "../../components/Clinical/footer-text";
import QuestionPopup from "../../components/Clinical/question-popup";
import PersonalData from "../../components/Clinical/personal-data";
import PersonalDataLogin from '../../components/Clinical/personal-data-login';

import MainMenu from "../../components/Clinical/Main/main-menu";

export default {
  name: "home",

  components: {
    "send-question-clinical": SendQuestion,
    "download-instruction-clinical": DownloadInstruction,
    "registration-clinical": Registration,
    "top-menu-clinical": TopMenu,
    "user-info-clinical": UserInfo,
    "login-clinical": Login,
    "info-clinical": Info,
    "footer-text": FooterText,
    "question-popup-clinical": QuestionPopup,
    "personal-data-clinical": PersonalData,
    "personal-data-clinical-login": PersonalDataLogin,
    "main-menu": MainMenu,
  },
  data: () => ({
    isRendered: false,
    popupCtrl: "",
    PersonalDataFromPopUp: "2",
    registrationStage: "1",
    routeKey: '',

    blockVisibility: null,
    orderBlock: null,
    mainMenuVisibility: null
  }),
  computed: {},
  created() {
    document.body.style.background = '#4A2A80';
    if (sessionStorage.getItem("key") === null) {
      this.popupCtrl = "2";
    }
  },
  updated() {
    this.blockVisibility = this.isVisibleBlock();
    this.orderBlock = this.getOrderBlock();
    this.mainMenuVisibility = this.isVisibleMainMenu();
  },
  mounted() {
    this.blockVisibility = this.isVisibleBlock();
    this.orderBlock = this.getOrderBlock();
    this.mainMenuVisibility = this.isVisibleMainMenu();

    this.$nextTick(() => {
      if (this.popupCtrl !== "") {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      }

      this.isRendered = true;
    });
  },

  methods: {
    isVisibleBlock() {
      if (
          this.getActivePage() !== "/clinical/algorithms" &&
          this.getActivePage() !== "/clinical/algorithms/" &&
          this.getActivePage() !== "/clinical/library" &&
          this.getActivePage() !== "/clinical/library/" &&
          this.getActivePage() !== "/clinical/products" &&
          this.getActivePage() !== "/clinical/instruction"
      ) {
        return true;
      }
    },
    getOrderBlock() {
      if (
          this.getActivePage() !== "/clinical/algorithms" &&
          this.getActivePage() !== "/clinical/algorithms/" &&
          this.getActivePage() !== "/clinical/library" &&
          this.getActivePage() !== "/clinical/products"
      ) {
        return 2;
      } else {
        return 4;
      }
    },
    isVisibleMainMenu() {
      if (
          this.getActivePage() !== "/clinical/algorithms" &&
          this.getActivePage() !== "/clinical/algorithms/" &&
          this.getActivePage() !== "home" &&
          this.getActivePage() !== "/clinical/products" &&
          this.getActivePage() !== "/clinical/instruction"
      ) {
        return true;
      }
    },
    getActivePage() {
      let activePage = localStorage.getItem("active-page");

      if (!activePage) {
        localStorage.setItem('active-page', '/clinical/library');
        activePage = '/clinical/library';
      }

      return activePage;
    },
    openPopUp(id) {
      if (id !== this.popupCtrl && id !== this.fromPopUp) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        this.popupCtrl = id;
      } else {
        this.popupCtrl = "";
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      }
    },

    openPopupInMenu(value) {
      if (value !== this.popupCtrl) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        this.fromPopUp = value;
        this.popupCtrl = value;
      } else {
        document.getElementsByTagName("body")[0].style.overflow = "visible";
        this.registrationStage = "";
        this.fromPopUp = "";
        this.popupCtrl = "";
      }
    },

    closeLoginPopUp() {
      this.popupCtrl = "";
      this.fromPopUp = "";
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },

    openRegistrationFromLogin() {
      this.popupCtrl = "5";
    },

    openPersonalDataFromRegistration() {
      this.PersonalDataFromPopUp = "5";
      this.popupCtrl = "3";
    },

    backFromPersonalData() {
      if (this.PersonalDataFromPopUp === "5") {
        this.registrationStage = "2";
        this.popupCtrl = this.PersonalDataFromPopUp;
      } else if (this.PersonalDataFromPopUp === "4") {
        this.popupCtrl = this.PersonalDataFromPopUp;
      }
    },

    registrationSendData() {
      this.popupCtrl = "2";
      this.PersonalDataFromPopUp = "";
    },

    closeMailQuestion() {
      this.popupCtrl = "";
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },

    openPersonalDataFromUserInfo() {
      this.PersonalDataFromPopUp = "4";
      this.popupCtrl = "3";
    },

    closeUserInfo() {
      this.popupCtrl = "";
      this.PersonalDataFromPopUp = "";
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/vars";

.home {
  position: relative;
  background: #f1edf7;

  display: flex;
  flex-flow: wrap;

  .block1,
  .block2,
  .block3,
  .block4,
  .block5,
  .block6,
  .block7 {
    width: 100%;
  }

  .components_send-question-block {
    display: none !important;
  }

  .head-bg {
    background: url("../../assets/images/head-bg.jpg") no-repeat top center;
    background-size: cover;
    width: 100%;
    height: 367px;

    .h1-title {
      padding-top: 25px;
      text-align: center;
      color: #313183;
      font-family: $fontR;
      font-size: 24px;
    }
  }

  .h3-title {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 25px 0;
    color: #9ab9d8;
    font-family: $fontB;
    font-size: 15px;
  }

  .content-wrapper {
    padding: 0 15px;

    .section {
      max-width: 395px;
      width: 100%;
      margin: 0 auto 15px auto;

      background-color: white;
      border-radius: 4px;

      img {
        width: 100%;
        height: auto;
      }

      .more-info {
        padding: 0 15px;
        margin-top: 20px;

        .h1-title {
          font-family: $fontR;
          font-size: 20px;
          margin-bottom: 15px;
          color: #1e3b89;
        }

        .reg-text {
          font-family: $fontL;
          font-size: 14px;
          color: #1e3b89;
          margin-bottom: 30px;
        }
      }

      .relocate {
        border-top: 1px solid #dae3ed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 0;

        a {
          color: #00ace6;
          letter-spacing: 2px;
          font-family: $fontB;
          margin-right: 7px;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
