<template>
  <div class="cards">

    <div class="cards-content">
      <div class="header-image">
        <img src="../assets/images/comfort-cards3/banner.jpg" alt=""/>
      </div>

      <div class="cards-container">
        <div class="card" v-for="(card, index) in cards" :key="index">
          <div class="card-image">
            <img :src="card.img" alt=""/>
          </div>
          <div class="card-content">
            <div class="number">
              {{ index + 1 }}
            </div>
            <hr/>
            <div class="title">
              {{ card.title }}
            </div>
            <div class="text" v-html="card.text"></div>
            <div class="drop-list" v-for="(list, index) in card.drop_down_lists" :key="index">
              <div class="list-title" @click="list.isOpened = !list.isOpened">
                <div class="text">
                  {{ list.title }}
                </div>
                <div class="icon" :class="list.isOpened ? 'rotate' : ''">
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75678 1.24322L6.26947 5.7787" stroke="white" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.26951 5.7787L10.7787 1.24323" stroke="white" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <div class="list-content" v-if="list.isOpened">
                <div v-html="list.data"></div>
              </div>
            </div>
          </div>
          <div class="card-link" v-if="card.link">
            <hr/>
            <router-link class="url" :to="card.link.ref">{{
                card.link.name
              }}
            </router-link>
          </div>
        </div>

        <div class="cards-container-info">
          <strong>Безумовна перевага у годуванні дитини належить грудному вигодовуванню.</strong>
          <br>
          <br>
          Згідно рекомендацій МОЗ України воно є основним харчуванням для малюка на першому році життя навіть після
          введення прикорму.
          <br>
          <br>
          ВООЗ рекомендує зберігати грудне вигодовування до 2 років.
          <br>
          <br>
          Компанія Nutricia повністю підтримує такі рекомендації.
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import Image1 from "../assets/images/comfort-cards3/img1.png";
import Image1_5 from "../assets/images/comfort-cards3/img1,5.png";
import Image2 from "../assets/images/comfort-cards3/img2.png";
import Image3 from "../assets/images/comfort-cards3/img3.png";
import Image4 from "../assets/images/comfort-cards3/img4.png";
import Image5 from "../assets/images/comfort-cards3/img5.png";
import Image6 from "../assets/images/comfort-cards3/img6.png";

export default {
  name: "cards3",
  data: () => ({

    cards: [
      {
        img: Image1,
        title: "Першість в інноваціях",
        text:
            "Перша антирефлюксна суміш Nutricia була розроблена у 1952 році й невпинно вдосконалюється водночас з дослідженнями грудного молока та функціональних розладів травлення",
        link: "",
      },
      {
        img: Image1_5,
        title: "Оновлена формула",
        drop_down_lists: [
          {
            title: '',
            isOpened: true,
            data: `
                    <table class="structure-table" cellspacing="0" cellpadding="0">
                        <thead>
                            <th></th>
                            <th>Попередня формула</th>
                            <th>Нова формула</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>DHA</th>
                                <td>6,7 мг</td>
                                <td>16,5 мг</td>
                            </tr>
                            <tr>
                                <th>ARA</th>
                                <td>12 мг</td>
                                <td>16,5 мг</td>
                            </tr>
                            <tr>
                                <th>Вітамін D<sub>3</sub></th>
                                <td>1,2 мкг<br>(48 МО)</td>
                                <td>1,6 мкг<br>(64 МО)</td>
                            </tr>
                            <tr>
                                <th>Інозитол</th>
                                <td>3,3 мг</td>
                                <td>7,3 мг</td>
                            </tr>
                        </tbody>
                    </table>
                `
          },
        ],
        text: `
                    <p>Збережено камедь бобів ріжкового дерева (в якості неперетравлюваного загущувача) та переважання казеїну над сироватковим білком для збільшення в’язкості харчової грудки.</p>
                    <p>Істотно збільшено вміст <strong>вітаміну D<sub>3</sub></strong> для розвитку кісток й імунної системи, докозагексаєнової <strong>(DHA)</strong> й арахідонової <strong>(ARA)</strong> жирних кислот, а також <strong>інозитолу</strong> для розвитку мозку і зору.</p>
                    `,
        link: "",
      },
      {
        img: Image2,
        title: "Особливості призначення",
        text:
            `
        <p>Суміш може бути <strong>як єдиним джерелом харчування, так і поєднуватися з іншою сумішшю або грудним молоком.</strong></p>
        <p>Терапевтичний ефект оцінюється протягом 1-2 тижнів. Призначається перед основною їжею чи на повний раціон.</p>
        <p>Через вміст цільного білка <strong>суміш не призначається дітям з алергією на БКМ</strong> ані в якості загущувача іншої суміші, ані на повний раціон</p>
        `,
        link: "",
      },
      {
        img: Image3,
        title: "Дозування",
        text: `
                    <p><strong>Якщо суміш призначається на повний раціон,</strong> перехід на неї відбувається поступово за стандартною схемою. </p>
                    <p><strong>Якщо суміш поєднується з основним харчуванням,</strong> введення починають з 5 мл (з ложечки чи окремої пляшки перед кожним годуванням) і поступово досягають об’єму, що запобігає зригуванням.</p>
                    <p><strong>Разова доза суміші підбирається індивідуально,</strong> вона має бути мінімально достатньою (зазвичай 30-60 мл)</p>
                    `,
        link: '',
      },
      {
        img: Image4,
        title: "Відповідність рекомендаціям МОЗ України",
        text: "Склад суміші відповідає методичним рекомендаціям з дієтотерапії функціональних зригувань, узгодженим Міністерством охорони здоров’я України й Національною академією медичних наук України",
        link: {
          name: "Ознайомитись",
          ref: "/library-item/guidance/1 ",
        },
      },
      {
        img: Image5,
        title: "Виникли запитання щодо діагностики й лікування?",
        text: `
<p>Визначити або перевірити призначення допоможе <strong>зручний інтерактивний алгоритм.</strong></p>
<p>«Червоні прапорці» й рекомендації щодо постуральної терапії та дієти годуючих мам <strong>можна одразу надсилати батькам у Viber</strong></p>`,
        link: {
          name: "Перейти до алгоритму",
          ref: "/algorithms/vomiting",
        },
      },
      {
        img: Image6,
        title: "Розрахунок добової норми суміші",
        text: "Добову потребу дитини в суміші при призначенні її на повний раціон зручно розрахувати за допомогою калькулятора, в основу якого покладені норми для здорових дітей, затверджені наказом МОЗ України №149 від 20.03.2008 р.",
        link: {
          name: "Розрахувати",
          ref: "/production/11 ",
        },
      }
    ],
  }),

};
</script>

<style lang="scss" scoped>
@import "../style/vars";

.cards {
  height: 100%;
  position: relative;

  &-content {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;

    .header-image {
      margin-bottom: 30px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-container {
    padding: 0 15px;

    .card {
      background: #fff;
      border-radius: 4px;
      margin-bottom: 36px;

      &-image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &-content {
        padding: 9px 19px 20px 20px;

        .number {
          width: 34px;
          height: 34px;

          max-width: 34px;
          max-height: 34px;

          margin-bottom: 8px;

          border-radius: 50%;
          border: 1.17241px solid #9ab9d8;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 17px;
          font-weight: bold;
          color: #9ab9d8;
        }

        hr {
          margin-bottom: 14px;
        }

        .title {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;

          color: #1e3b89;

          margin-bottom: 12px;
        }

        .text {
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;

          color: #1e3b89;

          ::v-deep p {
            margin-bottom: 15px;

            sub {
              line-height: 0;
            }
          }
        }

        .schema {
          margin-top: 20px;

          svg {
            min-width: 100%;
          }
        }
      }

      .drop-list {
        display: flex;
        flex-direction: column;

        .list-title {

          display: none;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          padding: 8px 20px 15px 19px;
          // background: rgba(0, 172, 230, 0.6);
          border-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .icon {
            display: flex;
            align-items: center;
          }

          .text {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #1e3b89;
          }

          .rotate {
            transform: rotate(180deg);
          }
        }

        .list-content {
          .content {
            border-top: 1px solid #dae3ed;
            background: rgba(0, 172, 230, 0.1);

            padding: 14px 19px 16px 20px;

            p {
              font-weight: 300;
              font-size: 14px;
              line-height: 130%;
              color: #1e3b89;

              padding-bottom: 10px;
            }

            p:last-child {
              padding-bottom: 0;
            }
          }

          ::v-deep .structure-table {
            width: 100%;
            margin-top: 4px;

            border-bottom: none;
            color: #1e3b89;
            border-top: 1px solid #E0ECF7 !important;


            thead {
              th {
                padding-top: 2px;

                text-align: center;

                font-size: 12px;
                line-height: 14px;

              }

              th:first-child {
                background: rgba(203, 223, 242, 0.15);
                border-left: 1px solid #e0ecf7;
              }

              th:last-child {
                border-right: 1px solid #e0ecf7;
                border-left: 1px solid #e0ecf7;
              }
            }

            tbody {
              padding-top: 17px;

              tr {
                border-top: 1px solid #E0ECF7 !important;

                th {
                  background: rgba(203, 223, 242, 0.15);

                  min-width: 90px;

                  text-align: left;
                  padding: 15px 6px 15px 11px;

                  font-size: 14px;
                  line-height: 18px;

                  border-left: 1px solid #e0ecf7;

                  sub {
                    line-height: 0;
                  }
                }

                td {
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: center;

                  padding: 0 10px;
                }

                td:last-child {
                  border-right: 1px solid #e0ecf7;
                  border-left: 1px solid #e0ecf7;
                }
              }

              tr:last-child {
                th,
                td {
                  border-bottom: 1px solid #e0ecf7;
                }
              }
            }
          }
        }
      }

      .drop-list:last-child {
        margin-bottom: 0;
      }

      .list {
        .item {
          display: flex;
          flex-direction: row;
          margin-bottom: 14px;

          .icon {
            min-width: 37px;
            margin-right: 16px;
          }

          .item-text {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #1E3B89;
          }
        }
      }

      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #dae3ed;
        margin-bottom: 7px;
        padding: 0;
      }

      &-link {
        padding-bottom: 8px;

        .url {
          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: bold;
          font-size: 15px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: #00ace6;
        }

        .url::after {
          content: "\203A";

          margin-left: 5px;
          margin-bottom: 3px;

          font-weight: bold;
          font-size: 25px;
          color: #00ace6;
        }
      }
    }

    &-info {
      margin: 0 -15px 0 -15px;
      background: #E1ECF7;
      padding: 20px 20px;

      font-weight: 300;
      font-size: 12px;
      line-height: 15px;

      color: #1E3B89;
    }
  }
}
</style>
