<template>
  <div class="library">
    <div class="head-links">
      <router-link class="back-links" to="/clinical/product-categories"
        >Продукти</router-link
      >
      <span>/</span>
      <router-link
        class="now-page"
        :to="`/clinical/products/${$route.meta.category}`"
        >{{ productCategory.title }}</router-link
      >
    </div>

    <div class="content">
      <div class="products">
        <div class="products-category">{{ productCategory.title }}</div>
        <div class="products__list">
          <div
            class="products__col"
            v-for="(product, key) in productCategory.products"
            :key="key"
          >
            <router-link
              tag="div"
              :to="`/clinical/product/${productCategory.slug}/${key}`"
              class="products__item"
            >
              <div class="products__item-image">
                <img
                  :src="
                    require(`@/assets/images/clinical-products/${$route.meta.category}/${product.image[0]}`)
                  "
                  alt
                />
              </div>
              <div class="products__item-title">{{ product.titlePreviev }}</div>
              <div class="products__item-detail">Деталі</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  data() {
    return {
      productCategory: {},
    };
  },
  computed: {
    ...mapGetters(["getClinicalProducts"]),
  },
  mounted() {
    this.productCategory = this.getClinicalProducts[this.$route.meta.category];
  },
};
</script>

<style scoped lang="scss">
@import "~@/style/vars";

.head-links {
  background: rgba(255, 255, 255, 0.49);
  width: 100%;
  padding: 10px 15px;

  .back-links {
    color: #ac98c5;
    font-family: $fontR;
    font-size: 11px;
  }

  span {
    padding: 0 5px;
    color: #ac98c5;
    font-family: $fontR;
    font-size: 11px;
  }

  .now-page {
    color: #8f67a4;
    font-family: $fontSB;
    font-size: 11px;
  }
}

.content {
  padding: 16px 15px 60px;
}
.products {
  &-category {
    margin-bottom: 16px;
    padding: 16px 15px;
    background: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    color: #ac98c5;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
  }
  &__col {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 7px;
  }
  &__item {
    position: relative;
    margin-bottom: 16px;
    height: 210px;
    padding: 16px 8px 12px;
    background: #ffffff;
    border-radius: 1px;
    text-align: center;
    &-image {
      margin-bottom: 4px;
      img {
        display: inline-block;
        max-width: 100%;
        height: 90px;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4a2a80;
    }
    &-detail {
      position: absolute;
      bottom: 14px;
      right: 12px;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-decoration-line: underline;
      color: #508ecc;
      cursor: pointer;
    }
  }
}
</style>
