<template>
    <div class="energy-calc">
        <breadcrumbs :pathList="pathList" />

        <div class="content-wrapper">
            <div class="page-title">
                Алгоритм вибору ентерального <br> харчування у ВРІТ
            </div>

            <div class="text">
                <p><strong>1 Етап </strong> 
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01529 9.70417C6.378 10.0835 6.9829 10.1002 7.36637 9.74138L11.7011 5.68535C12.0845 5.32652 12.1014 4.72809 11.7387 4.34872C11.376 3.96934 10.7711 3.95268 10.3876 4.31151L6.0529 8.36754C5.66943 8.72636 5.65259 9.32479 6.01529 9.70417Z" fill="#AC98C5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01504 0.296063C5.65248 0.675576 5.66955 1.274 6.05317 1.63268L10.3879 5.6856C10.7715 6.04428 11.3764 6.02739 11.7389 5.64787C12.1015 5.26836 12.0844 4.66993 11.7008 4.31125L7.3661 0.258339C6.98249 -0.10034 6.3776 -0.0834509 6.01504 0.296063Z" fill="#AC98C5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.261385 9.70423C0.62409 10.0836 1.22899 10.1003 1.61247 9.74144L5.94715 5.68541C6.33063 5.32658 6.34747 4.72815 5.98476 4.34878C5.62206 3.9694 5.01716 3.95274 4.63368 4.31157L0.298997 8.3676C-0.0844794 8.72643 -0.101319 9.32486 0.261385 9.70423Z" fill="#AC98C5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.261132 0.296063C-0.101425 0.675576 -0.0843527 1.274 0.299263 1.63268L4.63395 5.6856C5.01757 6.04428 5.62246 6.02739 5.98502 5.64787C6.34757 5.26836 6.3305 4.66993 5.94689 4.31125L1.6122 0.258339C1.22858 -0.10034 0.623689 -0.0834509 0.261132 0.296063Z" fill="#AC98C5"/>
                </svg>
                <strong> Ентеротерапія</strong> <br> (початкове  харчування)</p>
                <p><strong>Задача: </strong>оцінити  функцію шкт</p>
            </div>

            <div class="functions">
                <div class="function" @click="openBadFunctionPopup()">
                    <div class="function-icon">
                        <svg width="69" height="57" viewBox="0 0 69 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip3)">
                            <path d="M57.9456 39.9805L62.7191 44.741C63.0421 45.0631 63.5805 45.0631 63.9035 44.741C64.2265 44.4188 64.2265 43.882 63.9035 43.5598L59.13 38.7994C58.9685 38.6383 58.7532 38.5488 58.5378 38.5488C58.3225 38.5488 58.1071 38.6383 57.9456 38.7994C57.6226 39.1215 57.6226 39.6584 57.9456 39.9805Z" fill="#4A2A80"/>
                            <path d="M63.9035 20.1155C63.742 19.9545 63.5266 19.865 63.3113 19.865C63.096 19.865 62.8806 19.9545 62.7191 20.1155L57.9456 24.876C57.6226 25.1981 57.6226 25.735 57.9456 26.0571C58.2686 26.3793 58.807 26.3793 59.13 26.0571L63.9035 21.2967C64.2265 20.9746 64.2265 20.4556 63.9035 20.1155Z" fill="#4A2A80"/>
                            <path d="M68.1746 31.5871H61.4272C60.9606 31.5871 60.5837 31.963 60.5837 32.4283C60.5837 32.8936 60.9606 33.2694 61.4272 33.2694H68.1746C68.6412 33.2694 69.0181 32.8936 69.0181 32.4283C69.0001 31.963 68.6233 31.5871 68.1746 31.5871Z" fill="#4A2A80"/>
                            <path d="M48.614 36.2939C46.317 36.2939 44.4507 34.4327 44.4507 32.1419C44.4507 29.8512 46.317 27.99 48.614 27.99C50.911 27.99 52.7773 29.8512 52.7773 32.1419C52.7773 34.4327 50.911 36.2939 48.614 36.2939ZM48.614 29.6006C47.2143 29.6006 46.0658 30.746 46.0658 32.1419C46.0658 33.5378 47.2143 34.6832 48.614 34.6832C50.0138 34.6832 51.1623 33.5378 51.1623 32.1419C51.1623 30.746 50.0138 29.6006 48.614 29.6006Z" fill="#4A2A80"/>
                            <path d="M40.3054 23.7664C38.0084 23.7664 36.1421 21.9052 36.1421 19.6144C36.1421 17.3237 38.0084 15.4625 40.3054 15.4625C42.6024 15.4625 44.4687 17.3237 44.4687 19.6144C44.4687 21.9052 42.6024 23.7664 40.3054 23.7664ZM40.3054 17.0731C38.9057 17.0731 37.7572 18.2185 37.7572 19.6144C37.7572 21.0103 38.9057 22.1557 40.3054 22.1557C41.7052 22.1557 42.8537 21.0103 42.8537 19.6144C42.8537 18.2006 41.7052 17.0731 40.3054 17.0731Z" fill="#4A2A80"/>
                            <path d="M16.7969 17.5027C14.4999 17.5027 12.6335 15.6414 12.6335 13.3507C12.6335 11.06 14.4999 9.19873 16.7969 9.19873C19.0939 9.19873 20.9602 11.06 20.9602 13.3507C20.9602 15.6414 19.0939 17.5027 16.7969 17.5027ZM16.7969 10.8094C15.3971 10.8094 14.2486 11.9548 14.2486 13.3507C14.2486 14.7466 15.3971 15.892 16.7969 15.892C18.1966 15.892 19.3451 14.7466 19.3451 13.3507C19.3451 11.9369 18.1966 10.8094 16.7969 10.8094Z" fill="#4A2A80"/>
                            <path d="M7.87817 30.0301C5.58116 30.0301 3.71484 28.1689 3.71484 25.8782C3.71484 23.5874 5.58116 21.7262 7.87817 21.7262C10.1752 21.7262 12.0415 23.5874 12.0415 25.8782C12.0415 28.1689 10.1752 30.0301 7.87817 30.0301ZM7.87817 23.3369C6.47843 23.3369 5.32993 24.4822 5.32993 25.8782C5.32993 27.2741 6.47843 28.4195 7.87817 28.4195C9.27791 28.4195 10.4264 27.2741 10.4264 25.8782C10.4264 24.4822 9.27791 23.3369 7.87817 23.3369Z" fill="#4A2A80"/>
                            <path d="M41.3821 45.6716C41.9384 45.6716 42.387 45.2242 42.387 44.6694C42.387 44.1146 41.9384 43.6672 41.3821 43.6672H12.7771C9.86996 43.6672 7.50117 41.3049 7.50117 38.4057C7.50117 35.5064 9.86996 33.1441 12.7771 33.1441H43.7688C43.697 32.8041 43.6611 32.4819 43.6611 32.1419C43.6611 31.8019 43.697 31.4797 43.7688 31.1397H7.28582C4.37867 31.1397 2.00988 28.7774 2.00988 25.8782C2.00988 22.979 4.37867 20.6166 7.28582 20.6166H35.4601C35.3883 20.2766 35.3524 19.9545 35.3524 19.6144C35.3524 19.2923 35.3883 18.9523 35.4601 18.6122H16.5636C13.6564 18.6122 11.2876 16.2499 11.2876 13.3507C11.2876 10.4515 13.6564 8.08917 16.5636 8.08917H29.574C33.3425 8.08917 35.4062 6.04898 35.4062 2.34443C35.4062 2.32653 35.4242 1.32433 35.4242 1.0022C35.4242 0.44741 34.9756 0 34.4192 0C33.8629 0 33.4143 0.44741 33.4143 1.0022C33.4143 1.27064 33.3964 1.9686 33.3964 2.23705V2.32653C33.3964 4.9394 32.212 6.10267 29.556 6.10267H16.5636C12.5618 6.08477 9.29571 9.34191 9.29571 13.3507C9.29571 15.1224 9.95969 16.8405 11.1441 18.1648L11.5389 18.6122H7.28582C3.26606 18.6122 0 21.8694 0 25.8782C0 29.7975 3.21222 33.0546 7.14226 33.1262L7.73446 33.1441L7.33966 33.5736C6.15527 34.898 5.49129 36.616 5.49129 38.4057C5.49129 42.4144 8.75735 45.6716 12.7771 45.6716H20.2065L19.5784 46.1548C19.2913 46.3695 19.0221 46.6201 18.7888 46.8527C16.1867 49.4656 16.2047 53.0986 16.2226 55.0493C16.2226 55.2283 16.2226 55.4072 16.2226 55.5504V55.8188C16.2226 56.3736 16.6713 56.821 17.2276 56.821C17.7839 56.821 18.2325 56.3736 18.2325 55.8188V55.5325C18.2325 55.3714 18.2325 55.1925 18.2325 54.9777C18.2146 53.0986 18.2146 50.271 20.2244 48.2487C21.9293 46.5306 24.8544 45.6716 28.8921 45.6716H41.3821Z" fill="#4A2A80"/>
                            <path d="M48.7755 24.876H46.2093L46.6041 24.4286C47.7885 23.1043 48.4525 21.3862 48.4525 19.6145C48.4525 15.6057 45.1864 12.3485 41.1666 12.3485H36.77L37.3802 11.8653C38.1877 11.2389 38.8696 10.4694 39.3901 9.62827C40.9872 7.0154 40.9872 3.90143 40.9872 1.82544V1.36014C40.9872 0.80535 40.5386 0.357941 39.9823 0.357941C39.4259 0.357941 38.9773 0.80535 38.9773 1.36014V1.84334C38.9773 3.79405 38.9773 6.46061 37.6673 8.60818C36.106 11.1316 33.0553 12.3664 28.2998 12.3664H21.6062C21.678 12.7064 21.7138 13.0286 21.7138 13.3686C21.7138 13.7086 21.678 14.0308 21.6062 14.3708H41.1308C44.0379 14.3708 46.4067 16.7331 46.4067 19.6324C46.4067 22.5316 44.0379 24.8939 41.1308 24.8939H12.7232C12.7232 24.9297 12.7412 24.9655 12.7412 25.0013L12.7591 25.0908C12.7591 25.1265 12.777 25.1623 12.777 25.1981V25.216C12.8129 25.4308 12.8309 25.6634 12.8309 25.8782C12.8309 26.2003 12.795 26.5404 12.7232 26.8804H41.1666H48.7755C51.6826 26.8804 54.0514 29.2427 54.0514 32.1419C54.0514 35.0411 51.6826 37.4035 48.7755 37.4035H12.5617C12.0951 37.4035 11.6824 37.7256 11.5927 38.173C11.5747 38.2446 11.5747 38.3162 11.5747 38.3878C11.5747 38.9426 12.0233 39.39 12.5796 39.39H41.1846C44.0917 39.39 46.4605 41.7523 46.4605 44.6515C46.4605 47.5507 44.0917 49.913 41.1846 49.913H28.1203C28.0486 49.913 27.9947 49.913 27.923 49.913C25.608 49.913 24.0647 50.3605 23.0956 51.3448C21.8395 52.5796 21.8395 54.3692 21.8395 55.9262V55.9799C21.8395 56.5347 22.2881 56.9821 22.8444 56.9821C23.4007 56.9821 23.8493 56.5347 23.8493 55.9799V55.9262C23.8493 54.5661 23.8493 53.4028 24.5133 52.7586C25.1235 52.168 26.3079 51.8816 28.1383 51.9174H41.2025C45.2223 51.9174 48.4884 48.6603 48.4884 44.6515C48.4884 42.8798 47.8244 41.1617 46.64 39.8374L46.2452 39.39H48.8114C52.8311 39.39 56.0972 36.1328 56.0972 32.124C56.0972 28.1152 52.7952 24.876 48.7755 24.876Z" fill="#4A2A80"/>
                            </g>
                            <defs>
                            <clipPath id="clip3">
                            <rect width="69" height="57" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="function-text">
                        Функція <br>порушена
                    </div>
                    <div class="choise">Вибрати</div>
                </div>

                <div class="function" @click="openNormFunctionPopup()">
                    <div class="function-icon">
                        <svg width="50" height="57" viewBox="0 0 50 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41.3374 45.6704C41.9111 45.6704 42.395 45.1693 42.3233 44.5787C42.2696 44.0597 41.8035 43.6839 41.2837 43.6839C37.9853 43.6839 19.7369 43.6839 12.8534 43.6839C9.98532 43.6839 7.54742 41.4469 7.45779 38.5655C7.36816 35.5947 9.77021 33.1429 12.728 33.1429H48.0417C48.5794 33.1429 49.0096 32.7134 49.0096 32.1765V32.1407V32.1228C49.0096 31.586 48.5615 31.1385 48.0237 31.1385H7.38609C4.51797 31.1385 2.08007 28.9015 1.99044 26.0202C1.90081 23.0494 4.30286 20.5976 7.26061 20.5976H41.3105C41.8483 20.5976 42.2965 20.1502 42.2965 19.6133V19.5954V19.5775C42.2965 19.0406 41.8483 18.5932 41.3105 18.5932L16.6716 18.6111C13.8035 18.6111 11.3656 16.374 11.276 13.4927C11.1863 10.5219 13.5884 8.0701 16.5461 8.0701H29.5423C33.3067 8.0701 35.3682 6.02991 35.3682 2.32535C35.3682 2.30746 35.3861 1.30526 35.3861 0.983124C35.3861 0.428336 34.9379 -0.0190735 34.3822 -0.0190735C33.8265 -0.0190735 33.3784 0.428336 33.3784 0.983124C33.3784 1.25157 33.3605 1.94953 33.3605 2.21798V2.30746C33.3605 4.92033 32.1774 6.0836 29.5244 6.0836H16.5461C12.5487 6.0836 9.28622 9.34074 9.28622 13.3495C9.28622 15.1213 9.94947 16.8393 11.1326 18.1637L11.5269 18.6111H7.42194C3.62168 18.6111 0.341274 21.4745 0.0365364 25.2685C-0.304053 29.4563 3.01221 33.0535 7.15305 33.125L7.7446 33.1429L7.35024 33.5725C6.16714 34.8968 5.50389 36.6148 5.50389 38.4045C5.50389 42.4133 8.76637 45.6704 12.7817 45.6704H20.203L19.5756 46.1536C19.2888 46.3684 19.0199 46.6189 18.7869 46.8516C16.1876 49.4644 16.2056 53.0974 16.2235 55.0481C16.2235 55.2271 16.2235 55.4061 16.2235 55.5492V55.7819C16.2235 56.3009 16.5999 56.7662 17.1198 56.8199C17.7113 56.8736 18.2132 56.4082 18.2132 55.8356V55.5492C18.2132 55.3882 18.2132 55.2092 18.2132 54.9944C18.1953 53.1153 18.1953 50.2877 20.203 48.2654C21.9059 46.5473 24.8278 45.6883 28.8611 45.6883H41.3374V45.6704Z" fill="#4A2A80"/>
                        </svg>
                    </div>
                    <div class="function-text">
                        Функція <br>не порушена
                    </div>
                    <div class="choise">Вибрати</div>
                </div>
            </div>

            <bad-function-popup @closePopup="closeBadFunctionPopup" v-if="isBadFunctionPopupShow" />
            <norm-function-popup @closePopup="closeNormFunctionPopup" v-if="isNormFunctionPopupShow" />
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '../../../components/Clinical/Algorithms/Breadcrumbs';
import BadFunctionPopup from '../../../components/Clinical/Algorithms/EnergyCalc/BadFunction';
import NormFunctionPopup from '../../../components/Clinical/Algorithms/EnergyCalc/NormFunctionPopup';

export default {
    components: {
        'breadcrumbs' : Breadcrumbs,
        'bad-function-popup' : BadFunctionPopup,
        'norm-function-popup' : NormFunctionPopup
    },
    data : () => ({
        pathList: [
            {
                name : 'Алгоритми',
                fullPath: '/clinical/algorithms'
            },
            {
                name : 'Алгоритм вибору ентерального харчування у ВРІТ',
                fullPath : '/clinical/algorithms/enteral'
            }
        ],
        isBadFunctionPopupShow : false,
        isNormFunctionPopupShow : false
    }),
    methods : {
        openBadFunctionPopup(){
            this.isBadFunctionPopupShow = true;
        },
        openNormFunctionPopup(){
            this.isNormFunctionPopupShow = true;
        },
        closeBadFunctionPopup(){
            this.isBadFunctionPopupShow = false;
        },
        closeNormFunctionPopup(){
            this.isNormFunctionPopupShow = false;
        }
    },
    watch : {
        isBadFunctionPopupShow : function(val){
            if(val === true){
                document.getElementsByTagName("body")[0].style.overflow = "hidden";
            }
            else{
                document.getElementsByTagName("body")[0].style.overflow = "visible";
            }
        },
        isNormFunctionPopupShow : function(val){
            if(val === true){
                document.getElementsByTagName("body")[0].style.overflow = "hidden";
            }
            else{
                document.getElementsByTagName("body")[0].style.overflow = "visible";
            }
        }
    }
}
</script>

<style lang="scss">
@import "../../../style/vars";

.energy-calc{
    .content-wrapper{
        padding: 16px 15px 40px;

        .page-title{
            background: #ffffff;
            padding: 16px 15px;
            color: #AC98C5;
            text-align: center;
            font-family: $fontB;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.11em;
            text-transform: uppercase;

            margin-bottom: 17px;
        }

        .text{
            font-size: 18px;
            line-height: 25px;
            color: #8F67A4;

            padding-bottom: 11px;

            p{
                padding-bottom: 6px;
            }

            strong{
                font-weight: 600;
            }
        }

        .functions{
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .function{
                width: 138px;
                height: 180px;
                background: #ffffff;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                
                padding: 20px 16px 16px;
                
                .function-text{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;

                    color: #4A2A80;
                }

                .choise{
                    text-align: right;
                    width: 100%;
                    text-decoration-line: underline;
                    color: #508ECC;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 19px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>