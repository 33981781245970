<template>
  <div>
    <template v-if="!$route.fullPath.includes('clinical')">
      <div class="send-question-block components_send-question-block" v-if="$route.name !== 'home'">
        <p class="h2-title"><span>Лінія підтримки</span><br>У Вас є питання? Напишіть нам!</p>
        <div class="open-question-btn" @click="openSendMessagePopUp()">
          <svg
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M14.9123 1.97461H2.80212C1.81095 1.97461 1 2.83114 1 3.87802V10.9424V15.6166L3.62337 12.8458H14.9123C15.9035 12.8458 16.7145 11.9892 16.7145 10.9424V3.87802C16.7145 2.83114 15.9035 1.97461 14.9123 1.97461Z"
                stroke="#01A8E0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
            <path
                d="M4.5708 6.22729H13.2158"
                stroke="#01A8E0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
            <path
                d="M4.5708 8.97925H10.3196"
                stroke="#01A8E0"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
          </svg>
          <p>Написати</p>
        </div>
      </div>

      <div class="section-nav">
        <router-link to="/conferences">
          <div class="section-nav-item">
            <div class="icon">
              <svg
                  width="60"
                  height="56"
                  viewBox="0 0 60 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M38.8889 34.72H42.2222L45.5556 28H14.4445L17.7778 34.72H21.1111"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M38.8889 40.32V32.48H21.1111V40.32"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M12.2222 54.88C12.2222 51.7888 9.7333 49.28 6.66664 49.28C3.59997 49.28 1.11108 51.7888 1.11108 54.88"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M20 49.2799C20 46.1887 17.5111 43.6799 14.4445 43.6799C11.3778 43.6799 8.88892 46.1887 8.88892 49.2799"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M35.5556 49.2799C35.5556 46.1887 33.0667 43.6799 30 43.6799C26.9333 43.6799 24.4445 46.1887 24.4445 49.2799"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M51.1111 49.2799C51.1111 46.1887 48.6222 43.6799 45.5556 43.6799C42.4889 43.6799 40 46.1887 40 49.2799"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M27.7779 54.88C27.7779 51.7888 25.289 49.28 22.2223 49.28C19.1556 49.28 16.6667 51.7888 16.6667 54.88"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M43.3333 54.88C43.3333 51.7888 40.8444 49.28 37.7777 49.28C34.7111 49.28 32.2222 51.7888 32.2222 54.88"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M58.8889 54.88C58.8889 51.7888 56.4001 49.28 53.3334 49.28C50.2667 49.28 47.7778 51.7888 47.7778 54.88"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M34.4444 12.3201C34.4444 15.6801 32.4555 17.9201 30 17.9201C27.5444 17.9201 25.5555 15.6801 25.5555 12.3201C25.5555 9.84489 27.5444 7.84009 30 7.84009C32.4555 7.84009 34.4444 9.84489 34.4444 12.3201Z"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M38.8889 27.9999V23.7551C38.8889 22.8815 38.3889 22.0751 37.5889 21.7167C36.1444 21.0559 33.5333 20.1599 30.0444 20.1599C26.5555 20.1599 23.9 21.0559 22.4222 21.7167C21.6222 22.0751 21.1111 22.8815 21.1111 23.7663V27.9999"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                ></path>
                <path
                    d="M42.2221 39.2001H56.6666V1.12012H3.33325V39.2001H17.7777"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <div class="text">Конференцiї</div>
          </div>
        </router-link>
        <router-link to="/library">
          <div class="section-nav-item">
            <div class="icon">
              <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip2)">
                  <path
                      d="M29.98 0.73C29.98 0.33 29.65 0 29.25 0H16.47C16.07 0 15.74 0.33 15.74 0.73V7.86H0.74C0.33 7.87 0 8.2 0 8.6V57.77C0 58.17 0.33 58.5 0.73 58.5H29.25C29.65 58.5 29.98 58.17 29.98 57.77V0.73ZM15.73 57.03H1.47V50.63H15.74V57.03H15.73ZM15.73 49.17H1.47V45.72H15.74V49.17H15.73ZM15.73 44.25H1.47V14.25H15.74V44.25H15.73ZM15.73 12.78H1.47V9.33H15.74V12.78H15.73ZM28.52 57.03H17.2V52.6H28.52V57.03ZM28.52 51.13H17.2V48.66H28.52V51.13ZM28.52 47.2H17.2V12.28H28.52V47.2ZM28.52 10.82H17.2V7.37H28.52V10.82ZM28.52 5.9H17.2V1.47H28.52V5.9Z"
                      fill="#1E3B89"/>
                  <path
                      d="M30.5099 12.72L42.3099 57.95C42.3599 58.14 42.4799 58.3 42.6499 58.4C42.8199 58.5 43.0199 58.53 43.2099 58.47L57.9599 54.54C58.3499 54.44 58.5799 54.04 58.4799 53.65L46.6799 8.42C46.6299 8.23 46.5099 8.07 46.3399 7.97C46.2299 7.9 46.0999 7.87 45.9699 7.87C45.9099 7.87 45.8399 7.88 45.7799 7.89L31.0299 11.82C30.6399 11.93 30.4099 12.33 30.5099 12.72ZM56.8699 53.31L56.6299 53.37L43.5399 56.86L42.3399 52.27L55.6699 48.71L56.8699 53.31ZM55.3099 47.31L41.9799 50.86L40.8499 46.52L41.0899 46.46L54.1799 42.97L55.3099 47.31ZM53.7999 41.54L53.5599 41.6L40.4699 45.1L34.6399 22.74L47.9699 19.19L53.7999 41.54ZM47.0099 15.51L47.5999 17.76L34.2699 21.32L33.6799 19.06L47.0099 15.51ZM45.4399 9.5L46.6399 14.09L33.3099 17.64L32.1099 13.05L45.4399 9.5Z"
                      fill="#1E3B89"/>
                  <path
                      d="M12.5299 17.7H4.66994C4.26994 17.7 3.93994 18.03 3.93994 18.43V26.3C3.93994 26.7 4.26994 27.03 4.66994 27.03H12.5399C12.9399 27.03 13.2699 26.7 13.2699 26.3V18.43C13.2699 18.03 12.9399 17.7 12.5299 17.7ZM11.7999 25.57H5.39994V19.17H11.7999V25.57Z"
                      fill="#1E3B89"/>
                </g>
                <defs>
                  <clipPath id="clip2">
                    <rect width="58.5" height="58.5" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="text">Бібліотека</div>
          </div>
        </router-link>
        <router-link to="/algorithms">
          <div class="section-nav-item">
            <div class="icon">
              <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M34.6327 34H16.3673C16.1714 34 16 33.7667 16 33.5C16 33.2333 16.1714 33 16.3673 33H34.6327C34.8286 33 35 33.2333 35 33.5C35 33.7667 34.8286 34 34.6327 34Z"
                    fill="#1E3B89"/>
                <path
                    d="M34.5 36C34.2333 36 34 35.7558 34 35.4767V33.5233C34 33.2442 34.2333 33 34.5 33C34.7667 33 35 33.2442 35 33.5233V35.4767C35 35.7907 34.7667 36 34.5 36Z"
                    fill="#1E3B89"/>
                <path
                    d="M16.5 36C16.2333 36 16 35.7558 16 35.4767V33.5233C16 33.2442 16.2333 33 16.5 33C16.7667 33 17 33.2442 17 33.5233V35.4767C17 35.7907 16.7667 36 16.5 36Z"
                    fill="#1E3B89"/>
                <path
                    d="M25.5 34C25.2333 34 25 33.7558 25 33.4767V31.5233C25 31.2442 25.2333 31 25.5 31C25.7667 31 26 31.2442 26 31.5233V33.4767C26 33.7907 25.7667 34 25.5 34Z"
                    fill="#1E3B89"/>
                <path
                    d="M37.8475 43.4847H32.7715C31.7835 43.4847 31 42.7011 31 41.7132V38.5449C31 37.557 31.7835 36.7734 32.7715 36.7734H37.8475C38.8354 36.7734 39.619 37.557 39.619 38.5449V41.7132C39.653 42.7011 38.8354 43.4847 37.8475 43.4847ZM32.7715 37.7614C32.3627 37.7614 32.022 38.1021 32.022 38.5109V41.6791C32.022 42.0879 32.3627 42.4286 32.7715 42.4286H37.8475C38.2563 42.4286 38.597 42.0879 38.597 41.6791V38.5109C38.597 38.1021 38.2563 37.7614 37.8475 37.7614H32.7715Z"
                    fill="#1E3B89"/>
                <path
                    d="M18.8475 43.4847H13.7715C12.7835 43.4847 12 42.7011 12 41.7132V38.5449C12 37.557 12.7835 36.7734 13.7715 36.7734H18.8475C19.8354 36.7734 20.619 37.557 20.619 38.5449V41.7132C20.619 42.7011 19.8354 43.4847 18.8475 43.4847ZM13.7715 37.7614C13.3627 37.7614 13.022 38.1021 13.022 38.5109V41.6791C13.022 42.0879 13.3627 42.4286 13.7715 42.4286H18.8475C19.2563 42.4286 19.597 42.0879 19.597 41.6791V38.5109C19.597 38.1021 19.2563 37.7614 18.8475 37.7614H13.7715Z"
                    fill="#1E3B89"/>
                <path
                    d="M28.5545 30.1652H23.4445C22.4565 30.1652 21.673 29.3817 21.673 28.3937V25.1914C21.673 24.2035 22.4565 23.4199 23.4445 23.4199H28.5205C29.5084 23.4199 30.292 24.2035 30.292 25.1914V28.3597C30.326 29.3476 29.5084 30.1652 28.5545 30.1652ZM23.4445 24.4419C23.0357 24.4419 22.695 24.7826 22.695 25.1914V28.3597C22.695 28.7685 23.0357 29.1091 23.4445 29.1091H28.5205C28.9293 29.1091 29.2699 28.7685 29.2699 28.3597V25.1914C29.2699 24.7826 28.9293 24.4419 28.5205 24.4419H23.4445Z"
                    fill="#1E3B89"/>
                <path
                    d="M31.7227 21.8879H20.2761C20.0036 21.8879 19.7651 21.6494 19.7651 21.3769V15.6536C19.7651 15.381 20.0036 15.1426 20.2761 15.1426H31.7227C31.9952 15.1426 32.2337 15.381 32.2337 15.6536V21.3769C32.2337 21.6494 31.9952 21.8879 31.7227 21.8879ZM20.7871 20.8659H31.2117V16.1646H20.7871V20.8659Z"
                    fill="#1E3B89"/>
                <path
                    d="M31.7227 13.6437H20.2761C20.0036 13.6437 19.7651 13.4053 19.7651 13.1327V7.40944C19.7651 7.13691 20.0036 6.89844 20.2761 6.89844H31.7227C31.9952 6.89844 32.2337 7.13691 32.2337 7.40944V13.1327C32.2337 13.4053 31.9952 13.6437 31.7227 13.6437ZM20.7871 12.6217H31.2117V7.92045H20.7871V12.6217Z"
                    fill="#1E3B89"/>
                <path
                    d="M17.7553 19.3667H11.3848C10.3968 19.3667 9.61328 18.5832 9.61328 17.5952V11.8379C9.61328 10.8499 10.3968 10.0664 11.3848 10.0664H17.7213C17.9938 10.0664 18.2323 10.3049 18.2323 10.5774C18.2323 10.8499 17.9938 11.0884 17.7213 11.0884H11.3848C10.976 11.0884 10.6353 11.4291 10.6353 11.8379V17.5612C10.6353 17.97 10.976 18.3106 11.3848 18.3106H17.7213C17.9938 18.3106 18.2323 18.5491 18.2323 18.8217C18.2323 19.0942 18.0279 19.3667 17.7553 19.3667Z"
                    fill="#1E3B89"/>
                <path
                    d="M17.7553 19.368C17.6191 19.368 17.4828 19.3339 17.3806 19.2317L15.2344 17.0855C15.03 16.8811 15.03 16.5745 15.2344 16.3701C15.4388 16.1657 15.7454 16.1657 15.9498 16.3701L18.096 18.5163C18.3004 18.7207 18.3004 19.0273 18.096 19.2317C17.9938 19.2999 17.8575 19.368 17.7553 19.368Z"
                    fill="#1E3B89"/>
                <path
                    d="M15.4046 21.7169C15.2684 21.7169 15.1321 21.6829 15.0299 21.5807C14.8255 21.3763 14.8255 21.0697 15.0299 20.8653L17.3805 18.5146C17.5849 18.3102 17.8915 18.3102 18.0959 18.5146C18.3003 18.719 18.3003 19.0256 18.0959 19.23L15.7453 21.5807C15.6431 21.6488 15.5068 21.7169 15.4046 21.7169Z"
                    fill="#1E3B89"/>
                <path
                    d="M40.6146 26.9638H32.3363C32.0638 26.9638 31.8253 26.7253 31.8253 26.4528C31.8253 26.1803 32.0638 25.9418 32.3363 25.9418H40.5806C41.0234 25.9418 41.33 25.6352 41.33 25.2605V20.2867C41.33 19.8438 40.9553 19.4009 40.5806 19.4009H34.2441C33.9715 19.4009 33.7331 19.1624 33.7331 18.8899C33.7331 18.6174 33.9715 18.3789 34.2441 18.3789H40.5806C41.5344 18.3789 42.3521 19.2647 42.3521 20.2867V25.2264C42.3861 26.2143 41.6026 26.9638 40.6146 26.9638Z"
                    fill="#1E3B89"/>
                <path
                    d="M32.3358 27.0321C32.1995 27.0321 32.0632 26.998 31.961 26.8958C31.7566 26.6914 31.7566 26.3848 31.961 26.1804L34.1073 24.0342C34.3117 23.8298 34.6183 23.8298 34.8227 24.0342C35.0271 24.2386 35.0271 24.5452 34.8227 24.7496L32.7105 26.8958C32.6083 26.998 32.472 27.0321 32.3358 27.0321Z"
                    fill="#1E3B89"/>
                <path
                    d="M34.6863 29.3148C34.55 29.3148 34.4137 29.2808 34.3456 29.1785L31.995 26.8961C31.7906 26.6917 31.7906 26.385 31.995 26.1806C32.1994 25.9762 32.506 25.9762 32.7104 26.1806L35.061 28.4631C35.2654 28.6675 35.2654 28.9741 35.061 29.1785C34.9588 29.2808 34.8225 29.3148 34.6863 29.3148Z"
                    fill="#1E3B89"/>
                <path
                    d="M57.05 48.5H50.4V1.95C50.4 1.4256 49.9744 1 49.45 1H1.95C1.4256 1 1 1.4256 1 1.95V53.25C1 55.8691 3.13085 58 5.75 58H53.25C55.8691 58 58 55.8691 58 53.25V49.45C58 48.9256 57.5744 48.5 57.05 48.5ZM8.6 49.45V53.25C8.6 54.8213 7.3213 56.1 5.75 56.1C4.1787 56.1 2.9 54.8213 2.9 53.25V2.9H48.5V48.5H9.55C9.0256 48.5 8.6 48.9256 8.6 49.45ZM56.1 53.25C56.1 54.8213 54.8213 56.1 53.25 56.1H9.5481C10.1447 55.3058 10.5 54.3187 10.5 53.25V50.4H56.1V53.25Z"
                    fill="#1E3B89" stroke="#F8FAFC" stroke-width="0.3"/>
              </svg>

            </div>
            <div class="text">
              Алгоритми
            </div>
          </div>
        </router-link>
        <router-link to="/calculator">
          <div class="section-nav-item">
            <div class="icon">
              <svg
                  width="57"
                  height="54"
                  viewBox="0 0 57 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M23.0382 52.5342H4.38655C2.92468 52.5342 1.73462 51.3395 1.73462 49.8883V20.8779C1.73462 19.4267 2.92468 18.2319 4.38655 18.2319H23.0382C24.4927 18.2319 25.6901 19.4193 25.6901 20.8779V49.8883C25.6828 51.3395 24.4927 52.5342 23.0382 52.5342Z"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M5.81165 18.2392V16.2236C5.81165 14.7723 7.00171 13.5923 8.44888 13.5923H18.9685C20.423 13.5923 21.6057 14.7797 21.6057 16.2236V18.2392"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M16.537 13.5923C18.1898 13.5923 18.8951 11.7892 18.8951 10.4919C18.8951 9.19453 16.5884 7.51606 16.5884 7.51606C16.2872 1.64509 14.1862 2.18748 14.1862 2.18748H13.2459C13.2459 2.18748 11.145 1.63776 10.8438 7.51606C10.8438 7.51606 8.53711 9.1872 8.53711 10.4919C8.53711 11.7892 9.23499 13.5923 10.8952 13.5923"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M25.6828 23.6338H15.2"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M25.6828 29.0576H15.2"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M25.6828 34.4888H15.2"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M25.6828 39.9199H15.2"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M25.6828 45.3438H15.2"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M31.8755 25.488L35.6293 49.3897C35.8864 51.1928 37.4365 52.5341 39.2656 52.5341H47.3757C49.2122 52.5341 50.7696 51.1781 51.012 49.3604L54.5601 25.488H31.8755V25.488Z"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M36.5182 37.9116H49.7411"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M29.3044 12.5366L30.4064 19.2578H41.4108L42.8212 12.5366H29.3044Z"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M42.8212 12.5366H55.2654"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M34.8948 1V5.19983H38.0169"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M38.7441 1V8.5641"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M42.8212 2.57593L46.1563 5.91087"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M46.1563 2.57593L42.8212 5.91087"
                    stroke="#1E3B89"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <div class="text">
              Калькулятор

            </div>
          </div>
        </router-link>
        <!-- <router-link to="/greetings">
            <div class="section-nav-item">
                <div class="icon">
                    <svg
                            width="60"
                            height="61"
                            viewBox="0 0 60 61"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                                d="M41.599 7.24007L35.6129 3.03053C32.2441 0.662349 27.7558 0.662349 24.3922 3.03053L18.401 7.24007"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ></path>
                        <path
                                d="M13.7134 10.5392L1.25562 19.2958V59.7442H58.7444V19.2958L46.2815 10.3806"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ></path>
                        <path
                                d="M1.25562 59.744L24.6683 41.131C27.7917 38.6503 32.2135 38.6503 35.3318 41.131L58.7444 59.744"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ></path>
                        <path
                                d="M1.25562 19.2959L25.1437 40.6198"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ></path>
                        <path
                                d="M58.7445 19.2959L34.8411 40.6198"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ></path>
                        <path
                                d="M13.7134 30.4717V7.23999H46.2764V30.395"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ></path>
                        <path
                                d="M23.2676 18.8714C23.789 17.7512 24.7501 16.9635 25.7673 16.8203C26.0331 16.7845 26.2836 16.764 26.5188 16.764C28.2722 16.764 29.2434 17.6694 29.2843 17.7103C29.6728 18.099 30.3067 18.0939 30.7003 17.7154C30.7515 17.6643 31.9988 16.5083 34.2225 16.8152C35.2397 16.9584 36.1957 17.741 36.7171 18.8611C37.3459 20.2063 37.2129 21.7613 36.3592 23.1218C34.6723 25.8225 31.1962 27.807 29.9847 28.4413C28.7731 27.807 25.3021 25.8225 23.6152 23.1218C22.7717 21.7715 22.6388 20.2166 23.2676 18.8714Z"
                                stroke="#1E3B89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                        ></path>
                    </svg>
                </div>
                <div class="text">
                    Конструктор
                    <br>привітань
                </div>
            </div>
        </router-link> -->
      </div>

      <div class="download-info">
        <div class="left">
          <svg width="97" height="124" viewBox="0 0 97 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
              <path
                  d="M72.5018 100.057H40.9833C37.409 100.057 34.4846 97.1324 34.4846 93.5582V18.4987C34.4846 14.9244 37.409 12 40.9833 12H72.5018C76.076 12 79.0004 14.9244 79.0004 18.4987V93.5582C79.0004 97.1324 76.076 100.057 72.5018 100.057Z"
                  fill="white"/>
              <path d="M77.2137 89.3341L77.2137 18.1738L36.2722 18.1738L36.2722 89.3341L77.2137 89.3341Z"
                    fill="#C5D6E8"/>
              <g opacity="0.6">
                <path opacity="0.4"
                      d="M47.442 36.9575H41.2195C40.1472 36.9575 39.2699 36.0802 39.2699 35.0079V28.7854C39.2699 27.7131 40.1472 26.8358 41.2195 26.8358H47.442C48.5142 26.8358 49.3916 27.7131 49.3916 28.7854V35.0079C49.3916 36.0802 48.5142 36.9575 47.442 36.9575Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M47.442 50.3574H41.2195C40.1472 50.3574 39.2699 49.4801 39.2699 48.4078V42.1853C39.2699 41.113 40.1472 40.2357 41.2195 40.2357H47.442C48.5142 40.2357 49.3916 41.113 49.3916 42.1853V48.4078C49.3916 49.4801 48.5142 50.3574 47.442 50.3574Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M47.442 64.7144H41.2195C40.1472 64.7144 39.2699 63.8371 39.2699 62.7648V56.5424C39.2699 55.4701 40.1472 54.5928 41.2195 54.5928H47.442C48.5142 54.5928 49.3916 55.4701 49.3916 56.5424V62.7648C49.3916 63.8371 48.5142 64.7144 47.442 64.7144Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M47.442 79.0715H41.2195C40.1472 79.0715 39.2699 78.1942 39.2699 77.1219V70.8994C39.2699 69.8271 40.1472 68.9498 41.2195 68.9498H47.442C48.5142 68.9498 49.3916 69.8271 49.3916 70.8994V77.1219C49.3916 78.1942 48.5142 79.0715 47.442 79.0715Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M60.3397 36.957H54.1172C53.0449 36.957 52.1676 36.0797 52.1676 35.0075V28.785C52.1676 27.7127 53.0449 26.8354 54.1172 26.8354H60.3397C61.4119 26.8354 62.2893 27.7127 62.2893 28.785V35.0075C62.2893 36.0797 61.4119 36.957 60.3397 36.957Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M60.3397 50.3574H54.1172C53.0449 50.3574 52.1676 49.4801 52.1676 48.4078V42.1853C52.1676 41.113 53.0449 40.2357 54.1172 40.2357H60.3397C61.4119 40.2357 62.2893 41.113 62.2893 42.1853V48.4078C62.2893 49.4801 61.4119 50.3574 60.3397 50.3574Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M60.3397 64.7144H54.1172C53.0449 64.7144 52.1676 63.8371 52.1676 62.7648V56.5424C52.1676 55.4701 53.0449 54.5928 54.1172 54.5928H60.3397C61.4119 54.5928 62.2893 55.4701 62.2893 56.5424V62.7648C62.2893 63.8371 61.4119 64.7144 60.3397 64.7144Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M60.3397 79.0715H54.1172C53.0449 79.0715 52.1676 78.1942 52.1676 77.1219V70.8994C52.1676 69.8271 53.0449 68.9498 54.1172 68.9498H60.3397C61.4119 68.9498 62.2893 69.8271 62.2893 70.8994V77.1219C62.2893 78.1942 61.4119 79.0715 60.3397 79.0715Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M73.0754 36.957H66.8529C65.7806 36.957 64.9033 36.0797 64.9033 35.0075V28.785C64.9033 27.7127 65.7806 26.8354 66.8529 26.8354H73.0754C74.1477 26.8354 75.025 27.7127 75.025 28.785V35.0075C75.025 36.0797 74.1477 36.957 73.0754 36.957Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M73.0754 50.3574H66.8529C65.7806 50.3574 64.9033 49.4801 64.9033 48.4078V42.1853C64.9033 41.113 65.7806 40.2357 66.8529 40.2357H73.0754C74.1477 40.2357 75.025 41.113 75.025 42.1853V48.4078C75.025 49.4801 74.1477 50.3574 73.0754 50.3574Z"
                      fill="#1E3B89"/>
                <path opacity="0.4"
                      d="M73.0754 64.7144H66.8529C65.7806 64.7144 64.9033 63.8371 64.9033 62.7648V56.5424C64.9033 55.4701 65.7806 54.5928 66.8529 54.5928H73.0754C74.1477 54.5928 75.025 55.4701 75.025 56.5424V62.7648C75.025 63.8371 74.1477 64.7144 73.0754 64.7144Z"
                      fill="#1E3B89"/>
              </g>
              <path
                  d="M57.0687 97.6201C58.7735 97.6201 60.1555 96.2381 60.1555 94.5332C60.1555 92.8284 58.7735 91.4464 57.0687 91.4464C55.3638 91.4464 53.9818 92.8284 53.9818 94.5332C53.9818 96.2381 55.3638 97.6201 57.0687 97.6201Z"
                  fill="#DADFE0"/>
              <g filter="url(#filter1_d)">
                <path
                    d="M43.5015 57.5856H24.0837C20.7375 57.5856 17.9998 54.8429 17.9998 51.4906V32.0951C17.9998 28.7428 20.7375 26 24.0837 26H43.5015C46.8476 26 49.5854 28.7428 49.5854 32.0951V51.4906C49.5854 54.8429 46.8476 57.5856 43.5015 57.5856Z"
                    fill="#1E3B89"/>
              </g>
              <g clip-path="url(#clip10)">
                <path d="M32.7345 43.4402V40.371H33.908V39.6768H30.7543V40.371H31.9278V43.4402H32.7345Z" fill="white"/>
                <path
                    d="M24.2636 40.9922C24.3003 41.0652 24.4103 41.321 24.5937 41.5768L25.9505 43.4402H26.7206V39.6768H25.9505V42.1979C25.9138 42.1248 25.8038 41.9056 25.6205 41.6133L24.3003 39.6768H23.4935V43.4402H24.2636V40.9922Z"
                    fill="white"/>
                <path d="M43.0761 39.6768H42.2327V43.4402H43.0761V39.6768Z" fill="white"/>
                <path
                    d="M44.7262 39.6768C44.0295 40.7729 43.6628 42.1614 43.4427 43.4402H44.2862C44.3229 43.1479 44.3962 42.8191 44.4695 42.5268H45.8997C45.9731 42.8191 46.0097 43.1479 46.0831 43.4402H46.9999C46.7798 42.1614 46.4131 40.846 45.7164 39.6768H44.7262ZM44.5796 41.9056C44.7262 41.321 44.9096 40.8095 45.1663 40.298C45.423 40.8095 45.6064 41.321 45.753 41.9056H44.5796Z"
                    fill="white"/>
                <path
                    d="M40.8021 40.2978C41.0588 40.2978 41.3155 40.3709 41.5721 40.517L41.8288 39.8228C41.5721 39.7132 41.2054 39.6036 40.7654 39.6036C39.5186 39.6036 38.8951 40.5901 38.8951 41.6131C38.8951 42.7093 39.6286 43.5496 40.7654 43.5496C41.1321 43.5496 41.5722 43.44 41.8655 43.2939L41.6455 42.6362C41.4621 42.7458 41.2054 42.8554 40.8754 42.8554C40.2887 42.8554 39.7753 42.3804 39.7753 41.6131C39.7386 40.992 39.9953 40.2978 40.8021 40.2978Z"
                    fill="white"/>
                <path
                    d="M27.1974 39.6768V41.9422C27.1974 43.1479 27.9675 43.5133 29.141 43.5133C29.581 43.5133 30.0211 43.4767 30.4611 43.4037V39.6768H29.6544V42.8191C29.5077 42.8556 29.361 42.8921 29.1776 42.8921C28.5909 42.8921 28.0408 42.6729 28.0408 42.0152V39.6768H27.1974Z"
                    fill="white"/>
                <path d="M38.455 39.6768H37.6116V43.4402H38.455V39.6768Z" fill="white"/>
                <path
                    d="M37.062 40.7364C37.062 39.7864 36.1819 39.6768 35.4484 39.6768H34.2383V43.4402H35.0817V41.9422H35.3384C35.4118 41.9422 35.5218 41.9422 35.5951 42.1248L36.2919 43.4402H37.282L36.2552 41.7229C36.7319 41.6133 37.062 41.2479 37.062 40.7364ZM35.5218 41.321H35.0817V40.3345H35.4851C35.8152 40.3345 36.2185 40.371 36.2185 40.8095C36.2185 41.2114 35.8518 41.321 35.5218 41.321Z"
                    fill="white"/>
                <path
                    d="M22.54 43.4402C22.0999 42.6729 21.8799 41.796 21.8799 40.8826C21.8799 37.923 24.3735 35.5115 27.4172 35.5115C29.6909 35.5115 31.6711 36.8634 32.5146 38.7634C31.6345 36.5711 29.5075 35 26.9772 35C23.6768 35 20.9998 37.7038 20.9998 41.0653C20.9998 41.9056 21.1831 42.7094 21.4765 43.4402H22.54Z"
                    fill="white"/>
              </g>
            </g>
            <defs>
              <filter id="filter0_d" x="0.771227" y="0.514314" width="95.4577" height="122.514"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="5.74284"/>
                <feGaussianBlur stdDeviation="8.61426"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.122709 0 0 0 0 0.208115 0 0 0 0 0.510917 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
              <filter id="filter1_d" x="1.2618" y="14.8414" width="65.0615" height="65.0615"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <!-- <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/> -->
                <feOffset dy="5.57931"/>
                <feGaussianBlur stdDeviation="8.36898"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.122709 0 0 0 0 0.208115 0 0 0 0 0.510917 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
              <clipPath id="clip10">
                <rect width="26" height="11.0442" fill="white" transform="translate(20.9998 35)"/>
              </clipPath>
            </defs>
          </svg>
        </div>

        <div class="right">
          <p class="reg-text">
            Завантажте портал як <br> додаток на Ваш телефон
          </p>
          <router-link to="/instruction">

            <div class="btn">
              <p>
                Інструкція
              </p>
            </div>
          </router-link>

        </div>
      </div>

      <div class="footer">
        <p class="footer-h1">Безумовна перевага у годуванні дитини перших років життя надається грудному
          вигодовуванню.</p>
        <p class="reg-text">
          Згідно з рекомендаціями МОЗ України грудне молоко має бути основним харчуванням малюка на першому році життя
          навіть після введення прикорму.
        </p>
        <p class="reg-text">ВООЗ рекомендує зберігати грудне вигодовування до 2 років.</p>
        <p class="reg-text">Nutricia підтримує зазначені рекомендації та попереджає, що штучне вигодовування може
          негативно вплинути на грудне.</p>
      </div>
    </template>
  </div>
</template>

<script>
import router from "@/router";
import {mapActions} from "vuex";

export default {
  name: "block-footer",

  methods: {
    ...mapActions([
      'setMenuData'
    ]),
    router() {
      return router
    },
    openSendMessagePopUp() {
      this.setMenuData({popupState: '2'})
    },
  }
};
</script>

<style lang="scss">
@import "../style/vars";

.send-question-block {
  background: url("../assets/img/question-bg.jpg") no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 304px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


  .h2-title {
    font-family: $fontSB;
    font-size: 16px;
    text-align: center;
    color: white;

    span {
      font-family: $fontB;
      font-size: 28px;
    }

  }

  .open-question-btn {
    cursor: pointer;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    width: 148px;
    height: 41px;
    transition: all 0.2s ease;

    svg {
      path {
        transition: all 0.2s ease;
      }
    }

    p {
      transition: all 0.2s ease;
      margin-left: 15px;
      text-transform: uppercase;
      font-size: 15px;
      font-family: $fontB;
      color: #00ace6;
    }

    &:active {
      background-color: #00ace6;

      p {
        color: white;
      }

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}

.section-nav {
  display: flex;
  flex-flow: row wrap;

  border-bottom: 1px solid #d7e2ec;

  a {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(1) {
      border-top: 1px solid #d7e2ec;
      border-right: 1px solid #d7e2ec;
      border-bottom: 1px solid #d7e2ec;
    }

    &:nth-child(2) {
      border-top: 1px solid #d7e2ec;
      border-bottom: 1px solid #d7e2ec;
    }

    &:nth-child(3) {
      border-right: 1px solid #d7e2ec;
    }

    &:active {
      svg {
        path {
          stroke-width: 2;
        }
      }
    }

    svg {
      path {
        transition: all 0.2s ease;
      }
    }
  }

  .section-nav-item {
    height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-top: 40px;
      margin-bottom: 25px;
      height: 64px;
    }

    .text {
      text-align: center;
      color: #1e3b89;
      font-size: 14px;
      font-family: $fontB;
      text-transform: uppercase;
    }
  }
}

.footer {
  background-color: #e0e9f1;
  width: 100%;
  padding: 40px 30px;

  p {
    font-size: 11px;
    margin-bottom: 25px;
    color: #1e3b89;
    font-family: $fontR;

    &.footer-h1 {
      font-family: $fontSB;
    }
  }
}

.download-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 30px 0;

  .right {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .reg-text {
      font-size: 14px;
      font-family: $fontR;
      color: #1E3B89;
      margin-bottom: 15px;
    }

    .btn {
      width: 185px;
      height: 40px;
      border-radius: 4px;
      background-color: #00ACE6;
      display: flex;
      align-items: center;
      justify-content: center;

      p {

        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $fontB;
        color: white;
        font-size: 15px;

        span {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
