<template>
  <div class="main">

    <main-menu @click="openPopUp('1')"/>

  </div>
</template>

<script>
import MainMenu from '../../components/Clinical/Main/main-menu';

    export default {
        name: 'home',
        components : {
          'main-menu' : MainMenu
        },
        data: () => ({
            popupCtrl: "",
            PersonalDataFromPopUp: "2",
            registrationStage: "1",
        }),

        created() {
            if (sessionStorage.getItem("key") === null) {
                this.popupCtrl = "2";
            }
        },

        mounted() {
            this.$nextTick(() => {
                if (this.popupCtrl !== "") {
                    document.getElementsByTagName("body")[0].style.overflow = "hidden";
                } else {
                    document.getElementsByTagName("body")[0].style.overflow = "visible";
                }
            })
        },

        methods: {
            openPopUp(id) {
                if (id !== this.popupCtrl && id !== this.fromPopUp) {
                    document.getElementsByTagName("body")[0].style.overflow = "hidden";
                    this.popupCtrl = id;
                } else {
                    this.popupCtrl = '';
                    document.getElementsByTagName("body")[0].style.overflow = "visible";
                }
            },

            openPopupInMenu(value) {
                if (value !== this.popupCtrl) {
                    document.getElementsByTagName("body")[0].style.overflow = "hidden";
                    this.fromPopUp = value;
                    this.popupCtrl = value;
                } else {
                    document.getElementsByTagName("body")[0].style.overflow = "visible";
                    this.registrationStage = '';
                    this.fromPopUp = '';
                    this.popupCtrl = '';
                }
            },

            closeLoginPopUp() {
                this.popupCtrl = '';
                this.fromPopUp = '';
                document.getElementsByTagName("body")[0].style.overflow = "visible";
            },

            openRegistrationFromLogin() {
                this.popupCtrl = "5";
            },

            openPersonalDataFromRegistration() {
                this.PersonalDataFromPopUp = '5';
                this.popupCtrl = '3';
            },

            backFromPersonalData() {
                if (this.PersonalDataFromPopUp === '5') {
                    this.registrationStage = '2';
                    this.popupCtrl = this.PersonalDataFromPopUp;
                } else if (this.PersonalDataFromPopUp === '4') {
                    this.popupCtrl = this.PersonalDataFromPopUp;
                }
            },

            registrationSendData() {
                this.popupCtrl = '2';
                this.PersonalDataFromPopUp = '';

            },

            closeMailQuestion() {
                this.popupCtrl = '';
                document.getElementsByTagName("body")[0].style.overflow = "visible";


            },

            openPersonalDataFromUserInfo() {
                this.PersonalDataFromPopUp = '4';
                this.popupCtrl = '3';
            },

            closeUserInfo() {
                this.popupCtrl = '';
                this.PersonalDataFromPopUp = "";
                document.getElementsByTagName("body")[0].style.overflow = "visible";

            }

        }
    }
</script>

<style lang="scss">

  // @import "../../style/vars";

  // .home {
  //   position: relative;
  //   margin-top: 60px;

  //   .components_send-question-block {
  //     display: none !important;
  //   }

  //   .head-bg {
  //     background: url("../../assets/images/head-bg.jpg") no-repeat top center;
  //     background-size: cover;
  //     width: 100%;
  //     height: 367px;

  //     .h1-title {
  //       padding-top: 25px;
  //       text-align: center;
  //       color: #313183;
  //       font-family: $fontR;
  //       font-size: 24px;
  //     }
  //   }

  //   .send-question-block {
  //     background: url("../../assets/images/question-bg.jpg") no-repeat top center;
  //     background-size: cover;
  //     width: 100%;
  //     height: 229px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;

  //     .h2-title {
  //       font-family: $fontSB;
  //       font-size: 16px;
  //       text-align: center;
  //       color: white;

  //       span {
  //         font-family: $fontB;
  //         font-size: 28px;
  //       }

  //     }

  //     .open-question-btn {
  //       margin-top: 25px;
  //       display: flex;
  //       flex-direction: row;
  //       align-items: center;
  //       justify-content: center;
  //       background: white;
  //       border-radius: 4px;
  //       width: 148px;
  //       height: 41px;
  //       transition: all 0.2s ease;

  //       svg {
  //         path {
  //           transition: all 0.2s ease;

  //         }
  //       }

  //       p {
  //         transition: all 0.2s ease;
  //         margin-left: 15px;
  //         text-transform: uppercase;
  //         font-size: 15px;
  //         font-family: $fontB;
  //         color: #8F67A4;
  //       }

  //       &:active {
  //         background-color: #8F67A4;

  //         p {
  //           color: white;
  //         }

  //         svg {
  //           path {
  //             stroke: white;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .h3-title {
  //     text-align: center;
  //     text-transform: uppercase;
  //     letter-spacing: 2px;
  //     padding: 25px 0;
  //     color: #9AB9D8;
  //     font-family: $fontB;
  //     font-size: 15px;
  //   }

  //   .content-wrapper {
  //     padding: 0 15px;

  //     .section {
  //       max-width: 395px;
  //       width: 100%;
  //       margin: 0 auto 15px auto;

  //       background-color: white;
  //       border-radius: 4px;


  //       img {
  //         width: 100%;
  //         height: auto;
  //       }

  //       .more-info {
  //         padding: 0 15px;
  //         margin-top: 20px;

  //         .h1-title {
  //           font-family: $fontR;
  //           font-size: 20px;
  //           margin-bottom: 15px;
  //           color: #1E3B89;
  //         }

  //         .reg-text {
  //           font-family: $fontL;
  //           font-size: 14px;
  //           color: #1E3B89;
  //           margin-bottom: 30px;
  //         }
  //       }

  //       .relocate {
  //         border-top: 1px solid #DAE3ED;
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: center;
  //         align-items: center;
  //         padding: 15px 0;

  //         a {
  //           color: #00ACE6;
  //           letter-spacing: 2px;
  //           font-family: $fontB;
  //           margin-right: 7px;
  //           font-size: 15px;
  //         }
  //       }
  //     }
  //   }

  // }
</style>
