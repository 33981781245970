<template>
  <div class="legal">

    <div class="content">
      <div class="header-image">
        <div class="text top">
          <div class="main-text">
            Юридична клініка
          </div>
          <div class="under-text">
            відповіді на запитання
          </div>
        </div>
        <div class="text bottom">
          23.10.2020
        </div>
      </div>

      <div class="all-questions-navigation-block">
        <div class="visible-item" :class="!isOpen ? '': 'active'" @click="isOpen = !isOpen">
          <div class="icon-text">
            <div class="icon">
              <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.569 15.664C26.569 22.6083 21.1504 28.21 14.5 28.21C7.84957 28.21 2.43103 22.6083 2.43103 15.664C2.43103 8.71966 7.84957 3.11799 14.5 3.11799C21.1504 3.11799 26.569 8.71966 26.569 15.664Z"
                    stroke="#1E3B89" stroke-width="0.862069"/>
                <path
                    d="M13.1743 18.3586V17.8899C13.1743 17.3528 13.272 16.906 13.4673 16.5496C13.6626 16.1931 14.0044 15.8245 14.4927 15.4436C15.0737 14.9846 15.4473 14.6282 15.6133 14.3743C15.7842 14.1204 15.8696 13.8176 15.8696 13.4661C15.8696 13.0559 15.7329 12.741 15.4595 12.5212C15.186 12.3015 14.793 12.1917 14.2803 12.1917C13.8164 12.1917 13.3867 12.2576 12.9912 12.3894C12.5957 12.5212 12.21 12.6799 11.834 12.8655L11.2188 11.5764C12.21 11.0247 13.272 10.7488 14.4048 10.7488C15.3618 10.7488 16.1211 10.9832 16.6826 11.4519C17.2441 11.9207 17.5249 12.5676 17.5249 13.3928C17.5249 13.759 17.4712 14.0862 17.3638 14.3743C17.2563 14.6575 17.0928 14.9285 16.873 15.1873C16.6582 15.446 16.2847 15.783 15.7524 16.198C15.2983 16.5544 14.9932 16.8499 14.8369 17.0842C14.6855 17.3186 14.6099 17.6335 14.6099 18.0291V18.3586H13.1743ZM12.874 20.6951C12.874 19.9578 13.2329 19.5891 13.9507 19.5891C14.3022 19.5891 14.5708 19.6868 14.7563 19.8821C14.9419 20.0725 15.0347 20.3435 15.0347 20.6951C15.0347 21.0417 14.9395 21.3176 14.749 21.5227C14.5635 21.7229 14.2974 21.823 13.9507 21.823C13.604 21.823 13.3379 21.7253 13.1523 21.53C12.9668 21.3298 12.874 21.0515 12.874 20.6951Z"
                    fill="#1E3B89"/>
              </svg>
            </div>
            <p>Всі питання</p>
          </div>
          <div class="arrow" :class="!isOpen ? '': 'rotated'">
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.022 1L5.50928 5.53548" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.50924 5.53548L1 1" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <transition name="fade">
          <div class="container" v-if="isOpen">
            <div :data-question="q.number" class="hidden-item" v-for="(q, index) in questions"
                 :class="activeQuestionNumber == q.number ? 'active' : ''" @click="goToQuestion(q.number)" :key="index">
              <div class="number">
                {{ q.number }}.
              </div>
              <div class="text">
                {{ q.question }}
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="question-answers-block">
        <div :data-answer="q.number" class="item" v-for="(q, index) in questions" :key="index">
          <div class="number-block">
            <div class="number">
              {{ q.number }}
            </div>
          </div>
          <div class="question">
            <div class="text">
              {{ q.question }}
            </div>
            <div class="answer" v-html="q.answer"></div>
          </div>
          <div class="thanks">
            За надання юридичної консультації висловлюємо особливу подяку <a href="http://babich-law.com/ua/"
                                                                             target="_blank">Адвокатському бюро Олени
            Бабич</a> та медичному адвокату, Голові Ради Київської міської профспілки працівників охорони здоров'я
            <a href="https://www.facebook.com/sergiy.kubanskyy" target="_blank">Сергію Кубанському</a>
          </div>
        </div>
      </div>

      <div class="partners-block">
        <div class="card-image">
          <img src="../assets/img/partners-img.png" alt="partners image">
        </div>
        <div class="card-content">
          <div class="title">
            Наш партнер
          </div>
          <div class="text">
            Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться.
          </div>
        </div>
        <div class="card-footer">
          <a href="" target="_blank">ПЕРЕЙТИ ></a>
        </div>
      </div>

      <div class="footer">
        <p class="footer-h1">Безумовна перевага у годуванні дитини належить грудному вигодовуванню.</p>
        <p class="reg-text">
          Згідно рекомендацій МОЗ України, воно є основним харчуванням для малюка на першому році життя
          навіть після введення прикорму.
        </p>
        <p class="reg-text">ВООЗ рекомендує зберігати грудне вигодовування до 2 років.</p>
        <p class="reg-text">Компанія Nutricia Україна повністю підтримує такі рекомендації.</p>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "instruction",
  data: () => ({


    isOpen: false,
    activeQuestionNumber: 1,

    questions: [
      {
        number: '1',
        question: 'Чи правомірно вимагати від лікаря бути на зв`язку з пацієнтами 24 години на добу 7 днів на тиждень?',
        answer: `
                        <p>Ні, така вимога є неправомірною, адже порушує  норми законодавство і трудові права працівника.</p>
                        <p>Загалом в Україні законодавчо встановлено, що нормальна тривалість робочого часу працівників не може перевищувати 40 годин на тиждень.</p>
                        <p>При цьому, для окремих категорій працівників, зокрема, для медичних, встановлюється скорочена тривалість робочого часу.</p>
                        <p>Нормами наказу Міністерства охорони здоров’я України від 25.05.2006 року № 319 «Про затвердження норм робочого часу рокудля працівників закладів та установ охорони здоров'я» (зареєстровано в Міністерстві юстиції України 9 червня 2006 року за № 696/12570) для працівників  амбулаторно-поліклінічних  закладів  охорони здоров’я з числа керівників  структурних підрозділів  (відділень,  відділів, лабораторій, кабінетів тощо) з числа  лікарів  та  фахівців  з базовою та неповною вищою освітою, керівників закладів охорони здоров'я з числа фахівців з базовою та неповною  вищою освітою, лікарів та фахівців з базовою та неповною вищою  медичною освітою (середнього медичного персоналу), медичних реєстраторів, дезінфекторів закладів охорони здоров'я (структурних підрозділів) за винятком тих, хто працює у шкідливих умовах праці, - встановлено норму робочого часу 38,5  години  на  тиждень.</p>
                        <p>Наведені норми робочого часу є обов’язковими для всіх медичних працівників  незалежно  від  відомчого підпорядкування та форм власності.</p>
                    `
      },
      {
        number: '2',
        question: 'Що робити хворому на COVID-19 педіатру під час розслідування випадку?',
        answer: `
                        <p>Процедура проведення розслідування гострого професійного захворювання в т.ч. на хворобу COVID-19, спричинену коронавірусом SARS-CoV-2, регламентовано Порядком розслідування та обліку нещасних випадків, професійних захворювань та аварій на виробництві, затвердженим постановою Кабінету Міністрів України від 17.04.2019 року № 337.</p>
                        <p>Так, у разі встановлення тимчасової втрати працездатності (надано лікарняний лист) медичному або іншому працівникові закладу охорони здоров’я, такий працівник:</p>
                        <p>- повідомляє сімейному лікарю або лікуючому лікарю (якщо пацієнта доправлено на лікування до стаціонару) назву, адресу та контактний телефон закладу охорони здоров’я, в якому він працює для надання екстреного повідомлення про звернення потерпілого з посиланням на гостре професійне захворювання (п. 6 Порядку);</p>
                        <p>- самостійно (за можливості) або за допомогою  оточуючих має повідомити про захворювання та його обставини свого безпосереднього керівника.</p>
                        <p>Медичний працівник, члени його сім’ї або уповноважена ними особа, не входять до складу комісії, але мають право брати участь у її засіданнях, одержувати від голови комісії із розслідування гострого професійного захворювання інформацію про хід проведення розслідування, ознайомлюватися з матеріалами розслідування, отримувати витяги та копії з них, вносити пропозиції, подавати документи щодо гострого професійного захворювання, надавати відповідні пояснення, а також з метою сприяння об’єктивному та своєчасному розслідуванню надавати відповідну інформацію, документи та висновки або сприяти їх отриманню від відповідних органів, установ і закладів тощо.</p>
                        <p>За результатами роботи комісії з розслідування: складається акт за формою Н-1/П (якщо гостре професійне захворювання пов’язане з виробництвом) або Н-1/НП (якщо не пов'язано).</p>
                        <p>Рішення щодо визнання гострого професійного захворювання пов’язаного чи не пов’язаного з виробництвом приймається комісією (спеціальною комісією) шляхом голосування простою більшістю голосів. У разі рівної кількості голосів членів комісії голос голови комісії є вирішальним (п. 34 Порядку).</p>
                        <p>Після завершення розслідування і підписання акту Н-1, протягом трьох робочих днів  потерпілому, членам його сім’ї або уповноваженій  ними особі  повинен бути наданий один примірник акту.</p>
                    `
      },
      {
        number: '3',
        question: 'Хто повинен забезпечити лікарів засобами індивідуального захисту? Чи може лікар відмовитися надавати допомогу, якщо у нього немає маски чи захисного костюму?',
        answer: `
                        <p>Роботодавець повинен за власний рахунок забезпечувати медичних працівників засобами індивідуального захисту. Це передбачено у ст. 153, 163, 141 КЗПП та ст. 8 
                        Закону України «Про охорону праці».</p>
                        <p>У разі, якщо роботодавець не забезпечує такими засобами медичного працівника, той має право відмовитися від виконання дорученої йому роботи. При цьому він не може бути притягнений до кримінальної відповідальності за відмову від надання медичної допомоги пацієнту. Стаття 139 Кримінального кодексу передбачає, що кримінальна відповідальність настає для тих медичних працівників, які без поважних причин не надали таку допомогу. Не отримавши від свого роботодавця належні засоби індивідуального захисту, визначені нормативами та стандартами, медичний працівник сам наражається на небезпеку захворіти на COVID-19 або інші небезпечні інфекційні захворювання. Тож це є поважна причина для відмови.</p>
                    `
      },
      {
        number: '4',
        question: 'З якого віку дитина має право самостійно давати згоду на лікування?',
        answer: `
                        <p>Основи законодавства про охорону здоров’я та ряд спеціальних законів говорять про те, що дитина з 14-річного віку має право самостійно підписувати добровільну інформовану згоду.</p>
                        <p>Однак те, що 14-річний підліток може надавати згоду на медичне втручання не означає, що батьки таким чином звільняються від відповідальності за свою неповнолітню дитину. Батьки несуть повну відповідальність за дитину і стан її здоров’я до настання 18-річного віку.</p>
                        <p>Рекомендую отримувати від пацієнтів 2 згоди – від одного із батьків і від дитини, якщо вона досягла 14-річного віку.</p>
                    `
      },
      {
        number: '5',
        question: 'Чи може інформовану згоду на лікування дитини підписувати бабуся або інший родич?',
        answer: `
                        <p>Якщо у родичів немає документу, який підтверджує їхні права щодо дитини на час відсутності батьків, то згода, яку вони підпишуть, не матиме захисного механізму для лікаря. Оскільки ці люди ситуативно знаходяться поряд з дитиною і юридично не мають права надавати згоду на медичне втручання.</p>
                        <p>Якщо за таких обставин після медичного втручання виникнуть негативні наслідки, то у лікаря і медичного закладу можуть бути проблеми у зв’язку з тим, що медичне втручання було проведено без належної інформованої згоди.</p>
                    `
      },
      {
        number: '6',
        question: 'Чи може завідувач відділенням зобов`язати лікаря чергувати на 0,5 ставки під час проходження курсу спеціалізації тривалістю 5 місяців?',
        answer: `
                        <p>Ні, така вимога є протиправною.</p>
                        <p>Спеціалізація — це набуття лікарем або провізором спеціальності, передбаченої Номенклатурою лікарських спеціальностей, затвердженою наказом Міністерства охорони здоров'я України від 22 лютого 2019 року № 446, та Номенклатурою провізорських спеціальностей, затвердженою наказом Міністерства охорони здоров'я України від 12 грудня 2006 року № 818, зареєстрованою у Міністерстві юстиції України 26 грудня 2006 року за № 1366/13240.</p>
                        <p>Спеціалізація є формою післядипломної підготовки спеціаліста, а цикли спеціалізації проводяться у очній формі, тобто з повним відривом лікаря від роботи на період навчання в інституті (факультеті) удосконалення лікарів.</p>
                        <p>Відповідно до норм постанови Кабінету Міністрів України від 28 червня 1997 року № 695 «Про гарантії і компенсації для працівників, які направляються для підвищення кваліфікації, підготовки, перепідготовки, навчання інших професій з відривом від виробництва», для працівників,   які   направляються  для підвищення кваліфікації,  підготовки, перепідготовки  і  навчання інших професій з відривом від виробництва зберігається середня заробітна плата за  час  навчання.</p>
                    `
      },
      {
        number: '7',
        question: 'Чи може сімейний лікар з 1300 деклараціями (у т.ч. 950 дітей) цим обмежитись і отримувати гідну зарплату?',
        answer: `
                        <p>Кошти, які сплачує держава через Національну службу здоров’я України за деклараціями укладеними лікарями з пацієнтами, не є власним доходом лікаря комунального закладу охорони здоров’я. Цей пул надходжень включає видатки не лише на заробітну плату самого лікаря, а і іншого медичного, адміністративного та технічного персоналу, експлуатаційні витрати закладу та медикаментозне забезпечення тощо.</p>
                        <p>Загалом норми ст. 15 Закону України «Про оплату праці», встановлюють, що форми і системи оплати праці, норми праці, розцінки, тарифні сітки, схеми посадових окладів, умови запровадження та розміри надбавок, доплат, премій, винагород та інших заохочувальних, компенсаційних і гарантійних виплат встановлюються підприємствами у колективному договорі з дотриманням норм і гарантій, передбачених законодавством, генеральною, галузевими (міжгалузевими) і територіальними угодами. У разі, коли колективний договір на підприємстві не укладено, роботодавець зобов'язаний погодити ці питання з виборним органом первинної профспілкової організації (профспілковим представником), що представляє інтереси більшості працівників.</p>
                        <p>Отже, визначення рівня оплати праці конкретної категорії лікарів, диференціації в залежності від кількості укладених декларацій, розміру стимулюючих та компенсаційних виплат повинні бути визначені саме у колективному договорі.</p>
                    `
      },
      {
        number: '8',
        question: 'Чи збільшиться оклад сімейного лікаря у зв`язку з підвищенням мінімальної зарплати у країні?',
        answer: `
                        <p>Законодавчо визначений розмір мінімальної заробітної плати є державною гарантією, за якою жоден працівник за повну місячну норму праці не може отримати меншу винагороду. Разом з тим, законодавство не передбачає обов’язкового підвищення заробітної плати працівника, вищої за мінімальну, у зв’язку з ростом останньої.</p>
                        <p>Загалом норми ст. 15 Закону України «Про оплату праці», встановлюють, що форми і системи оплати праці, норми праці, розцінки, тарифні сітки, схеми посадових окладів, умови запровадження та розміри надбавок, доплат, премій, винагород та інших заохочувальних, компенсаційних і гарантійних виплат встановлюються підприємствами у колективному договорі з дотриманням норм і гарантій, передбачених законодавством, генеральною, галузевими (міжгалузевими) і територіальними угодами. У разі, коли колективний договір на підприємстві не укладено, роботодавець зобов'язаний погодити ці питання з виборним органом первинної профспілкової організації (профспілковим представником), що представляє інтереси більшості працівників.</p>
                        <p>Тому зростання заробітної плати працівників первинної ланки надання медичної допомоги (сімейних лікарів, терапевтів, педіатрів, медичних сестер тощо), які працюються у закладі охорони здоров’я — комунальному некомерційному підприємстві, можливе виключно у разі, коли це буде прямо встановлено у нормах колективного договору такого закладу.</p>
                    `
      },
      {
        number: '9',
        question: 'Чи надаються в цьому році сімейним лікарям три додаткові дні відпустки?',
        answer: `
                        <p>Право лікарів загальної практики (сімейних лікарів), медичних сестер загальної практики — сімейної медицини, керівників амбулаторій та відділень сімейної медицини і інших категорій працівників на додаткову оплачувану щорічну відпустку тривалістю три календарних дні є незмінним, і визначене нормами пункту «Н» ст. 77 Основ законодавства  України про охорону здоров'я.</p>
                        <p>Надання цієї відпустки повинно здійснюватися в обов’язковому порядку працівникам за безперервну роботу понад три роки на зазначених посадах та лише у визначених законом закладах (на територіальних ділянках).</p>
                    `
      }
    ]
  }),

  methods: {
    goToQuestion(number) {
      this.isOpen = false;

      let answers = document.querySelectorAll('.question-answers-block .item');

      answers.forEach(element => {
        let answerNumber = element.getAttribute('data-answer');
        if (answerNumber === number) {
          let elementOffset = element.offsetTop
          window.scrollTo({
            top: elementOffset,
            left: 0,
            scroll: "auto"
          })
        }
      });

      this.activeQuestionNumber = number;
      document.documentElement.scrollTop -= 124;
    },
  }
}
</script>

<style lang="scss">
@import "../style/vars";


.legal {
  height: 100%;
  position: relative;

  .content {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;

    .header-image {
      position: relative;

      background: url('../assets/img/legal-img.png') no-repeat center;
      height: 245px;

      .text {
        font-weight: 300;
        font-size: 28px;
        line-height: 118.18%;

        text-align: right;

        color: #ffffff;

        padding-right: 20px;

        position: absolute;
        right: 20px;
      }

      .top {
        top: 0;
        right: 0;

        padding: 14px 20px 20px 30px;
        background: radial-gradient(185.48% 107.41% at 15.21% 107.41%, #A2E6FC 5.42%, rgba(30, 59, 137, 0) 100%), #1E3B89;
        border-bottom-left-radius: 40px;

        .main-text {
          font-family: $fontR;
          font-size: 21px;
          line-height: 25px;

          text-transform: uppercase;
        }

        .under-text {
          font-family: $fontL;
          font-size: 16px;
          line-height: 20px;
        }

      }

      .bottom {
        bottom: 10px;

        font-size: 12px;
        line-height: 16px;

        color: #1E3B89;
      }
    }

    .all-questions-navigation-block {
      position: sticky;
      z-index: 10;
      top: 60px;

      .visible-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 29px 15px 18px;

        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);

        .arrow {
          transition: transform 500ms ease;
        }

        .rotated {
          transform: rotate(-180deg);
          transition: transform 500ms ease;
        }


        .icon-text {
          display: flex;
          align-items: center;
          width: 100%;

          .icon {
            margin-right: 15px;
            max-width: 30px;
            max-height: 33px;
            width: 100%;

            svg {
              height: auto;
              display: block;
            }

          }

          p {
            color: #1E3B89;
            font-size: 15px;
            font-family: $fontR;
          }
        }
      }

      .active {
        background: #E0ECF7;
        border-bottom: 1px solid #CBDFF2;

        .hidden-item {
          background: #E0ECF7;
          box-shadow: 0px 14px 21px rgba(0, 0, 0, 0.08);
        }
      }

      .container {
        max-height: 320px;
        overflow: scroll;
        background: #E0ECF7;

        padding: 16px 25px 27px 22px;

        .active {
          .text {
            font-family: $fontB;
          }
        }

        .hidden-item {
          color: #1E3B89;
          font-size: 15px;
          line-height: 20px;

          display: flex;
          flex-direction: row;

          padding-bottom: 20px;


          .number {
            font-family: $fontB;

            padding-right: 9px;
          }
        }

        .hidden-item:last-child {
          padding-bottom: 0;
        }
      }
    }

    .question-answers-block {
      padding: 38px 15px 40px;

      .item {
        padding: 9px 0 0;
        background: #FFFFFF;
        border-radius: 4px;

        margin-bottom: 20px;

        padding-bottom: 25px;

        display: flex;
        flex-direction: column;

        .number-block {
          border-bottom: 1px solid #DAE3ED;
          padding: 0 20px;
          padding-bottom: 8px;

          .number {
            width: 34px;
            height: 34px;
            border-radius: 50%;

            border: 1px solid #9AB9D8;
            color: #9AB9D8;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .question {
          padding: 0 20px;
          padding-top: 14px;
          color: #1E3B89;

          .text {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;

            padding-bottom: 12px;
          }

          .answer {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;

            p {
              padding-bottom: 10px;
            }

            p:last-child {
              padding-bottom: 0;
            }
          }
        }

        .thanks {
          display: none;

          background: #9AB9D8;
          opacity: 0.6;
          border-radius: 4px;
          color: #1E3B89;

          margin-top: 20px;

          padding: 12px 34px 20px 20px;

          font-family: $fontR;
          font-size: 14px;
          line-height: 20px;

          a {
            color: #1E3B89;
            font-family: $fontB;
            text-decoration: underline;
          }
        }
      }

      .item:last-child {
        .thanks {
          display: block;
        }

        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .partners-block {
      padding: 0 15px;
      margin-bottom: 73px;
      border-radius: 4px;

      display: none;

      .card-image {
        background: #ffffff;

        img {
          width: 100%;
        }
      }

      .card-content {
        padding: 20px 14px;
        background: #ffffff;

        .title {
          padding-bottom: 16px;

          font-size: 20px;
          line-height: 27px;
          color: #1E3B89;
        }

        .text {
          font-family: $fontL;
          font-size: 14px;
          line-height: 130%;
          color: #1E3B89;
        }
      }

      .card-footer {
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding: 10px 0;

        border-top: 1px solid #DAE3ED;

        a {
          font-family: $fontB;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #00ACE6;
        }
      }
    }

  }
}

@media screen and (min-width: 426px) {
  .legal {
    .content {
      .all-questions-navigation-block {
        top: 0;
      }
    }
  }
}

</style>
