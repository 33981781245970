<template>
    <div class="breadcrumbs">
        <router-link :to="path.fullPath" v-for="(path, index) in pathList" :key="index" class="path">
            {{path.name}} 
            <span v-if="index !== pathList.length - 1">/ </span> 
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['pathList'],
    created(){

    }
}
</script>

<style lang="scss">
@import "../../../style/vars";

.breadcrumbs{
    padding: 12px 15px;
    background-color: rgba(255, 255, 255, 0.49);

    font-family: $fontR;
    
    a{
        color: #AC98C5;
        font-size: 11px;
        line-height: 13px;
    }

    .router-link-exact-active{
        font-family: $fontB;
        color: #8F67A4;
    }
}


</style>