<template>
    <div class="zond">
        <breadcrumbs :pathList="pathList" />

        <div class="content-wrapper">

            <div class="page-title">
                Рекомендації щодо вибору ентерального харчування пацієнтам з COVID-19 
            </div>

            <div class="covid-info">
                <div class="title">Для застосування під час Covid-19 пандемії</div>
                <div class="text">
                    <p><b>Зондове харчування для використання у ВIT пiд час пандемiї COVID 19</b></p>
                    <p>Високобiлкове, помiрної калорiйностi зондове харчування на основi цiльних бiлкiв,  рекомендованi усiма гайдлайнами по iнтенсивнiй терапiї
                        (SCCM/ ASPEN, ESPEN,  Asia-Pacific and Middle East regions та iншi) <sup>1-4</sup></p>
                </div>
            </div>

            <div class="products-list">
                <div class="product-row">
                    <product :product="products[0]" @openDetail="openProductDetail(0)">
                        <img src="../../../assets/images/products/nutrizon-protein-intens-79x131-2.png" width="40" alt="">
                    </product>
                    <product :product="products[1]" @openDetail="openProductDetail(1)">
                        <img src="../../../assets/images/products/nutrieon-protein-advance-87x87-2.png" width="40" alt="">
                    </product>
                </div>

                <div class="text-row">
                    <div class="text">У разi показiв <br>до обмеження рiдини</div>
                    <div class="text">У разi гiперглiкемiї <br>або цукрового дiабету</div>
                </div>

                <div class="product-row">
                    <product :product="products[2]" @openDetail="openProductDetail(2)">
                        <img src="../../../assets/images/products/nutrieon-energy-83x113.jpg" width="83" alt="">
                    </product>
                    <product :product="products[3]" @openDetail="openProductDetail(3)">
                        <img src="../../../assets/images/products/nutrieon-advance-diazon-83x113.jpg" width="87" alt="">
                    </product>
                </div>

                <div class="intermediate-block">
                    <div class="top arrow">
                        <div class="left">
                            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.29583 5.98477C-0.0835457 5.62206 -0.100205 5.01717 0.25862 4.63369L4.31465 0.299006C4.67348 -0.084471 5.27191 -0.10131 5.65128 0.261394C6.03066 0.624098 6.04732 1.229 5.68849 1.61247L1.63246 5.94716C1.27364 6.33063 0.675206 6.34747 0.29583 5.98477Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70394 5.98502C9.32442 6.34758 8.726 6.33051 8.36732 5.94689L4.3144 1.6122C3.95572 1.22859 3.97261 0.623696 4.35213 0.261139C4.73164 -0.101418 5.33007 -0.084346 5.68874 0.29927L9.74166 4.63396C10.1003 5.01757 10.0835 5.62247 9.70394 5.98502Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.295769 11.7386C-0.0836067 11.3759 -0.100266 10.771 0.258559 10.3875L4.31459 6.05285C4.67342 5.66937 5.27185 5.65253 5.65122 6.01524C6.0306 6.37794 6.04726 6.98284 5.68843 7.36632L1.6324 11.701C1.27357 12.0845 0.675145 12.1013 0.295769 11.7386Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70394 11.7389C9.32442 12.1014 8.726 12.0844 8.36732 11.7007L4.3144 7.36605C3.95572 6.98243 3.97261 6.37754 4.35213 6.01498C4.73164 5.65243 5.33007 5.6695 5.68874 6.05311L9.74166 10.3878C10.1003 10.7714 10.0835 11.3763 9.70394 11.7389Z" fill="#AC98C5"/>
                            </svg>
                        </div>
                        <div class="right">
                            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.29583 5.98477C-0.0835457 5.62206 -0.100205 5.01717 0.25862 4.63369L4.31465 0.299006C4.67348 -0.084471 5.27191 -0.10131 5.65128 0.261394C6.03066 0.624098 6.04732 1.229 5.68849 1.61247L1.63246 5.94716C1.27364 6.33063 0.675206 6.34747 0.29583 5.98477Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70394 5.98502C9.32442 6.34758 8.726 6.33051 8.36732 5.94689L4.3144 1.6122C3.95572 1.22859 3.97261 0.623696 4.35213 0.261139C4.73164 -0.101418 5.33007 -0.084346 5.68874 0.29927L9.74166 4.63396C10.1003 5.01757 10.0835 5.62247 9.70394 5.98502Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.295769 11.7386C-0.0836067 11.3759 -0.100266 10.771 0.258559 10.3875L4.31459 6.05285C4.67342 5.66937 5.27185 5.65253 5.65122 6.01524C6.0306 6.37794 6.04726 6.98284 5.68843 7.36632L1.6324 11.701C1.27357 12.0845 0.675145 12.1013 0.295769 11.7386Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70394 11.7389C9.32442 12.1014 8.726 12.0844 8.36732 11.7007L4.3144 7.36605C3.95572 6.98243 3.97261 6.37754 4.35213 6.01498C4.73164 5.65243 5.33007 5.6695 5.68874 6.05311L9.74166 10.3878C10.1003 10.7714 10.0835 11.3763 9.70394 11.7389Z" fill="#AC98C5"/>
                            </svg>
                        </div>
                    </div>
                    <div class="content-block">
                        Може знадобитись додатковий <br>бiлок: Розглянути сiппiнг
                    </div>
                    <div class="bottom arrow">
                        <div class="left">
                            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70417 6.01523C10.0835 6.37794 10.1002 6.98283 9.74138 7.36631L5.68535 11.701C5.32652 12.0845 4.72809 12.1013 4.34872 11.7386C3.96934 11.3759 3.95268 10.771 4.31151 10.3875L8.36754 6.05284C8.72636 5.66937 9.32479 5.65253 9.70417 6.01523Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.296063 6.01498C0.675576 5.65242 1.274 5.66949 1.63268 6.05311L5.6856 10.3878C6.04428 10.7714 6.02739 11.3763 5.64787 11.7389C5.26836 12.1014 4.66993 12.0843 4.31126 11.7007L0.25834 7.36604C-0.10034 6.98243 -0.0834509 6.37753 0.296063 6.01498Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70423 0.261386C10.0836 0.62409 10.1003 1.22899 9.74144 1.61247L5.68541 5.94715C5.32658 6.33063 4.72815 6.34747 4.34878 5.98476C3.9694 5.62206 3.95274 5.01716 4.31157 4.63368L8.3676 0.298997C8.72643 -0.0844793 9.32486 -0.101319 9.70423 0.261386Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.296063 0.261132C0.675576 -0.101425 1.274 -0.0843527 1.63268 0.299264L5.6856 4.63395C6.04428 5.01757 6.02739 5.62246 5.64787 5.98502C5.26836 6.34757 4.66993 6.3305 4.31126 5.94689L0.25834 1.6122C-0.10034 1.22858 -0.0834509 0.623689 0.296063 0.261132Z" fill="#AC98C5"/>
                            </svg>
                        </div>
                        <div class="right">
                            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70417 6.01523C10.0835 6.37794 10.1002 6.98283 9.74138 7.36631L5.68535 11.701C5.32652 12.0845 4.72809 12.1013 4.34872 11.7386C3.96934 11.3759 3.95268 10.771 4.31151 10.3875L8.36754 6.05284C8.72636 5.66937 9.32479 5.65253 9.70417 6.01523Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.296063 6.01498C0.675576 5.65242 1.274 5.66949 1.63268 6.05311L5.6856 10.3878C6.04428 10.7714 6.02739 11.3763 5.64787 11.7389C5.26836 12.1014 4.66993 12.0843 4.31126 11.7007L0.25834 7.36604C-0.10034 6.98243 -0.0834509 6.37753 0.296063 6.01498Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70423 0.261386C10.0836 0.62409 10.1003 1.22899 9.74144 1.61247L5.68541 5.94715C5.32658 6.33063 4.72815 6.34747 4.34878 5.98476C3.9694 5.62206 3.95274 5.01716 4.31157 4.63368L8.3676 0.298997C8.72643 -0.0844793 9.32486 -0.101319 9.70423 0.261386Z" fill="#AC98C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.296063 0.261132C0.675576 -0.101425 1.274 -0.0843527 1.63268 0.299264L5.6856 4.63395C6.04428 5.01757 6.02739 5.62246 5.64787 5.98502C5.26836 6.34757 4.66993 6.3305 4.31126 5.94689L0.25834 1.6122C-0.10034 1.22858 -0.0834509 0.623689 0.296063 0.261132Z" fill="#AC98C5"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="product-row">
                    <product :product="products[4]" @openDetail="openProductDetail(4)">
                        <img src="../../../assets/images/products/nutridrink-protein-31x62.jpg" width="31" alt="">
                    </product>
                    <product :product="products[5]" @openDetail="openProductDetail(5)">
                        <img src="../../../assets/images/products/kubitan-34x73.jpg" width="34" alt="">
                    </product>
                </div>
            </div>

            <div class="covid-info footer-text">
                <div class="text">
                    <p>
                        <strong>1.</strong> McClave SA, Taylor BE, Martindale RG, et al. Guidelines for the Provision and Assessment of Nutrition Support Therapy 
                        in the Adult Critically Ill Patient: Society of Critical Care Medicine (SCCM) and American Society for Parenteral and Enteral Nutrition (A.S.P.E.N.). 
                        Journal of Parenteral and Enteral Nutrition. 2016; 40:159-211.<br>
                        2018;xxx:1-32 (article in press).
                    </p>
                    <p>
                        <strong>2.</strong> Singer P, Reintaim Blaser A, Berger M, et al. ESPEN guideline on clinical nutrition in the intensive care unit. Clinical Nutrition.
                    </p>
                    <p>
                        <strong>3.</strong> Sioson MS, Martindale R, Abayadeera A, et al. Nutrition therapy for critically ill patients across the Asia-Pacific and Middle East regions: 
                        A consensus statement. Clin Nutr ESPEN. 2018; 24:156-164.
                    </p>
                    <p><strong>4.</strong> Nutrition in Clinical Practice. 2017;32:142S–151S.</p>
                    <p><strong>* ГІ</strong> – глікемічний індекс.</p>
                </div>
            </div>
        </div>

        <product-popup :product="products[selectedProduct]" v-if="selectedProduct !== null" @closeDetail="selectedProduct = null" />

    </div>
</template>

<script>
import Breadcrumbs from '../../../components/Clinical/Algorithms/Breadcrumbs';
import Product from '../../../components/Clinical/Algorithms/Zond/Product';
import ProductPopup from '../../../components/Clinical/Algorithms/Zond/ProductPopup';

export default {
    components: {
        'breadcrumbs' : Breadcrumbs,
        'product' : Product,
        'product-popup' : ProductPopup
    },
    data : () => ({
        selectedProduct : null,
        pathList: [
            {
                name : 'Алгоритми',
                fullPath: '/clinical/algorithms'
            },
            {
                name : 'Рекомендації щодо вибору ентерального харчування пацієнтам з COVID-19 ',
                fullPath : '/clinical/algorithms/zond'
            }
        ],
        products : [
            {
                name : `Нутрізон Протеїн Інтенс`,
                images : [
                    {
                        // path : '/img/nutrizon-protein-intens-79x131.jpg',
                        // path : 'download-local/nutrizon-protein-intens-79x131.jpg',
                        path : 'download-local/nutrizon-protein-intens-79x131-2.png',
                        width: 50
                    },
                    // {
                    //     // path : '/img/nutrizon-protein-intens-94x141.jpg',
                    //     path : 'download-local/nutrizon-protein-intens-94x141.jpg',
                    //     width: 94
                    // }                    
                ],
                properties : [
                    {
                        name : 'Упаковка',
                        value : '<b>500</b> мл'
                    },
                    {
                        name : 'Ккал',
                        value : '<b>126</b> ккал/<b>100</b> мл'
                    },
                    {
                        name : 'Бiлок',
                        value : '<b>10</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Вуглеводи',
                        value : '<b>10,4</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Жири',
                        value : '<b>4,9</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Харчовi волокна',
                        value : '<b>0,09</b> г/<b>100</b> мл'
                    }
                ]
            },
            {
                name : 'Нутрiзон Протеїн Едванс',
                images : [
                    {
                        // path : '/img/nutrieon-protein-advance-99x99.jpg',
                        path : 'download-local/nutrieon-protein-advance-99x99.jpg',
                        width: 99
                    }
                ],
                properties : [
                    {
                        name : 'Упаковка',
                        value : '<b>500</b> мл'
                    },
                    {
                        name : 'Ккал',
                        value : '<b>128</b> ккал/<b>100</b> мл'
                    },
                    {
                        name : 'Бiлок',
                        value : '<b>7,5</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Вуглеводи',
                        value : '<b>15,4</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Жири',
                        value : '<b>3,8</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Харчовi волокна',
                        value : '<b>1,5</b> г/<b>100</b> мл'
                    }
                ]
            },
            {
                name : 'Нутрiзон Енергiя',
                images : [
                    {
                        // path : '/img/nutrieon-energy-95x129.jpg',
                        path : 'download-local//nutrieon-energy-95x129.jpg',
                        width: 95
                    }
                ],
                properties : [
                    {
                        name : 'Упаковка',
                        value : '<b>1000</b> мл'
                    },
                    {
                        name : 'Ккал',
                        value : '<b>150</b> ккал/<b>100</b> мл'
                    },
                    {
                        name : 'Бiлок',
                        value : '<b>6</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Вуглеводи',
                        value : '<b>18,3</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Жири',
                        value : '<b>5,8</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Харчовi волокна',
                        value : '<b><0,1</b> г/<b>100</b> мл'
                    }
                ]
            },
            {
                name : 'Нутрiзон Едванст Діазон',
                note : 'ГІ*=17',
                images : [
                    {
                        // path : '/img/nutrieon-advance-diazon-95x129.jpg',
                        path : 'download-local/nutrieon-advance-diazon-95x129.jpg',
                        width: 95
                    }
                ],
                properties : [
                    {
                        name : 'Упаковка',
                        value : '<b>1000</b> мл'
                    },
                    {
                        name : 'Ккал',
                        value : '<b>103</b> ккал/<b>100</b> мл'
                    },
                    {
                        name : 'Бiлок',
                        value : '<b>4,3</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Вуглеводи',
                        value : '<b>11,3</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Жири',
                        value : '<b>4,2</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Харчовi волокна',
                        value : '<b>1,5</b> г/<b>100</b> мл'
                    }
                ]
            },
            {
                name : 'Нутрiдрiнк Протеїн',
                images : [
                    {
                        // path : '/img/nutridrink-protein-48x96.jpg',
                        path : 'download-local/nutridrink-protein-48x96.jpg',
                        width: 48
                    },
                    {
                        // path : '/img/nutridrink-protein-122x124.jpg',
                        path : 'download-local/nutridrink-protein-122x124.jpg',
                        width: 122
                    }
                ],
                properties : [
                    {
                        name : 'Упаковка',
                        value : '<b>4*125</b> мл'
                    },
                    {
                        name : 'Ккал',
                        value : '<b>245</b> ккал/<b>100</b> мл'
                    },
                    {
                        name : 'Бiлок',
                        value : '<b>14,6</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Вуглеводи',
                        value : '<b>25,4</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Жири',
                        value : '<b>9,6</b> г/<b>100</b> мл'
                    }
                ]
            },
            {
                name : 'Кубітан',
                note : 'ГІ*=34',
                images : [
                    {
                        // path : '/img/kubitan-60x127.jpg',
                        path : 'download-local/kubitan-60x127.jpg',
                        width: 60
                    },
                    {
                        // path : '/img/kubitan-156x149.jpg',
                        path : 'download-local/kubitan-156x149.jpg',
                        width: 145
                    }
                ],
                properties : [
                    {
                        name : 'Упаковка',
                        value : '<b>4*200</b> мл'
                    },
                    {
                        name : 'Ккал',
                        value : '<b>124</b> ккал/<b>100</b> мл'
                    },
                    {
                        name : 'Бiлок',
                        value : '<b>8,8</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Вуглеводи',
                        value : '<b>14,5</b> г/<b>100</b> мл'
                    },
                    {
                        name : 'Жири',
                        value : '<b>3,6</b> г/<b>100</b> мл'
                    }
                ]
            },
        ]
    }),
    methods : {
        openProductDetail(productIndex){
            this.selectedProduct = productIndex;
        }
    },
    watch : {
        selectedProduct : function(val){
            if(val != null)
                document.getElementsByTagName("body")[0].style.overflow = "hidden";
            else
                document.getElementsByTagName("body")[0].style.overflow = "visible";
        }
    }
}
</script>

<style lang="scss">
@import "../../../style/vars";

.zond{
    .content-wrapper{
        padding: 16px 15px;

        .page-title{
            background: #ffffff;
            padding: 16px 15px;
            color: #AC98C5;
            text-align: center;
            font-family: $fontB;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.11em;
            text-transform: uppercase;

            margin-bottom: 17px;
        }

        .covid-info{
            .title{
                background: rgba(172, 152, 197, 0.25);
                border-radius: 8px;
                padding: 8px;
                color: #8F67A4;
                font-weight: 600;

                font-size: 12px;
                line-height: 16px;

                margin-bottom: 8px;

                text-align: center;
            }

            .text{
                font-size: 14px;
                line-height: 18px;
                color: #8F67A4;

                p{
                    padding-bottom: 10px;

                    sup{
                        font-size: 10px;
                        line-height: 5px;
                    }
                }
            }
        }

        .products-list{
            display: flex;
            flex-direction: column;

            padding-bottom: 32px;

            .product-row, .text-row{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }

            .text-row{
                margin-top: 16px;
                margin-bottom: 6px;

                .text{
                    width: 138px;
                    font-size: 12px;
                    line-height: 130%;
                    color: #8F67A4;
                }
            }
            
        }

        .intermediate-block{
            .content-block{
                background: #AC98C5;
                padding: 10px;
                text-align: center;
                letter-spacing: 0.11em;
                text-transform: uppercase;
                color: #ffffff;

                font-size: 12px;
                line-height: 16px;
                font-family: $fontB;
            }

            .arrow{
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                padding: 8px 0;
                cursor: pointer;

                .left, .right{
                    width: 138px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .footer-text{
            .text{
                p{
                    font-size: 12px;
                    line-height: 130%;
                }
            }
        }
    }
}
</style>