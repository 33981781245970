<template>
  <div class="header-clinical">

    <div class="head-line">

      <div @click="menuCtrl('1')"
           v-bind:class="[menuActive === '1' ? activeClass: '', `menu-icon`]">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                fill="#8F67A4"/>
        </svg>
      </div>

      <div class="logo">
        <img src="../../assets/logo.png" height="55" alt="">
        <!-- <svg width="99" height="43" viewBox="0 0 99 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip1)">
            <path d="M44.6828 32.1377V20.4512H49.151V17.8079H37.1426V20.4512H41.6108V32.1377H44.6828Z" fill="#4A2A80"/>
            <path d="M12.4274 22.8163C12.567 23.0946 12.9859 24.0684 13.6841 25.0423L18.8505 32.1377H21.7828V17.8079H18.8505V27.4074C18.7109 27.1292 18.292 26.2944 17.5938 25.1814L12.567 17.8079H9.49512V32.1377H12.4274V22.8163Z" fill="#4A2A80"/>
            <path d="M84.0592 17.8079H80.8477V32.1377H84.0592V17.8079Z" fill="#4A2A80"/>
            <path d="M90.3427 17.8079C87.6897 21.9816 86.2934 27.2683 85.4556 32.1377H88.6671C88.8068 31.0247 89.086 29.7725 89.3653 28.6595H94.811C95.0903 29.7725 95.2299 31.0247 95.5092 32.1377H99C98.1622 27.2683 96.7659 22.2598 94.1128 17.8079H90.3427ZM89.7842 26.2944C90.3427 24.0684 91.0409 22.1207 92.0183 20.173C92.9958 22.1207 93.6939 24.0684 94.2525 26.2944H89.7842Z" fill="#4A2A80"/>
            <path d="M75.402 20.173C76.3795 20.173 77.3569 20.4513 78.3343 21.0078L79.3118 18.3644C78.3343 17.947 76.938 17.5297 75.2624 17.5297C70.5149 17.5297 68.1411 21.286 68.1411 25.1815C68.1411 29.3552 70.9338 32.5551 75.2624 32.5551C76.6587 32.5551 78.3343 32.1377 79.4514 31.5812L78.6136 29.077C77.9154 29.4943 76.938 29.9117 75.6813 29.9117C73.4472 29.9117 71.4923 28.1031 71.4923 25.1815C71.3527 22.8164 72.3301 20.173 75.402 20.173Z" fill="#4A2A80"/>
            <path d="M23.5981 17.8079V26.4336C23.5981 31.0247 26.5304 32.4159 30.9987 32.4159C32.6743 32.4159 34.3499 32.2768 36.0255 31.9985V17.8079H32.9536V29.7725C32.395 29.9117 31.8365 30.0508 31.1383 30.0508C28.9042 30.0508 26.8097 29.216 26.8097 26.7118V17.8079H23.5981Z" fill="#4A2A80"/>
            <path d="M66.4655 17.8079H63.2539V32.1377H66.4655V17.8079Z" fill="#4A2A80"/>
            <path d="M61.1595 21.8425C61.1595 18.2252 57.8083 17.8079 55.0156 17.8079H50.4077V32.1377H53.6193V26.4336H54.5967C54.876 26.4336 55.2949 26.4336 55.5741 27.1292L58.2272 32.1377H61.9973L58.0875 25.5988C59.9028 25.1814 61.1595 23.7902 61.1595 21.8425ZM55.2949 24.0684H53.6193V20.3121H55.1552C56.4119 20.3121 57.9479 20.4512 57.9479 22.1207C57.9479 23.6511 56.5516 24.0684 55.2949 24.0684Z" fill="#4A2A80"/>
            <path d="M5.8646 32.1377C4.189 29.2161 3.3512 25.8771 3.3512 22.399C3.3512 11.1299 12.8463 1.94774 24.4358 1.94774C33.0931 1.94774 40.6333 7.09533 43.8449 14.3298C40.4937 5.98234 32.3949 0 22.7602 0C10.1932 0 0 10.2952 0 23.0946C0 26.2945 0.698166 29.3552 1.81523 32.1377H5.8646Z" fill="#313183"/>
            </g>
            <defs>
            <clipPath id="clip1">
            <rect width="99" height="42.0531" fill="white"/>
            </clipPath>
            </defs>
        </svg> -->
      </div>

      <div @click="menuCtrl('2')"
           v-bind:class="[menuActive === '2' ? activeClass: '', `account`]">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9 10.2413C11.7614 10.2413 14 8.1726 14 5.62067C14 3.06875 11.7614 1 9 1C6.23858 1 4 3.06875 4 5.62067C4 8.1726 6.23858 10.2413 9 10.2413Z"
              stroke="#8F67A4" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round"/>
          <path d="M9 10.2413C13.4184 10.2413 17 13.9189 17 18.4559H1C1 15.6013 2.41538 13.0894 4.56504 11.617"
                stroke="#8F67A4" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
    </div>

    <div v-if="menuActive === '1'"
         v-bind:class="[menuActive === '1' ? activeClass: '', `drop-nav-menu drop-menu`]">

      <div class="link-list" @click="menuCtrl('1')">
        <router-link to="/clinical">Головна</router-link>
        <router-link to="/clinical/product-categories">Продукти</router-link>
        <router-link to="/clinical/algorithms">Алгоритми</router-link>
        <router-link to="/clinical/library">Бібліотека</router-link>
        <router-link to="/pediatrics">Портал дитячого харчування</router-link>
      </div>

    </div>

    <div v-if="menuActive === '2'"
         v-bind:class="[menuActive === '2' ? activeClass: '', `drop-account-menu drop-menu`]">

      <div class="popup-btn">
        <div class="user-data item" @click="doFunction('4')">
          <div class="icon">
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6 7.31143C7.70698 7.31143 9.09076 5.90792 9.09076 4.17659C9.09076 2.44527 7.70698 1.04175 6 1.04175C4.29302 1.04175 2.90924 2.44527 2.90924 4.17659C2.90924 5.90792 4.29302 7.31143 6 7.31143Z"
                  stroke="#4A2A80" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
              <path d="M1 12.3869C1 9.58375 3.24039 7.31555 6 7.31555C8.75961 7.31555 11 9.5879 11 12.3869H1Z"
                    stroke="#4A2A80" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

          </div>
          <p>Особисті дані</p>
        </div>

        <div class="quit item" @click="doFunction('0');  exit();">
          <div class="icon">
            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.41534 7.86113H9.3274V9.75849L14.5114 5.49997L9.3274 1.24146V3.13881H3.41534"
                    stroke="#4A2A80" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M6.35666 10.5315H0.488586V0.468506H6.35666" stroke="#4A2A80" stroke-width="0.7"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
          <p>Вийти з сайту</p>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "topMenu",
  data: () => ({
    menuActive: "",
    activeClass: "active",
    currentRout: ""
  }),
  mounted() {
    this.currentRout = this.$route.path;
  },
  methods: {
    ...mapActions([
      'setMenuData'
    ]),

    menuCtrl(id) {
      if (id !== this.menuActive) {
        this.menuActive = id;
      } else {
        this.menuActive = '';
      }
    },
    doFunction(value) {
      this.setMenuData({popupState: '6'})
      this.menuActive = '';

    },

    goToConf() {
      sessionStorage.setItem("myConf", "true");
      this.$router.push({name: "Conferences"})
    },

    exit() {
      sessionStorage.removeItem("key");
      this.$router.go();
    }
  }
}
</script>

<style lang="scss">
@import "../../style/vars";

.header-clinical {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  max-height: 60px;
  height: 100%;
  background-color: white;

  @media screen and (min-width: 426px) {
    position: absolute;
  }

  .head-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
  }

  .menu-icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    svg {
      path {

        transition: all 0.2s ease-in-out;
      }

    }

    &.active, &:active {
      background-color: #8F67A4;

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .account {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
      path {
        transition: all 0.2s ease-in-out;
      }

    }

    &.active, &:active {
      background-color: #8F67A4;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .drop-menu {
    background: white;

    &.drop-nav-menu {
      padding-bottom: 30px;
      padding-top: 45px;

      .link-list {
        display: flex;
        flex-direction: column;
        border-left: 1px solid rgba(172, 152, 197, 0.3);
        margin-left: 30px;

        a {
          color: #4A2A80;
          position: relative;
          font-size: 16px;
          font-family: $fontL;
          padding-left: 30px;
          padding-bottom: 45px;
          transition: all 0.2s ease;

          &:before {
            transition: all 0.2s ease;
            content: '';
            opacity: 0;
            width: 3px;
            height: 22px;
            background: #8F67A4;
            position: absolute;
            left: 0;
            top: 0;
          }

          &:last-child {
            padding-bottom: 20px;
          }

          &.router-link-exact-active, &:active {
            font-family: $fontSB;
            color: #4A2A80;

            &:before {
              opacity: 1;
            }

          }
        }
      }
    }

    &.drop-account-menu {
      padding-top: 45px;

      .popup-btn {
        margin-left: 30px;
        cursor: pointer;

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 45px;
          cursor: pointer;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            margin-right: 25px;
          }

          p {
            color: #4A2A80;
            font-size: 16px;
            font-family: $fontL;
          }

          svg {
            path {
              transition: all 0.2s ease;
            }
          }

          &.active, &:active {
            .icon {
              svg {
                path {
                  stroke-width: 1.7;
                }
              }
            }

            p {
              font-family: $fontSB;
              color: #4A2A80;
            }

          }

        }
      }
    }
  }
}

</style>
