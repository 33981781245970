<template>
    <div class="container">
        
        <card-header :cardType="cardType" :card="card" />

        <card-content :cardType="cardType" :cardTitle="card.title" />

        <card-footer :cardType="cardType" :useful="card.useful" :authors="card.authors" :author="card.author" :publisher="card.publisher ? card.publisher.title : ''" :year="card.publishYear" :publishTitle="card.publishTitle" />
    </div>
</template>

<script>
import cardHeader from '../short_cards/short-card-header';
import cardContent from '../short_cards/short-card-content';
import cardFooter from '../short_cards/short-card-footer';

export default {
    props : ['card', 'cardType'],
    components : {
        'card-header' : cardHeader,
        'card-content' : cardContent,
        'card-footer' : cardFooter
    }
}
</script>

<style lang="scss">
@import "../../../style/vars";
</style>