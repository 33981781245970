<template>
  <div class="question-wrapper-clinical">

    <div class="question-block">

      <transition name="fade">
        <div class="send-ok-block_btn" v-if="popupState2 === '2'">
          <div class="close-btn" @click="closePopup()">
            <p>закрити</p>
          </div>
        </div>
      </transition>

      <p class="h1-title"><span>Напишіть нам</span>
        <i class="close-btn" @click="closePopup()">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M0.25824 0.258301L18.7417 18.7418" stroke="#8F67A4" stroke-width="1.7"
                    stroke-miterlimit="10"/>
              <path d="M18.7417 0.258301L0.25824 18.7418" stroke="#8F67A4" stroke-width="1.7"
                    stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="19" height="19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </i>
      </p>

      <transition name="fade">
        <div class="form-wrapper" v-if="popupState === '1'">
          <textarea placeholder="Ваше питання" v-model="questionField"></textarea>
        </div>
      </transition>

      <transition name="fade">
        <div class="answer-data-block" v-if="popupState === '1'">

          <div class="h4-title">Куди бажаєте отримати відповідь?</div>


          <div class="radio-item">

            <label class="container">
              <!--              <span v-if="number !== ''">{{number}}</span>-->
              <!--              <span v-if="number === ''">телефон</span>-->
              <input type="radio" checked="checked" name="radio" value="phone" v-model="radioChose">
              <span class="checkmark"></span>
            </label>

            <the-mask class="corr" :mask="['+38 (0##) ### ## ##']" v-model="rowNumber"/>


          </div>

          <div class="radio-item">

            <label class="container">
              <input type="radio" name="radio" value="email" v-model="radioChose">
              <span class="checkmark"></span>
            </label>

            <input class="corr" type="email" name="email" v-model="email"
                   placeholder="email">

          </div>

          <div class="error-message" v-if="error === 'field'">
            <p>Заповніть всі поля</p>
          </div>
          <div class="error-message" v-if="error  === 'email'">
            <p>некорекктно заповнений поле email</p>
          </div>
          <div class="error-message" v-if="error  === 'phone'">
            <p>неправильний формат телефону</p>
          </div>

          <div class="send-bnt" @click="sendQuestion()">
            <p>{{ sendBtn }}</p>
          </div>

        </div>
      </transition>

      <transition name="fade">
        <div class="send-ok-block" v-if="popupState2 === '2'">
          <div class="message-block">
            <svg width="65" height="30" viewBox="0 0 65 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#qwerty)">
                <path d="M20.59 1H63.98L60.31 28.3H16.92" stroke="#313183" stroke-width="1.5"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.59 1L39.98 18.14L63.98 1" stroke="#313183" stroke-width="1.5"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M36.04 14.6499L16.92 28.2999" stroke="#313183" stroke-width="1.5"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M60.31 28.2999L44.86 14.6499" stroke="#313183" stroke-width="1.5"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.42 8.98999H9.33" stroke="#313183" stroke-width="1.5" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.59 14.6499H13.61" stroke="#313183" stroke-width="1.5"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 20.3201H16.92" stroke="#313183" stroke-width="1.5" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="qwerty">
                  <rect width="64.98" height="29.3" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <p class="text1">Ваше повідомлення відправлено. </p>
            <p class="text2">Ми зв’яжемося з Вами найближчим часом.</p>
          </div>

        </div>
      </transition>


    </div>

  </div>

</template>

<script>

import axios from "axios"
import {mapActions} from "vuex";

export default {
  name: "question-popup",
  data: () => ({
    error: false,
    corrNum: false,
    corrEmail: false,
    popupState: "1",
    popupState2: "1",
    radioChose: "phone",
    sendBtn: "Відправити",
    number: "",
    rowNumber: "672173124",
    email: "",
    isOpenDrop: false,
    activeClass: "active",
    selectRubric: null,
    questionField: "",
    rubricField: "Рубрика",
    rubric: [
      {"id": 1, "title": "Вопрос педиатру"},
      {"id": 2, "title": "Вопрос о продукте"},
      {"id": 3, "title": "Вопрос об акциях"},
      {"id": 4, "title": "Вопрос о сотрудничестве"},
      {"id": 5, "title": "Вопрос технического характера"},
      {"id": 6, "title": "Другое"}]
  }),
  created() {
    this.getCategory();
    this.getUserData();
  },
  methods: {

    ...mapActions(['setMenuData']),

    getUserData() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/security/participant", {}).then((res) => {
        let data = JSON.parse(res.data)

        this.rowNumber = data.phone.replace("380", "")
        this.number = data.phone

        this.email = data.email
      })
    },

    getCategory() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/feedback-category").then((res) => {
        this.rubric = JSON.parse(res.data)

      }).catch((error) => {

      })
    },


    selectRubricF(id, name) {
      this.selectRubric = id;
      this.rubricField = name;
      this.isOpenDrop = false;

    },

    openDropList() {
      if (this.isOpenDrop !== true) {
        this.isOpenDrop = true;
      } else {
        this.isOpenDrop = false;
      }

    },

    sendQuestion() {
      if (this.questionField !== '') {

        this.number = "380" + this.rowNumber

        if (this.radioChose === "phone") {
          if (this.number.length === 12) {
            this.sendFunction("", this.number);
          } else {
            this.error = 'phone';
          }
        } else if (this.radioChose === "email") {
          if (this.email.includes('@')) {
            this.sendFunction(this.email, "");
          } else {
            this.error = 'email';
          }
        }
      } else {
        this.error = 'field';
      }
    },
    sendFunction(email, phoneNubmer) {
      this.error = '';
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.post(this.$root.globalUrl + "api/v1/feedback", {
        "category_id": 7,
        "question": this.questionField,
        "callback_type": this.radioChose,
        "callback_phone": phoneNubmer,
        "callback_email": email,
      }).then((res) => {
        this.popupState = "2";
        setTimeout(() => {
          this.popupState2 = "2";
        }, 300)
      })

      this.popupState = "2";
      setTimeout(() => {
        this.popupState2 = "2";
      }, 300)
    },

    closePopup() {
      this.setMenuData({popupState: '0'})

      this.popupState = "1";
      this.popupState2 = "1";
    },
  }
}
</script>

<style lang="scss">
@import "../../style/vars";


.question-wrapper-clinical {
  overflow: auto;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(88, 101, 136, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 51;

  @media screen and (min-width: 426px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }



  .question-block {
    min-height: 400px;
    max-width: 395px;
    margin: 50px auto 20px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    .h1-title {
      position: relative;
      z-index: 2;
      border-radius: 4px 4px 0 0;
      padding: 0 14px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F1EDF7;

      span {
        font-size: 15px;
        font-family: $fontB;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #AC98C5;
      }

    }

  }

  .form-wrapper {
    margin-top: 30px;
    padding: 0 15px;

    textarea {
      padding: 15px;
      width: 100%;
      min-height: 130px;
      color: #AC98C5;
      font-family: $fontL;
      font-size: 16px;
      border-color: rgba(172, 152, 197, 0.3);

      &:focus {
        outline-offset: 0;
        border-color: #AC98C5;
        outline: none;
      }
    }

  }

  .rubric {
    .select {
      margin-top: 10px;


      transition: all 0.2s ease;


      .visible-item {
        margin-bottom: 10px;

        .arrow-icon {
          transition: all 0.2s ease;
        }


        .border {
          padding: 10px 0;
          border-bottom: 1px solid rgba(172, 152, 197, 0.3);
          margin: 0 15px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        p {
          color: #AC98C5;
          font-size: 16px;
          font-family: $fontL;
        }


      }

      .hide-item-list {
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 1s ease;

        p {
          &:first-child {
            margin-top: 40px;
          }

          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #1E3B89;
          font-family: $fontL;
          font-size: 16px;
          padding-bottom: 30px;

          &:active, &.active {
            font-family: $fontSB;

            span {
              opacity: 1;
            }
          }

          span {
            transition: all 0.2s ease;
            opacity: 0;
          }
        }
      }

      &.active {
        background-color: #E1EAF0;

        .border {


          .arrow-icon {
            transform: rotate(180deg);
          }
        }

        .hide-item-list {
          max-height: 1200px;

        }
      }

    }
  }

  .answer-data-block {
    padding: 15px 15px;
    margin-top: 0;

    .h4-title {
      font-family: $fontSB;
      font-size: 14px;
      margin-bottom: 20px;
      color: #8F67A4;
    }

    .radio-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;

      .corr {
        padding: 3px 5px;
        color: #8F67A4;
        font-family: $fontL;
        font-size: 16px;
        border: 1px solid rgba(172, 152, 197, 0.3);

        &:focus {
          border: 1px solid #AC98C5;
          cursor: none;
          outline: none;
        }
      }

    }

    .container {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 28px;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: #8F67A4;
      font-family: $fontL;

    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: -5px;
      left: 0;
      height: 12px;
      width: 12px;
      border: 1px solid #8F67A4;
      border-radius: 50%;
    }


    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      top: 1px;
      left: 1px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #8F67A4;
    }
  }

  .send-bnt {
    cursor: pointer;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #8F67A4;
    border-radius: 4px;


    p {
      color: white;
      font-family: $fontB;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  .send-ok-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 15px;

    .message-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        margin-top: 40px;
      }

      .text1 {
        margin-top: 30px;
        color: #313183;
        text-align: center;
        font-size: 19px;
        font-family: $fontR;
      }

      .text2 {
        margin-top: 30px;

        color: #4A2A80;
        text-align: center;
        font-size: 19px;
        font-family: $fontL;
      }

    }


  }

  .send-ok-block_btn {
    z-index: 1;
    display: flex;
    padding: 0 15px;
    position: absolute;
    align-items: flex-end;
    left: 0;
    top: 0;
    bottom: 15px;
    width: 100%;

    .close-btn {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #8F67A4;
      border-radius: 4px;


      p {
        color: white;
        font-family: $fontB;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

  }

  .error-message {
    text-align: center;
    margin-bottom: 20px;
    color: red;
    font-family: $fontR;
  }

}

</style>
