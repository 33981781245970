<template>
    <div class="global-menu-items">
        <div class="section-nav">
            <router-link to="/clinical/product-categories">
                <div class="section-nav-item">
                    <div class="icon">
                        <svg width="94" height="86" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M42.4644 36.4918L42.4644 42.2766" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M76.9116 36.4918V49.5212" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M76.9116 51.921L76.9116 75.2897" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M47.5891 76.3293C47.5651 76.1495 47.705 75.9897 47.8864 75.9897H77.9983C78.164 75.9897 78.2983 76.124 78.2983 76.2897V79.1459C78.2983 79.3116 78.164 79.4459 77.9983 79.4459H48.2663C48.1159 79.4459 47.9888 79.3346 47.9689 79.1855L47.5891 76.3293Z" stroke="#4A2A80" stroke-width="1.4"/>
                            <mask id="path-5-inside-1" fill="white">
                            <rect x="38.3198" y="31.9199" width="43.059" height="4.57191" rx="1"/>
                            </mask>
                            <rect x="38.3198" y="31.9199" width="43.059" height="4.57191" rx="1" stroke="#4A2A80" stroke-width="2.8" mask="url(#path-5-inside-1)"/>
                            <path d="M61.5117 48.9926H60.8117V49.6926V59.3919C60.8117 60.5255 61.733 61.4468 62.8666 61.4468H70.236C71.3694 61.4468 72.2905 60.5254 72.2905 59.3919V52.2396C72.2905 52.1772 72.3409 52.1268 72.4033 52.1268H90.7725C91.6369 52.1268 92.3394 51.4244 92.3394 50.5599C92.3394 49.6952 91.6372 48.9926 90.7725 48.9926H85.95H61.5117Z" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M29.0165 59.9239C27.9376 58.5948 27.3062 56.9854 27.3062 55.2511C27.3062 52.8836 28.483 50.7486 30.3686 49.2421C34.9642 49.2421 40.8753 49.2421 43.774 49.2421C45.6597 50.7486 46.8365 52.8836 46.8365 55.2511C46.8365 56.6998 46.4064 57.9559 45.6324 59.1397" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M28.0575 73.5277C27.1038 74.7713 26.5552 76.2284 26.5552 77.7861C26.5552 79.9634 27.6269 81.9439 29.3785 83.4199C29.3785 83.4199 32.5789 84.9344 36.941 84.8651C41.303 84.7959 44.7645 83.4199 44.7645 83.4199C46.5161 81.9439 47.5879 79.9634 47.5879 77.7861C47.5879 76.2284 47.0392 74.7713 46.0855 73.5277" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M28.2951 58.8325C29.7001 60.6984 30.5552 63.3122 30.5156 66.1946C30.4733 69.2699 29.4213 72.0114 27.7944 73.849" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M45.8392 58.8325C44.4342 60.6984 43.579 63.3122 43.6187 66.1946C43.661 69.2699 44.713 72.0114 46.3399 73.849" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M44.2801 49.6332V45.0065C44.2801 43.3496 42.937 42.0065 41.2801 42.0065H32.9478C31.2909 42.0065 29.9478 43.3496 29.9478 45.0065V49.6332" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M33.564 43.9711V46.1215" stroke="#4A2A80" stroke-width="1.4" stroke-linecap="round"/>
                            <path d="M40.9668 43.9711V46.1215" stroke="#4A2A80" stroke-width="1.4" stroke-linecap="round"/>
                            <path d="M37.3804 43.9711V46.1215" stroke="#4A2A80" stroke-width="1.4" stroke-linecap="round"/>
                            <path d="M31.3167 53.5918C31.3167 53.1237 31.5651 52.8429 31.8815 52.781C32.7675 52.6076 34.3619 52.4067 37.0789 52.3619C39.9392 52.3148 41.5408 52.5467 42.3753 52.7478C42.6124 52.8049 42.818 53.031 42.818 53.4563V54.0075C42.818 54.8179 42.0704 55.4624 41.2299 55.3408C39.924 55.1519 38.2961 54.9627 37.0587 54.9779C35.8233 54.9931 34.1888 55.1838 32.878 55.3669C32.0508 55.4825 31.3167 54.8459 31.3167 54.0418V53.5918Z" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M30.7095 63.827C34.1285 63.4578 40.9667 65.0456 40.9667 74.3513C40.9667 81.4608 38.1979 84.2642 37.7369 84.846" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M32.2121 28.0722C32.2121 19.4398 25.2141 14.6747 16.5816 14.6747C7.94916 14.6747 0.951172 19.4398 0.951172 28.0722" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M27.5673 30.9811C27.5673 23.4975 22.6207 19.3666 16.5188 19.3666C10.4168 19.3666 5.47021 23.4975 5.47021 30.9811" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M2.06168 58.0278C1.34523 59.6287 0.951172 61.4594 0.951172 63.5078C0.951172 68.6662 3.44992 73.2409 7.30302 76.0876C9.89808 78.0049 13.1075 77.7 16.5816 77.7C20.0557 77.7 23.2652 78.0049 25.8602 76.0876C26.2602 75.7921 26.6455 75.478 27.015 75.1466" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M6.25519 56.9504C5.74876 58.3383 5.47021 59.9253 5.47021 61.7012C5.47021 66.173 7.23648 70.1389 9.96009 72.6068C11.7944 74.2689 14.063 74.0046 16.5188 74.0046C18.9745 74.0046 21.2431 74.2689 23.0775 72.6068C25.8011 70.1389 27.5673 66.173 27.5673 61.7012C27.5673 59.6943 27.2116 57.9286 26.5733 56.4189" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M0.953613 28.0677C0.953613 29.7198 3.18595 39.1337 3.58182 43.9162C3.9677 48.5781 3.12187 55.6781 2.03076 58.0815" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M5.47266 30.9774C5.47266 32.4096 7.05061 40.5707 7.33043 44.7167C7.6032 48.7582 7.00531 54.9133 6.23405 56.9968" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M32.2119 28.0677C32.2119 29.7198 29.9796 39.1337 29.5837 43.9162C29.4318 45.7516 29.4708 47.9649 29.6405 50.1207" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M27.5674 30.9774C27.5674 32.4096 25.9894 40.5707 25.7096 44.7167C25.4368 48.7582 26.0347 54.9133 26.806 56.9968" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M11.1214 5.5708C11.1214 5.40512 11.2557 5.2708 11.4214 5.2708H21.7105C21.8761 5.2708 22.0105 5.40512 22.0105 5.5708V13.6746C22.0105 13.8403 21.8761 13.9746 21.7105 13.9746H11.4214C11.2557 13.9746 11.1214 13.8403 11.1214 13.6746V5.5708Z" stroke="#4A2A80" stroke-width="1.4"/>
                            <path d="M13.3182 1.78442C13.3182 1.61874 13.4525 1.48442 13.6182 1.48442H19.5134C19.6791 1.48442 19.8134 1.61874 19.8134 1.78442V4.54349C19.8134 4.70917 19.6791 4.84348 19.5134 4.84348H13.6182C13.4525 4.84348 13.3182 4.70917 13.3182 4.54348V1.78442Z" stroke="#4A2A80" stroke-width="1.4"/>
                            <mask id="path-27-inside-2" fill="white">
                            <rect x="8.74561" y="27.1905" width="15.8168" height="5.21235" rx="1"/>
                            </mask>
                            <rect x="8.74561" y="27.1905" width="15.8168" height="5.21235" rx="1" stroke="#4A2A80" stroke-width="2.8" mask="url(#path-27-inside-2)"/>
                        </svg>
                    </div>
                    <div class="text">продукти</div>
                </div>
            </router-link>
            <router-link to="/clinical/algorithms">
                <div class="section-nav-item">
                    <div class="icon">
                        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M34.6327 34H16.3673C16.1714 34 16 33.7667 16 33.5C16 33.2333 16.1714 33 16.3673 33H34.6327C34.8286 33 35 33.2333 35 33.5C35 33.7667 34.8286 34 34.6327 34Z" fill="#4A2A80"/>
                            <path d="M34.5 36C34.2333 36 34 35.7558 34 35.4767V33.5233C34 33.2442 34.2333 33 34.5 33C34.7667 33 35 33.2442 35 33.5233V35.4767C35 35.7907 34.7667 36 34.5 36Z" fill="#4A2A80"/>
                            <path d="M16.5 36C16.2333 36 16 35.7558 16 35.4767V33.5233C16 33.2442 16.2333 33 16.5 33C16.7667 33 17 33.2442 17 33.5233V35.4767C17 35.7907 16.7667 36 16.5 36Z" fill="#4A2A80"/>
                            <path d="M25.5 34C25.2333 34 25 33.7558 25 33.4767V31.5233C25 31.2442 25.2333 31 25.5 31C25.7667 31 26 31.2442 26 31.5233V33.4767C26 33.7907 25.7667 34 25.5 34Z" fill="#4A2A80"/>
                            <path d="M37.8475 43.4855H32.7715C31.7835 43.4855 31 42.7019 31 41.714V38.5457C31 37.5578 31.7835 36.7742 32.7715 36.7742H37.8475C38.8354 36.7742 39.619 37.5578 39.619 38.5457V41.714C39.653 42.7019 38.8354 43.4855 37.8475 43.4855ZM32.7715 37.7622C32.3627 37.7622 32.022 38.1028 32.022 38.5117V41.6799C32.022 42.0887 32.3627 42.4294 32.7715 42.4294H37.8475C38.2563 42.4294 38.597 42.0887 38.597 41.6799V38.5117C38.597 38.1028 38.2563 37.7622 37.8475 37.7622H32.7715Z" fill="#4A2A80"/>
                            <path d="M18.8475 43.4855H13.7715C12.7835 43.4855 12 42.7019 12 41.714V38.5457C12 37.5578 12.7835 36.7742 13.7715 36.7742H18.8475C19.8354 36.7742 20.619 37.5578 20.619 38.5457V41.714C20.619 42.7019 19.8354 43.4855 18.8475 43.4855ZM13.7715 37.7622C13.3627 37.7622 13.022 38.1028 13.022 38.5117V41.6799C13.022 42.0887 13.3627 42.4294 13.7715 42.4294H18.8475C19.2563 42.4294 19.597 42.0887 19.597 41.6799V38.5117C19.597 38.1028 19.2563 37.7622 18.8475 37.7622H13.7715Z" fill="#4A2A80"/>
                            <path d="M28.5544 30.166H23.4443C22.4564 30.166 21.6729 29.3825 21.6729 28.3945V25.1922C21.6729 24.2043 22.4564 23.4207 23.4443 23.4207H28.5203C29.5083 23.4207 30.2918 24.2043 30.2918 25.1922V28.3604C30.3259 29.3484 29.5083 30.166 28.5544 30.166ZM23.4443 24.4427C23.0355 24.4427 22.6949 24.7834 22.6949 25.1922V28.3604C22.6949 28.7693 23.0355 29.1099 23.4443 29.1099H28.5203C28.9291 29.1099 29.2698 28.7693 29.2698 28.3604V25.1922C29.2698 24.7834 28.9291 24.4427 28.5203 24.4427H23.4443Z" fill="#4A2A80"/>
                            <path d="M31.7227 21.8876H20.2761C20.0036 21.8876 19.7651 21.6491 19.7651 21.3766V15.6533C19.7651 15.3808 20.0036 15.1423 20.2761 15.1423H31.7227C31.9952 15.1423 32.2337 15.3808 32.2337 15.6533V21.3766C32.2337 21.6491 31.9952 21.8876 31.7227 21.8876ZM20.7871 20.8656H31.2117V16.1643H20.7871V20.8656Z" fill="#4A2A80"/>
                            <path d="M31.7227 13.6437H20.2761C20.0036 13.6437 19.7651 13.4053 19.7651 13.1327V7.40944C19.7651 7.13691 20.0036 6.89844 20.2761 6.89844H31.7227C31.9952 6.89844 32.2337 7.13691 32.2337 7.40944V13.1327C32.2337 13.4053 31.9952 13.6437 31.7227 13.6437ZM20.7871 12.6217H31.2117V7.92045H20.7871V12.6217Z" fill="#4A2A80"/>
                            <path d="M17.7553 19.3673H11.3848C10.3968 19.3673 9.61328 18.5837 9.61328 17.5958V11.8384C9.61328 10.8505 10.3968 10.067 11.3848 10.067H17.7213C17.9938 10.067 18.2323 10.3054 18.2323 10.578C18.2323 10.8505 17.9938 11.089 17.7213 11.089H11.3848C10.976 11.089 10.6353 11.4296 10.6353 11.8384V17.5617C10.6353 17.9705 10.976 18.3112 11.3848 18.3112H17.7213C17.9938 18.3112 18.2323 18.5497 18.2323 18.8222C18.2323 19.0947 18.0279 19.3673 17.7553 19.3673Z" fill="#4A2A80"/>
                            <path d="M17.7553 19.3679C17.6191 19.3679 17.4828 19.3339 17.3806 19.2317L15.2344 17.0854C15.03 16.881 15.03 16.5744 15.2344 16.37C15.4388 16.1656 15.7454 16.1656 15.9498 16.37L18.096 18.5163C18.3004 18.7207 18.3004 19.0273 18.096 19.2317C17.9938 19.2998 17.8575 19.3679 17.7553 19.3679Z" fill="#4A2A80"/>
                            <path d="M15.4045 21.7182C15.2682 21.7182 15.132 21.6842 15.0298 21.582C14.8254 21.3775 14.8254 21.0709 15.0298 20.8665L17.3804 18.5159C17.5848 18.3115 17.8914 18.3115 18.0958 18.5159C18.3002 18.7203 18.3002 19.0269 18.0958 19.2313L15.7452 21.582C15.643 21.6501 15.5067 21.7182 15.4045 21.7182Z" fill="#4A2A80"/>
                            <path d="M40.6145 26.964H32.3362C32.0637 26.964 31.8252 26.7256 31.8252 26.453C31.8252 26.1805 32.0637 25.942 32.3362 25.942H40.5804C41.0233 25.942 41.3299 25.6354 41.3299 25.2607V20.2869C41.3299 19.844 40.9552 19.4011 40.5804 19.4011H34.244C33.9714 19.4011 33.733 19.1627 33.733 18.8901C33.733 18.6176 33.9714 18.3791 34.244 18.3791H40.5804C41.5343 18.3791 42.3519 19.2649 42.3519 20.2869V25.2266C42.386 26.2146 41.6025 26.964 40.6145 26.964Z" fill="#4A2A80"/>
                            <path d="M32.3357 27.0328C32.1994 27.0328 32.0631 26.9987 31.9609 26.8965C31.7565 26.6921 31.7565 26.3855 31.9609 26.1811L34.1071 24.0349C34.3116 23.8305 34.6182 23.8305 34.8226 24.0349C35.027 24.2393 35.027 24.5459 34.8226 24.7503L32.7104 26.8965C32.6082 26.9987 32.4719 27.0328 32.3357 27.0328Z" fill="#4A2A80"/>
                            <path d="M34.6864 29.3149C34.5501 29.3149 34.4139 29.2808 34.3457 29.1786L31.9951 26.8961C31.7907 26.6917 31.7907 26.3851 31.9951 26.1807C32.1995 25.9763 32.5061 25.9763 32.7105 26.1807L35.0611 28.4632C35.2655 28.6676 35.2655 28.9742 35.0611 29.1786C34.9589 29.2808 34.8227 29.3149 34.6864 29.3149Z" fill="#4A2A80"/>
                            <path d="M57.05 48.5H50.4V1.95C50.4 1.4256 49.9744 1 49.45 1H1.95C1.4256 1 1 1.4256 1 1.95V53.25C1 55.8691 3.13085 58 5.75 58H53.25C55.8691 58 58 55.8691 58 53.25V49.45C58 48.9256 57.5744 48.5 57.05 48.5ZM8.6 49.45V53.25C8.6 54.8213 7.3213 56.1 5.75 56.1C4.1787 56.1 2.9 54.8213 2.9 53.25V2.9H48.5V48.5H9.55C9.0256 48.5 8.6 48.9256 8.6 49.45ZM56.1 53.25C56.1 54.8213 54.8213 56.1 53.25 56.1H9.5481C10.1447 55.3058 10.5 54.3187 10.5 53.25V50.4H56.1V53.25Z" fill="#4A2A80" stroke="white" stroke-width="0.3"/>
                        </svg>
                    </div>
                    <div class="text">
                        aлгоритми
                    </div>
                </div>
            </router-link>
            <router-link to="/clinical/library">
                <div class="section-nav-item">
                    <div class="icon">
                        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip2)">
                            <path d="M29.98 0.73C29.98 0.33 29.65 0 29.25 0H16.47C16.07 0 15.74 0.33 15.74 0.73V7.86H0.74C0.33 7.87 0 8.2 0 8.6V57.77C0 58.17 0.33 58.5 0.73 58.5H29.25C29.65 58.5 29.98 58.17 29.98 57.77V0.73ZM15.73 57.03H1.47V50.63H15.74V57.03H15.73ZM15.73 49.17H1.47V45.72H15.74V49.17H15.73ZM15.73 44.25H1.47V14.25H15.74V44.25H15.73ZM15.73 12.78H1.47V9.33H15.74V12.78H15.73ZM28.52 57.03H17.2V52.6H28.52V57.03ZM28.52 51.13H17.2V48.66H28.52V51.13ZM28.52 47.2H17.2V12.28H28.52V47.2ZM28.52 10.82H17.2V7.37H28.52V10.82ZM28.52 5.9H17.2V1.47H28.52V5.9Z" fill="#4A2A80"/>
                            <path d="M30.5102 12.72L42.3102 57.95C42.3602 58.14 42.4802 58.3 42.6502 58.4C42.8202 58.5 43.0202 58.53 43.2102 58.47L57.9602 54.54C58.3502 54.44 58.5802 54.04 58.4802 53.65L46.6802 8.42C46.6302 8.23 46.5102 8.07 46.3402 7.97C46.2302 7.9 46.1002 7.87 45.9702 7.87C45.9102 7.87 45.8402 7.88 45.7802 7.89L31.0302 11.82C30.6402 11.93 30.4102 12.33 30.5102 12.72ZM56.8702 53.31L56.6302 53.37L43.5402 56.86L42.3402 52.27L55.6702 48.71L56.8702 53.31ZM55.3102 47.31L41.9802 50.86L40.8502 46.52L41.0902 46.46L54.1802 42.97L55.3102 47.31ZM53.8002 41.54L53.5602 41.6L40.4702 45.1L34.6402 22.74L47.9702 19.19L53.8002 41.54ZM47.0102 15.51L47.6002 17.76L34.2702 21.32L33.6802 19.06L47.0102 15.51ZM45.4402 9.5L46.6402 14.09L33.3102 17.64L32.1102 13.05L45.4402 9.5Z" fill="#4A2A80"/>
                            <path d="M12.5299 17.7H4.66994C4.26994 17.7 3.93994 18.03 3.93994 18.43V26.3C3.93994 26.7 4.26994 27.03 4.66994 27.03H12.5399C12.9399 27.03 13.2699 26.7 13.2699 26.3V18.43C13.2699 18.03 12.9399 17.7 12.5299 17.7ZM11.7999 25.57H5.39994V19.17H11.7999V25.57Z" fill="#4A2A80"/>
                            </g>
                            <defs>
                            <clipPath id="clip2">
                            <rect width="58.5" height="58.5" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="text">
                        бібліотека
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "footer",

        data: () => ({}),
        mounted() {
        },
        methods: {
            openSendMessagePopUp() {
                this.$emit('click');
            }
        }
    };
</script>

<style lang="scss">
@import "../../../style/vars";

.global-menu-items{
    .section-nav {
        display: flex;
        flex-flow: row wrap;

        background: #F1EDF7;

        a {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:nth-child(1) {
                border-bottom: 1px solid rgba(172, 152, 197, 0.3);

                width: 100%;

                .section-nav-item{
                    .icon{
                        height: 86px;
                        margin-top: 30px;
                        margin-bottom: 15px;
                    }
                }
            }

            &:nth-child(2) {
                border-right: 1px solid rgba(172, 152, 197, 0.3);
                border-bottom: 1px solid rgba(172, 152, 197, 0.3);
            }

            &:nth-child(3) {
                border-bottom: 1px solid rgba(172, 152, 197, 0.3);
            }

            &:active {
                svg {
                    path {
                        stroke-width: 2;
                    }
                }
            }

            svg {
                path {
                    transition: all 0.2s ease;
                }
            }
        }



        .section-nav-item {
            height: 185px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .icon {
                margin-top: 40px;
                margin-bottom: 25px;
                height: 64px;
            }

            .text {
                text-align: center;
                color: #4A2A80;
                font-size: 14px;
                font-family: $fontB;
                text-transform: uppercase;
            }
        }
    }
}
    
</style>
