<template>
  <div class="library">

    <div class="head-links">
      <router-link class="back-links" to="/clinical"
      >Головна</router-link
      >
      <span>/</span>
      <router-link
          class="now-page"
          to="/clinical/product-categories"
      >Продукти</router-link>
    </div>

    <div class="content">
      <div class="product-categories">
        <div class="product-categories__title">продукти</div>
        <div class="product-categories__list">
          <router-link
            to="/clinical/products/oral"
            class="product-categories__item"
          >
          <svg width="17" height="32" viewBox="0 0 17 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.71695 13.498C1.96436 12.5709 1.52393 11.4483 1.52393 10.2386C1.52393 8.58711 2.34478 7.09789 3.66009 6.04707C6.21594 5.35397 11.3019 5.32654 13.0108 6.04707C14.3261 7.09789 15.147 8.58711 15.147 10.2386C15.147 11.2491 14.847 12.1252 14.3071 12.951" stroke="#4A2A80"/>
            <path d="M2.04793 22.9871C1.38268 23.8545 1 24.8709 1 25.9574C1 27.4761 1.74758 28.8576 2.96938 29.8872C2.96938 29.8872 5.20176 30.9436 8.24441 30.8953C11.2871 30.847 13.7016 29.8872 13.7016 29.8872C14.9234 28.8576 15.671 27.4761 15.671 25.9574C15.671 24.8709 15.2883 23.8545 14.623 22.9871" stroke="#4A2A80"/>
            <path d="M2.21371 12.7367C3.19374 14.0382 3.79023 15.8614 3.76257 17.872C3.73307 20.0171 2.99928 21.9294 1.86445 23.2112" stroke="#4A2A80"/>
            <path d="M14.4512 12.7367C13.4712 14.0382 12.8747 15.8614 12.9023 17.872C12.9318 20.0171 13.6656 21.9294 14.8005 23.2112" stroke="#4A2A80"/>
            <path d="M13.3637 6.31986V4C13.3637 2.34315 12.0206 1 10.3637 1H6.36645C4.7096 1 3.36646 2.34315 3.36646 4V6.31986" stroke="#4A2A80"/>
            <path d="M5.88892 2.37033V3.87033" stroke="#4A2A80" stroke-width="0.5" stroke-linecap="round"/>
            <path d="M11.0525 2.37033V3.87033" stroke="#4A2A80" stroke-width="0.5" stroke-linecap="round"/>
            <path d="M8.55103 2.37033V3.87033" stroke="#4A2A80" stroke-width="0.5" stroke-linecap="round"/>
            <path d="M4.08301 9.18482C4.08301 8.70658 4.35093 8.33951 4.76896 8.26285C5.42055 8.14335 6.52802 8.01477 8.33665 7.98499C10.231 7.95379 11.3516 8.09656 11.9827 8.23583C12.3528 8.3175 12.5821 8.65574 12.5821 9.10232C12.5821 9.95813 11.7982 10.6178 10.934 10.4991C10.0809 10.3819 9.09243 10.2768 8.32946 10.2862C7.54678 10.2958 6.5233 10.4088 5.65241 10.5265C4.82712 10.6381 4.08301 10.005 4.08301 9.18482Z" stroke="#4A2A80" stroke-width="0.5"/>
            <path d="M3.89771 16.2206C6.28261 15.963 11.0524 17.0706 11.0524 23.5616C11.0524 28.5207 9.1211 30.4761 8.79958 30.882" stroke="#4A2A80" stroke-width="0.5"/>
          </svg>
          Пероральне харчування</router-link>
          <router-link
            to="/clinical/products/probe"
            class="product-categories__item"
          >
          <svg width="17" height="37" viewBox="0 0 17 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5677 12.7162C15.5677 8.69341 12.3066 6.47287 8.28384 6.47287C4.26109 6.47287 1 8.69341 1 12.7162" stroke="#4A2A80"/>
            <path d="M13.4033 14.0718C13.4033 10.5844 11.0982 8.65938 8.25462 8.65938C5.41109 8.65938 3.10596 10.5844 3.10596 14.0718" stroke="#4A2A80" stroke-width="0.5"/>
            <path d="M1.5175 26.6756C1.18363 27.4216 1 28.2747 1 29.2293C1 31.6331 2.16442 33.765 3.95998 35.0915C5.16929 35.985 6.66488 35.8429 8.28384 35.8429C9.90279 35.8429 11.3984 35.985 12.6077 35.0915C14.4033 33.765 15.5677 31.6331 15.5677 29.2293C15.5677 28.1506 15.3332 27.2014 14.9123 26.3899" stroke="#4A2A80"/>
            <path d="M3.47176 26.1735C3.23576 26.8202 3.10596 27.5598 3.10596 28.3874C3.10596 30.4713 3.92904 32.3194 5.19825 33.4694C6.05307 34.244 7.11024 34.1208 8.25462 34.1208C9.399 34.1208 10.4562 34.244 11.311 33.4694C12.5802 32.3194 13.4033 30.4713 13.4033 28.3874C13.4033 27.4522 13.2375 26.6293 12.9401 25.9258" stroke="#4A2A80" stroke-width="0.5"/>
            <path d="M1.00122 12.7141C1.00122 13.484 2.0415 17.8709 2.22597 20.0995C2.4058 22.272 2.01164 25.5806 1.50317 26.7006" stroke="#4A2A80"/>
            <path d="M3.10706 14.07C3.10706 14.7374 3.84239 18.5405 3.97279 20.4725C4.0999 22.3559 3.82128 25.2242 3.46187 26.1951" stroke="#4A2A80" stroke-width="0.5"/>
            <path d="M15.5676 12.7141C15.5676 13.484 14.5273 17.8709 14.3429 20.0995C14.1631 22.272 14.5572 25.5806 15.0657 26.7006" stroke="#4A2A80"/>
            <path d="M13.4033 14.07C13.4033 14.7374 12.668 18.5405 12.5376 20.4725C12.4105 22.3559 12.6891 25.2242 13.0485 26.1951" stroke="#4A2A80" stroke-width="0.5"/>
            <path d="M5.91309 2.76446C5.91309 2.48832 6.13694 2.26447 6.41309 2.26447H10.1398C10.416 2.26447 10.6398 2.48832 10.6398 2.76447V5.47287C10.6398 5.74901 10.416 5.97287 10.1398 5.97287H6.41309C6.13694 5.97287 5.91309 5.74901 5.91309 5.47287V2.76446Z" stroke="#4A2A80"/>
            <path d="M6.93689 1C6.93689 0.723858 7.16075 0.5 7.43689 0.5H9.11609C9.39223 0.5 9.61609 0.723858 9.61609 1V1.59555C9.61609 1.87169 9.39223 2.09555 9.11609 2.09555H7.43689C7.16075 2.09555 6.93689 1.87169 6.93689 1.59555V1Z" stroke="#4A2A80"/>
            <rect x="4.8822" y="12.5553" width="6.87067" height="1.92897" rx="0.75" stroke="#4A2A80" stroke-width="0.5"/>
          </svg>
          Зондове харчування</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductCategories",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getClinicalProducts"]),
  },
};
</script>

<style scoped lang="scss">
@import "../../style/vars";

.head-links {
  background: rgba(255, 255, 255, 0.49);
  width: 100%;
  padding: 10px 15px;

  .back-links {
    color: #ac98c5;
    font-family: $fontR;
    font-size: 11px;
  }

  span {
    padding: 0 5px;
    color: #ac98c5;
    font-family: $fontR;
    font-size: 11px;
  }

  .now-page {
    color: #8f67a4;
    font-family: $fontSB;
    font-size: 11px;
  }
}

.content {
  padding: 26px 15px 60px;
}
.product-categories {
  background: #fff;
  &__title {
    padding: 9px 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    color: #ac98c5;
  }
  &__item {
    position: relative;
    display: block;
    padding: 17px 45px 17px 20px;
    border-top: 1px solid rgba($color: #ac98c5, $alpha: 0.3);
    background-repeat: no-repeat;
    background-position: 23px center;
    font-weight: normal;
    text-decoration: none;
    font-size: 14px;
    line-height: 19px;
    color: #4a2a80;

    display: flex;
    align-items: center;

    svg{
      margin-right: 10px;
    }

    &:active {
      background-color: #efe1f7;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 23px;
      width: 5px;
      height: 5px;
      border-top: 2px solid #8f67a4;
      border-right: 2px solid #8f67a4;
      transform: translateY(-50%) rotate(45deg);
    }
    // &:nth-child(1) {
    //   background-image: url("~@/assets/images/clinical-products/oral-icon.png");
    //   background-size: auto 60%;
    // }
    // &:nth-child(2) {
    //   background-image: url("~@/assets/images/clinical-products/probe-icon.png");
    // }
  }
}
</style>
