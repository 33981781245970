<template>
    <div class="item" :class="{disabled: isDisabled}">
        <div class="visible-item">
            <div class="icon-text">
                <div class="icon">
                    <svg width="34" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Вік'">
                        <path d="M29.1586 15.8381L29.1586 15.8383C27.9975 21.0915 23.0152 24.9128 17.3057 24.9128C11.5963 24.9128 6.61496 21.0916 5.45382 15.8385C5.3943 15.565 5.15185 15.3715 4.87389 15.3715H4.54975C3.19279 15.3715 2.08828 14.2669 2.08828 12.911C2.08828 11.555 3.19284 10.4496 4.54975 10.4496H5.20296C5.45816 10.4496 5.68519 10.287 5.76615 10.0445C7.3672 5.28932 12.0031 2.08822 17.3057 2.08822C22.6083 2.08822 27.2432 5.28931 28.8453 10.0445L28.8453 10.0446C28.9271 10.2866 29.1528 10.4496 29.4084 10.4496H30.0626C31.4195 10.4496 32.5231 11.554 32.5231 12.911C32.5231 14.268 31.4196 15.3715 30.0626 15.3715H29.7385C29.46 15.3715 29.2191 15.5665 29.1586 15.8381ZM0.900061 12.911C0.900061 14.875 2.45947 16.4814 4.40564 16.5566C5.86955 22.1176 11.2173 26.1 17.3057 26.1C23.3941 26.1 28.7428 22.1176 30.2087 16.5566C32.1539 16.4814 33.7133 14.875 33.7133 12.911C33.7133 10.8981 32.0755 9.26133 30.0646 9.26133H29.8287C27.9416 4.23972 22.9631 0.9 17.3067 0.9C11.6503 0.9 6.67177 4.23972 4.78466 9.26133H4.54975C2.53684 9.26133 0.900061 10.8991 0.900061 12.911Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                        <path d="M21.4465 16.1107C22.161 16.1107 22.7402 15.5315 22.7402 14.8169C22.7402 14.1024 22.161 13.5232 21.4465 13.5232C20.7319 13.5232 20.1527 14.1024 20.1527 14.8169C20.1527 15.5315 20.7319 16.1107 21.4465 16.1107Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                        <path d="M13.6207 16.1107C14.3353 16.1107 14.9145 15.5315 14.9145 14.8169C14.9145 14.1024 14.3353 13.5232 13.6207 13.5232C12.9062 13.5232 12.327 14.1024 12.327 14.8169C12.327 15.5315 12.9062 16.1107 13.6207 16.1107Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                        <path d="M13.5656 20.2471L13.5656 20.2471L13.5662 20.2475L13.7608 20.367C13.7608 20.367 13.7608 20.367 13.7608 20.367C14.8529 21.0399 16.0777 21.376 17.3046 21.376C18.5306 21.376 19.7574 21.0388 20.8514 20.366L20.8515 20.3659L21.0452 20.2463L21.0453 20.2463C21.3231 20.0741 21.4113 19.7083 21.2389 19.4288C21.0662 19.1491 20.6993 19.0653 20.4217 19.235L20.4217 19.235L20.4211 19.2353L20.2287 19.3547C20.2287 19.3547 20.2286 19.3547 20.2286 19.3548C18.4227 20.4664 16.1845 20.4654 14.3835 19.3557L14.1899 19.2362L14.1899 19.2362L14.1895 19.2359C13.9118 19.0662 13.5461 19.1503 13.3725 19.4296L13.3725 19.4296L13.3724 19.4298C13.1998 19.7095 13.2883 20.074 13.5656 20.2471Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                    </svg>
                    <svg width="37" height="21" viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Вага'">
                        <path d="M10.9165 12.6151H10.9167H26.0833L26.0835 12.6151C30.3893 12.6082 33.9597 9.17489 34.3132 4.73526C35.309 4.70071 36.1 3.85217 36.1 2.81818C36.1 1.76242 35.2753 0.9 34.25 0.9H2.75C1.72469 0.9 0.9 1.76242 0.9 2.81818C0.9 3.85217 1.69104 4.70071 2.68684 4.73526C3.04031 9.17489 6.61075 12.6082 10.9165 12.6151ZM26.0832 11.203H10.9168C7.36874 11.1984 4.40266 8.40101 4.05045 4.73636H32.9496C32.5973 8.40101 29.6313 11.1984 26.0832 11.203ZM34.25 3.32424H2.75C2.48665 3.32424 2.26667 3.10128 2.26667 2.81818C2.26667 2.53507 2.48665 2.31212 2.75 2.31212H34.25C34.5133 2.31212 34.7333 2.53507 34.7333 2.81818C34.7333 3.10128 34.5133 3.32424 34.25 3.32424Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                        <path d="M26.537 12.8783L26.3884 12.7712L26.3127 12.938L25.9269 13.7882L25.8762 13.9L25.9758 13.9718C27.2625 14.8996 28.0699 16.6352 28.0722 18.531C28.0721 18.7526 27.9348 18.8706 27.8333 18.8706H9.16667C9.06517 18.8706 8.92783 18.7526 8.92778 18.531C8.931 16.6456 9.72967 14.9182 11.0052 13.9859L11.1041 13.9136L11.053 13.8023L10.6641 12.9546L10.5875 12.7877L10.4393 12.896C8.83231 14.0704 7.85405 16.2191 7.85 18.5306V18.5309C7.85 19.391 8.40908 20.15 9.16667 20.15H27.8333C28.5909 20.15 29.15 19.391 29.15 18.5309V18.5307C29.1471 16.2066 28.1584 14.0476 26.537 12.8783Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.3"/>
                        <path d="M13 13.85H12.85V14V17V17.15H13H24H24.15V17V14V13.85H24H13ZM23.1167 16.1H13.8833V14.9H23.1167V16.1Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.3"/>
                    </svg>
                    <svg width="39" height="25" viewBox="0 0 39 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Зріст'">
                        <path d="M37.85 6.65584C37.85 8.13198 36.6997 9.62473 34.4888 10.7869C32.3081 11.9331 29.2506 12.6617 25.8376 12.6617C22.4247 12.6617 19.3671 11.9331 17.1864 10.7869C14.9755 9.62473 13.8253 8.13198 13.8253 6.65584C13.8253 5.17971 14.9755 3.68695 17.1864 2.52481C19.3671 1.37855 22.4247 0.65 25.8376 0.65C29.2506 0.65 32.3081 1.37855 34.4888 2.52481C36.6997 3.68695 37.85 5.17971 37.85 6.65584Z" stroke="#1E3B89" stroke-width="1.3"/>
                        <path d="M14.1024 7.68073V13.1494M14.1024 13.1494H1V23.8636C7.00649 23.8636 20.6753 23.8636 27.2987 23.8636C33.9221 23.8636 38.0482 20.6169 38.0482 18.3442V6.59131C38.0482 11.5612 29.012 13.3684 23.5649 13.1494H14.1024Z" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="4.73439" y1="20.7793" x2="4.73439" y2="24.0261" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="12.5266" y1="20.7793" x2="12.5266" y2="24.0261" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="9.92924" y1="19.1558" x2="9.92924" y2="24.0259" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="17.7214" y1="19.1558" x2="17.7214" y2="24.0259" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="25.5137" y1="19.1558" x2="25.5137" y2="24.0259" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="20.3188" y1="20.7793" x2="20.3188" y2="24.0261" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="7.33184" y1="20.7793" x2="7.33184" y2="24.0261" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="15.124" y1="20.7793" x2="15.124" y2="24.0261" stroke="#1E3B89" stroke-width="1.3"/>
                        <line x1="22.9162" y1="20.7793" x2="22.9162" y2="24.0261" stroke="#1E3B89" stroke-width="1.3"/>
                    </svg>
                    <svg width="19" height="31" viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Калорійність'">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29862 0C5.21718 0 5.14517 0.0528657 5.12077 0.130564L0.060812 16.2425C0.0430296 16.2991 0.0532714 16.3609 0.0883927 16.4087C0.123514 16.4565 0.179317 16.4848 0.238667 16.4848H6.8014L4.05071 29.9763C4.03297 30.0632 4.07903 30.1507 4.16079 30.1852C4.24256 30.2198 4.33735 30.1919 4.38737 30.1186L18.1025 10.0119C18.1415 9.95482 18.1456 9.88087 18.1133 9.81977C18.081 9.75867 18.0176 9.72044 17.9485 9.72044H12.2562L16.9173 0.268871C16.9458 0.211089 16.9424 0.142689 16.9084 0.0879776C16.8744 0.0332664 16.8145 0 16.7501 0H5.29862ZM10.2926 10.7908C10.265 10.8486 10.2689 10.9165 10.3031 10.9706C10.3372 11.0248 10.3967 11.0577 10.4608 11.0577H15.644L6.28036 25.0934L8.45514 15.4976C8.46767 15.4424 8.45436 15.3844 8.41899 15.3401C8.38361 15.2958 8.33001 15.27 8.27333 15.27H1.71427L6.20079 1.29831H14.8309L10.2926 10.7908Z" fill="#1E3B89"/>
                        <path d="M6.2953 5.75839C6.08818 5.79103 5.91821 5.9397 5.8583 6.14063L4.01387 12.3267C3.89942 12.7105 4.22468 13.0824 4.62035 13.02C4.82747 12.9874 4.99744 12.8387 5.05735 12.6378L6.90178 6.45175C7.01622 6.0679 6.69096 5.69605 6.2953 5.75839Z" fill="#1E3B89" stroke="#1E3B89" stroke-width="0.186419" stroke-linejoin="round"/>
                    </svg>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Білок'">
                        <g clip-path="url(#clip0_4478_6545)">
                        <path d="M16.2254 12.668H10.0808C9.8358 12.668 9.61125 12.521 9.50918 12.2689L6.77368 5.23106C6.6512 4.91594 6.7941 4.53779 7.12072 4.41174C7.42694 4.28568 7.79439 4.43274 7.91687 4.76888L10.5095 11.4285H15.8376L18.4097 4.74787C18.5322 4.43274 18.8997 4.26468 19.2059 4.39073C19.5121 4.51678 19.6754 4.89493 19.5529 5.21005L16.797 12.2689C16.7154 12.521 16.4908 12.668 16.2254 12.668Z" fill="#1E3B89"/>
                        <path d="M12.6122 9.72691C12.3672 9.72691 12.1222 9.57985 12.0406 9.30674L10.6933 5.54624C10.5708 5.2101 10.7341 4.85296 11.0607 4.72691C11.3873 4.60086 11.7344 4.76893 11.8569 5.10506L13.2042 8.86556C13.3267 9.2017 13.1634 9.55884 12.8367 9.68489C12.7551 9.7059 12.6938 9.72691 12.6122 9.72691Z" fill="#1E3B89"/>
                        <path d="M13.9187 9.91608C13.9187 10.4413 13.5104 10.8615 13.0001 10.8615C12.4897 10.8615 12.0814 10.4413 12.0814 9.91608C12.0814 9.39087 12.4897 8.9707 13.0001 8.9707C13.5104 8.9707 13.9187 9.39087 13.9187 9.91608Z" fill="#1E3B89"/>
                        <path d="M7.26372 5.33597C7.10041 5.33597 6.93709 5.27294 6.81461 5.12588C6.59005 4.87378 6.61047 4.47462 6.85544 4.24353C8.3865 2.79395 10.6933 1.95361 13.1634 1.95361C15.6539 1.95361 17.9403 2.79395 19.4714 4.24353C19.7163 4.47462 19.7367 4.87378 19.5122 5.12588C19.2876 5.37798 18.8998 5.39899 18.6548 5.1679C17.3687 3.94941 15.3273 3.21412 13.1634 3.21412C11.0199 3.21412 8.97851 3.94941 7.672 5.1679C7.54952 5.29395 7.40662 5.33597 7.26372 5.33597Z" fill="#1E3B89"/>
                        <path d="M13 24.5797C12.653 24.5797 12.3876 24.3066 12.3876 23.9495V19.1176C12.3876 18.7604 12.653 18.4873 13 18.4873C13.347 18.4873 13.6124 18.7604 13.6124 19.1176V23.9495C13.6124 24.3066 13.347 24.5797 13 24.5797Z" fill="#1E3B89"/>
                        <path d="M19.0222 25H6.97791C5.36519 25 3.75248 23.6765 3.38502 22.0378L0.200422 7.41597C0.180008 7.35294 0.180008 7.31092 0.180008 7.2479C0.0983517 6.80672 0.0371094 6.34454 0.0371094 5.88235C0.0371094 2.64706 2.60929 0 5.75306 0H20.2471C23.3909 0 25.963 2.64706 25.963 5.88235C25.963 6.47059 25.8814 7.03782 25.7181 7.60504C25.7181 7.66807 25.7181 7.7521 25.7181 7.81513L22.6151 22.0378C22.2477 23.6765 20.635 25 19.0222 25ZM1.44568 7.33193L4.58946 21.7647C4.81401 22.8361 5.91637 23.7395 6.97791 23.7395H19.0222C20.0838 23.7395 21.1861 22.8361 21.4107 21.7647L24.4524 7.81513C24.432 7.68908 24.432 7.56303 24.4728 7.45798C24.6565 6.95378 24.7382 6.42857 24.7382 5.88235C24.7382 3.34034 22.7172 1.2605 20.2471 1.2605H5.75306C3.28295 1.2605 1.26196 3.34034 1.26196 5.88235C1.26196 6.30252 1.3232 6.70168 1.42527 7.10084C1.44568 7.18487 1.44568 7.2479 1.44568 7.33193Z" fill="#1E3B89"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4478_6545">
                        <rect width="25.9259" height="25" fill="white" transform="translate(0.0371094)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <p>
                    {{defaultName}}
                    <span v-if="defaultName === 'Калорійність'">,<br>ккал</span>
                    <span v-if="defaultName === 'Білок'">,<br>грам</span>
                </p>
            </div>
            <div class="counter">
                <div class="minus"  @mousedown="startMinus" @mouseleave="stop" @mouseup="stop"
                   @touchstart="startMinus" @touchend="stop" @touchcancel="stop" @click="lowerValue()">
                    <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.87598 2.37425C1.38357 2.37425 0.992188 1.97093 0.992188 1.47852C0.992188 0.98611 1.38357 0.594727 1.87598 0.594727H25.0957C25.5881 0.594727 25.9922 0.98611 25.9922 1.47852C25.9922 1.97093 25.5881 2.37504 25.0957 2.37504H1.87598V2.37425Z" fill="#00ACE6"/>
                    </svg>
                </div>
                <div class="value">
                    <div v-if="defaultName === 'Вік'">
                        <span v-if="!value">роки</span>
                        <span v-else-if="value < 1"><1 року</span>
                        <span v-else-if="value === 1">1 рік</span>
                        <span v-else-if="value < 5">{{value}} роки</span>
                        <span v-else>{{value}} років</span>
                    </div>
                    <div v-if="defaultName === 'Вага'">
                        <span>{{value}} кг</span>
                    </div>
                    <div v-if="defaultName === 'Зріст'">
                        <span>{{value}} см</span>
                    </div>
                    <div v-if="defaultName === 'Калорійність'">
                        <the-mask
                            class="mask"
                            :mask="['#','##','###','####','#####']"
                            v-model="value"
                            @input="e => $emit('selectedDataChange', e)"
                        ></the-mask>
                    </div>
                    <div v-if="defaultName === 'Білок'">
                        <the-mask
                            class="mask"
                            :mask="['#','##','###']"
                            v-model="value"
                            @input="e => $emit('selectedDataChange', e)"
                        ></the-mask>
                    </div>
                </div>
                <div class="plus" @mousedown="startPlus" @mouseleave="stop" @mouseup="stop"
                   @touchstart="startPlus" @touchend="stop" @touchcancel="stop" @click="moreValue()">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.883316 13.3897C0.391172 13.3897 0 12.9858 0 12.4936C0 12.0015 0.391172 11.6103 0.883316 11.6103H11.5968V0.896037C11.5976 0.403893 12.0015 0 12.4936 0C12.9858 0 13.377 0.403893 13.377 0.896037V11.6103H24.0904C24.5826 11.6103 24.9865 12.0015 24.9865 12.4936C24.9865 12.9858 24.5826 13.3897 24.0904 13.3897H13.377V24.104C13.377 24.5961 12.9858 25 12.4936 25C12.0015 25 11.5976 24.5961 11.5976 24.104V13.3897H0.883316Z" fill="#00ACE6"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {TheMask} from 'vue-the-mask';

export default {
    components: {
        "the-mask": TheMask
    },
    props : ['value', 'defaultName', 'step', 'age', 'minAge', 'maxAge', 'minWeight', 'maxWeight', 'minHeight', 'maxHeight', 'isDisabled', 'isManual'],
    data : () => ({
        interval : null
    }),
    methods : {
        startMinus(){
            if (!this.interval && this.defaultName !== 'Вік') {
                this.interval = setInterval(() => {
                    this.lowerValue();
                }, 50)
            }
        },
        startPlus() {
            if (!this.interval && this.defaultName !== 'Вік') {
                this.interval = setInterval(() => {
                    this.moreValue();
                }, 50)
            }
        },
        stop(){
            clearInterval(this.interval)
            this.interval = false
        },
        lowerValue(){
            if(this.defaultName === 'Вік'){
                if(this.value > 0.5 && this.value > this.minAge){
                    let result;

                    if(this.value < 3)
                        result = this.value - 0.5;
                    else{
                        result = this.value - 1;
                    }

                    this.$emit('selectedDataChange', result);
                } else {
                    this.$emit('selectedDataChange', 0);
                }
            } else if (this.defaultName === 'Вага') {
                let minWeight = this.minWeight < this.getMinWeight() ? this.getMinWeight() : this.minWeight;

                if(this.value > minWeight){
                    let result = +(this.value - this.step).toFixed(1);
                    this.$emit('selectedDataChange', result);
                } else {
                    this.$emit('selectedDataChange', 0);
                }
            } else if (this.defaultName === 'Зріст') {
                let minHeight = this.minHeight;

                if(this.value > minHeight){
                    let result = +(this.value - this.step).toFixed(1);
                    this.$emit('selectedDataChange', result);
                } else {
                    this.$emit('selectedDataChange', 0);
                }
            } else if (this.defaultName === 'Калорійність' || this.defaultName === 'Білок') {
                if(this.value > 0){
                    let result = +(this.value - this.step).toFixed(1);
                    this.$emit('selectedDataChange', result);
                } else {
                    this.$emit('selectedDataChange', 0);
                }
            }
        },
        getMaxWeight(){
            if(this.age <= 3)
                return 20;
            else if(this.age <= 10)
                return 44;
            else
                return 85;
        },
        getMinWeight(){
            if(this.age <= 3)
                return 3;
            else if(this.age <= 10)
                return 10;
            else
                return 10;
        },
        moreValue(){
            if(this.defaultName === 'Вік'){

                if(!this.value){
                    if(this.minAge < 1){
                        this.value = 0.5;
                        let result = 0.5;
                        this.$emit('selectedDataChange', result);
                    }
                    else{
                        this.value = this.minAge;
                        let result = this.minAge;
                        this.$emit('selectedDataChange', result);
                    }
                }

                else if(this.value < this.maxAge){
                    let result;
                        
                    if(+this.value > 1.5){
                        result = this.value + 1;
                    }
                    else{
                        result = this.value + 0.5;
                    }

                    this.$emit('selectedDataChange', result);
                }
            }

            if (this.defaultName === 'Вага') {
                if(!this.value){
                    let minWeight = this.minWeight < this.getMinWeight() ? this.getMinWeight() : this.minWeight;
                    this.value = minWeight;
                    let result = minWeight;
                    this.$emit('selectedDataChange', result);
                }

                else if(this.value < this.maxWeight){
                    let result = +(this.value + this.step).toFixed(1);
                    this.$emit('selectedDataChange', result);
                }
            }

            if (this.defaultName === 'Зріст') {
                if(!this.value){
                    let minHeight = this.minHeight;
                    this.value = minHeight;
                    let result = minHeight;
                    this.$emit('selectedDataChange', result);

                }

                else if(this.value < this.maxHeight){
                    let result = +(this.value + this.step).toFixed(1);
                    this.$emit('selectedDataChange', result);
                }
            }
            
            if (this.defaultName === 'Калорійність' || this.defaultName === 'Білок') {
                let result = +(+this.value + +this.step).toFixed(1);
                this.$emit('selectedDataChange', result);
            }      
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../style/vars";

.item {
    border-bottom: 1px solid rgba(30, 59, 137, 0.25);

    * {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;  
    }

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .visible-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 20px;
        cursor: pointer;

        .icon-text {
            display: flex;
            align-items: center;

            width: 45%;

            padding: 10px 0;

            border-right: 1px solid rgba(30, 59, 137, 0.25);

            @media (max-width: 500px) {
                width: 60%;
            }

            .icon {
                margin-right: 15px;
                max-width: 30px;
                max-height: 33px;
                width: 100%;

                svg {
                    height: auto;
                    display: block;
                }
            }

            p {
                color: #1E3B89;
                font-size: 15px;
                font-family: $fontR;
            }
        }

        .counter{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 60%;

            .value{
                width: 80px;

                font-size: 15px;
                line-height: 20px;
                color: #1E3B89;
                text-align: center;
            }

            .minus{
                display: flex;
                align-items: center;
                padding: 8px 0;
            }
        }

        .mask {
            border: none;
            outline: none;

            max-width: 100%;
            width: 80px;

            padding: 0 10px;

            font-size: 15px;
            line-height: 20px;
            color: #1E3B89;
            text-align: center;
        }
    }
}
</style>