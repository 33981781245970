<template>
  <div class="legal">

    <div class="content">
      <div class="header-image">
        <div class="text top">
          <div class="main-text">
            Юридична клініка
          </div>
          <div class="under-text">
            відповіді<br> на запитання
          </div>
        </div>
        <div class="text bottom">
          22.10.2021
        </div>
      </div>

      <div class="all-questions-navigation-block">
        <div class="visible-item" :class="!isOpen ? '': 'active'" @click="isOpen = !isOpen">
          <div class="icon-text">
            <div class="icon">
              <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.569 15.664C26.569 22.6083 21.1504 28.21 14.5 28.21C7.84957 28.21 2.43103 22.6083 2.43103 15.664C2.43103 8.71966 7.84957 3.11799 14.5 3.11799C21.1504 3.11799 26.569 8.71966 26.569 15.664Z"
                    stroke="#1E3B89" stroke-width="0.862069"/>
                <path
                    d="M13.1743 18.3586V17.8899C13.1743 17.3528 13.272 16.906 13.4673 16.5496C13.6626 16.1931 14.0044 15.8245 14.4927 15.4436C15.0737 14.9846 15.4473 14.6282 15.6133 14.3743C15.7842 14.1204 15.8696 13.8176 15.8696 13.4661C15.8696 13.0559 15.7329 12.741 15.4595 12.5212C15.186 12.3015 14.793 12.1917 14.2803 12.1917C13.8164 12.1917 13.3867 12.2576 12.9912 12.3894C12.5957 12.5212 12.21 12.6799 11.834 12.8655L11.2188 11.5764C12.21 11.0247 13.272 10.7488 14.4048 10.7488C15.3618 10.7488 16.1211 10.9832 16.6826 11.4519C17.2441 11.9207 17.5249 12.5676 17.5249 13.3928C17.5249 13.759 17.4712 14.0862 17.3638 14.3743C17.2563 14.6575 17.0928 14.9285 16.873 15.1873C16.6582 15.446 16.2847 15.783 15.7524 16.198C15.2983 16.5544 14.9932 16.8499 14.8369 17.0842C14.6855 17.3186 14.6099 17.6335 14.6099 18.0291V18.3586H13.1743ZM12.874 20.6951C12.874 19.9578 13.2329 19.5891 13.9507 19.5891C14.3022 19.5891 14.5708 19.6868 14.7563 19.8821C14.9419 20.0725 15.0347 20.3435 15.0347 20.6951C15.0347 21.0417 14.9395 21.3176 14.749 21.5227C14.5635 21.7229 14.2974 21.823 13.9507 21.823C13.604 21.823 13.3379 21.7253 13.1523 21.53C12.9668 21.3298 12.874 21.0515 12.874 20.6951Z"
                    fill="#1E3B89"/>
              </svg>
            </div>
            <p>Всі питання</p>
          </div>
          <div class="arrow" :class="!isOpen ? '': 'rotated'">
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.022 1L5.50928 5.53548" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.50924 5.53548L1 1" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <transition name="fade">
          <div class="container" v-if="isOpen">
            <div :data-question="q.number" class="hidden-item" v-for="(q, index) in questions"
                 :class="activeQuestionNumber == q.number ? 'active' : ''" @click="goToQuestion(q.number)" :key="index">
              <div class="number">
                {{ q.number }}.
              </div>
              <div class="text">
                {{ q.question }}
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="question-answers-block">
        <div
            v-for="(q, index) in questions"
            :data-answer="q.number" class="item"
            :key="index"
        >
          <div class="number-block">
            <div class="number">
              {{ q.number }}
            </div>
          </div>
          <div class="question">
            <div class="text">
              {{ q.question }}
            </div>
            <div v-if="q.context !== null" class="context">
              <div class="context__title" @click="open(q)">
                Подробиці
                <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    :class="q.context.openContext ? 'rotate' : ''"
                >
                  <path
                      d="M1.75678 1.24322L6.26947 5.7787"
                      stroke="#00ACE6"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                  <path
                      d="M6.26951 5.7787L10.7787 1.24323"
                      stroke="#00ACE6"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div v-show="q.context.openContext" class="context__text" style="{ color: red, }"
                   v-html="q.context.text"></div>
            </div>
            <p
                class="text answer"
                v-html="activeMore === q.number ? q.answer : q.number === '6' || q.number === '7' || q.number === '9' ? q.answer.substring(0, 250) + '...' : q.answer.substring(0, 150) + '...'"
            >
            </p>
            <button
                type="button"
                class="more-btn"
                :class="{active: activeMore === q.number}"
                @click="showMore(q.number)"
            >
              {{ activeMore !== q.number ? 'Показати повністю' : 'Згорнути' }}
              <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.44645 4.06836L4.44531 1.28317" stroke="#00ACE6" stroke-width="1.5" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.44562 1.28317L1.44678 4.06836" stroke="#00ACE6" stroke-width="1.5" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>

        </div>
        <div class="thanks">
          <img src="@/assets/img/LegalClinic_Answer_October_2021/footer-img.svg">
          За надання юридичної консультації висловлюємо особливу подяку медичному адвокату, Голові Ради Київської
          міської профспілки працівників охорони здоров’я
          <a href="https://www.facebook.com/sergiy.kubanskyy" target="_blank">Сергію Кубанському</a>
        </div>
      </div>


      <div class="partners-block">
        <div class="card-image">
          <img src="../assets/img/partners-img.png" alt="partners image">
        </div>
        <div class="card-content">
          <div class="title">
            Наш партнер
          </div>
          <div class="text">
            Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться.
          </div>
        </div>
        <div class="card-footer">
          <a href="" target="_blank">ПЕРЕЙТИ ></a>
        </div>
      </div>

      <div class="footer">
        <p class="footer-h1">Безумовна перевага у годуванні дитини перших років життя надається грудному
          вигодовуванню.</p>
        <p class="reg-text">
          Згідно рекомендацій МОЗ України, грудне молоко має бути основним харчуванням малюка на першому році життя
          навіть після введення прикорму.
        </p>
        <p class="reg-text">ВООЗ рекомендує зберігати грудне вигодовування до 2 років.</p>
        <p class="reg-text">Nutricia підтримує зазначені рекомендації та попереджає, що штучне вигодовування може
          негативно вплинути на грудне.</p>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "legal-clinic-2021",
  data: () => ({


    isOpen: false,
    activeQuestionNumber: 1,
    activeMore: null,

    questions: [
      {
        number: '1',
        question: 'Як діяти, якщо наказ МОЗ України про зміни до календаря профілактичних щеплень суперечить наказу про порядок їх проведення?',
        answer: `
                        <p>Наказ МОЗ України від 03.07.2020 №1510 «Про затвердження Порядку проведення епідеміологічного нагляду за дифтерією» вносить зміни до наказу МОЗ України від 16.09.2011 №595 «Про порядок проведення профілактичних щеплень в Україні та контроль якості й обігу медичних імунобіологічних препаратів» та безпосередньо до Календаря профілактичних щеплень в Україні.</p>
                        <p>Необхідно керуватись <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z1159-11#Text" target="_blank">чинною редакцією наказу МОЗ України від 16.09.2011 №595</a> «Про порядок проведення профілактичних щеплень в Україні та контроль якості й обігу медичних імунобіологічних препаратів», що враховує останні внесені зміни.</p>
                    `,
        context: {
          text: `<p><a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z1159-11/ed20191210#Text" target="_blank">Попередня редакція наказу МОЗ України від 16.09.2011 №595</a> «Про порядок проведення профілактичних щеплень в Україні та контроль якості й обігу медичних імунобіологічних препаратів» містила положення про <strong>чотириразове</strong> щеплення проти кашлюку дітей до 7 років з порушенням календаря, а <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z1011-20#Text" target="_blank">наказ МОЗ України від 03.07.2020 №1510</a> «Про затвердження Порядку проведення епідеміологічного нагляду за дифтерією»  встановлює <strong>триразове</strong> щеплення від кашлюку для таких дітей</p>`,
          openContext: false,
        }
      },
      {
        number: '2',
        question: 'Чи впливає дозвіл на самостійне працевлаштування після здобуття вищої освіти за рахунок коштів держбюджету на підвищення кваліфікаційної категорії у майбутньому або інші сфери?',
        answer: `
                        <p>Керівники закладів охорони здоров'я, незалежно від форм власності й відомчого підпорядкування, можуть приймати на роботу випускників медичних (фармацевтичних) закладів освіти I-IV рівнів акредитації, що навчалися за державним замовленням, з довідкою про надання можливості самостійного працевлаштування за встановленою формою.</p>
                        <p>Надання дозволу на самостійне працевлаштування випускником не створює для нього обов’язку з трирічного відпрацювання у конкретного роботодавця. Навпаки, створює право вимоги випускника, який отримав довідку про надання можливості самостійного працевлаштування, протягом навчання в інтернатурі та трьох років після її закінчення, щодо оформлення керівником закладу охорони здоров'я йому направлення на роботу.</p>
                        <p>Проходження атестації і виконання вимог безперервного професійного розвитку містять єдині вимоги до всіх лікарів, що диференціюються залежно від періодів роботи на певних посадах та за конкретними спеціальностями, й не залежать від форми фінансування отриманої освіти її здобувачем.</p>
                    `,
        context: {
          text: `<p>На розподіленому місці не було вакансій і надали відкріплення. У іншому державному закладі відпрацьовано 2 роки. Після звільнення за згодою сторін працюю у приватній клініці</p>`,
          openContext: false,
        }
      },
      {
        number: '3',
        question: 'Чи повинен лікар первинної ланки наново укладати декларації з пацієнтами після виходу з декрету, якщо під час його відсутності пацієнтів обслуговував інший лікар?',
        answer: `
                        <p>Відповідно до вимог Порядку надання первинної медичної допомоги, затвердженого <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z0348-18#Text" target="_blank">наказом МОЗ України від 19.03.2018 №504</a>, первинна медична допомога (ПМД) надається пацієнтам безперервно.</p>
                        <p>Перебування лікаря ПМД у відпустках (у зв'язку з вагітністю й пологами або для догляду за дитиною до досягнення нею трирічного віку чи будь-яких інших) не тягне за собою припинення трудових відносин між лікарем ПМД та закладом охорони здоров’я. Виключається можливість автоматичного відкріплення пацієнтів чи припинення декларації з лікарем ПМД, що тимчасово відсутній. Навпаки, на час такої відсутності лікаря ПМД заклад охорони здоров’я має забезпечити йому заміну. Всі пацієнти практики цього лікаря ПМД мають бути поінформовані закладом охорони здоров’я про тимчасового нового лікаря завчасно: за тиждень, якщо заміна передбачувана.</p>
                        <p>При виході лікаря ПМД з відпустки він продовжує здійснювати медичне обслуговування пацієнтів за укладеними раніше деклараціями. Повторне укладення декларацій не вимагається.</p>
                    `,
        context: null,
      },
      {
        number: '4',
        question: 'Яку заробітну плату отримуватиме педіатр первинної ланки після виходу з декрету, якщо за час його відсутності 400 з 900 пацієнтів переуклали декларації з іншими лікарями?',
        answer: `
                        <p>Питання оплати праці медичних працівників має різне правове регулювання, що залежить від організаційно-правової форми закладу охорони здоров’я, в якому він працює.</p>
                        <p>Якщо заклад функціонує як підприємство (комунальне, комунальне некомерційне тощо), то правове регулювання оплати праці всього персоналу (в т.ч. і лікарів) встановлюється виключно у колективному договорі відповідно до домовленості його сторін.</p>
                        <p>Норми ст. 97 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/322-08#Text" target="_blank">КЗпП України</a> та ст. 15 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/108/95-%D0%B2%D1%80#Text" target="_blank">Закону України «Про оплату праці»</a> чітко встановлюють, що форми й системи оплати праці, норми праці, розцінки, тарифні сітки, схеми посадових окладів, умови запровадження й розміри надбавок, доплат, премій, винагород та інших заохочувальних, компенсаційних і гарантійних виплат встановлюються підприємствами у колективному договорі з дотриманням норм і гарантій, передбачених законодавством, генеральною, галузевими (міжгалузевими) й територіальними угодами.</p>
                        <p>Якщо колективний договір на підприємстві не укладено, роботодавець зобов'язаний погодити ці питання з виборним органом первинної профспілкової організації (профспілковим представником), що представляє інтереси більшості працівників.</p>
                        <p>Для отримання відповіді на запитання слід звернутись до норм колективного договору закладу. Кількість укладених декларацій не може бути єдиним критерієм визначення заробітної плати у колективному договорі, адже розмір зарплати залежить від складності й умов виконуваної роботи, професійно-ділових якостей працівника, результатів його праці й господарської діяльності підприємства.</p>
                    `,
        context: null,
      },
      {
        number: '5',
        question: 'Чи має право на додаткову відпустку працівник, що працює за внутрішнім сумісництвом (дитячий інфекціоніст і дитячий гастроентеролог)?',
        answer: `
                        <p>Сумісництвом вважається виконання працівником, крім своєї основної, іншої регулярної оплачуваної роботи на умовах трудового договору у вільний від основної роботи час на тому самому або іншому підприємстві, в установі, організації або в громадянина (підприємця, приватної особи). Чинне законодавство не диференціює зовнішнього чи внутрішнього сумісництва.</p>
                        <p>На сумісника поширюються всі права, гарантії та обов’язки, передбачені чинним законодавством, колективним договором i правилами внутрішнього трудового розпорядку підприємств, установ та організацій. Сумісники виконують свої трудові обов’язки на умовах неповного робочого часу. Відповідно до норм частини 3 ст. 56 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/322-08#Text" target="_blank">КЗпП України</a>, робота на умовах неповного робочого часу не тягне за собою жодних обмежень обсягу трудових прав працівників. Оскільки всі громадяни, які перебувають у трудових відносинах, мають право на відпустку (що закріплено нормами ст. 2 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/504/96-%D0%B2%D1%80#Text" target="_blank">Закону України «Про відпустки»</a>), працівники-сумісники, які уклали трудовий договір на роботу за сумісництвом і перебувають у трудових відносинах, також мають право на щорічну відпустку – як основну, так і додаткові. Зокрема, і право на щорічну додаткову відпустку за особливий характер праці.</p>
                        <p>Визначення права працівника на щорічну додаткову відпустку за особливий характер праці здійснюється, згідно з вимогами:</p>
                        <p> – ст. 8 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/504/96-%D0%B2%D1%80#Text" target="_blank">Закону України «Про відпустки»</a>;</p>
                        <p> – Списку виробництв, робіт, професій і посад працівників, робота яких пов'язана з підвищеним нервово-емоційним та інтелектуальним навантаженням або виконується в особливих природних географічних і геологічних умовах та умовах підвищеного ризику для здоров'я, що дає право на щорічну додаткову відпустку за особливий характер праці, затвердженого <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/1290-97-%D0%BF#Text" target="_blank">постановою Кабінету Міністрів України від 17.11.1997 №1290</a>;</p>
                        <p> – Порядку застосування з Списку виробництв, робіт, професій і посад працівників, робота яких пов'язана з підвищеним нервово-емоційним та інтелектуальним навантаженням або виконується в особливих природних географічних і геологічних умовах та умовах підвищеного ризику для здоров'я, що дає право на щорічну додаткову відпустку за особливий характер праці, затвердженого <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z0057-98#Text" target="_blank">наказом Мінпраці України від 30.01.1998 №16</a>.</p>
                        <p>Додаткова відпустка за особливий характер праці надається пропорційно до фактично відпрацьованого часу. У розрахунок часу, що надає право працівникові на таку відпустку, зараховуються дні, коли він фактично був зайнятий на роботах з особливим характером праці не менш як половину тривалості робочого дня, установленого для такої категорії працівників. Гранична тривалість робочого часу працівників у сфері охорони здоров’я встановлена нормами <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z0696-06#Text" target="_blank">наказу МОЗ України від 25.05.2006 №319</a> «Про затвердження норм робочого часу для працівників закладів та установ охорони здоров'я». Однак, цей наказ не встановлює тривалості робочого дня, а лише норми робочого часу на тиждень. Структура робочого часу медичних працівників імперативно жодним нормативно-правовим актом не визначена, а посадова інструкція будь-якого медичного працівника містить виключно положення щодо безпосередньої трудової функції та видів посадових обов’язків.</p>
                        <p>Лише робота на посаді, передбаченій <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/1290-97-%D0%BF#Text" target="_blank">постановою Кабінету Міністрів України від 17.11.1997 №1290</a>, не менше половини тривалості робочого дня, встановленого для конкретного працівника за професію й посадою, може надавати право на додаткову щорічну відпустку за особливий характер праці. Безпосередня тривалість роботи такого працівника може визначатись табелем обліку робочого часу.</p>
                    `,
        context: null,
      },
      {
        number: '6',
        question: 'Скільки днів додаткової відпустки належить матері двох дітей до 15 років, якщо одна дитина виховується матір’ю без участі батька?',
        answer: `
                        <p>Норми ст. 19 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/504/96-%D0%B2%D1%80#Text" target="_blank">Закону України «Про відпустки»</a> чітко не визначають сукупність кількох підстав для набуття права на додаткову соціальну відпустку тривалістю понад десять календарних днів для одного з батьків. З урахуванням правової експертизи цього питання, проведеної Мінпраці України разом з Мін'юстом України й Головним науково-експертним управлінням Апарату Верховної Ради України, кожну підставу, визначену ч. 1 цієї статті, є правомірним вважати окремою підставою, а саме:</p>
                        <p> – один з батьків, який має двох або більше дітей віком до 15 років;</p>
                        <p> – один з батьків, який має дитину з інвалідністю;</p>
                        <p> – один з батьків, який усиновив дитину;</p>
                        <p> – матір (батько), особи з інвалідністю з дитинства підгрупи А I групи;</p>
                        <p> – одинока мати;</p>
                        <p> – батько, або особа з інвалідністю з дитинства підгрупи А I групи, який виховує їх без матері (у тому числі у разі тривалого перебування матері в лікувальному закладі);</p>
                        <p> – особа, яка взяла дитину під опіку або особу з інвалідністю з дитинства підгрупи А I групи;</p>
                        <p> – прийомні батьки.</p>
                        <p>За наявності декількох підстав для надання цієї відпустки її загальна тривалість не може перевищувати 17 календарних днів. Одинокою матір’ю, згідно з чинним законодавством, слід вважати жінку:</p>
                        <p> – яка не перебуває у шлюбі й у свідоцтві про народження дитини якої відсутній запис про батька дитини або запис про батька зроблено в установленому порядку за вказівкою матері;</p>
                        <p> – вдову;</p>
                        <p> – іншу жінку, яка виховує і утримує дитину сама.</p>
                        <p>У разі наявності у жінки двох підстав: (1) наявність двох дітей віком до 15 років, (2) з яких до однієї дитини жінка має статус одинокої матері, така жінка матиме дві підстави для отримання додаткової відпустки тривалістю 17 календарних днів, до моменту виповнення одній дитині 15 років.</p>
                    `,
        context: null,
      },
      {
        number: '7',
        question: 'Чи може лікар відмовити в обслуговуванні й попередити про анулювання декларації з пацієнтом, який безпідставно ганьбить лікаря?',
        answer: `
                        <p>Відповідно до норм ст. 34 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/2801-12#Text" target="_blank">Закону України «Основи законодавства України про охорону здоров'я»</a> та Порядку вибору лікаря, який надає первинну медичну допомогу (ПМД), затвердженого <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z0347-18#Text" target="_blank">наказом МОЗ України від 19.03.2018 №503</a>, лікар має право відмовитися від подальшого ведення пацієнта лише, якщо останній не виконує медичних приписів або правил внутрішнього розпорядку закладу охорони здоров'я, за умови, що це не загрожуватиме життю хворого й здоров'ю населення.</p>
                        <p>Вказане може бути реалізоване лікарем ПМД шляхом подання заяви керівнику закладу ПМД про відмову від подальшого ведення пацієнта у зв’язку з тим, що пацієнт не виконує медичних приписів або правил внутрішнього розпорядку надавача ПМД. Правила внутрішнього розпорядку закладу повинні містити чіткий і вичерпний перелік обов’язків пацієнта, в т.ч. і щодо взаємодії з персоналом закладу й дотримання етичних норм. Факт порушення правил або невиконання медичних приписів повинен бути зафіксований документально. Це може бути оформлено за допомогою акту, складеного в довільній формі за підписом лікаря ПМД і щонайменше двох свідків, виключно з кола медичного персоналу цього ж закладу.</p>
                        <p>Практична реалізація наведеного можлива лише у разі, коли обов’язки пацієнта були документально підтверджені, а сам пацієнт був поінформований про свої обов’язки. Заклад ПМД протягом одного місяця з дати припинення декларації повинен повідомити про це пацієнта (або його законного представника) за допомогою бажаного способу зв'язку, зазначеного пацієнтом у декларації. Декларація припиняється через десять календарних днів з дати повідомлення надавачем ПМД про її припинення.</p>
                    `,
        context: null,
      },
      {
        number: '8',
        question: 'Чи має право адміністрація медичного закладу відсторонити працівника від роботи у зв’язку з відмовою від вакцинації проти COVID-19?',
        answer: `
                        <p>Відсторонення працівників від роботи допускається виключно у разі: появи на роботі в нетверезому стані, у стані наркотичного або токсичного сп'яніння; відмови або ухилення від обов'язкових медичних оглядів, навчання, інструктажу й перевірки знань з охорони праці та протипожежної охорони; в інших випадках, передбачених законодавством.</p>
                        <p>Норми ст. 12 <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/1645-14#Text" target="_blank">Закону України «Про захист населення від інфекційних хвороб»</a> встановлюють обов’язок працівників окремих професій, виробництв і організацій, діяльність яких може призвести до зараження цих працівників та/або поширення ними інфекційних хвороб, підлягати профілактичним щепленням також проти інфекційних хвороб, що не передбачені календарем обов’язкових щеплень. Перелік таких професій, виробництв та організацій, працівники яких підлягають обов'язковим профілактичним щепленням проти інших відповідних інфекційних хвороб, встановлюється МОЗ України.</p>
                        <p><a class="ansver-link" href="https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-04102021--2153-pro-zatverdzhennja-pereliku-profesij-virobnictv-ta-organizacij-pracivniki-jakih-pidljagajut-obovjazkovim-profilaktichnim-scheplennjam" target="_blank">Наказ МОЗ України від 04.10.2021 №2153</a> «Про затвердження Переліку професій, виробництв та організацій, працівники яких підлягають обов'язковим профілактичним щепленням», яким затверджено перелік професій, для яких щеплення проти COVID-19 є обов’язковим, не містить посад працівників закладів охорони здоров’я. Такий обов’язок щодо вакцинації проти COVID-19 передбачено виключно для працівників:</p>
                        <p> – центральних органів виконавчої влади та їхніх територіальних органів;</p>
                        <p> – місцевих державних адміністрацій та їхніх структурних підрозділів;</p>
                        <p> – закладів вищої, післядипломної, фахової передвищої, професійної (професійно-технічної), загальної середньої, у тому числі спеціальних, дошкільної, позашкільної освіти, закладів спеціалізованої освіти та наукових установ незалежно від типу та форми власності.</p>
                        <p>Керівник закладу охорони здоров’я не має права застосовувати будь-які заходи впливу до працівників, якщо останні не бажають проходити вакцинацію проти COVID-19. У разі незаконного відсторонення працівника від роботи у зв’язку з відмовою від вакцинації проти COVID-19 він може оскаржити таке відсторонення у судовому порядку.</p>
                    `,
        context: null,
      },
      {
        number: '9',
        question: 'Чи правомірне винесення догани й депреміювання за відмову від виклику додому до дитини з температурою через зайнятість іншими пацієнтами відповідно до черги?',
        answer: `
                        <p>Відповідно до норм Порядку надання первинної медичної допомоги, затвердженого <a class="ansver-link" href="https://zakon.rada.gov.ua/laws/show/z0348-18#Text" target="_blank">наказом МОЗ України від 19.03.2018 №504</a>, первинна медична допомога (ПМД) надається під час особистого прийому пацієнта за місцем надання ПМД.</p>
                        <p>Лікар з надання ПМД може прийняти рішення про надання окремих послуг ПМД за місцем проживання (перебування) пацієнта або з використанням засобів телекомунікації відповідно до режиму роботи надавача ПМД. Рішення про надання ПМД за місцем проживання (перебування) пацієнта не може прийматися лише на підставі віддаленості його місця проживання (перебування) від місця надання ПМД та/або відсутності у пацієнта коштів на проїзд до місця надання ПМД.</p>
                        <p>За загальним правилом, вирішення питання про надання медичних послуг з ПМД за місцем проживання (перебування) пацієнта є дискретним повноваженням самого лікаря з надання ПМД. Інше також може бути передбачено (зокрема, у трудовому договорі між лікарем з надання ПМД та закладом охорони здоров’я) й визначено, як його істотну умову, та, наприклад, закріплено у посадовій інструкції лікаря.</p>
                        <p>У разі незгоди зі застосованим заходом дисциплінарного стягнення працівник має право у встановленому порядку оскаржити такі дії роботодавця до комісії з трудових спорів або суду.</p>
                    `,
        context: {
          text: `<p>Матері 2-річної дитини з температурою 39 °С було запропоновано дати жарознижуюче та після зниження температури прибути на огляд поза чергою або викликати швидку допомогу. Матір поскаржилася на урядову «гарячу лінію»</p>`,
          openContext: false,
        }
      },
      {
        number: '10',
        question: 'Чи правомірна заборона адміністрації й профспілок працювати добовими змінами у дитячій реанімації лікарям, які не є членами профспілок?',
        answer: `
                        <p>Трудовий розпорядок у закладах охорони здоров’я визначається правилами внутрішнього трудового розпорядку, які затверджуються трудовими колективами за поданням роботодавця й виборного органу первинної профспілкової організації на основі типових правил. Сфера дії цього локального нормативно-правового акту поширюється на всіх працівників закладу незалежно від виду трудового договору, виконуваної роботи, посади тощо.</p>
                        <p>Типовими для сфери охорони здоров’я є Галузеві правила внутрішнього трудового розпорядку для працівників закладів, установ, організацій та підприємств системи охорони здоров'я України, затверджені <a class="ansver-link" href="https://zakon.rada.gov.ua/rada/show/vb204282-00#Text" target="_blank">наказом МОЗ України від 18.12.2000 №204-о</a>.</p>
                        <p>Пунктом 16 вказаних правил визначено, що в закладах охорони здоров’я тривалість нічної роботи дорівнює денній і становить до 12 годин у зміну. Призначати працівника на роботу протягом двох змін підряд забороняється. За рішенням трудового колективу дозволяється встановлювати тривалість робочої зміни до 24 годин (крім водіїв санітарного транспорту). У кожному конкретному випадку питання встановлення тому чи іншому працівнику тривалості робочої зміни до 24 годин має вирішуватись лише за згодою працівника й профспілкового органу закладу охорони здоров'я.</p>
                        <p>Обмеження щодо встановлення робочої зміни до 24 годин може запроваджуватись у закладах охорони здоров’я, проте воно не може бути зумовлене належністю або неналежністю працівника до профспілкової організації.</p>
                    `,
        context: null,
      },
      {
        number: '11',
        question: 'Як можна відновитись на робочому місці після звільнення у зв’язку з незгодою працювати за новими умовами, які було введено без попередження?',
        answer: `
                        <p>Про зміну істотних умов праці (систем та розмірів оплати праці, пільг, режиму роботи, встановлення або скасування неповного робочого часу, суміщення професій, зміну розрядів і найменування посад та інших) працівник повинен бути повідомлений не пізніше ніж за два місяці. У разі порушення роботодавцем вказаних приписів, працівник має право оскаржити зміну істотних умов праці та/або звільнення з роботи, шляхом подання трудового спору до суду.</p>
                        <p>У разі звільнення без законної підстави або незаконного переведення на іншу роботу, працівник повинен бути поновлений на попередній роботі органом, який розглядає трудовий спір. Рішення суду про поновлення працівника на попередній роботі, після набрання ним законної сили, може бути добровільно виконане самим роботодавцем, або у примусовому порядку, шляхом звернення працівника до органів виконавчої служби.</p>
                        <p>У разі невиконання без поважних причин у встановлений виконавцем строк рішення про поновлення на роботі, виконавець виносить постанову про накладення штрафу на роботодавця та попередження про кримінальну відповідальність, й встановлює новий строк виконання. У разі повторного невиконання роботодавцем без поважних причин рішення про поновлення працівника, виконавець надсилає органу досудового розслідування повідомлення про вчинення таким роботодавцем кримінального правопорушення та вживає заходів примусового виконання рішення з поновлення працівника.</p>
                    `,
        context: {
          text: `<p>При об'єднанні відділень без завчасного попередження змінено графік роботи (з'явилися добові чергування). Новий графік оскаржено у заяві, поданій на ім'я директора лікарні. У відповідь працівника залишили працювати у ліквідованому відділенні, де він лишився єдиним співробітником. Згодом було повідомлено про скорочення до 0,25 ставки й звільнено у зв'язку з незгодою</p>`,
          openContext: false,
        }
      },
    ]
  }),

  methods: {
    open(q) {
      q.context.openContext = !q.context.openContext;
    },
    goToQuestion(number) {
      this.isOpen = false;

      let answers = document.querySelectorAll('.question-answers-block .item');

      answers.forEach(element => {
        let answerNumber = element.getAttribute('data-answer');
        if (answerNumber === number) {
          const duration = 500;
          const target = element;

          const top = target.getBoundingClientRect().top;
          const startPos = window.pageYOffset;
          const diff = top - 400;

          let startTime = null;
          let requestId;

          const loop = function (currentTime) {
            if (!startTime) {
              startTime = currentTime;
            }
            const time = currentTime - startTime;

            const percent = Math.min(time / duration, 1);
            setTimeout(() => {
              window.scrollTo(0, startPos + diff * percent);
            }, 200);

            if (time < duration) {
              requestId = window.requestAnimationFrame(loop);
            } else {
              window.cancelAnimationFrame(requestId);
            }
          };
          requestId = window.requestAnimationFrame(loop);
        }
      });

      this.activeQuestionNumber = number;
    },
    showMore(id) {
      if (this.activeMore !== id) {
        this.activeMore = id;
      } else {
        this.activeMore = null;
      }

      setTimeout(() => {
        document.getElementById(id).scrollIntoView({block: "start", behavior: "smooth"})
      }, 0);
    },
  }
}
</script>

<style lang="scss">
@import "../style/vars";

.ansver-link {
  border-bottom: 1px solid #1E3B89;
  font-weight: 600;
  color: #1E3B89;
}

.legal {
  height: 100%;
  position: relative;
  margin-top: 60px;

  .content {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;

    .header-image {
      position: relative;

      background: url('../assets/img/LegalClinic_Answer_October_2021/legal-img2.png') no-repeat center;
      height: 245px;
      background-size: cover;

      .text {
        font-weight: 300;
        font-size: 28px;
        line-height: 118.18%;

        text-align: left;

        color: #ffffff;

        padding-right: 20px;

        position: absolute;
        left: 15px;
      }

      .top {
        top: -71px;
        left: -40px;
        padding: 0;
        background: radial-gradient(66.6% 66.99% at 76.18% 104.39%, #34BAE7 0%, #2875B4 48.96%, #1E3B89 100%), linear-gradient(
                153deg, #1E3B89 35.54%, #245FA4 69.94%, #34BAE7 99.41%);
        border-radius: 50%;
        width: 230px;
        height: 230px;
        padding-top: 97px;
        padding-left: 55px;

        .main-text {
          border-bottom: 0.7px solid #FFFFFF;
          max-width: 136px;
          padding-bottom: 15px;
          font-weight: 700;
        }

        .under-text {
          text-transform: uppercase;
          margin-top: 10px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
        }
      }

      .legal .content .header-image .text {
        font-weight: 300;
        font-size: 28px;
        line-height: 118.18%;
        text-align: left;
        color: #ffffff;
        padding-right: 20px;
        position: absolute;
        left: 15px;

        .main-text {
          font-family: $fontR;
          font-size: 21px;
          line-height: 25px;

          text-transform: uppercase;
          border-bottom: 0.7px solid #FFFFFF;
        }

        .under-text {
          font-family: $fontL;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
        }

      }

      .bottom {
        bottom: 10px;

        font-size: 12px;
        line-height: 16px;
      }
    }

    .all-questions-navigation-block {
      position: sticky;
      z-index: 10;
      top: 60px;

      .visible-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 29px 15px 18px;

        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);

        .arrow {
          transition: transform 500ms ease;
        }

        .rotated {
          transform: rotate(-180deg);
          transition: transform 500ms ease;
        }


        .icon-text {
          display: flex;
          align-items: center;
          width: 100%;

          .icon {
            margin-right: 15px;
            max-width: 30px;
            max-height: 33px;
            width: 100%;

            svg {
              height: auto;
              display: block;
            }

          }

          p {
            color: #1E3B89;
            font-size: 15px;
            font-family: $fontR;
          }
        }
      }

      .active {
        background: #E0ECF7;
        border-bottom: 1px solid #CBDFF2;

        .hidden-item {
          background: #E0ECF7;
          box-shadow: 0px 14px 21px rgba(0, 0, 0, 0.08);
        }
      }

      .container {
        max-height: 320px;
        overflow: scroll;
        background: #E0ECF7;

        padding: 16px 25px 27px 22px;

        .active {
          .text {
            font-family: $fontB;
          }
        }

        .hidden-item {
          color: #1E3B89;
          font-size: 15px;
          line-height: 20px;

          display: flex;
          flex-direction: row;

          padding-bottom: 20px;


          .number {
            font-family: $fontB;

            padding-right: 9px;
          }
        }

        .hidden-item:last-child {
          padding-bottom: 0;
        }
      }
    }

    .question-answers-block {
      padding: 38px 15px 0px;

      .item {
        padding: 9px 0 0;
        background: #FFFFFF;
        border-radius: 4px;

        margin-bottom: 20px;

        padding-bottom: 25px;

        display: flex;
        flex-direction: column;

        .number-block {
          border-bottom: 1px solid #DAE3ED;
          padding: 0 20px;
          padding-bottom: 8px;

          .number {
            width: 34px;
            height: 34px;
            border-radius: 50%;

            border: 1px solid #9AB9D8;
            color: #9AB9D8;

            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
          }
        }

        .more-btn {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 12px;
          line-height: 130%;
          color: #00ACE6;
          margin-left: 14px;
          margin-top: 8px;

          svg {
            transform: rotate(180deg);
            transition: .4s;
            margin-left: 5px;
          }

          &.active {
            svg {
              transform: rotate(0);
            }
          }
        }

        .question {
          /*padding: 0 20px;
          padding-top: 14px;*/
          padding: 0;
          color: #1E3B89;
          margin-top: 30px;

          .text {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            padding: 0 14px;
            padding-bottom: 12px;
          }

          .answer {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            padding: 0 14px;

            p {
              padding-bottom: 10px;
            }

            p:last-child {
              padding-bottom: 0;
            }
          }

          .context {
            margin: 8px 0;

            &__title {
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #1e3b89;
              border-top: 1px solid #dae3ed;
              border-bottom: 1px solid #dae3ed;
              padding: 12px;
            }

            &__text {
              background: rgba(0, 172, 230, .1);
              padding: 14px 12px 16px 12px;

              p {
                font-size: 14px;

                a {
                  font-weight: 400;
                }
              }
            }

            .rotate {
              transform: rotate(180deg);

            }

            .icon {
              transition: transform 500ms ease, -webkit-transform 500ms ease;
            }
          }
        }

        .thanks {
          display: none;

          background: #9AB9D8;
          opacity: 0.6;
          border-radius: 4px;
          color: #1E3B89;

          margin-top: 20px;

          padding: 12px 34px 20px 20px;

          font-family: $fontR;
          font-size: 14px;
          line-height: 20px;

          a {
            color: #1E3B89;
            font-family: $fontB;
            text-decoration: underline;
          }
        }
      }

      .item:last-child {
        margin-bottom: 0;
      }
    }

    .thanks {
      display: block;
      padding: 10px 5px 10px 24px;
      border: 1px solid #1E3B89;
      margin: 25px auto;
      color: #1e3b89;
      border-radius: 4px;
      font-size: 11px;
      font-weight: 400;
      line-height: 130%;
      position: relative;
      width: 90%;

      a {
        font-weight: 700;
        border-bottom: 1px solid #1E3B89;
        color: #1e3b89;
      }

      img {
        position: absolute;
        top: calc(50% - 34px / 2);
        right: calc(100% - 35px / 2);

      }
    }

    .partners-block {
      padding: 0 15px;
      margin-bottom: 73px;
      border-radius: 4px;

      display: none;

      .card-image {
        background: #ffffff;

        img {
          width: 100%;
        }
      }

      .card-content {
        padding: 20px 14px;
        background: #ffffff;

        .title {
          padding-bottom: 16px;

          font-size: 20px;
          line-height: 27px;
          color: #1E3B89;
        }

        .text {
          font-family: $fontL;
          font-size: 14px;
          line-height: 130%;
          color: #1E3B89;
        }
      }

      .card-footer {
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding: 10px 0;

        border-top: 1px solid #DAE3ED;

        a {
          font-family: $fontB;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #00ACE6;
        }
      }
    }

    .footer {
      background: #E1ECF7;

      p {
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (min-width: 426px) {
  .legal {
    .content {
      .all-questions-navigation-block {
        top: 0;
      }
    }
  }
}

</style>
