<template>
    <div class="item">
        <div class="visible-item" :class="!isOpen ? '': 'active'" @click="isOpen = !isOpen">
            <div class="icon-text">
                <div class="icon" v-html="iconArr.i[selectedData.title]"></div>
                <p>{{selectedData.title}}</p>
            </div>
            <div class="arrow" :class="!isOpen ? '': 'rotated'">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.022 1L5.50928 5.53548" stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.50924 5.53548L1 1" stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <transition name="fade">
            <div class="container" v-if="isOpen">
                <div class="hidden-item" v-for="(d, index) in data" :key="index" :class="selectedData !== null ? selectedData.title === d.title ? 'active' : '' : ''" @click="$emit('selectedDataChenge', d); isOpen=false">
                    <div class="icon">
                        <div v-html="iconArr.i[d.title]"></div>
                    </div>
                    <div class="text">
                        {{d.title}}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props : ['data', 'selectedData', 'iconArr', 'defaultName'],
    data : () => ({
        isOpen : false
    }),
    created(){

    }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/vars";

.item {
    border-bottom: 1px solid rgba(172, 152, 197, 0.3);

    .visible-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        cursor: pointer;

        .arrow{
            transition: transform 500ms ease;
        }

        .rotated{
            transform: rotate(-180deg);
            transition: transform 500ms ease;
        }


        .icon-text {
            display: flex;
            align-items: center;
            width: 100%;

            .icon {
                margin-right: 15px;
                max-width: 30px;
                max-height: 33px;
                width: 100%;

                svg {
                    height: auto;
                    display: block;
                }

            }

            p {
                color: #4A2A80;
                font-size: 15px;
                font-family: $fontR;
            }
        }
    }

    .active{
        background: #EFE1F7;

        .hidden-item{
            background: #EFE1F7;
            box-shadow: 0px 14px 21px rgba(0, 0, 0, 0.08);
        }
    }

    .container{
        max-height: 320px;
        overflow: scroll;

        .active{
            border-left: 3px solid #8F67A4;

            .text{
                font-family: $fontB;
            }
        }
    }

    .hidden-item{
        padding: 15px 20px;
        cursor: pointer;

        border-top: 1px solid rgba(172, 152, 197, 0.3);

        display: flex;
        flex-direction: row;
        align-items: center;

        background: #EFE1F7;
        box-shadow: 0px 14px 21px rgba(0, 0, 0, 0.08);

        .icon{
            width: 39px;
            height: 33px;
            margin-right: 10px;

            div{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            svg{
                width: 100%;
                height: auto;
            }
            }
        }

        .text{
            font-size: 15px;
            line-height: 20px;
            color: #4A2A80;
        }
    }

}
</style>