<template>
    <div class="header-item">
        <div class="name">{{cardType}}</div>

        <div class="links" v-if="getLangCount() > 1">
            <a v-if="card.contentUa || card.linkUa">Укр | </a> 
            <span v-if="card.contentRu || card.linkRu"><a>Рус</a></span>
            <span v-if="(card.contentEn || card.linkEn) && (card.contentRu || card.linkRu)" class="delimetr">|</span>
            <span v-if="card.contentEn || card.linkEn"><a>EN</a></span>
        </div>
    </div>
</template>

<script>
export default {
    props : ['cardType', 'card'],
    methods : {
        getLangCount(){
            let count = 0;
            count = this.card.contentUa || this.card.linkUa ? count + 1 : count;
            count = this.card.contentRu || this.card.linkRu ? count + 1 : count;
            count = this.card.contentEn || this.card.linkEn ? count + 1 : count;

            return count;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../style/vars";

.header-item {
    background: #D5EAF8;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    .name {
        color: #1E3B89;
        font-size: 12px;
        font-family: $fontL;
    }

    .links {
        font-family: $fontSB;
        font-size: 12px;
        color: #00ACE6;
        text-transform: uppercase;

        display: flex;
        align-items: center;

        span {
            display: inline-block;
            margin: 0 5px;
        }

        .delimetr{
            margin: 0;
        }

        a {
            color: #00ACE6;
        }
    }
}
</style>