<template>
  <div class="popup">
    <div class="popup-content">

      <div class="popup-header">
        {{ popupName }}
        <svg @click="$emit('closePopup')" width="19" height="19" viewBox="0 0 19 19" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M0.258301 0.258301L18.7418 18.7418" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
          <path d="M18.7418 0.258301L0.258301 18.7418" stroke="#00ACE6" stroke-width="1.7" stroke-miterlimit="10"/>
        </svg>
      </div>

      <slot></slot>

    </div>
  </div>
</template>

<script>
export default {
  props: ['popupName'],
  created(){
    this.$nextTick(() => {
      if(window.screen.width > 426){
        const scrollPosition = window.pageYOffset;
        let element = document.getElementsByClassName('popup-content');
        element[0].style.top = 50 + scrollPosition + 'px';
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  height: 100%;

  background: rgba(88, 101, 136, 0.6);

  padding: 31px 15px;

  overflow: scroll;
  
  @media screen and (min-width: 426px) {
    position: absolute;
    display: flex;
    align-items: center;
  }

  .popup-content {
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;

    max-width: 425px;

    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 426px) {
      position: absolute;
      left: 0;
      right: 0;
      // top: 50px;

      max-width: 95%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      
    }

    .popup-header {
      padding: 20px 24px 20px 14px;

      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.11em;
      font-weight: bold;
      text-transform: uppercase;
      color: #9AB9D8;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      background: #EEF3F8;
      border-radius: 4px 4px 0px 0px;
    }
  }
}
</style>
