<template>
  <div class="diagnostics">

    <div class="head-links">
      <router-link class="back-links" to="/algorithms"
      >Алгоритми діагностики
      </router-link
      >
      <span>/</span>
      <router-link class="back-links" to="/algorithms/fg">
        Недостатність харчування
      </router-link>
      <span v-if="qStage === '1'">/</span>
      <a
          @click="reload()"
          v-if="qStage === '1'"
          class="now-page"
      >
        Розрахунок добової норми
      </a>
      <span v-if="qStage === '2'">/</span>
      <a v-if="qStage === '2'" class="now-page">
        Розрахунок добового дефіциту
      </a>
    </div>

    <div class="content">
      <div v-if="qStage === '1'" class="q1 q-block">
        <div class="header-title">
          <p>Розрахунок добової норми</p>
        </div>

        <div class="filter-items">
          <multiple-select
              @selectedDataChange="selectedStatesChange"
              :defaultName="'Стан'"
              :selectedData="selectedStates"
              :data="states"
          />

          <single-select
              @selectedDataChange="selectedMaleChange"
              :defaultName="'Стать'"
              :selectedData="selectedMale"
              :data="males"
              :isDisabled="isDisabled"
          />

          <!-- <column-item
            @selectedDataChange="selectedAgeChange"
            :value="selectedAge"
            :defaultName="'Вік'"
            :minAge="0"
            :maxAge="18"
            :step="0.5"
            :isDisabled="isDisabled"
          /> -->
          <div class="birth-date" :class="{disabled: isDisabled}">
            <div class="label">
              <svg width="34" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M28.6888 15.8381L28.6888 15.8383C27.5277 21.0915 22.5454 24.9128 16.8358 24.9128C11.1264 24.9128 6.14511 21.0916 4.98397 15.8385C4.92445 15.565 4.68201 15.3715 4.40404 15.3715H4.0799C2.72294 15.3715 1.61843 14.2669 1.61843 12.911C1.61843 11.555 2.72299 10.4496 4.0799 10.4496H4.73311C4.98831 10.4496 5.21534 10.287 5.2963 10.0445C6.89735 5.28932 11.5333 2.08822 16.8358 2.08822C22.1384 2.08822 26.7734 5.28931 28.3754 10.0445L28.3754 10.0446C28.4573 10.2866 28.683 10.4496 28.9386 10.4496H29.5928C30.9497 10.4496 32.0533 11.554 32.0533 12.911C32.0533 14.268 30.9497 15.3715 29.5928 15.3715H29.2687C28.9901 15.3715 28.7493 15.5665 28.6888 15.8381ZM0.430212 12.911C0.430212 14.875 1.98963 16.4814 3.93579 16.5566C5.39971 22.1176 10.7475 26.1 16.8358 26.1C22.9243 26.1 28.273 22.1176 29.7388 16.5566C31.6841 16.4814 33.2435 14.875 33.2435 12.911C33.2435 10.8981 31.6057 9.26133 29.5948 9.26133H29.3589C27.4718 4.23972 22.4932 0.9 16.8368 0.9C11.1804 0.9 6.20192 4.23972 4.31482 9.26133H4.0799C2.06699 9.26133 0.430212 10.8991 0.430212 12.911Z"
                    fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                <path
                    d="M20.9766 16.1108C21.6911 16.1108 22.2704 15.5316 22.2704 14.8171C22.2704 14.1025 21.6911 13.5233 20.9766 13.5233C20.2621 13.5233 19.6828 14.1025 19.6828 14.8171C19.6828 15.5316 20.2621 16.1108 20.9766 16.1108Z"
                    fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                <path
                    d="M13.1509 16.1108C13.8654 16.1108 14.4446 15.5316 14.4446 14.8171C14.4446 14.1025 13.8654 13.5233 13.1509 13.5233C12.4363 13.5233 11.8571 14.1025 11.8571 14.8171C11.8571 15.5316 12.4363 16.1108 13.1509 16.1108Z"
                    fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                <path
                    d="M13.0958 20.2471L13.0958 20.2471L13.0964 20.2475L13.291 20.367C13.291 20.367 13.291 20.367 13.291 20.367C14.3831 21.0399 15.6078 21.376 16.8348 21.376C18.0608 21.376 19.2875 21.0389 20.3815 20.3661L20.3817 20.366L20.5754 20.2464L20.5755 20.2463C20.8533 20.0742 20.9415 19.7084 20.769 19.4289C20.5964 19.1492 20.2294 19.0654 19.9519 19.235L19.9519 19.235L19.9513 19.2354L19.7589 19.3548C19.7588 19.3548 19.7588 19.3548 19.7588 19.3548C17.9528 20.4665 15.7146 20.4654 13.9137 19.3558L13.7201 19.2362L13.7201 19.2362L13.7197 19.236C13.442 19.0663 13.0762 19.1504 12.9027 19.4296L12.9027 19.4296L12.9025 19.4299C12.73 19.7096 12.8185 20.0741 13.0958 20.2471Z"
                    fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
              </svg>
              Дата<br>народження
            </div>

            <div class="date">
              <date-picker
                  v-model="selectedBirthDate"
                  valueType="format"
                  :popup-style="{color: '#1E3B89'}"
                  appendBody
                  ref="datepicker"
                  :lang="lang"
                  :key="lang"
                  :disabled="isDisabled"
                  :disabled-date="(date) => date >= new Date() || date < new Date().setFullYear(new Date().getFullYear()-19)"
              >
              </date-picker>
              <p class="text">{{ selectedBirthDate || 'дд.мм.рррр' }}</p>
            </div>
          </div>

          <column-item
              @selectedDataChange="selectedWeightChange"
              :value="selectedWeight"
              :age="selectedAge"
              :minWeight="1"
              :maxWeight="300"
              :defaultName="'Вага'"
              :step="0.1"
          />

          <column-item
              @selectedDataChange="selectedHeightChange"
              :value="selectedHeight"
              :height="selectedHeight"
              :minHeight="minHeight"
              :maxHeight="250"
              :defaultName="'Зріст'"
              :step="1"
              :isDisabled="isDisabled"
          />

          <single-select
              @selectedDataChange="selectedActivityChange"
              :defaultName="'Активність'"
              :selectedData="selectedActivity"
              :data="activities"
              :isDisabled="isDisabled"
          />

          <single-select
              @selectedDataChange="selectedGrowthRateChange"
              :defaultName="'Швидкість зростання'"
              :selectedData="selectedGrowthRate"
              :data="growthRates"
              :isDisabled="isDisabled"
          />
        </div>

        <div class="content">
          <button
              class="next-btn"
              :class="{ active: q1Result}"
              @click="q1Calc()"
              :disabled="
              ((!selectedStates || !selectedActivity || !selectedGrowthRate || !selectedWeight || !selectedBirthDate || !selectedHeight) && !isDisabled)
              || ((!selectedStates || !selectedWeight) && isDisabled)
            "
          >
            <p>розрахувати</p>
          </button>

          <div v-if="!q2Result" class="info">
            <p>
              <strong>Швидкість зростання дітей </strong>
              від народження до трьох років та у пубертатному віці вважається інтенсивною,
              а у інші періоди – нормальною
            </p>
            <div class="icon">
              <svg width="4" height="15" viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.95685 14.7198H0.694818V4.31041H2.95685V14.7198ZM0.560059 1.55277C0.560059 1.15747 0.669149 0.853158 0.887331 0.639824C1.11193 0.426491 1.42958 0.319824 1.84027 0.319824C2.23813 0.319824 2.54615 0.426491 2.76434 0.639824C2.98894 0.853158 3.10123 1.15747 3.10123 1.55277C3.10123 1.92924 2.98894 2.22728 2.76434 2.44688C2.54615 2.66022 2.23813 2.76688 1.84027 2.76688C1.42958 2.76688 1.11193 2.66022 0.887331 2.44688C0.669149 2.22728 0.560059 1.92924 0.560059 1.55277Z"
                    fill="#1E3B89"/>
              </svg>
            </div>
          </div>
        </div>

        <div class="day-norm" v-if="q1Result">
          <div class="title">
            добова норма дитини
          </div>
          <div class="row">
            <div class="kkal-norm">
              <div class="name">
                Калорії
              </div>
              <div class="kkal-container">
                <div class="item">
                  <div class="icon">
                    <svg width="23" height="38" viewBox="0 0 23 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="38"
                            fill="black">
                        <rect fill="white" width="23" height="38"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"/>
                      </mask>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"
                            fill="#00ACE6"/>
                      <path
                          d="M7.10714 1V0.8H6.96032L6.91633 0.940076L7.10714 1ZM1 20.4464L0.809188 20.3865L0.727559 20.6464H1V20.4464ZM20.9286 1L21.1079 1.08846L21.2502 0.8H20.9286V1ZM15.1429 12.7321L14.9635 12.6437L14.8212 12.9321H15.1429V12.7321ZM22.375 12.7321L22.5402 12.8448L22.7535 12.5321H22.375V12.7321ZM5.82143 37L5.62546 36.96L5.98665 37.1127L5.82143 37ZM9.19643 20.4464L9.3924 20.4864L9.44132 20.2464H9.19643V20.4464ZM18.9691 2.11701L19.1496 2.20328L19.2864 1.91701H18.9691V2.11701ZM8.03192 2.11701V1.91701H7.88609L7.8415 2.05586L8.03192 2.11701ZM13.3376 13.8961L13.1572 13.8098L13.0203 14.0961H13.3376V13.8961ZM20.0141 13.8961L20.1805 14.0071L20.388 13.6961H20.0141V13.8961ZM7.80345 32.1994L7.60839 32.1552L7.96982 32.3104L7.80345 32.1994ZM10.6974 19.4303L10.8925 19.4745L10.9479 19.2303H10.6974V19.4303ZM2.47239 19.4303L2.28197 19.3691L2.19811 19.6303H2.47239V19.4303ZM6.91633 0.940076L0.809188 20.3865L1.19081 20.5064L7.29796 1.05992L6.91633 0.940076ZM20.9286 0.8H7.10714V1.2H20.9286V0.8ZM15.3222 12.8206L21.1079 1.08846L20.7492 0.911542L14.9635 12.6437L15.3222 12.8206ZM22.375 12.5321H15.1429V12.9321H22.375V12.5321ZM5.98665 37.1127L22.5402 12.8448L22.2098 12.6194L5.65621 36.8873L5.98665 37.1127ZM9.00046 20.4065L5.62546 36.96L6.0174 37.04L9.3924 20.4864L9.00046 20.4065ZM1 20.6464H9.19643V20.2464H1V20.6464ZM18.9691 1.91701H8.03192V2.31701H18.9691V1.91701ZM13.518 13.9824L19.1496 2.20328L18.7887 2.03074L13.1572 13.8098L13.518 13.9824ZM20.0141 13.6961H13.3376V14.0961H20.0141V13.6961ZM7.96982 32.3104L20.1805 14.0071L19.8477 13.7851L7.63707 32.0884L7.96982 32.3104ZM10.5024 19.386L7.60839 32.1552L7.9985 32.2436L10.8925 19.4745L10.5024 19.386ZM2.47239 19.6303H10.6974V19.2303H2.47239V19.6303ZM7.8415 2.05586L2.28197 19.3691L2.66281 19.4914L8.22234 2.17816L7.8415 2.05586Z"
                          fill="#00ACE6" mask="url(#path-1-outside-1)"/>
                      <path
                          d="M8.31175 7.7377C8.06652 7.77634 7.86527 7.95237 7.79434 8.19027L5.5682 15.6565C5.43269 16.111 5.81779 16.5513 6.28626 16.4775C6.53149 16.4388 6.73274 16.2628 6.80368 16.0249L9.02981 8.55864C9.16532 8.10416 8.78021 7.66388 8.31175 7.7377Z"
                          fill="#00ACE6" stroke="#00ACE6" stroke-width="0.2"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ q1Result.dayNormKkal }}
                    </div>
                    <div class="text">
                      ккал
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kkal-norm">
              <div class="name">
                Білок
              </div>
              <div class="kkal-container">
                <div class="item">
                  <div class="icon">
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M21.2299 16.2151H13.1716C12.8503 16.2151 12.5558 16.0269 12.422 15.7042L8.83458 6.69579C8.67395 6.29243 8.86135 5.8084 9.2897 5.64706C9.69128 5.48571 10.1732 5.67395 10.3338 6.1042L13.7338 14.6286H20.7212L24.0944 6.07731C24.2551 5.67395 24.7369 5.45882 25.1385 5.62016C25.5401 5.78151 25.7543 6.26554 25.5936 6.6689L21.9795 15.7042C21.8724 16.0269 21.5779 16.2151 21.2299 16.2151Z"
                          fill="#00ACE6"/>
                      <path
                          d="M16.4913 12.4504C16.17 12.4504 15.8487 12.2622 15.7416 11.9126L13.9747 7.09913C13.8141 6.66888 14.0283 6.21174 14.4566 6.0504C14.885 5.88905 15.3401 6.10418 15.5007 6.53443L17.2676 11.3479C17.4283 11.7781 17.2141 12.2353 16.7857 12.3966C16.6787 12.4235 16.5983 12.4504 16.4913 12.4504Z"
                          fill="#00ACE6"/>
                      <path
                          d="M18.2046 12.6925C18.2046 13.3648 17.6692 13.9026 16.9999 13.9026C16.3306 13.9026 15.7952 13.3648 15.7952 12.6925C15.7952 12.0202 16.3306 11.4824 16.9999 11.4824C17.6692 11.4824 18.2046 12.0202 18.2046 12.6925Z"
                          fill="#00ACE6"/>
                      <path
                          d="M9.4772 6.83027C9.26302 6.83027 9.04885 6.74959 8.88822 6.56136C8.59373 6.23867 8.6205 5.72775 8.94176 5.43195C10.9496 3.57648 13.9748 2.50085 17.2142 2.50085C20.4803 2.50085 23.4788 3.57648 25.4866 5.43195C25.8079 5.72775 25.8347 6.23867 25.5402 6.56136C25.2457 6.88405 24.737 6.91094 24.4158 6.61514C22.7292 5.05548 20.052 4.1143 17.2142 4.1143C14.4032 4.1143 11.726 5.05548 10.0126 6.61514C9.852 6.77648 9.6646 6.83027 9.4772 6.83027Z"
                          fill="#00ACE6"/>
                      <path
                          d="M16.9999 31.4621C16.5448 31.4621 16.1968 31.1126 16.1968 30.6554V24.4705C16.1968 24.0134 16.5448 23.6638 16.9999 23.6638C17.455 23.6638 17.8031 24.0134 17.8031 24.4705V30.6554C17.8031 31.1126 17.455 31.4621 16.9999 31.4621Z"
                          fill="#00ACE6"/>
                      <path
                          d="M24.8976 32H9.10236C6.9874 32 4.87244 30.3059 4.39055 28.2084L0.214173 9.49244C0.187402 9.41177 0.187402 9.35798 0.187402 9.27731C0.080315 8.71261 0 8.12101 0 7.52941C0 3.38824 3.37323 0 7.49606 0H26.5039C30.6268 0 34 3.38824 34 7.52941C34 8.28235 33.8929 9.0084 33.6787 9.73445C33.6787 9.81513 33.6787 9.92269 33.6787 10.0034L29.6094 28.2084C29.1276 30.3059 27.0126 32 24.8976 32ZM1.84724 9.38488L5.97008 27.8588C6.26457 29.2303 7.71024 30.3866 9.10236 30.3866H24.8976C26.2898 30.3866 27.7354 29.2303 28.0299 27.8588L32.0189 10.0034C31.9921 9.84202 31.9921 9.68067 32.0457 9.54622C32.2866 8.90084 32.3937 8.22857 32.3937 7.52941C32.3937 4.27563 29.7433 1.61345 26.5039 1.61345H7.49606C4.25669 1.61345 1.6063 4.27563 1.6063 7.52941C1.6063 8.06723 1.68661 8.57815 1.82047 9.08908C1.84724 9.19664 1.84724 9.27731 1.84724 9.38488Z"
                          fill="#00ACE6"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ q1Result.dayNormOfProteinGrams }}
                    </div>
                    <div class="text">
                      грам
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
              class="day-norm-btn btn"
              @click="q1Ctrl()"
          >
            дізнатись, чи є<br>
            ДЕФІЦИТ ​КАЛОРІЙ І БІЛКА​
          </button>
        </div>
      </div>

      <div v-if="qStage === '2'" class="q2">
        <div class="day-norm" v-if="q1Result">
          <div class="title">
            добова норма дитини
          </div>
          <div class="row">
            <div class="kkal-norm">
              <div class="kkal-container">
                <div class="item">
                  <div class="icon">
                    <svg width="23" height="38" viewBox="0 0 23 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="38"
                            fill="black">
                        <rect fill="white" width="23" height="38"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"/>
                      </mask>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"
                            fill="#00ACE6"/>
                      <path
                          d="M7.10714 1V0.8H6.96032L6.91633 0.940076L7.10714 1ZM1 20.4464L0.809188 20.3865L0.727559 20.6464H1V20.4464ZM20.9286 1L21.1079 1.08846L21.2502 0.8H20.9286V1ZM15.1429 12.7321L14.9635 12.6437L14.8212 12.9321H15.1429V12.7321ZM22.375 12.7321L22.5402 12.8448L22.7535 12.5321H22.375V12.7321ZM5.82143 37L5.62546 36.96L5.98665 37.1127L5.82143 37ZM9.19643 20.4464L9.3924 20.4864L9.44132 20.2464H9.19643V20.4464ZM18.9691 2.11701L19.1496 2.20328L19.2864 1.91701H18.9691V2.11701ZM8.03192 2.11701V1.91701H7.88609L7.8415 2.05586L8.03192 2.11701ZM13.3376 13.8961L13.1572 13.8098L13.0203 14.0961H13.3376V13.8961ZM20.0141 13.8961L20.1805 14.0071L20.388 13.6961H20.0141V13.8961ZM7.80345 32.1994L7.60839 32.1552L7.96982 32.3104L7.80345 32.1994ZM10.6974 19.4303L10.8925 19.4745L10.9479 19.2303H10.6974V19.4303ZM2.47239 19.4303L2.28197 19.3691L2.19811 19.6303H2.47239V19.4303ZM6.91633 0.940076L0.809188 20.3865L1.19081 20.5064L7.29796 1.05992L6.91633 0.940076ZM20.9286 0.8H7.10714V1.2H20.9286V0.8ZM15.3222 12.8206L21.1079 1.08846L20.7492 0.911542L14.9635 12.6437L15.3222 12.8206ZM22.375 12.5321H15.1429V12.9321H22.375V12.5321ZM5.98665 37.1127L22.5402 12.8448L22.2098 12.6194L5.65621 36.8873L5.98665 37.1127ZM9.00046 20.4065L5.62546 36.96L6.0174 37.04L9.3924 20.4864L9.00046 20.4065ZM1 20.6464H9.19643V20.2464H1V20.6464ZM18.9691 1.91701H8.03192V2.31701H18.9691V1.91701ZM13.518 13.9824L19.1496 2.20328L18.7887 2.03074L13.1572 13.8098L13.518 13.9824ZM20.0141 13.6961H13.3376V14.0961H20.0141V13.6961ZM7.96982 32.3104L20.1805 14.0071L19.8477 13.7851L7.63707 32.0884L7.96982 32.3104ZM10.5024 19.386L7.60839 32.1552L7.9985 32.2436L10.8925 19.4745L10.5024 19.386ZM2.47239 19.6303H10.6974V19.2303H2.47239V19.6303ZM7.8415 2.05586L2.28197 19.3691L2.66281 19.4914L8.22234 2.17816L7.8415 2.05586Z"
                          fill="#00ACE6" mask="url(#path-1-outside-1)"/>
                      <path
                          d="M8.31175 7.7377C8.06652 7.77634 7.86527 7.95237 7.79434 8.19027L5.5682 15.6565C5.43269 16.111 5.81779 16.5513 6.28626 16.4775C6.53149 16.4388 6.73274 16.2628 6.80368 16.0249L9.02981 8.55864C9.16532 8.10416 8.78021 7.66388 8.31175 7.7377Z"
                          fill="#00ACE6" stroke="#00ACE6" stroke-width="0.2"/>
                    </svg>
                  </div>
                  <div class="value">
                    <span class="number">
                      {{ q1Result.dayNormKkal }}
                    </span>
                    <span class="text">
                      ккал
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="kkal-norm">
              <div class="kkal-container">
                <div class="item">
                  <div class="icon">
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M21.2299 16.2151H13.1716C12.8503 16.2151 12.5558 16.0269 12.422 15.7042L8.83458 6.69579C8.67395 6.29243 8.86135 5.8084 9.2897 5.64706C9.69128 5.48571 10.1732 5.67395 10.3338 6.1042L13.7338 14.6286H20.7212L24.0944 6.07731C24.2551 5.67395 24.7369 5.45882 25.1385 5.62016C25.5401 5.78151 25.7543 6.26554 25.5936 6.6689L21.9795 15.7042C21.8724 16.0269 21.5779 16.2151 21.2299 16.2151Z"
                          fill="#00ace6"/>
                      <path
                          d="M16.4913 12.4504C16.17 12.4504 15.8487 12.2622 15.7416 11.9126L13.9747 7.09913C13.8141 6.66888 14.0283 6.21174 14.4566 6.0504C14.885 5.88905 15.3401 6.10418 15.5007 6.53443L17.2676 11.3479C17.4283 11.7781 17.2141 12.2353 16.7857 12.3966C16.6787 12.4235 16.5983 12.4504 16.4913 12.4504Z"
                          fill="#00ace6"/>
                      <path
                          d="M18.2046 12.6925C18.2046 13.3648 17.6692 13.9026 16.9999 13.9026C16.3306 13.9026 15.7952 13.3648 15.7952 12.6925C15.7952 12.0202 16.3306 11.4824 16.9999 11.4824C17.6692 11.4824 18.2046 12.0202 18.2046 12.6925Z"
                          fill="#00ace6"/>
                      <path
                          d="M9.4772 6.83027C9.26302 6.83027 9.04885 6.74959 8.88822 6.56136C8.59373 6.23867 8.6205 5.72775 8.94176 5.43195C10.9496 3.57648 13.9748 2.50085 17.2142 2.50085C20.4803 2.50085 23.4788 3.57648 25.4866 5.43195C25.8079 5.72775 25.8347 6.23867 25.5402 6.56136C25.2457 6.88405 24.737 6.91094 24.4158 6.61514C22.7292 5.05548 20.052 4.1143 17.2142 4.1143C14.4032 4.1143 11.726 5.05548 10.0126 6.61514C9.852 6.77648 9.6646 6.83027 9.4772 6.83027Z"
                          fill="#00ace6"/>
                      <path
                          d="M16.9999 31.4621C16.5448 31.4621 16.1968 31.1126 16.1968 30.6554V24.4705C16.1968 24.0134 16.5448 23.6638 16.9999 23.6638C17.455 23.6638 17.8031 24.0134 17.8031 24.4705V30.6554C17.8031 31.1126 17.455 31.4621 16.9999 31.4621Z"
                          fill="#00ace6"/>
                      <path
                          d="M24.8976 32H9.10236C6.9874 32 4.87244 30.3059 4.39055 28.2084L0.214173 9.49244C0.187402 9.41177 0.187402 9.35798 0.187402 9.27731C0.080315 8.71261 0 8.12101 0 7.52941C0 3.38824 3.37323 0 7.49606 0H26.5039C30.6268 0 34 3.38824 34 7.52941C34 8.28235 33.8929 9.0084 33.6787 9.73445C33.6787 9.81513 33.6787 9.92269 33.6787 10.0034L29.6094 28.2084C29.1276 30.3059 27.0126 32 24.8976 32ZM1.84724 9.38488L5.97008 27.8588C6.26457 29.2303 7.71024 30.3866 9.10236 30.3866H24.8976C26.2898 30.3866 27.7354 29.2303 28.0299 27.8588L32.0189 10.0034C31.9921 9.84202 31.9921 9.68067 32.0457 9.54622C32.2866 8.90084 32.3937 8.22857 32.3937 7.52941C32.3937 4.27563 29.7433 1.61345 26.5039 1.61345H7.49606C4.25669 1.61345 1.6063 4.27563 1.6063 7.52941C1.6063 8.06723 1.68661 8.57815 1.82047 9.08908C1.84724 9.19664 1.84724 9.27731 1.84724 9.38488Z"
                          fill="#00ace6"/>
                    </svg>
                  </div>
                  <div class="value">
                    <span class="number">
                      {{ q1Result.dayNormOfProteinGrams }}
                    </span>
                    <span class="text">
                      грам
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="q-block">
          <div class="header-title">
            <p>Розрахунок добового дефіциту</p>
            <!-- <div @click="qStage = '1'" class="back-arrow"> -->
            <div @click="reload()" class="back-arrow">
              <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M5.53564 1.52856L1.00017 5.81836"
                    stroke="#00ACE6"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1.00016 5.81799L5.53564 10.1045"
                    stroke="#00ACE6"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <p class="subtitle">
            Вкажіть кількість калорій і білка, що надходять з основного раціону
          </p>

          <div class="filter-items">
            <column-item
                @selectedDataChange="selectedKkalChange"
                :value="selectedKkal"
                :defaultName="'Калорійність,'"
                :step="10"
            />
            <column-item
                @selectedDataChange="selectedProteineChange"
                :value="selectedProteine"
                :defaultName="'Білок,'"
                :step="1"
            />
          </div>

          <div class="content">
            <button
                class="calc-btn btn"
                :class="{ active: q2Result}"
                @click="q2Calc()"
                :disabled="selectedProteine < 0 || selectedKkal < 0"
            >
              розрахувати
            </button>
          </div>

          <div class="content">
            <div v-if="!q2Result" class="info">
              <p>
                <strong>Підрахувати добову кількість споживання калорій і білка</strong> можна за допомогою щоденника
                харчування чи мобільних застосунків для спортсменів або тих, хто дотримується дієти
                <span class="m-t m-b"><strong>Якщо дитина на грудному вигодовуванні,</strong> скористайтеся середніми значеннями для 1 л грудного молока:</span>
                <span class="list"><strong>калорійність</strong> – <span style="word-break: keep-all">650-700 ккал</span></span>
                <span class="list"><strong>білки:</strong> при народженні – <span style="word-break: keep-all">14-16 г</span>, у <span style="word-break: keep-all">3-4 місяці</span> – <span style="word-break: keep-all">8-10&nbsp;г</span>, після 6&nbsp;місяців – <span style="word-break: keep-all">7-8 г</span></span>

              </p>
              <div class="icon">
                <svg width="4" height="15" viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M2.95685 14.7198H0.694818V4.31041H2.95685V14.7198ZM0.560059 1.55277C0.560059 1.15747 0.669149 0.853158 0.887331 0.639824C1.11193 0.426491 1.42958 0.319824 1.84027 0.319824C2.23813 0.319824 2.54615 0.426491 2.76434 0.639824C2.98894 0.853158 3.10123 1.15747 3.10123 1.55277C3.10123 1.92924 2.98894 2.22728 2.76434 2.44688C2.54615 2.66022 2.23813 2.76688 1.84027 2.76688C1.42958 2.76688 1.11193 2.66022 0.887331 2.44688C0.669149 2.22728 0.560059 1.92924 0.560059 1.55277Z"
                      fill="#1E3B89"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="content">
            <div v-if="q2Result" class="dificite" id="dificite">
              <div class="dificite-item">
                <div class="dificite-title">
                  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3.47443 0C3.42049 0 3.3728 0.0350104 3.35664 0.0864664L0.00567282 10.7567C-0.00610367 10.7942 0.000679037 10.835 0.0239382 10.8667C0.0471974 10.8984 0.0841532 10.9171 0.123458 10.9171H4.46964L2.64799 19.8519C2.63625 19.9095 2.66675 19.9674 2.7209 19.9902C2.77505 20.0131 2.83782 19.9947 2.87095 19.9461L11.9538 6.63041C11.9796 6.5926 11.9824 6.54363 11.961 6.50317C11.9396 6.4627 11.8976 6.43739 11.8518 6.43739H8.08213L11.1689 0.178061C11.1878 0.139794 11.1856 0.0944961 11.163 0.0582634C11.1405 0.0220307 11.1009 0 11.0582 0H3.47443ZM6.78169 7.14625C6.7634 7.1845 6.76602 7.22947 6.78863 7.26533C6.81124 7.3012 6.85067 7.32296 6.89307 7.32296H10.3257L4.12458 16.6182L5.56484 10.2634C5.57313 10.2267 5.56432 10.1884 5.5409 10.159C5.51747 10.1297 5.48197 10.1126 5.44443 10.1126H1.10068L4.07189 0.859809H9.78721L6.78169 7.14625Z"
                          fill="#1E3B89"/>
                    <path
                        d="M4.12959 3.78261C3.98045 3.8061 3.85806 3.91316 3.81492 4.05785L2.59346 8.15457C2.51105 8.43097 2.74526 8.69874 3.03017 8.65385C3.17931 8.63035 3.30171 8.52329 3.34485 8.3786L4.5663 4.28188C4.64871 4.00548 4.4145 3.73771 4.12959 3.78261Z"
                        fill="#1E3B89" stroke="#1E3B89" stroke-width="0.186419" stroke-linejoin="round"/>
                  </svg>
                  <p v-if="+q2Result.selectedKkal < +q1Result.dayNormKkal">
                    Дефіцит калорій
                    <strong>
                      {{ q2Result.kkalD }}
                      ккал
                    </strong>
                  </p>
                  <p v-if="+q2Result.selectedKkal >= +q1Result.dayNormKkal">
                    Дефіцит калорій
                    <strong>
                      компенсовано
                    </strong>
                  </p>
                </div>
                <div class="dificite-bar">
                  <div class="dificite-indicator"
                       :style="{ width: `${ (q2Result.selectedKkal * 100) / q1Result.dayNormKkal }%` }">
                    <p class="texr">
                      {{ q2Result.selectedKkal }}
                    </p>
                  </div>
                  <p class="text">
                    {{ q2Result.kkalD }}
                  </p>
                </div>
                <p class="dificite-text">
                  Добова норма
                  {{ q1Result.dayNormKkal }}
                  ккал
                </p>
              </div>

              <div class="dificite-item">
                <div class="dificite-title">
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.2396 8.61423H6.97345C6.80337 8.61423 6.64747 8.51423 6.5766 8.3428L4.67739 3.55708C4.59235 3.3428 4.69156 3.08566 4.91834 2.99994C5.13093 2.91423 5.38605 3.01423 5.47109 3.2428L7.27109 7.77137H10.9703L12.7561 3.22851C12.8412 3.01423 13.0963 2.89994 13.3089 2.98565C13.5215 3.07137 13.6349 3.32851 13.5498 3.5428L11.6364 8.3428C11.5798 8.51423 11.4238 8.61423 11.2396 8.61423Z"
                        fill="#1E3B89"/>
                    <path
                        d="M8.73063 6.61449C8.56056 6.61449 8.39048 6.51449 8.33378 6.32878L7.39835 3.77164C7.31331 3.54307 7.4267 3.30021 7.65347 3.21449C7.88024 3.12878 8.12119 3.24306 8.20623 3.47164L9.14166 6.02878C9.2267 6.25735 9.11331 6.50021 8.88654 6.58592C8.82985 6.60021 8.78733 6.61449 8.73063 6.61449Z"
                        fill="#1E3B89"/>
                    <path
                        d="M9.6379 6.74295C9.6379 7.1001 9.35443 7.38581 9.0001 7.38581C8.64577 7.38581 8.3623 7.1001 8.3623 6.74295C8.3623 6.38581 8.64577 6.1001 9.0001 6.1001C9.35443 6.1001 9.6379 6.38581 9.6379 6.74295Z"
                        fill="#1E3B89"/>
                    <path
                        d="M5.01733 3.62861C4.90394 3.62861 4.79055 3.58576 4.70551 3.48576C4.54961 3.31433 4.56378 3.0429 4.73386 2.88576C5.79685 1.90004 7.39843 1.32861 9.11339 1.32861C10.8425 1.32861 12.4299 1.90004 13.4929 2.88576C13.663 3.0429 13.6772 3.31433 13.5213 3.48576C13.3654 3.65719 13.0961 3.67147 12.926 3.51433C12.0331 2.68576 10.6158 2.18576 9.11339 2.18576C7.6252 2.18576 6.20788 2.68576 5.30079 3.51433C5.21575 3.60004 5.11654 3.62861 5.01733 3.62861Z"
                        fill="#1E3B89"/>
                    <path
                        d="M9.00015 16.7141C8.7592 16.7141 8.57495 16.5284 8.57495 16.2856V12.9999C8.57495 12.757 8.7592 12.5713 9.00015 12.5713C9.24109 12.5713 9.42534 12.757 9.42534 12.9999V16.2856C9.42534 16.5284 9.24109 16.7141 9.00015 16.7141Z"
                        fill="#1E3B89"/>
                    <path
                        d="M13.1811 17H4.8189C3.69921 17 2.57953 16.1 2.32441 14.9857L0.113386 5.04286C0.0992126 5 0.0992126 4.97143 0.0992126 4.92857C0.0425197 4.62857 0 4.31429 0 4C0 1.8 1.78583 0 3.9685 0H14.0315C16.2142 0 18 1.8 18 4C18 4.4 17.9433 4.78571 17.8299 5.17143C17.8299 5.21429 17.8299 5.27143 17.8299 5.31429L15.6756 14.9857C15.4205 16.1 14.3008 17 13.1811 17ZM0.977953 4.98571L3.16063 14.8C3.31654 15.5286 4.08189 16.1429 4.8189 16.1429H13.1811C13.9181 16.1429 14.6835 15.5286 14.8394 14.8L16.9512 5.31429C16.937 5.22857 16.937 5.14286 16.9654 5.07143C17.0929 4.72857 17.1496 4.37143 17.1496 4C17.1496 2.27143 15.7465 0.857143 14.0315 0.857143H3.9685C2.25354 0.857143 0.850394 2.27143 0.850394 4C0.850394 4.28571 0.892913 4.55714 0.96378 4.82857C0.977953 4.88571 0.977953 4.92857 0.977953 4.98571Z"
                        fill="#1E3B89"/>
                  </svg>
                  <p v-if="+q2Result.selectedProteine < +q1Result.dayNormOfProteinGrams">
                    Дефіцит білка
                    <strong>
                      {{ q2Result.proteineD }}
                      грам
                    </strong>
                  </p>
                  <p v-if="+q2Result.selectedProteine >= +q1Result.dayNormOfProteinGrams">
                    Дефіцит білка
                    <strong>
                      компенсовано
                    </strong>
                  </p>
                </div>
                <div class="dificite-bar">
                  <div class="dificite-indicator"
                       :style="{ width: `${ (q2Result.selectedProteine * 100) / q1Result.dayNormOfProteinGrams }%` }">
                    <p class="texr">
                      {{ q2Result.selectedProteine }}
                    </p>
                  </div>
                  <p class="text">
                    {{ q2Result.proteineD }}
                  </p>
                </div>
                <p class="dificite-text">
                  Добова норма
                  {{ q1Result.dayNormOfProteinGrams }}
                  грам
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="q2Result" class="dificite-fixed" :class="{ active: isResultFixed }">
          <div class="dificite-item">
            <div class="dificite-title">
              <p v-if="+q2Result.selectedKkal < +q1Result.dayNormKkal">
                Дефіцит калорій
                <strong>
                  {{ q2Result.kkalD }}
                  ккал
                </strong>
              </p>
              <p v-if="+q2Result.selectedKkal >= +q1Result.dayNormKkal">
                Дефіцит калорій
                <strong>
                  компенсовано
                </strong>
              </p>
            </div>
            <div class="dificite-bar">
              <div class="dificite-indicator"
                   :style="{ width: `${ (q2Result.selectedKkal * 100) / q1Result.dayNormKkal }%` }">
                <nobr class="text">
                  <strong>{{ q2Result.selectedKkal }}</strong> / {{ q2Result.kkalD }} ккал добової норми
                </nobr>
              </div>
              <nobr class="text">
                <strong>{{ q2Result.selectedKkal }}</strong> / {{ q2Result.kkalD }} ккал добової норми
              </nobr>
            </div>
          </div>

          <div class="dificite-item">
            <div class="dificite-title">
              <p v-if="+q2Result.selectedProteine < +q1Result.dayNormOfProteinGrams">
                Дефіцит білка
                <strong>
                  {{ q2Result.proteineD }}
                  грам
                </strong>
              </p>
              <p v-if="+q2Result.selectedProteine >= +q1Result.dayNormOfProteinGrams">
                Дефіцит білка
                <strong>
                  компенсовано
                </strong>
              </p>
            </div>
            <div class="dificite-bar">
              <div class="dificite-indicator"
                   :style="{ width: `${ (q2Result.selectedProteine * 100) / q1Result.dayNormOfProteinGrams }%` }">
                <nobr class="text">
                  <strong>{{ q2Result.selectedProteine }}</strong> / {{ q2Result.proteineD }} грам добової норми
                </nobr>
              </div>
              <nobr class="text">
                <strong>{{ q2Result.selectedProteine }}</strong> / {{ q2Result.proteineD }} грам добової норми
              </nobr>
            </div>
          </div>
        </div>

        <div class="correction q-block" id="correction" v-if="q2Result">
          <p class="title">
            корекція добового дефіциту
          </p>
          <p class="subtitle">
            Оберіть продукти для покриття добового дефіциту харчування​
          </p>

          <ul class="list">
            <li
                class="card"
                v-for="product of products"
                :key="product.id"
            >
              <div class="img">
                <img :src="require(`@/assets/img/pack/${product.img}.png`)" alt="">
              </div>
              <div class="description">
                <div>
                  <p class="description-title">
                    {{ product.name }}
                  </p>
                  <div class="description-size">
                    {{ product.size }} {{ product.type }}
                  </div>
                  <p class="description-text">
                    {{ product.text }}
                  </p>
                  <p v-if="product.info" class="description-subtext">
                    {{ product.info }}
                  </p>
                </div>

                <div class="description-counter">
                  <button @click="decrementProduct(product.id)" class="description-dectrement">
                    <svg width="25" height="3" viewBox="0 0 25 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.883794 2.37425C0.391383 2.37425 0 1.97093 0 1.47852C0 0.98611 0.391383 0.594727 0.883794 0.594727H24.1035C24.5959 0.594727 25 0.98611 25 1.47852C25 1.97093 24.5959 2.37504 24.1035 2.37504L0.883794 2.37425Z"
                          fill="#00ACE6"/>
                    </svg>
                  </button>
                  <div class="description-quantity">
                    {{ product.counter }} {{ product.stepType }}
                  </div>
                  <button @click="incrementProduct(product.id)" class="description-increment">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.891129 13.3897C0.398984 13.3897 0.0078125 12.9858 0.0078125 12.4936C0.0078125 12.0015 0.398984 11.6103 0.891129 11.6103H11.6046V0.896037C11.6054 0.403893 12.0093 0 12.5015 0C12.9936 0 13.3848 0.403893 13.3848 0.896037V11.6103H24.0983C24.5904 11.6103 24.9943 12.0015 24.9943 12.4936C24.9943 12.9858 24.5904 13.3897 24.0983 13.3897H13.3848V24.104C13.3848 24.5961 12.9936 25 12.5015 25C12.0093 25 11.6054 24.5961 11.6054 24.104V13.3897H0.891129Z"
                          fill="#00ACE6"/>
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <div class="selected" v-if="selectedProducts.length">
            <div ref="toPrint">
              <p class="title">
                Набір продуктів для покриття<br> дефіциту на добу
              </p>
              <ul class="list">
                <li
                    v-for="product of selectedProducts"
                    class="item"
                    :key="product.id"
                >
                  <div class="img">
                    <img :src="require(`@/assets/img/pack/${product.img}.png`)" alt="">
                  </div>
                  <p class="name">
                    {{ product.name }}
                  </p>
                  <p class="quantity">
                    {{ product.counter }} {{ product.stepType }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="buttons">
              <button class="print-btn btn" @click="saveImg()">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 12.4434H12" stroke="#00ACE6" stroke-width="1.5" stroke-miterlimit="10"
                        stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M6.46484 8.08519V1" stroke="#00ACE6" stroke-width="1.5" stroke-miterlimit="10"
                        stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M9.68072 5.66943L6.46463 8.85281L3.24854 5.66943" stroke="#00ACE6" stroke-width="1.5"
                        stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                Завантажити
              </button>
              <a id="viber_share" class="send-btn btn" @click="sendToServerScreen()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M17.0315 9.02942L17.0622 8.99573L17.0315 8.96204L9.7397 0.966308L9.65276 0.87097V1V5.73955H8.85571C6.74419 5.73955 4.75869 6.56196 3.26555 8.0551C1.77241 9.54824 0.95 11.5337 0.95 13.6454V17V17.129L1.03693 17.0337L2.43635 15.5004C4.28757 13.4722 6.91073 12.2937 9.65276 12.253V16.9916V17.1206L9.7397 17.0253L17.0315 9.02942ZM15.658 8.99573L10.6903 14.443V11.2644V11.2144H10.6403H9.79907C6.86704 11.2144 4.04602 12.3946 1.98762 14.4673V13.6454C1.98762 11.8107 2.70196 10.086 3.99922 8.78877C5.29649 7.49151 7.02107 6.77717 8.85571 6.77717H10.6403H10.6903V6.72717V3.54858L15.658 8.99573Z"
                      fill="white" stroke="white" stroke-width="0.1"/>
                </svg>
                надіслати
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="(qStage === '2' && q2Result)" class="footer">
      <p class="footer-h1">Безумовна перевага у годуванні дитини перших років життя надається грудному
        вигодовуванню.</p>
      <p class="reg-text">
        Згідно з рекомендаціями МОЗ України грудне молоко має бути основним харчуванням малюка на першому році життя
        навіть після введення прикорму.
      </p>
      <p class="reg-text">ВООЗ рекомендує зберігати грудне вигодовування до 2 років.</p>
      <p class="reg-text">Nutricia підтримує зазначені рекомендації та попереджає, що штучне вигодовування може
        негативно вплинути на грудне.</p>
    </div>

  </div>
</template>

<script>
import SingleSelect from "@/components/production-energy/filter-items/one-column/single-select";
import MultipleSelect from "@/components/fg/multiple-select";
import ColumnItem from "@/components/fg/column-item";

import {mapActions, mapGetters} from 'vuex';
import {getElementVisible} from "@/helper.js";

import axios from "axios"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/uk'
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  name: "first-consultation",
  components: {
    "single-select": SingleSelect,
    "multiple-select": MultipleSelect,
    "column-item": ColumnItem,
    "date-picker": DatePicker,
  },
  data: () => ({
    // stage 1q
    males: ["Хлопчик", "Дівчинка"],
    selectedMale: "",
    selectedAge: 0,
    selectedAgeInMonth: null,
    lang: 'uk',
    selectedBirthDate: null,
    selectedWeight: 0,
    selectedHeight: 0,
    activities: [
      'Лежачий хворий',
      'Сидячий хворий',
      'Нормальна активність дитини'
    ],
    selectedActivity: '',

    growthRates: [
      'Нормальне зростання',
      'Інтенсивне зростання'
    ],
    selectedGrowthRate: '',

    states: [
      {
        name: 'Відсутній метаболічний стрес'
      },
      {
        name: 'Відсутній метаболічний стрес, але необхідно набрати вагу'
      },
      {
        name: 'Невеликий метаболічний стрес',
        points: [
          'Лежачий пацієнт із церебральним паралічем',
          'Анемія, лихоманка',
          'Легка інфекція',
          'Планова велика хірургічна операція'
        ]
      },
      {
        name: 'Середній метаболічний стрес',
        points: [
          'Перелом кісток',
          'Розширене хірургічне втручання',
          'Закрита черепно-мозкова травма',
          'Сепсис',
          'Виражена інфекція',
          'Опіки 10-30% поверхні тіла',
          'Пошкодження слизової оболонки після опромінення або хіміотерапії'
        ]
      },
      {
        name: 'Значний метаболічний стрес',
        points: [
          'Травма, інфекція',
          'Множинна травма',
          'Онкопатологія',
          'Опіки 30-50% поверхні тіла',
          'До корекції вродженої вади серця'
        ]
      },
      {
        name: 'Тяжкий метаболічний стрес',
        points: [
          'Опіки >50% поверхні тіла',
          'Муковісцидоз'
        ]
      },
      {
        name: 'Вроджені вади серця'
      },
      {
        name: 'Бронхолегенева дисплазія'
      }
    ],
    selectedStates: null,
    isDisabled: false,

    kkalK1: null,
    kkalK2: null,
    kkalK3: null,
    kkalK4: null,

    proteinK1: null,
    proteinK2: null,

    q1Result: null,

    // stage 2q

    selectedProteine: 0,
    selectedKkal: 0,
    selectedProducts: [],

    q2Result: null,

    isResultFixed: false,

    products: null,

    // system

    qStage: "1",
    literaturePopup: false,
  }),

  mounted() {
    this.setFooterState(false)

    document.addEventListener("scroll", this.fixResult);
  },

  beforeRouteLeave(to, from, next) {
    this.setFooterState(true)
    next();
  },

  computed: {
    ...mapGetters([
      'getProductsFG',
    ]),
    minHeight() {
      if (this.selectedAge < 1) {
        return 45;
      }

      if (this.selectedAge >= 1) {
        return 65;
      }
    },
    maxHeight() {
      if (this.selectedAge < 1) {
        return 110;
      }

      if (this.selectedAge >= 1) {
        return 120;
      }
    },
  },

  watch: {
    selectedStates(val) {
      if (val === 'Вроджені вади серця' || val === 'Бронхолегенева дисплазія') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    selectedBirthDate(val) {
      if (val) {
        console.log(moment().diff(moment(val, "YYYY-MM-DD"), 'years'))
        this.selectedAge = moment().diff(moment(val, "YYYY-MM-DD"), 'years');
        this.selectedAgeInMonth = moment().diff(moment(val, "YYYY-MM-DD"), 'months');
      }
    },
    qStage() {
      window.scrollTo(0, 0);
    }
  },

  methods: {
    ...mapActions([
      'setFooterState'
    ]),
    // stage 1q

    selectedMaleChange(value) {
      this.selectedMale = value;
    },
    selectedAgeChange(value) {
      this.selectedAge = value;
    },
    selectedWeightChange(value) {
      this.selectedWeight = value;
    },
    selectedHeightChange(value) {
      this.selectedHeight = value;
    },
    selectedActivityChange(value) {
      this.selectedActivity = value;
    },
    selectedGrowthRateChange(value) {
      this.selectedGrowthRate = value
    },
    selectedStatesChange(value) {
      this.selectedStates = value.name;
    },
    q1Calc() {
      if (this.selectedStates === 'Вроджені вади серця') {
        this.calcHD();

        return;
      }

      if (this.selectedStates === 'Бронхолегенева дисплазія') {
        this.calcBD();

        return;
      }

      this.calcKkalK1();
      this.calcKkalK2();
      this.calcKkalK3();
      this.calcKkalK4();


      this.q1Result = {
        dayNormKkal: Math.ceil(this.kkalK1 * this.kkalK2 * this.kkalK3 * this.kkalK4)
      };

      this.calcProteinK1();
      this.calcProteinK2();

      this.q1Result.dayNormOfProteinGrams = (this.selectedWeight * this.proteinK1 * this.proteinK2).toFixed(1);
    },
    calcHD() {
      this.q1Result = {
        dayNormKkal: +(this.selectedWeight * 150).toFixed(1)
      };
      this.q1Result.dayNormOfProteinGrams = +(this.selectedWeight * 3.75).toFixed(1)
    },
    calcBD() {
      this.q1Result = {
        dayNormKkal: +(this.selectedWeight * 135).toFixed(1)
      };
      this.q1Result.dayNormOfProteinGrams = +(this.selectedWeight * 3).toFixed(1)
    },
    calcKkalK1() {
      if (this.selectedAge < 3) {
        if (this.selectedMale === 'Хлопчик') {
          this.kkalK1 = (0.167 * this.selectedWeight) + (15.174 * this.selectedHeight) - 617.6
        } else {
          this.kkalK1 = (16.252 * this.selectedWeight) + (10.232 * this.selectedHeight) - 413.5
        }
      } else if (this.selectedAge >= 3 && this.selectedAge <= 10) {
        if (this.selectedMale === 'Хлопчик') {
          this.kkalK1 = (19.59 * this.selectedWeight) + (1.303 * this.selectedHeight) + 414.9
        } else {
          this.kkalK1 = (16.969 * this.selectedWeight) + (1.618 * this.selectedHeight) + 371.2
        }
      } else if (this.selectedAge > 10) {
        if (this.selectedMale === 'Хлопчик') {
          this.kkalK1 = (16.25 * this.selectedWeight) + (1.372 * this.selectedHeight) + 515.5
        } else {
          this.kkalK1 = (8.365 * this.selectedWeight) + (4.65 * this.selectedHeight) + 200
        }
      }
    },
    calcKkalK2() {
      switch (this.selectedStates) {
        case 'Відсутній метаболічний стрес':
          this.kkalK2 = 1;
          break;
        case 'Відсутній метаболічний стрес, але необхідно набрати вагу':
          this.kkalK2 = 1.3;
          break;
        case 'Невеликий метаболічний стрес':
          this.kkalK2 = 1.1;
          break;
        case 'Середній метаболічний стрес':
          this.kkalK2 = 1.3;
          break;
        case 'Значний метаболічний стрес':
          this.kkalK2 = 1.55;
          break;
        case 'Тяжкий метаболічний стрес':
          this.kkalK2 = 1.85;
          break;
      }
    },
    calcKkalK3() {
      if (this.selectedGrowthRate === 'Нормальне зростання' || !this.selectedGrowthRate)
        this.kkalK3 = 1;
      else
        this.kkalK3 = 1.2;
    },
    calcKkalK4() {
      if (this.selectedActivity === 'Лежачий хворий')
        this.kkalK4 = 1;
      else if (this.selectedActivity === 'Сидячий хворий')
        this.kkalK4 = 1.2;
      else
        this.kkalK4 = 1.4;
    },
    calcProteinK1() {
      if (this.selectedAge <= 1) {
        if (this.selectedAgeInMonth <= 6) {
          this.proteinK1 = 1.12;
        }
        if (this.selectedAgeInMonth > 6 && this.selectedAgeInMonth <= 12) {
          this.proteinK1 = 0.95;
        }
      }
      if (this.selectedAge >= 1 && this.selectedAge <= 2) {
        if (this.selectedAgeInMonth <= 18 && this.selectedAgeInMonth > 12) {
          this.proteinK1 = 0.85;
        }
        if (this.selectedAgeInMonth > 18) {
          this.proteinK1 = 0.79;
        }
      }
      if (this.selectedAge > 2 && this.selectedAge <= 3) {
        this.proteinK1 = 0.73;
      }
      if (this.selectedAge > 3 && this.selectedAge <= 4) {
        this.proteinK1 = 0.69;
      }
      if (this.selectedAge > 4 && this.selectedAge <= 5) {
        this.proteinK1 = 0.69;
      }
      if (this.selectedAge > 5 && this.selectedAge <= 6) {
        this.proteinK1 = 0.72;
      }
      if (this.selectedAge > 6 && this.selectedAge <= 7) {
        this.proteinK1 = 0.74;
      }
      if (this.selectedAge > 7 && this.selectedAge <= 8) {
        this.proteinK1 = 0.75;
      }
      if (this.selectedAge > 8 && this.selectedAge <= 9) {
        this.proteinK1 = 0.75;
      }
      if (this.selectedAge > 9 && this.selectedAge <= 10) {
        this.proteinK1 = 0.75;
      }

      if (this.selectedAge > 10 && this.selectedAge <= 11) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.75;
        } else {
          this.proteinK1 = 0.73;
        }
      }
      if (this.selectedAge > 11 && this.selectedAge <= 12) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.74;
        } else {
          this.proteinK1 = 0.72;
        }
      }
      if (this.selectedAge > 12 && this.selectedAge <= 13) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.73;
        } else {
          this.proteinK1 = 0.71;
        }
      }
      if (this.selectedAge > 13 && this.selectedAge <= 14) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.72;
        } else {
          this.proteinK1 = 0.70;
        }
      }
      if (this.selectedAge > 14 && this.selectedAge <= 15) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.72;
        } else {
          this.proteinK1 = 0.69;
        }
      }
      if (this.selectedAge > 15 && this.selectedAge <= 16) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.71;
        } else {
          this.proteinK1 = 0.68;
        }
      }
      if (this.selectedAge > 16 && this.selectedAge <= 17) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.70;
        } else {
          this.proteinK1 = 0.67;
        }
      }
      if (this.selectedAge > 17 && this.selectedAge <= 18) {
        if (this.selectedMale === 'Хлопчик') {
          this.proteinK1 = 0.69;
        } else {
          this.proteinK1 = 0.66;
        }
      }
    },
    calcProteinK2() {
      switch (this.selectedStates) {
        case 'Відсутній метаболічний стрес':
          this.proteinK2 = 1;
          break;
        case 'Відсутній метаболічний стрес, але необхідно набрати вагу':
          this.proteinK2 = 2.5;
          break;
        case 'Невеликий метаболічний стрес':
          this.proteinK2 = 1.65;
          break;
        case 'Середній метаболічний стрес':
          this.proteinK2 = 2.25;
          break;
        case 'Значний метаболічний стрес':
          this.proteinK2 = 2.75;
          break;
        case 'Тяжкий метаболічний стрес':
          this.proteinK2 = 3.5;
          break;
      }
    },
    q1Ctrl() {
      this.qStage = "2";
    },

    // stage 2q

    selectedKkalChange(value) {
      this.selectedKkal = value;
    },
    selectedProteineChange(value) {
      this.selectedProteine = value;
    },
    q2Calc() {
      this.q2Result = {
        selectedKkal: this.selectedKkal,
        kkalD: (this.q1Result.dayNormKkal - this.selectedKkal).toFixed(1)
      };
      this.q2Result.proteineD = (this.q1Result.dayNormOfProteinGrams - this.selectedProteine).toFixed(1);
      this.q2Result.selectedProteine = this.selectedProteine;
      this.setProducts();
      this.selectedProducts = [];
    },
    fixResult() {
      if (this.qStage === '2' && this.q2Result && getElementVisible("dificite")) {
        this.isResultFixed = true;
      } else {
        this.isResultFixed = false;
      }
    },
    setProducts() {
      let result = null;

      if ((this.selectedStates !== 'Вроджені вади серця') && (this.selectedStates !== 'Бронхолегенева дисплазія')) {
        result = this.getProductsFG.filter(item => (
            item.maxAge * 12 >= this.selectedAgeInMonth
            && item.minAge <= this.selectedAge
            && item.maxWeight >= this.selectedWeight
            && item.minWeight <= this.selectedWeight
        ))
      } else {
        result = this.getProductsFG.filter(item => (
            item.maxWeight >= this.selectedWeight
            && item.minWeight <= this.selectedWeight
        ))
      }

      this.products = result.map(item => ({...item, counter: 0}))
    },
    incrementProduct(id) {
      const product = this.products.find(item => item.id === id);
      const selectedProduct = this.selectedProducts.find(item => item.id === id);

      product.counter += product.step;

      this.q2Result.selectedKkal = +(+this.q2Result.selectedKkal + +product.kkalPerStep).toFixed(2);
      this.q2Result.selectedProteine = +(+this.q2Result.selectedProteine + +product.proteinePerStep).toFixed(2);

      this.q2Result.kkalD = +(this.q2Result.kkalD - product.kkalPerStep).toFixed(2);
      this.q2Result.proteineD = +(this.q2Result.proteineD - product.proteinePerStep).toFixed(2);

      if (selectedProduct) {
        selectedProduct.counter = product.counter;
      } else {
        this.selectedProducts = [
          ...this.selectedProducts,
          {
            ...product,
          }
        ]
      }
    },
    decrementProduct(id) {
      const product = this.products.find(item => item.id === id);
      const selectedProduct = this.selectedProducts.find(item => item.id === id);

      if (product.counter >= product.step) {
        product.counter -= product.step;

        this.q2Result.selectedKkal = +(this.q2Result.selectedKkal - product.kkalPerStep).toFixed(2);
        this.q2Result.selectedProteine = +(this.q2Result.selectedProteine - product.proteinePerStep).toFixed(2);

        this.q2Result.kkalD = +(this.q2Result.kkalD + product.kkalPerStep).toFixed(2);
        this.q2Result.proteineD = +(this.q2Result.proteineD + product.proteinePerStep).toFixed(2);
      }

      if (selectedProduct && selectedProduct.counter > selectedProduct.step) {
        selectedProduct.counter = product.counter;
      } else {
        this.selectedProducts = this.selectedProducts.filter(item => item.id !== id);
      }
    },
    async saveImg() {
      const el = this.$refs.toPrint;

      let options = {
        allowTaint: true,
        type: "dataURL"
      };
      this.output = await this.$html2canvas(el, options);

      this.saveAs(this.output, 'Selected-products.png');
    },
    async sendToServerScreen() {
      const el = this.$refs.toPrint;

      let options = {
        allowTaint: true,
        type: "dataURL"
      };
      this.output = await this.$html2canvas(el, options);


      let myFormData = new FormData();
      let blob = this.dataURItoBlob(this.output);
      myFormData.append("picture", blob);


      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.post(this.$root.globalUrl + "api/v1/calculator/save_calc_result", myFormData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      }).then(res => {
        let img = res.data.data.hash;
        document
            .getElementById("viber_share")
            .setAttribute(
                "href",
                "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
                encodeURIComponent(
                    "viber://forward?text=" +
                    encodeURIComponent(this.$root.globalUrl + "" + "uploads/calculator/" + img)
                )
            );
        document.getElementById("viber_share").click();
      })

    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }

      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], {type: mimeString});
      return blob;
    },
    saveAs(uri, filename) {
      let link = document.createElement('a');

      if (typeof link.download === 'string') {

        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);

      } else {

        window.open(uri);

      }
    },

    // system

    goToNextStage(prodNumber) {
      this.$router.push({name: "prod", params: {id: prodNumber}});
    },

    goToMenu() {
      this.$router.push("/algorithms");
    },

    openPopUp(id) {
      if (id !== this.popupCtrl && id !== this.fromPopUp) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        this.popupCtrl = id;
      } else {
        this.popupCtrl = "";
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      }
    },

    openPopupInMenu(value) {
      if (value !== this.popupCtrl) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        this.fromPopUp = value;
        this.popupCtrl = value;
      } else {
        document.getElementsByTagName("body")[0].style.overflow = "visible";
        this.registrationStage = "";
        this.fromPopUp = "";
        this.popupCtrl = "";
      }
    },

    closeLoginPopUp() {
      this.popupCtrl = "";
      this.fromPopUp = "";
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },

    openRegistrationFromLogin() {
      this.popupCtrl = "5";
    },

    openPersonalDataFromRegistration() {
      this.PersonalDataFromPopUp = "5";
      this.popupCtrl = "3";
    },

    backFromPersonalData() {
      if (this.PersonalDataFromPopUp === "5") {
        this.registrationStage = "2";
        this.popupCtrl = this.PersonalDataFromPopUp;
      } else if (this.PersonalDataFromPopUp === "4") {
        this.popupCtrl = this.PersonalDataFromPopUp;
      }
    },

    registrationSendData() {
      this.popupCtrl = "2";
      this.PersonalDataFromPopUp = "";
    },

    closeMailQuestion() {
      this.popupCtrl = "";
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },

    openPersonalDataFromUserInfo() {
      this.PersonalDataFromPopUp = "4";
      this.popupCtrl = "3";
    },

    closeUserInfo() {
      this.popupCtrl = "";
      this.PersonalDataFromPopUp = "";
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    reload() {
      this.$router.go()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/vars";

.diagnostics {

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    .back-links {
      color: #7392b1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392b1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ace6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }

  .content {
    padding: 15px;

    &.hidden {
      @media (max-width: 425px) {
        display: none;
      }
    }

    .q-block {
      display: flex;
      flex-direction: column;
      background: white;
      width: 100%;
      // min-height: calc(100vh - 200px);

      .header-title {
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #dae3ed;

        p {
          color: #9ab9d8;
          font-family: $fontB;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        .back-arrow {
          width: 30px;
          position: absolute;
          left: 15px;
          top: 8px;
          cursor: pointer;
        }
      }

      &.q1,
      &.q2 {
        .h2-title {
          text-align: center;
          color: #1e3b89;
          font-family: $fontSB;
          font-size: 15px;

          padding: 15px;
        }

        .birth-date {
          display: flex;
          align-items: center;

          border-bottom: 1px solid rgba(30, 59, 137, 0.25);

          padding: 12px 20px;

          &.disabled {
            opacity: 0.3;
            pointer-events: none;
          }


          .label {
            display: flex;
            align-items: center;

            width: 45%;

            color: #1E3B89;
            font-size: 15px;
            font-family: $fontR;

            svg {
              margin-right: 15px;
            }
          }

          .mx-datepicker {
            opacity: 0;
            z-index: 1;
          }


          .date {
            position: relative;

            .text {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              display: flex;
              align-items: center;
              justify-content: center;

              height: 35px;
              width: 120px;

              font-size: 12px;
              line-height: 16px;

              border: 1px solid rgba(30, 59, 137, 0.25);
              border-radius: 4px;

              color: #00ACE6;
            }
          }
        }

        .checkbox-wrapper {
          margin-top: 20px;
          padding: 0 15px;

          .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 15px;
            font-size: 15px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            color: #1e3b89;
            font-family: $fontR;
          }

          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 17px;
            width: 17px;
            background-color: white;
            border: 1px solid #00ace6;
            border-radius: 2px;
          }

          .container input:checked ~ .checkmark {
            background: #00ace6;
          }

          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          .container input:checked ~ .checkmark:after {
            display: block;
          }

          .container .checkmark:after {
            left: 5px;
            top: 1px;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        .next-btn {
          margin: 20px 0;
          width: 100%;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;
          background: #00ace6;
          border: 2px solid transparent;
          border-radius: 4px;
          border: none;

          cursor: pointer;
          transition: 0.4s;
          outline: none;
          user-select: none;

          p {
            text-transform: uppercase;
            font-family: $fontB;
            font-size: 15px;
            color: white;
          }

          &:disabled {
            border: 2px solid;
            background: #fff;
            color: #e0ecf7;

            p {
              color: #e0ecf7;
            }
          }

          &.active {
            background-color: #fff;
            color: #00ace6;
            border: 2px solid #00ace6;

            p {
              color: #00ace6;
            }
          }
        }

        .day-norm {
          margin: 0 15px 25px;

          .title {
            height: 35px;

            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            letter-spacing: 0.05em;
            text-transform: uppercase;

            color: #9AB9D8;
            background: rgba(0, 172, 230, 0.1);
            border-radius: 4px 4px 0 0;
            border-bottom: 1px solid #9AB9D8;
          }

          .row {
            display: flex;
            align-items: center;

            margin-bottom: 40px;

            background: rgba(0, 172, 230, 0.1);
            border-radius: 0 0 4px 4px;
          }

          .kkal-norm {
            color: #00ACE6;

            margin: 8px 0;
            padding: 0 20px;

            width: 100%;

            &:first-child {
              border-right: 1px solid #9AB9D8;
            }

            .name {
              margin-bottom: 8px;

              font-weight: 600;
              font-size: 14px;
              line-height: 120%;
            }
          }

          .kkal-container {
            .item {
              display: flex;
              align-items: center;

              font-size: 14px;

              .icon {
                margin-right: 8px;
              }

              .number {
                font-weight: 600;
                font-size: 18px;
                line-height: 105%;
              }
            }
          }

          &-btn {
            margin: auto auto 20px auto;
            width: 100%;
            height: 50px;

            display: flex;
            justify-content: center;
            align-items: center;
            background: #00ace6;
            border: 2px solid transparent;
            border-radius: 4px;
            border: none;

            text-transform: uppercase;
            font-family: $fontB;
            font-size: 15px;
            color: white;

            cursor: pointer;
            transition: 0.4s;
            outline: none;
          }
        }
      }
    }

    .q2 {
      .day-norm {
        margin: 0 0 15px;

        .title {
          height: 30px;

          font-size: 12px;
          line-height: 16px;

          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          letter-spacing: 0.05em;
          text-transform: uppercase;

          color: #9AB9D8;
          background: rgba(0, 172, 230, 0.1);
          border-radius: 4px 4px 0 0;
        }

        .row {
          display: flex;
          align-items: center;

          background: rgba(0, 172, 230, 0.1);
          border-radius: 0 0 4px 4px;
        }

        .kkal-norm {
          color: #00ACE6;

          margin: 0 0 8px 0;
          padding: 0 30px;

          width: 100%;

          &:first-child {
            border-right: 1px solid #9AB9D8;
          }
        }

        .kkal-container {
          .item {
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 14px;

            .icon {
              margin-right: 8px;

              svg {
                max-height: 20px;
                width: auto;
              }
            }

            .number {
              font-weight: 600;
              font-size: 15px;
              line-height: 105%;
            }
          }
        }

        &-btn {
          // margin: auto auto 20px auto;
          width: 100%;
          height: 50px;

          display: flex;
          justify-content: center;
          align-items: center;
          background: #00ace6;
          border: 2px solid transparent;
          border-radius: 4px;
          border: none;

          text-transform: uppercase;
          font-family: $fontB;
          font-size: 15px;
          color: white;

          cursor: pointer;
          transition: 0.4s;
          outline: none;
        }
      }

      .subtitle {
        padding: 13px;

        font-family: $fontB;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;

        color: #1E3B89;
        border-bottom: 1px solid rgba(30, 59, 137, 0.2);
      }

      .calc-btn {
        // margin: 20px 15px;
        width: 100%;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
        background: #00ace6;
        border: 2px solid transparent;
        border-radius: 4px;
        border: none;

        cursor: pointer;
        transition: 0.4s;
        outline: none;

        text-transform: uppercase;
        font-family: $fontB;
        font-size: 15px;
        color: white;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        &:disabled {
          border: 2px solid;
          background: #fff;
          color: #e0ecf7;
        }

        &.active {
          background-color: #fff;
          color: #00ace6;
          border: 2px solid #00ace6;
        }
      }

      .dificite {
        &-item {
          padding: 10px;
          margin-bottom: 15px;

          border: 1px solid #E0ECF7;
          border-radius: 12px;
        }

        &-title {
          display: flex;
          align-items: center;

          margin-bottom: 11px;

          font-size: 14px;
          line-height: 105%;

          color: #1E3B89;

          svg {
            margin-right: 8px;
          }

          strong {
            font-family: $fontB;
          }
        }

        &-bar {
          position: relative;

          display: flex;
          align-items: center;
          justify-content: flex-end;

          height: 28px;
          width: 100%;

          padding: 0 10px;

          border-radius: 91px;

          background: rgba(224, 236, 247, 0.8);
          color: #1E3B89;

          font-size: 12px;
        }

        &-indicator {
          position: absolute;
          left: 0;
          top: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          height: 100%;
          max-width: 100%;

          padding: 0 10px;

          background: #00ACE6;
          color: #fff;
          border-radius: 91px;

          font-size: 12px;
        }

        &-text {
          margin-top: 5px;

          font-size: 11px;
          line-height: 105%;
          font-family: $fontB;

          display: flex;
          align-items: center;

          color: #00ACE6;
        }

        &-fixed {
          display: none;

          position: fixed;
          top: 60px;
          left: 0;
          z-index: 100;

          width: 100%;

          padding: 7px 15px;

          background: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(20px);

          &.active {
            @media (max-width: 425px) {
              display: block;
            }
          }

          .text {
            position: absolute;
            top: 50%;
            left: 8px;

            transform: translateY(-50%);

            font-size: 10px;
            line-height: 105%;

            display: flex;
            align-items: center;

            color: rgba(30, 59, 137, 0.5);
          }

          .dificite-bar {
            height: 17px;
          }

          .dificite-indicator {
            overflow: hidden;

            .text {
              color: #fff;
              z-index: 1;
            }
          }

          .dificite-item {
            padding: 0;
            border: none;
          }

          .dificite-title {
            font-size: 12px;
            line-height: 105%;

            margin-bottom: 6px;
          }
        }
      }

      .correction {
        margin-top: 30px;
        min-height: unset;

        .title {
          font-size: 12px;
          line-height: 16px;
          font-family: $fontB;

          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          padding: 20px 0;

          letter-spacing: 0.11em;
          text-transform: uppercase;

          color: #9AB9D8;
          background: #D5EAF8;
          border-bottom: 1px solid rgba(154, 185, 216, 0.25);
          border-radius: 4px 4px 0 0;
        }

        .subtitle {
          text-align: center;

          background: #D5EAF8;
          border: none;
        }

        .list {

          .card {
            display: flex;

            padding: 12px 13px 20px 0;

            border-bottom: 1px solid rgba(154, 185, 216, 0.25);

            .img {
              width: 40%;
            }

            img {
              display: block;
              max-width: 70%;
              max-height: 180px;
              margin: 0 auto;
            }

            .description {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              width: 60%;

              &-title {
                font-size: 15px;
                line-height: 18px;
                font-family: $fontB;

                color: #1E3B89;

                margin-bottom: 8px;
              }

              &-size {
                font-size: 14px;
                line-height: 122.56%;

                color: #1E3B89;

                margin-bottom: 8px;
              }

              &-text {
                font-size: 12px;
                line-height: 122.56%;

                color: rgba(30, 59, 137, 0.7);
              }

              &-subtext {
                margin-top: 11px;
                font-weight: 300;
                font-size: 11px;
                line-height: 122.56%;

                color: rgba(30, 59, 137, 0.7);
              }

              &-counter {
                display: flex;
                align-items: center;

                margin-top: auto;
              }

              button {
                display: flex;
                align-items: center;

                height: 25px;

                border: none;
                outline: none;
                background: inherit;

                cursor: pointer;
              }

              &-quantity {
                width: 100%;
                height: 38px;

                margin: 0 15px;

                font-size: 12px;
                line-height: 300%;

                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                color: #1E3B89;
                border: 1px solid #DAE3ED;
              }
            }
          }
        }
      }

      .selected {
        margin-top: 15px;

        background: #D5EAF8;
        border-radius: 4px;

        .list {
          .item {
            position: relative;

            display: flex;
            align-items: center;

            padding: 7px 15px 7px 0;

            border-bottom: 1px solid rgba(154, 185, 216, 0.25);

            &:after {
              content: '+';

              position: absolute;
              bottom: -5px;
              left: 30%;

              transform: translateX(-50%);

              width: 10px;
              height: 10px;

              font-size: 18px;
              line-height: 10px;

              color: #1E3B89;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }

            .img {
              width: 30%;
            }

            img {
              max-width: 50%;
              max-height: 100px;
              display: block;
              margin: 0 auto;
            }

            .name {
              font-size: 14px;
              line-height: 16px;
              font-family: $fontB;

              color: #1E3B89;
            }

            .quantity {
              font-size: 14px;
              line-height: 16px;

              margin-left: auto;

              color: #1E3B89;
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 35px 12px 22px;

          .print-btn {
            height: 40px;
            width: 100%;

            margin-right: 5px;

            border: 1px solid #00ACE6;
            border-radius: 4px;
            background: transparent;

            font-family: $fontB;
            font-size: 12px;
            line-height: 122.56%;

            display: flex;
            align-items: center;
            text-transform: uppercase;
            justify-content: center;

            color: #00ACE6;

            svg {
              margin-right: 5px;
            }
          }

          .send-btn {
            height: 40px;
            width: 100%;

            border-radius: 4px;
            border: none;
            background: #00ACE6;

            font-family: $fontB;
            font-size: 12px;
            line-height: 122.56%;

            display: flex;
            align-items: center;
            text-transform: uppercase;
            justify-content: center;

            color: #fff;

            svg {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .q1Result {
      display: flex;
      flex-direction: column;
      background: white;
      width: 100%;
      min-height: calc(100vh - 200px);

      .header-title {
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        border-bottom: 1px solid #dae3ed;

        p {
          color: #9ab9d8;
          font-family: $fontB;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        .back-arrow {
          width: 30px;
          position: absolute;
          left: 15px;
          top: 8px;
          cursor: pointer;
        }
      }

      .chart-wrapper {
        padding: 25px 0;
        border-bottom: 1px solid #dae3ed;
      }

      .q1Result-bottom {
        padding: 20px;
        margin-bottom: 5px;

        color: #1E3B89;
        background: #D5EAF8;

        .title {
          width: 100%;

          padding-bottom: 12px;
          margin-bottom: 8px;

          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-transform: uppercase;

          border-bottom: 1px solid;
        }

        .text {
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .calc-btn {
        position: fixed;
        bottom: 15px;
        margin: auto 15px 0 15px;
        max-width: 365px;
        width: 84%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00ace6;
        border-radius: 4px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        p {
          color: white;
          text-transform: uppercase;
          font-family: $fontB;
          letter-spacing: 2px;
          font-size: 15px;
        }
      }

      .q1Result-block-wrapper {
        padding: 15px 0;

        .pack-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 0;
          border-top: 1px solid #dae3ed;
          border-bottom: 1px solid #dae3ed;

          img {
            display: block;
            max-width: 130px;
            width: 100%;
            height: auto;
          }

          .pack-name {
            color: #1e3b89;
            font-size: 15px;
            font-family: $fontB;
            text-align: center;
            margin-bottom: 20px;
          }

          .desk {
            margin-top: 10px;
          }

          .calc-btn {
            margin-top: 30px;
            max-width: 260px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            border: 1px solid #00ace6;
            box-sizing: border-box;
            border-radius: 4px;
            height: 40px;
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Chrome/Safari/Opera */
            -khtml-user-select: none; /* Konqueror */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

            p {
              text-transform: uppercase;
              color: #00ace6;
              font-size: 14px;
              font-family: $fontB;
            }
          }
        }
      }
    }

    .info {
      position: relative;
      width: auto;
      height: auto;
      padding: 9px 6px 12px 19px;
      margin-left: 10px;
      border: 1px solid #1E3B89;
      border-radius: 4px;

      p {
        font-size: 13px;
        color: #1E3B89;

        span {
          display: inline-block;

          &.m-t {
            margin-top: 11px;
          }
          &.m-b {
            margin-bottom: 6px;
          }

          &.list {
            padding-left: 20px;

            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 2px;
              top: 8px;
              width: 4px;
              height: 4px;
              background: #1E3B89;
              border-radius: 50%;
            }
          }
        }
      }

      strong {
        font-family: $fontB;
      }

      .icon {
        position: absolute;
        z-index: 1;
        left: -12px;
        top: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #1E3B89;
        background-color: #fff;
      }
    }
  }
}
</style>
