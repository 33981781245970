import { render, staticRenderFns } from "./first-popup.vue?vue&type=template&id=5803e269&scoped=true&"
import script from "./first-popup.vue?vue&type=script&lang=js&"
export * from "./first-popup.vue?vue&type=script&lang=js&"
import style0 from "./first-popup.vue?vue&type=style&index=0&id=5803e269&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5803e269",
  null
  
)

export default component.exports