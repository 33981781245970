<template>
  <div class="cards">
    <div class="cards-content">
      <div class="header-image">
        <img src="@/assets/images/comfort-cards4/banner.jpg" alt=""/>
      </div>

      <div class="cards-container">
        <div class="card" v-for="(card, index) in cards" :key="index">
          <div class="card-image">
            <img :src="card.img" alt=""/>
          </div>
          <div class="card-content">
            <div class="number">
              {{ index + 1 }}
            </div>
            <hr/>
            <div class="title">
              {{ card.title }}
            </div>
            <div class="text" v-html="card.text"></div>
            <div class="drop-list" v-for="(list, index) in card.drop_down_lists" :key="index">
              <div class="list-title" @click="list.isOpened = !list.isOpened">
                <div class="text">
                  {{ list.title }}
                </div>
                <div class="icon" :class="list.isOpened ? 'rotate' : ''">
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75678 1.24322L6.26947 5.7787" stroke="white" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.26951 5.7787L10.7787 1.24323" stroke="white" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <div class="list-content" v-if="list.isOpened">
                <div v-html="list.data"></div>
              </div>
            </div>
          </div>
          <div class="card-link" v-if="card.link">
            <hr/>
            <router-link class="url" :to="card.link.ref">{{
                card.link.name
              }}
            </router-link>
          </div>
          <div class="card-note" v-if="card.note">
            <hr/>
            <p class="card-note__text">
              {{ card.note }}
            </p>
          </div>
        </div>

        <div class="cards-container-info">
          <strong>
            Безумовна перевага у годуванні дитини перших років життя надається грудному вигодовуванню.
          </strong>
          <br>
          <br>
          Згідно рекомендацій МОЗ України воно є основним харчуванням для малюка на першому році життя навіть після
          введення прикорму.
          <br>
          <br>
          ВООЗ рекомендує зберігати грудне вигодовування до 2 років.
          <br>
          <br>
          Nutricia підтримує зазначені рекомендації та попереджає, що штучне вигодовування може негативно вплинути на
          грудне.
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import Image1 from "../assets/images/comfort-cards4/img1.png";
import Image2 from "../assets/images/comfort-cards4/img2.png";
import Image3 from "../assets/images/comfort-cards4/img3.png";
import Image4 from "../assets/images/comfort-cards4/img4.png";
import Image5 from "../assets/images/comfort-cards4/img5.png";
import Image6 from "../assets/images/comfort-cards4/img6.png";
import Image7 from "../assets/images/comfort-cards4/img7.png";
import Image8 from "../assets/images/comfort-cards4/img8.png";
import Image9 from "../assets/images/comfort-cards4/img9.png";
import Image10 from "../assets/images/comfort-cards4/img10.png";

export default {
  name: "cards3",
  data: () => ({


    cards: [
      {
        img: Image1,
        title: "1896 – народження Nutricia",
        text:
            "У нідерландському місті Зутермер налагоджено виробництво перших у світі адаптованих сумішей завдяки запатентованій технології нормалізації вмісту казеїну. Інші неадаптовані суміші мало вирішували проблему дитячої смертності, а послуги жінок-годувальниць могли дозволити не всі",
        link: "",
        note: "Nutricia – «годувальниця» у перекладі з латини",
      },
      {
        img: Image2,
        title: "1924 – робота з медичними працівниками",
        text: `Для поширення знань серед сімейних лікарів засновано журнал «Для кабінету лікаря». У 1950 сформовано команду експертів з дієтології – перших медичних представників. У 1986 відкрито першу телефонну лінію підтримки. У 2001 медичні працівники й телефонна лінія запрацювали в Україні`,
        link: "",
      },
      {
        img: Image3,
        title: "1946 – власні фундаментальні дослідження",
        text:
            `
        <p>Першу дослідницьку лабораторію відкрито на фабриці в Зутермері​</p>
        <p>
          <strong>1968</strong> – аналіз білкових фракцій грудного молока​<br>
          <strong>1980</strong> – амінокислотний профіль грудного молока​<br>
          <strong>1983</strong> – ефекти омега-3 й омега-6 жирних кислот​<br>
          <strong>1994</strong> – знайдено олігосахариди у грудному молоці​<br>
          <strong>1995</strong> – створено працюючу модель кишечника​<br>
          <strong>2011</strong> – досліджено мікрофлору грудного молока й метаболіти<br>
        </p>
        `,
        link: "",
      },
      {
        img: Image4,
        title: "1951 – суміші для передчасно народжених дітей",
        text: `Nutricia першою в світі розробила суміші для передчасно народжених і дітей з малою вагою від народження й постійно їх вдосконалює. У 1995 такі суміші вперше збагачено докозагексаєновою й арахідоновою жирними кислотами`,
        link: '',
      },
      {
        img: Image5,
        title: "1952 – суміші при розладах травлення",
        text: "Першою серед сумішей для нормалізації травлення стала антирефлюксна. У 2000 розроблено першу в світі суміш комплексної дії при кольках, запорах і зригуваннях, назва якої дала назву цілої категорії – Комфорт",
        link: "",
      },
      {
        img: Image6,
        title: "1984 – суміші для дітей з алергією",
        text: `Перший глибокий гідролізат було розроблено для дітей від 1 року. Вдосконалення технології дозволило з 1992 виробляти гідролізати для дітей від народження. Згодом розпочалось виробництво амінокислотних сумішей`,
        link: "",
      },
      {
        img: Image7,
        title: "1987 – стадії базових сумішей",
        text: "Для дітей від 6 місяців розроблено окрему формулу базової суміші, що краще відповідала їхнім нутритивним потребам. Загальноприйнятий зараз підхід свого часу став революцією. У 2000 суміші Nutrilon першими в світі збагачено пребіотиками, у 2018 – постбіотиками, новим класом речовин",
        link: "",
      },
      {
        img: Image8,
        title: "1994 – суміші при недоборі ваги",
        text: "Розроблено першу суміш для зондового вигодовування дітей від 1 року з нутритивною недостатністю. Сучасний асортимент клінічного харчування охоплює суміші для усіх вікових груп і різних супутніх захворювань",
        link: "",
      },
      {
        img: Image9,
        title: "2013 – відкриття центру інновацій​",
        text: "Сучасна дослідницька мережа охоплює головний центр інновацій у нідерландському місті Утрехт та наукові центри у Великобританії, Сінгапурі, Бразилії і Китаї. Наукова робота – основа регулярного вдосконалення рецептур",
        link: "",
      },
      {
        img: Image10,
        title: "2019 – найсучасніше виробництво",
        text: "У нідерландському місті Хапс збудовано новітню фабрику з виробництва спеціальних сумішей. До 2023 усі фабрики Nutricia споживатимуть енергію виключно з відновлюваних джерел і повністю утилізуватимуть відходи",
        link: "",
      },
    ],
  })
};
</script>

<style lang="scss" scoped>
@import "../style/vars";

.cards {
  height: 100%;
  position: relative;

  &-content {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;

    .header-image {
      margin-bottom: 30px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-container {
    padding: 0 15px;

    .card {
      background: #fff;
      border-radius: 4px;
      margin-bottom: 36px;

      &-note {
        padding: 0 20px 20px;

        &__text {
          padding-top: 17px;
          font-style: italic;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: #9AB9D8;
        }
      }

      &-image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &-content {
        padding: 9px 19px 20px 20px;

        .number {
          width: 34px;
          height: 34px;

          max-width: 34px;
          max-height: 34px;

          margin-bottom: 8px;

          border-radius: 50%;
          border: 1.17241px solid #9ab9d8;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 17px;
          font-weight: bold;
          color: #9ab9d8;
        }

        hr {
          margin-bottom: 14px;
        }

        .title {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;

          color: #1e3b89;

          margin-bottom: 12px;
        }

        .text {
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;

          color: #1e3b89;

          ::v-deep p {
            margin-bottom: 15px;

            sub {
              line-height: 0;
            }
          }
        }

        .schema {
          margin-top: 20px;

          svg {
            min-width: 100%;
          }
        }
      }

      .drop-list {
        display: flex;
        flex-direction: column;

        .list-title {

          display: none;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          padding: 8px 20px 15px 19px;
          // background: rgba(0, 172, 230, 0.6);
          border-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .icon {
            display: flex;
            align-items: center;
          }

          .text {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #1e3b89;
          }

          .rotate {
            transform: rotate(180deg);
          }
        }

        .list-content {
          .content {
            border-top: 1px solid #dae3ed;
            background: rgba(0, 172, 230, 0.1);

            padding: 14px 19px 16px 20px;

            p {
              font-weight: 300;
              font-size: 14px;
              line-height: 130%;
              color: #1e3b89;

              padding-bottom: 10px;
            }

            p:last-child {
              padding-bottom: 0;
            }
          }

          ::v-deep .structure-table {
            width: 100%;
            margin-top: 4px;

            border-bottom: none;
            color: #1e3b89;
            border-top: 1px solid #E0ECF7 !important;


            thead {
              th {
                padding-top: 2px;

                text-align: center;

                font-size: 12px;
                line-height: 14px;

              }

              th:first-child {
                background: rgba(203, 223, 242, 0.15);
                border-left: 1px solid #e0ecf7;
              }

              th:last-child {
                border-right: 1px solid #e0ecf7;
                border-left: 1px solid #e0ecf7;
              }
            }

            tbody {
              padding-top: 17px;

              tr {
                border-top: 1px solid #E0ECF7 !important;

                th {
                  background: rgba(203, 223, 242, 0.15);

                  min-width: 90px;

                  text-align: left;
                  padding: 15px 6px 15px 11px;

                  font-size: 14px;
                  line-height: 18px;

                  border-left: 1px solid #e0ecf7;

                  sub {
                    line-height: 0;
                  }
                }

                td {
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: center;

                  padding: 0 10px;
                }

                td:last-child {
                  border-right: 1px solid #e0ecf7;
                  border-left: 1px solid #e0ecf7;
                }
              }

              tr:last-child {
                th,
                td {
                  border-bottom: 1px solid #e0ecf7;
                }
              }
            }
          }
        }
      }

      .drop-list:last-child {
        margin-bottom: 0;
      }

      .list {
        .item {
          display: flex;
          flex-direction: row;
          margin-bottom: 14px;

          .icon {
            min-width: 37px;
            margin-right: 16px;
          }

          .item-text {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #1E3B89;
          }
        }
      }

      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #dae3ed;
        margin-bottom: 7px;
        padding: 0;
      }

      &-link {
        padding-bottom: 8px;

        .url {
          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: bold;
          font-size: 15px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: #00ace6;
        }

        .url::after {
          content: "\203A";

          margin-left: 5px;
          margin-bottom: 3px;

          font-weight: bold;
          font-size: 25px;
          color: #00ace6;
        }
      }
    }

    &-info {
      margin: 0 -15px 0 -15px;
      background: #E1ECF7;
      padding: 20px 20px;

      font-weight: 300;
      font-size: 12px;
      line-height: 15px;

      color: #1E3B89;
    }
  }
}
</style>
