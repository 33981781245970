<template>
  <root-popup :popupName="getPopupName()" @closePopup="$emit('closePopup')">

    <div class="language">
      <div class="text">Оберіть мову</div>

      <a id="viber_share" style="display: none;" href=""></a>

      <!-- READ -->
      <a :href="$root.globalUrl + contentUa.name" target="_blank" class="ukr"
         v-if="action === 'read' && contentUa != null">українська</a>
      <a :href="$root.globalUrl + contentRu.name" target="_blank" class="ru"
         v-if="action === 'read' && contentRu != null">русский</a>
      <a :href="$root.globalUrl + contentEn.name" target="_blank" class="en"
         v-if="action === 'read' && contentEn != null">english</a>

      <!-- DOWNLOAD -->
      <a :href="$root.globalUrl + contentUa.name" download class="ukr"
         v-if="action === 'download' && contentUa != null">українська</a>
      <a :href="$root.globalUrl + contentRu.name" download class="ru"
         v-if="action === 'download' && contentRu != null">русский</a>
      <a :href="$root.globalUrl + contentEn.name" download class="en"
         v-if="action === 'download' && contentEn != null">english</a>

      <!-- send-video -->
      <div class="ukr" @click="sendToServerScreen(contentUa.linkUa)"
           v-if="action === 'send' && contentUa.linkUa != null">українська
      </div>
      <div class="ru" @click="sendToServerScreen(contentRu.linkRu)"
           v-if="action === 'send' && contentRu.linkRu != null">русский
      </div>
      <div class="en" @click="sendToServerScreen(contentEn.linkEn)"
           v-if="action === 'send' && contentEn.linkEn != null">english
      </div>

      <!-- scientific-article -->
      <div class="ukr" @click="sendToServerScreen2(contentUa.name)"
           v-if="action === 'send2' && contentUa != null">українська
      </div>
      <div class="ru" @click="sendToServerScreen2(contentRu.name)"
           v-if="action === 'send2' && contentRu != null">русский
      </div>
      <div class="en" @click="sendToServerScreen2(contentEn.name)"
           v-if="action === 'send2' && contentEn != null">english
      </div>

    </div>

  </root-popup>
</template>

<script>
import RootPopup from '../popups/root-popup';

export default {
  props: ['action', 'contentUa', 'contentRu', 'contentEn'],
  components: {
    'root-popup': RootPopup
  },
  created(){

  },
  mounted(){
        const UNAUTHORIZED = 401;
          axios.interceptors.response.use(
            response => response,
            error => {
              const {status} = error.response;
              if (status === UNAUTHORIZED) {
                sessionStorage.removeItem('key');
              }
              return Promise.reject(error);
            }
          );
    },
  methods: {
    sendToServerScreen(link) {
      document
          .getElementById("viber_share")
          .setAttribute(
              "href",
              "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
              encodeURIComponent(
                  "viber://forward?text=" +
                  encodeURIComponent(link)
              )
          );
      document.getElementById("viber_share").click();
    },

    sendToServerScreen2(link) {
      document
          .getElementById("viber_share")
          .setAttribute(
              "href",
              "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
              encodeURIComponent(
                  "viber://forward?text=" +
                  encodeURIComponent(this.$root.globalUrl + link)
              )
          );
      document.getElementById("viber_share").click();
    },

    getPopupName() {
      switch (this.action) {
        case 'read' :
          return 'читання';
          break;
        case 'download' :
          return 'завантаження';
          break;
        case 'send' :
          return 'надіслати';
          break;
        case 'send2' :
          return 'надіслати';
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  padding-top: 124px;
}

.language {
  padding: 29px 14px 37px 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .text {
    width: 100%;

    text-align: center;
    padding-bottom: 35px;

    font-size: 19px;
    line-height: 26px;
    color: #4A2A80;
  }

  .ukr, .en, .ru {
    width: 125px;
    height: 40px;

    margin: 0 auto;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    background: #8F67A4;
    border-radius: 4px;

    text-transform: uppercase;
  }
}
</style>
