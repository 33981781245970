<template>
    <div class="item">
        <div class="visible-item" :class="!isOpen ? '': 'active'" @click="isOpen = !isOpen">
            <div class="icon-text">
                <div class="icon">
                    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Автор'">
                        <path d="M10.4288 12.8462C13.9003 12.8462 16.7145 10.1943 16.7145 6.92308C16.7145 3.65185 13.9003 1 10.4288 1C6.95728 1 4.14307 3.65185 4.14307 6.92308C4.14307 10.1943 6.95728 12.8462 10.4288 12.8462Z" stroke="#4A2A80" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.4286 12.8462C15.636 12.8462 19.8572 17.392 19.8572 23H1C1 19.4716 2.66813 16.3666 5.20166 14.5466" stroke="#4A2A80" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Видання'">
                        <path d="M18.5164 2.56829H16.4489V0.500855C16.4489 0.349948 16.3886 0.214131 16.2679 0.123586C16.1622 0.0330413 16.0113 -0.0122311 15.8604 0.00285971L0.422542 2.58338C0.181089 2.61356 0 2.82483 0 3.08138V23.2125C0 23.4841 0.226362 23.7105 0.497996 23.7105H18.5013C18.7729 23.7105 18.9993 23.4841 18.9993 23.2125V3.08138C19.0144 2.79465 18.788 2.56829 18.5164 2.56829ZM18.0033 3.57937V22.6994H6.76067L10.1712 22.0958L16.0264 21.1299C16.2679 21.0847 16.4489 20.8734 16.4489 20.632V3.57937H18.0033ZM15.4379 1.10449V20.2094L1.01108 22.6088V3.50392L15.4379 1.10449Z" fill="#4A2A80"/>
                        <path d="M4.07426 7.63893L12.4496 6.34112C12.5855 6.32603 12.7062 6.25057 12.7816 6.14494C12.8571 6.0393 12.8873 5.91858 12.8722 5.78276C12.8571 5.64694 12.7816 5.52622 12.676 5.45076C12.5704 5.37531 12.4345 5.34513 12.2987 5.36022L3.98371 6.64293C3.86298 6.67312 3.74226 6.73348 3.6668 6.8542C3.57626 6.95984 3.54608 7.09566 3.57626 7.21638C3.60644 7.45784 3.81771 7.63893 4.07426 7.63893Z" fill="#4A2A80"/>
                    </svg>
                    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="defaultName === 'Суміші'">
                        <line x1="2.50742" y1="3.71423" x2="2.50742" y2="22.2857" stroke="#4A2A80" stroke-width="1.3"/>
                        <line x1="21.0787" y1="3.71423" x2="21.0787" y2="9.28566" stroke="#4A2A80" stroke-width="1.3"/>
                        <line x1="21.0787" y1="11.1428" x2="21.0787" y2="22.2857" stroke="#4A2A80" stroke-width="1.3"/>
                        <mask id="path-4-inside-1" fill="white">
                        <rect y="22.2858" width="23.2143" height="3.71429" rx="0.928571"/>
                        </mask>
                        <rect y="22.2858" width="23.2143" height="3.71429" rx="0.928571" stroke="#4A2A80" stroke-width="2.41429" mask="url(#path-4-inside-1)"/>
                        <mask id="path-5-inside-2" fill="white">
                        <rect width="23.2143" height="3.71429" rx="0.928571"/>
                        </mask>
                        <rect width="23.2143" height="3.71429" rx="0.928571" stroke="#4A2A80" stroke-width="2.41429" mask="url(#path-5-inside-2)"/>
                        <path d="M12.5029 9.02464H11.9458V9.58179V14.811C11.9458 15.5214 12.5229 16.0986 13.2334 16.0986H17.2065C17.9168 16.0986 18.4938 15.5213 18.4938 14.811V11.0739H28.2782C28.8436 11.0739 29.3027 10.6147 29.3027 10.0494C29.3027 9.48399 28.8437 9.02464 28.2782 9.02464H25.6783H12.5029Z" stroke="#4A2A80" stroke-width="1.11429"/>
                    </svg>
                </div>
                <p v-if="selectedData.length === 0">{{defaultName}}</p>
                <p class="clip" v-else>{{getStringFromArr(selectedData)}}</p>
            </div>
            <div class="arrow" :class="!isOpen ? '': 'rotated'">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.022 1L5.50928 5.53548" stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.50924 5.53548L1 1" stroke="#8F67A4" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <transition name="fade">
            <div class="container" :class="!isOpen ? '': 'active'" v-if="isOpen">
                <label class="checkbox-container" v-for="(d, index) in data" :key="index">{{d.name}}
                    <input type="checkbox" :value="d" v-model="localSelectedData">
                    <span class="checkmark"></span>
                </label>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props : ['data', 'selectedData', 'iconArr', 'defaultName'],
    data : () => ({
        isOpen : false,
        localSelectedData : []
    }),
    created(){
        this.localSelectedData = this.selectedData;
    },
    watch : {
        localSelectedData : function(value){
            this.$emit('selectedDataChange', value);
        }
    },
    methods : {
        getStringFromArr(val){
            let outStr = '';

            for(let i = 0; i < val.length; i++){
                if(i !== val.length - 1)
                    outStr += val[i].name + ', ';
                else{
                    outStr += val[i].name;
                }
            }

            return outStr;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/vars";

.item {
    border-bottom: 1px solid rgba(172, 152, 197, 0.3);

    .visible-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        cursor: pointer;

        .arrow{
            transition: transform 500ms ease;
        }

        .rotated{
            transform: rotate(-180deg);
            transition: transform 500ms ease;
        }


        .icon-text {
            display: flex;
            align-items: center;
            width: 90%;

            .icon {
                margin-right: 15px;
                max-width: 30px;
                width: 100%;

                svg {
                    height: auto;
                    display: block;
                }

            }

            p {
                color: #4A2A80;
                font-size: 15px;
                font-family: $fontR;
            }

            .clip{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .active{
        background: #EFE1F7;
    }

    .container{
        padding: 16px 39px 0 34px;
        border-top: 1px solid rgba(172, 152, 197, 0.3);

        max-height: 320px;
        overflow: scroll;

        .checkbox-container {
            display: block;
            position: relative;
            padding-left: 35px;
            padding-bottom: 15px;
            font-size: 15px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            color: #4A2A80;
            font-family: $fontR;
        }

        .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 17px;
            width: 17px;
            background-color: white;
            border: 1px solid #4A2A80;
            border-radius: 2px;
            cursor: pointer;
        }

        .checkbox-container input:checked ~ .checkmark {
            background: #4A2A80;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        .checkbox-container input:checked ~ .checkmark:after {
            display: block;
        }

        .checkbox-container .checkmark:after {
            left: 5px;
            top: 1px;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .hidden-item{
        padding: 15px 20px;
        cursor: pointer;

        border-top: 1px solid #DAE3ED;

        display: flex;
        flex-direction: row;
        align-items: center;

        background: #E0ECF7;
        box-shadow: 0px 14px 21px rgba(0, 0, 0, 0.08);

        .icon{
            width: 39px;
            height: 33px;
            margin-right: 10px;

            div{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg{
                    width: 100%;
                    height: auto;
                }
            }
        }

        .text{
            font-size: 15px;
            line-height: 20px;
            color: #4A2A80;
        }
    }

}
</style>