<template>
  <div class="first-popup-wrapper">

    <div v-if="!$route.fullPath.includes('clinical')" class="first-popup">
      <div class="popup-block">
        <div class="head">
          <p>важливо</p>
        </div>
        <div class="text">
          <span>Ви підтверджуєте, що маєте професійну медичну освіту та є спеціалістом системи охорони здоров’я України?</span>

          Цей портал компанії Nutricia призначений виключно для медичних працівників системи охорони здоров’я України.
          Інформація про продукти компанії Nutricia, що міститься на порталі, має науково-інформаційний характер та не
          повинна розцінюватись як реклама для широкого кола осіб
        </div>
        <div class="btn-wrapper">
          <div class="confirm" @click="confirm()">Підтверджую</div>
          <div class="deni" @click="reject()">Не підтверджую</div>
        </div>
      </div>
    </div>

    <first v-if="$route.fullPath.includes('clinical')"></first>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import first from "@/components/Clinical/first-popup.vue";

export default {
    name: "first-popup",
    components: {
        first
    },
    methods: {
        ...mapActions(['setMenuData']),
        confirm() {
            this.setMenuData({popupState: '3'})
        },
        reject() {
            this.setMenuData({popupState: '7'})
        },
    }
}
</script>

<style scoped lang="scss">

@import "../style/vars";

.first-popup {
  overflow: auto;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(88, 101, 136, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 100;

  @media screen and (min-width: 426px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 20px;

    margin: 0 auto;

    max-width: 425px;
    width: 100%;
  }

  .popup-block {
    min-height: 400px;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    max-width: 425px;
    overflow: hidden;
    padding-bottom: 20px;

    @media screen and (min-width: 426px) {
      position: absolute;

      max-width: 395px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .head {
      padding: 20px 14px;

      background: #EEF3F8;

      p {
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.11em;
        text-transform: uppercase;

        color: #9AB9D8;

        font-family: $fontB;
      }
    }

    .text {
      font-size: 14px;
      line-height: 19px;
      padding: 20px 15px 30px 15px;

      color: #1E3B89;
      font-family: $fontL;

      span {
        font-size: 15px;
        line-height: 20px;

        font-family: $fontSB;
        margin-bottom: 12px;
        display: block;
      }
    }

    .confirm {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: #FFFFFF;
      font-family: $fontB;
      background: #00ACE6;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 20px;

      cursor: pointer;
    }

    .deni {
      font-size: 15px;
      line-height: 18px;

      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: #00ACE6;
      font-family: $fontB;

      border: 1px solid #00ACE6;
      border-radius: 4px;

      padding: 10px;
      cursor: pointer;
    }

    .btn-wrapper {
      padding: 0 20px;
    }
  }
}
</style>