<template>
  <root-popup :popupName="'продукт'" @closePopup="$emit('closePopup')">

    <div class="product-info">
      <div class="product-name">
        {{ productInfo.title }}
      </div>
      <div class="product-description">
        {{ productInfo.description }}
      </div>
      <div class="for-container">
        <div class="product-use-for" v-for="(word, index) in productInfo.tags" :key="index">
          {{ word.title }}
        </div>
      </div>
      <div class="product-image">
        <img :src="$root.globalUrl + productInfo.image.name" alt="">
      </div>

      <!-- <router-link class="more-info" :to="getRef(productInfo.link)" tag="div">
          докладніше
      </router-link> -->
      <a :href="productInfo.link" class="more-info">докладніше</a>
    </div>

  </root-popup>
</template>

<script>
import RootPopup from '../popups/root-popup';

export default {
  props: ['productInfo'],
  components: {
    'root-popup': RootPopup
  },
  methods: {
    getRef(str){
      return str.substring(7);
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  padding-top: 48px;
}

.product-info {
  padding: 15px 16px 23px 14px;

  .product-name {
    font-weight: 600;
    font-size: 17px;
    line-height: 135%;
    color: #1E3B89;

    padding-bottom: 3px;
  }

  .product-description {
    color: #1E3B89;
    line-height: 135%;
    font-size: 13px;
    padding-bottom: 10px;
  }

  .for-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .product-use-for {
      font-size: 13px;
      line-height: 122.56%;
      // width: 120px;
      // height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      color: rgba(30, 59, 137, 0.7);
      background: #E0ECF7;
      border-radius: 11px;

      padding: 5px 10px;

      margin-bottom: 10px;
      margin-right: 15px;
    }
  }


  .product-image {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 13px;
    padding-bottom: 33px;

    img {
      max-width: 140px;
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .more-info {
    width: 100%;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    font-weight: bold;

    background: #00ACE6;
    border-radius: 4px;
    color: #ffffff;
    text-transform: uppercase;
  }
}
</style>
