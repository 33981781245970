<template>
  <div class="user-info-clinical">
    <div class="user-info-block">

      <p class="h1-title">
        <span>Інформація</span>
      </p>

      <div class="hello-text">
        <p>
          <span>Вітаємо!</span><br>
          Будь ласка, заповніть нижче інформацію про себе:
        </p>
      </div>

      <div class="field-wrapper">

        <div class="areas">
          <div v-bind:class="[isOpenDrop === true ? activeClass: '', `select`]">
            <div class="visible-item"
                 @click="openDropList()">

              <div class="border">
                <p>{{ areasField }}</p>
                <div class="arrow-icon">
                  <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M17.5666 0.413574L9.00002 8.58661L0.433411 0.413574"
                            stroke="#AC98C5"
                            stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="18" height="9" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>

            </div>

            <div class="hide-item-list">
              <p v-bind:class="[selectRubric === key.id ? activeClass: '', ``]"
                 @click="selectRubricF(key.id, key.title)"
                 v-for="(key, value) in areas">
                {{ key.title }}
                <span class="icon-check">
                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.9946 2L6.48911 9.49411L3.01253 6.02985L2 5.01178" stroke="#4A2A80"
                                              stroke-width="3"
                                              stroke-miterlimit="10"/>
                                    </svg>
                                </span>
              </p>
            </div>

          </div>
        </div>

        <div class="city">
          <input type="text" v-model="city" placeholder="Населений пункт">
        </div>

        <div class="profession">
          <div v-bind:class="[isOpenDrop2 === true ? activeClass: '', `select`]">
            <div class="visible-item"
                 @click="openDropList2()">

              <div class="border">
                <p>{{ professionField }}</p>
                <div class="arrow-icon">
                  <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M17.5666 0.413574L9.00002 8.58661L0.433411 0.413574"
                            stroke="#AC98C5"
                            stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="18" height="9" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>

            </div>

            <div class="hide-item-list">
              <p v-bind:class="[selectProfession === key.id ? activeClass: '', ``]"
                 @click="selectProfessionF(key.id, key.title)"
                 v-for="(key, value) in profession">
                {{ key.title }}
                <span class="icon-check">
                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.9946 2L6.48911 9.49411L3.01253 6.02985L2 5.01178" stroke="#4A2A80"
                                              stroke-width="3"
                                              stroke-miterlimit="10"/>
                                    </svg>
                                </span>
              </p>
            </div>

          </div>
        </div>

        <div class="agree-wrapper">
          <label class="container">
            <input type="checkbox" v-model="isAgree">
            <span class="checkmark"></span>
          </label>

          <p class="link">Даю згоду на <span @click="openPopUpFromPopUp()">обробку персональних даних</span>
          </p>
        </div>

        <div class="save-btn-wrapper">

          <button class="save-btn" @click="closePopup()" :disabled="!isAgree">
            <p>Зберегти</p>
          </button>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "user-info",
  data: () => ({
    activeClass: "active",
    city: "",

    areasField: "Область",
    selectRubric: null,
    isOpenDrop: false,

    professionField: "Спеціальність",
    selectProfession: null,
    isOpenDrop2: false,

    isAgree: false,

    profession: [],
    areas: []
  }),

  created() {
    this.getArea();
    this.getSpeciality();
    this.getUserData();
  },


  computed: {
    ...mapGetters(['getMenuCtrl'])
  },
  methods: {

    ...mapActions([
      'setMenuData',
      'setPrevPopup'
    ]),
    getArea() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/area").then((res) => {
        this.areas = JSON.parse(res.data);

        this.areas.sort((a, b) => {
          return a.title.localeCompare(b.title)
        });
      })
    },

    getSpeciality() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/speciality/clinical").then((res) => {
        this.profession = JSON.parse(res.data);
      })
    },

    getUserData() {
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/security/participant", {}).then((res) => {
        let data = JSON.parse(res.data)

        this.selectProfession = data.speciality.id;
        this.professionField = data.speciality.title;
        this.selectRubric = data.area.id;
        this.areasField = data.area.title;
        this.city = data.city;
      })
    },

    // system

    selectRubricF(id, name) {
      this.selectRubric = id;
      this.areasField = name;
      this.isOpenDrop = false;
    },

    openDropList() {
      if (this.isOpenDrop !== true) {
        this.isOpenDrop = true;
      } else {
        this.isOpenDrop = false;
      }

    },

    selectProfessionF(id, name) {
      this.selectProfession = id;
      this.professionField = name;
      this.isOpenDrop2 = false;

    },

    openDropList2() {
      if (this.isOpenDrop2 !== true) {
        this.isOpenDrop2 = true;
      } else {
        this.isOpenDrop2 = false;
      }
    },

    closePopup() {
      axios.post(this.$root.globalUrl + "api/v1/security/participant", {
        "area_id": this.selectRubric,
        "code": sessionStorage.getItem('lastCode'),
        "speciality_id": this.selectProfession,
        "city": this.city
      }).then((res) => {
        this.loginStage = "2"

      }).catch((error) => {

      })
      this.setMenuData({popupState: '0'})
    },
    openPopUpFromPopUp() {
      this.setPrevPopup(this.getMenuCtrl.popupState)
      this.setMenuData({popupState: '4'})
    },
  }
}
</script>

<style lang="scss">
@import "../../style/vars";


.user-info-clinical {
  overflow-y: auto;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(115, 89, 130, 0.6);
  width: 100%;
  height: 100%;
  z-index: 51;

  @media screen and (min-width: 426px) {
    position: absolute;
  }

  .user-info-block {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    .h1-title {
      /*margin-bottom: 25px;*/
      border-radius: 4px 4px 0 0;
      padding: 0 14px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F1EDF7;

      span {
        font-size: 12px;
        font-family: $fontB;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #AC98C5;
      }

    }

    .hello-text {
      background-color: #F1EDF7;
      padding: 0 15px 30px 15px;

      p {
        color: #4A2A80;
        font-family: $fontL;
        font-size: 16px;

        span {
          font-family: $fontR;
        }
      }
    }


    .field-wrapper {
      margin-top: 20px;

      .areas {
        .select {
          margin-top: 10px;


          transition: all 0.2s ease;


          .visible-item {
            margin-bottom: 10px;
            cursor: pointer;

            .arrow-icon {
              transition: all 0.2s ease;
            }


            .border {
              padding: 10px 0;
              border-bottom: 1px solid #AC98C5;
              margin: 0 15px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            p {
              color: #AC98C5;
              font-size: 16px;
              font-family: $fontL;
            }


          }

          .hide-item-list {
            padding: 0 15px;
            max-height: 0;
            overflow: hidden;
            transition: all 1s ease;

            p {
              cursor: pointer;

              &:first-child {
                margin-top: 10px;
              }

              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #4A2A80;
              font-family: $fontL;
              font-size: 16px;
              padding-bottom: 30px;

              &:active, &.active {
                font-family: $fontSB;

                span {
                  opacity: 1;
                }
              }

              span {
                transition: all 0.2s ease;
                opacity: 0;
              }
            }
          }

          &.active {
            background-color: #F1EDF7;

            .border {


              .arrow-icon {
                transform: rotate(180deg);
              }
            }

            .hide-item-list {
              max-height: 3000px;

            }
          }

        }
      }

      .city {
        padding: 0 15px;
        margin-top: 30px;

        input {

          width: 100%;
          border: none;
          border-bottom: 1px solid #AC98C5;
          color: #4A2A80;
          padding: 5px 0;
          font-family: $fontL;
          font-size: 16px;
          cursor: text;

          &::placeholder {
            color: #AC98C5;
          }
        }
      }

      .profession {
        margin-top: 30px;
        cursor: pointer;

        .select {
          margin-top: 10px;


          transition: all 0.2s ease;


          .visible-item {
            margin-bottom: 10px;

            .arrow-icon {
              transition: all 0.2s ease;
            }


            .border {
              padding: 10px 0;
              border-bottom: 1px solid #AC98C5;
              margin: 0 15px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            p {
              color: #AC98C5;
              font-size: 16px;
              font-family: $fontL;
            }


          }

          .hide-item-list {
            padding: 0 15px;
            max-height: 0;
            overflow: hidden;
            transition: all 1s ease;

            p {
              &:first-child {
                margin-top: 10px;
              }

              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #4A2A80;
              font-family: $fontL;
              font-size: 16px;
              padding-bottom: 30px;

              &:active, &.active {
                font-family: $fontSB;

                span {
                  opacity: 1;
                }
              }

              span {
                transition: all 0.2s ease;
                opacity: 0;
              }
            }
          }

          &.active {
            background-color: #F1EDF7;

            .border {


              .arrow-icon {
                transform: rotate(180deg);
              }
            }

            .hide-item-list {
              max-height: 3000px;

            }
          }

        }
      }
    }


  }

  .agree-wrapper {
    margin-top: 30px;
    padding: 0 15px;

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      font-family: $fontL;
      color: #1E3B89;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .link {
      padding-left: 35px;
      color: #1E3B89;
      font-family: $fontL;
      font-size: 16px;
      cursor: pointer;
      cursor: pointer;

      span {
        color: #8F67A4;
        text-decoration: underline;

      }
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: white;
      border: 1px solid #8F67A4;
      cursor: pointer;
    }

    /* On mouse-over, add a grey background color */

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #8F67A4;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 2px;
      top: 0;
      width: 4px;
      height: 6px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .save-btn-wrapper {
    margin-top: 30px;
    padding: 0 15px;
    margin-bottom: 20px;

    .save-btn {
      border: none;
      outline: none;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #8F67A4;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      p {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: white;
        font-family: $fontB;
        font-size: 15px;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

}

</style>
