import { render, staticRenderFns } from "./vomiting.vue?vue&type=template&id=7e31f0be&scoped=true&"
import script from "./vomiting.vue?vue&type=script&lang=js&"
export * from "./vomiting.vue?vue&type=script&lang=js&"
import style0 from "./vomiting.vue?vue&type=style&index=0&id=7e31f0be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e31f0be",
  null
  
)

export default component.exports